import React from 'react'
import styled from 'styled-components'
import { Input } from 'antd';

interface TextAreaProps {
  onChange: (e: any) => void,
  value: string,
  placeholder?: string
}

const { TextArea } = Input;

const TextAreaBase = styled(TextArea) <Partial<TextAreaProps>>`
  color: ${(props) => `${props?.theme?.colors?.primary}`};
  font-weight: ${(props) => `${props?.theme?.fontWeights?.medium}`};
  font-size: ${(props) => `${props?.theme?.fontSizes?.h3?.fontSize}`};
  border-radius: 8px;
  padding:20px;
`

function Textarea(props: TextAreaProps) {
  return (
    <TextAreaBase
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      autoSize={{ minRows: 3, maxRows: 5 }}
    />
  )
}

export default Textarea
