import React from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TITLE } from "./constant";
import "./VendorFilter.scss";
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "antd";

const AdditionalTagsFiltersComp = (props: any) => {
  const { Option } = Select;
  const [selectedValues, setSelectedValues] = React.useState({});

  return (
    <div className="additional-filter-wrapper">
      <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" wrap="wrap">
        {Object.entries(props.filterOption).map((filter: any, index: number) => {
          const keyName: string = filter[0];
          const options = filter[1];
          const handleChange = (value: any, keyName: string) => {
            props?.handleAdvancedFilter(value.length ? value.join(",").split(",") : [], keyName);
            setSelectedValues({ ...selectedValues, [keyName]: value.length ? value.join(",").split(",") : [] });
          };
          return options?.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // width: (keyName === "regionServed" || keyName === "customerTier") ?  "10%" : "20%",
                width: props?.width,
                marginRight: "16px",
                marginBottom: "8px",
              }}
            >
              <span>
                {TITLE?.[props?.titleKey]?.[keyName]}
                {/* {`${props?.showTotalTags ? " " + `(` + options?.length + " " + `values)` : ""}`} */}
                {props?.showTotalTags ? ` (${options?.length})` : ""}
              </span>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select"
                onChange={(value) => handleChange(value, keyName)}
                optionLabelProp="label"
                maxTagCount={0}
                autoClearSearchValue={false}
                size="small"
              >
                {options.map((item: any, index: number) => {
                  return selectedValues?.[keyName]?.includes(item) ? (
                    <Option value={item} label={item}>
                      <div className="demo-option-label-item">{item}</div>
                    </Option>
                  ) : (
                    <></>
                  );
                })}
                {options.map((item: any, index: number) => {
                  return !selectedValues?.[keyName]?.includes(item) ? (
                    <Option value={item} label={item}>
                      <div className="demo-option-label-item">{item}</div>
                    </Option>
                  ) : (
                    <></>
                  );
                })}
              </Select>
            </div>
          ) : (
            <></>
          );
        })}
      </Grid>
    </div>
  );
};

export default AdditionalTagsFiltersComp;
