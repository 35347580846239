import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "92vw",
    maxHeight: "72vh",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {},
  container: {
    maxHeight: "50vh",
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 150,
    height: 40,
  },
  extraWide: {
    width: 250,
    height: 40,
  },
  extraWideXL: {
    width: 350,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  serialNumWidth: {
    width: 50,
  },
  jobRequestProperty: {
    width: 300,
  },
  widthForCellWithIcons: {
    maxWidth: "1rem",
    verticalAlign: "top",
  },
  JRIdCell: {
    maxWidth: "8rem",
    verticalAlign: "top",
  },
  JRDesc: {
    maxWidth: "6rem",
    verticalAlign: "top",
    whiteSpace: "normal",
  },
}));
