import React from "react";
import styled from "styled-components";
import { AntdModal } from "../../../components/Modal";

interface DeleteMessageModalProps {
  positiveAction: (value: string) => void;
  negativeAction: (value: string) => void;
  singleAction?: boolean;
}
const DeleteModal = (props: DeleteMessageModalProps) => {
  const renderDeleteModal = () => {
    return <div>Are you sure you want to delete this message?</div>;
  };
  return (
    <AntdModal
      isFooterAvailable
      usedFor="deleteMessage"
      buttonDirectionColumn
      disabled={false}
      variant={"red"}
      negativeButtonColor={"red"}
      positiveActionText={"Delete for ME"}
      negativeActionText={props.singleAction ? "" : "Delete for Everyone"}
      header={" "}
      positiveAction={() => {
        props.positiveAction("all");
      }}
      closable={true}
      body={renderDeleteModal()}
      negativeAction={value => {

        if (value === "onCancel") {
          props.negativeAction("only");
        } else {
          props.negativeAction("close");
        }
      }}
    />
  );
};

export default DeleteModal;
