import React, { useEffect, useState, useRef } from "react";
import {
  RequestForQuotationsContainer,
  FileStatsContainer,
  FileDetailsHeader,
  RFQFilesContainer,
  FileSizeContainer,
  RFQFileCard,
  FileDate,
  FileSize,
  ButtonContainer,
  DownloadAllButton,
  DownloadImg,
  TabContainerLayout,
} from "./requestForQuotations.styles";
import { Checkbox, Empty, Switch } from "antd";
import { formatTime } from "../../../../Utils/DisplayTimeFormat";
import { checkIsVendorRequest, GENERATE_ZIP_FILE_NAME } from "../../jobRequest.helper";
import {
  bytesToMB,
  zipMultipleFilesAndDownload,
  zipSingleFileAndDownload,
} from "../../file.handler";
import { useDispatch, useSelector } from "react-redux";
import { IJobRequestData, IDMSFile } from "container/JobRequests/JobRequestDetails.interfaces";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { JobRequestActions } from "container/JobRequests/actions";
import { FILE_LOG } from "container/JobRequests/Constants/constants";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { handleUploadRFQFiles } from "shared/handlers/jobRequest.handler";
import {  checkHasAnyOfTheRole } from "shared/helpers/checkHasAccess";
import ROLES from "constants/RolesConstants";
import { Flex , Typography} from "venwiz-ui";
import AntdToast from "components/Toast/AntdToast";

export interface IReceivedRFQFiles extends IDMSFile {
  fileToBeDownloaded: boolean;
}

export interface IPublishRfqFiles extends IDMSFile {
  status: boolean;
}

export const RequestForQuotations = () => {
  const uploadFileRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const { jobRequestDetails }: { jobRequestDetails: IJobRequestData } = useSelector((state: any) => state.ClientRequestReducer);
  const { isLoading } = useSelector((state: any) => state.LoaderReducers);
  const jrMongoId = jobRequestDetails?._id;
  const jobRequestId = jobRequestDetails?.requestId;
  const zipFileName = GENERATE_ZIP_FILE_NAME(jobRequestId);
  const [isZipping, setIsZipping] = useState(false);
  const [reloadUpdate, setReloadUpdate] = useState(false);
  const [receivedRFQFiles, setReceivedRFQFiles] = useState<IReceivedRFQFiles[]>([]);
  const [publishRfqFIles, setPublishRfqFiles] = useState<IReceivedRFQFiles[]>([]);
  const [filesToBeZipped, setFilesToBeZipped] = useState<IReceivedRFQFiles[] | []>([]);
  const hasAccessToUpload =
    checkIsVendorRequest(jobRequestDetails.requestType) && checkHasAnyOfTheRole([ROLES.CLIENT_VR_EDIT_PUBLISH]);

  const prepareSelectedFilesToBeZipped = () => {
    const selectedMultipleFiles = receivedRFQFiles.filter((filterObj) => filterObj?.fileToBeDownloaded === true);
    setFilesToBeZipped(selectedMultipleFiles);
  };

  const prepareReceivedRFQFiles = (jobRequestData: IJobRequestData) => {
    setReceivedRFQFiles(
      jobRequestData?.jobRfq?.map((fileObj: any) => ({
        ...fileObj,
        fileToBeDownloaded: false,
        publishFile: fileObj.isAdminPublished && fileObj.publishVersion !== -1
      })) || []
    );
  }

  useEffect(() => {
    if (reloadUpdate) {
      // get uploaded rfqDocuments
      dispatch(ClientRequestActions.getJobRequestData(jrMongoId)).then((resp: any) => {
        const { jobRequests } : { jobRequests: IJobRequestData[] }  = resp;
        prepareReceivedRFQFiles(jobRequests[0]);
        setReloadUpdate(false);
      });
    } else {
      prepareReceivedRFQFiles(jobRequestDetails);
    }
  }, [reloadUpdate]);

  useEffect(() => {
    prepareSelectedFilesToBeZipped();
  }, [receivedRFQFiles]);

  const areMultipleFilesSelected = receivedRFQFiles.filter((fileObj) => fileObj.fileToBeDownloaded === true).length > 1;

  const handleToggleCheckbox = (selectedFileObj: IDMSFile) => {
    setReceivedRFQFiles((prev) =>
      prev.map((stateFileObj) =>
        stateFileObj?.files?.[0]?.file?.fileUrl !== selectedFileObj?.files?.[0]?.file?.fileUrl
          ? stateFileObj
          : {
              ...stateFileObj,
              fileToBeDownloaded: !stateFileObj.fileToBeDownloaded,
            }
      )
    );
  };

  const handleToggleForPublish = (selectedFileObj: IDMSFile, status: boolean) => {
    setReceivedRFQFiles((prev) =>
      prev.map((stateFileObj) =>
        stateFileObj?.files?.[0]?.file?.fileUrl !== selectedFileObj?.files?.[0]?.file?.fileUrl
          ? stateFileObj
          : {
              ...stateFileObj,
              publishFile: status,
              modified: selectedFileObj.publishFile === (selectedFileObj.isAdminPublished && selectedFileObj.publishVersion !== -1)
            }
      )
    );
  };

  const handleDownloadAllSelectedFiles = (
    zipFileName: string,
    files: IReceivedRFQFiles[],
    setIsZipping: React.Dispatch<React.SetStateAction<boolean>>,
    isZipping: boolean
  ) => {
    if (!isZipping) {
      zipMultipleFilesAndDownload("", zipFileName, files, setIsZipping);
      dispatch(JobRequestActions.updateLastActivityDate(jobRequestId));
    }
  };

  const handleOnClickUploadButton = () => {
    uploadFileRef?.current?.click();
  };

  useEffect(() => {
    prepareReceivedRFQFiles(jobRequestDetails);
  }, [jobRequestDetails?.jobRfq]);

  const onSingleFileDownload = (fileObj:any) => {
    zipSingleFileAndDownload(fileObj);
    dispatch(JobRequestActions.updateLastActivityDate(jobRequestId));
  }

  const getFileState = (fileObj: IReceivedRFQFiles) => {
    switch(true) {
      case !fileObj?.isFinal: return "Draft";
      case fileObj.activeVersion !== fileObj.publishVersion && fileObj?.isFinal: return "Final";
      case (fileObj.publishVersion !== -1 && !fileObj.isAdminPublished): return "Client Published"
      case (fileObj.publishVersion !== -1 && fileObj.isAdminPublished): return "Published";
    }
  }

  const onFileUpload = () => {
    setReloadUpdate(true);
    AntdToast({ type: "success", message: "Success", description: "RFQ UPloaded and Published"});
  }

  const publishRFQ = () => {
    let files: any[] = [];
    receivedRFQFiles.map((file) => {
      if(file.modified)
      files.push({fileName: file.fileName, status: file.publishFile})
    })
    const payload = {
      files,
      jobRequestId,
      docType: "rfq"
    }
    dispatch(JobRequestActions.publishDocs(payload)).then(() => {
      setReloadUpdate(true);
      AntdToast({ type: "success", message: "Success", description: "Published RFQ Changes"});
    }).catch(() => {
      AntdToast({ type: "error", message: "Error", description: "Something went wrong"});
    })
  }


  return (
    <>
    <Flex>
      <ButtonContainer>
            <Button
              variant="outlined"
              disabled={isLoading || isZipping}
              onClick={publishRFQ}
            >
                Publish RFQ
            </Button>
        </ButtonContainer>
        {hasAccessToUpload && (
          <ButtonContainer>
            <Button
              variant="outlined"
              disabled={isLoading || isZipping}
              onClick={handleOnClickUploadButton}
              startIcon={isLoading ? <CircularProgress size="18px" /> : <FileUploadOutlinedIcon fontSize="large" />}
            >
              {isLoading ? "Uploading..." : "Upload"}
            </Button>
          </ButtonContainer>
        )}
    </Flex>
    
      <TabContainerLayout>
        <RequestForQuotationsContainer>
          {receivedRFQFiles?.length === 0 ? (
            <Empty description="No RFQ documents!" />
          ) : (
            <>
              <FileDetailsHeader>
                File Details
                {areMultipleFilesSelected && (
                  <ButtonContainer>
                    <DownloadAllButton
                      onClick={() => {
                        handleDownloadAllSelectedFiles(zipFileName, filesToBeZipped, setIsZipping, isZipping);
                      }}
                    >
                      <DownloadImg src="/images/download-blue.svg" alt="download-icon" marginRight="4px" />
                      {isZipping ? "Downloading..." : "Download"}
                    </DownloadAllButton>
                  </ButtonContainer>
                )}
              </FileDetailsHeader>
              <RFQFilesContainer>
                {receivedRFQFiles.map((fileObj) => (
                  <RFQFileCard key={fileObj?.fileName}>
                    <Checkbox
                      checked={fileObj.fileToBeDownloaded}
                      value={fileObj?.fileName}
                      onChange={() => handleToggleCheckbox(fileObj)}
                    >
                      {fileObj.newFileName || fileObj.fileName}
                     <Typography variant="span" color={"#215ec9"} fontWeight={"600"}>{`(${getFileState(fileObj)})`}</Typography> 
                     <Switch defaultChecked={fileObj.publishFile} style={{ marginLeft: "10px" }} onChange={(state: boolean) => {
                      handleToggleForPublish(fileObj, state)
                  }
                    }/>
                    </Checkbox>
                    <FileStatsContainer>
                      <FileDate>{formatTime(fileObj?.updatedAt, "hh:mm a Do MMM YYYY")}</FileDate>
                      <FileSizeContainer>
                        <FileSize>{`${bytesToMB(fileObj?.files?.[0]?.file?.fileSize)} MB`}</FileSize>
                        <DownloadImg
                          onClick={() => {onSingleFileDownload(fileObj)}}
                          src="/images/download-blue.svg"
                          alt="download-icon"
                        />
                      </FileSizeContainer>
                    </FileStatsContainer>
                  </RFQFileCard>
                ))}
              </RFQFilesContainer>
            </>
          )}
        </RequestForQuotationsContainer>
        <input
          hidden
          multiple
          type="file"
          ref={uploadFileRef}
          onInput={(e: any) =>
            handleUploadRFQFiles(e?.target?.files, jobRequestDetails?.requestId, jobRequestDetails?.jobRfq, dispatch, onFileUpload)
          }
        />
      </TabContainerLayout>
    </>
  );
};
