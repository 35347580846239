import DateFnsUtils from "@date-io/date-fns";
import {
  Backdrop,
  Button,
  CircularProgress,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import * as moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ClientInfoUpdate.scss";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../NoAccess/NoAccess";

const useStyles = makeStyles((theme) => ({
  peopleInfoContainer: {
    padding: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  subHeader: {
    color: "#66759c",
    fontWeight: 600,
    float: "left",
  },
  editAddFields: {
    marginTop: "15px",
    color: "#8fc9f9",
    fontWeight: 400,
    fontSize: "13px",
  },
  editButton: {
    backgroundColor: "#06c396",
    color: "#fff",
  },
  splitLine: {
    borderTop: "1px solid #17a2b8",
    width: "112%",
    position: "relative",
    right: "44px",
  },
}));

const ClientInfoUpdate = () => {
  const [loader, enableLoader] = React.useState(false);
  const [vendorEditable, setVendorEditable] = React.useState(false);
  const [reloadUpdate, setReloadUpdate] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({});
  const { clientId } = useParams();
  const [hasAccess, setHasAccess] =React.useState(true);

  const updateClient = (clientId, userInfo) => {
    // updateCompanyInfo(gstn, companyInfo);
    let payload = {
      fullName: userInfo?.fullName,
      roleInCompany: userInfo?.roleInCompany,
    };
    updateClientInfo(clientId, payload);
  };

  const updateClientInfo = async (updateId, payload) => {
    const result = await axios.put(
      process.env.REACT_APP_BASE_URL +
        "/client-onboarding/updateUser/" +
        updateId,
        payload,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
    );
    if (result.data.status === "SUCCESS") {
      setReloadUpdate(true);
      setVendorEditable(false);
    } else {
      setReloadUpdate(true);
      setVendorEditable(false);
    }
  };

  const enableEdit = () => {
    setVendorEditable(true);
  };

  const cancelEdit = () => {
    setReloadUpdate((update) => !update);
    setVendorEditable(false);
  };

  useEffect(async () => {
    enableLoader(true);
    await axios.get(
      process.env.REACT_APP_BASE_URL + "/client-onboarding/user/" + clientId,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    ).then((result) => {
      if (result.data.status == "SUCCESS") {
        const resultData = result.data.data;
        const fullName =
          (resultData?.firstName || "") + " " + (resultData?.lastName || "");
        setUserInfo({ ...resultData, fullName: fullName });
        setReloadUpdate(false);
      }
  
      enableLoader(false);
    })
    .catch((error) => {
      if(error.response.status === 403){
        setHasAccess(false);
      } 
    });;
    
  }, [reloadUpdate]);

  const classes = useStyles();
  return (
    hasAccess ?
    <div className="container-fluid">
      <div className={classes.peopleInfoContainer}>
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="row">
          <div className="col">
            <InputLabel className={classes.subHeader}>Full Name</InputLabel>
            <TextField
              id="1"
              value={userInfo?.fullName || ""}
              variant="outlined"
              disabled={!vendorEditable}
              onChange={(e) => {
                setUserInfo((data) => ({
                  ...data,
                  fullName: e.target.value,
                }));
              }}
              fullWidth
            />
          </div>
          <div className="col">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="col">
                <InputLabel className={classes.subHeader}>
                  Created Date
                </InputLabel>
                <KeyboardDatePicker
                  margin="normal"
                  id="projStartDate"
                  format="dd-MM-yyyy"
                  fullWidth
                  disabled={true}
                  variant="outlined"
                  value={
                    userInfo?.createdAt
                      ? moment(userInfo?.createdAt, "YYYY-MM-DD")
                          .utc(true)
                          .toDate()
                      : new Date()
                  }
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <InputLabel className={classes.subHeader}>Phone Number</InputLabel>
            <TextField
              id="5"
              value={userInfo?.phoneNumber || ""}
              variant="outlined"
              disabled={true}
              fullWidth
            />
          </div>
          <div className="col">
            <InputLabel className={classes.subHeader}>Email</InputLabel>
            <TextField
              id="gstn"
              value={userInfo?.email || ""}
              variant="outlined"
              disabled={true}
              fullWidth
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <InputLabel className={classes.subHeader}>
              Role in Company
            </InputLabel>
            <TextField
              id="6"
              value={userInfo?.roleInCompany || ""}
              variant="outlined"
              disabled={!vendorEditable}
              onChange={(e) => {
                setUserInfo((data) => ({
                  ...data,
                  roleInCompany: e.target.value,
                }));
              }}
              fullWidth
            />
          </div>
          <div className="col"></div>
        </div>

        <div className="row mt-4">
          <div className="col text-right">
            {vendorEditable ? (
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                onClick={() => updateClient(clientId, userInfo)}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                className="editButton"
                startIcon={<EditIcon />}
                onClick={enableEdit}
              >
                Edit
              </Button>
            )}
            <Button
              variant="contained"
              className="ml-2 cancelButton"
              startIcon={<ClearIcon />}
              onClick={cancelEdit}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
        :
        <NoAccess></NoAccess>
  );
};

export default ClientInfoUpdate;
