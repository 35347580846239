import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
`;

export const DropdownContainer = styled.div<{color: string, bgColor: string }>`
  width: 300px ;
  background-color: ${props => props?.bgColor };
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.16);

  //changing color for selected option
  & .MuiOutlinedInput-root .MuiAutocomplete-input {
    color: ${props => props?.color };
    z-index:2 ;
  }
  //changing color for dropdown icon
  & .css-i4bv87-MuiSvgIcon-root {
    color: ${props => props?.color };
  }
  & .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
    color: ${props => props?.color };
  }
  & .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color:${props => props?.color };
  }
  //hiding the space occupied by floating label(placeholder)
  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }
    //hiding the floating label(placeholder)
  & .css-186xcr5{
    display: none ;
  }
  & .MuiOutlinedInput-root .MuiAutocomplete-endAdornment{
    z-index: 2;
  }
  & .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    background-color: ${props => props?.bgColor };
    border: none;
    border-radius: 0;
  }
`;
