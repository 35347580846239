import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import ApiClient from "../_services/ApiService";
import createMiddleware from "../store/clientMiddleware";

const createHistory = require("history").createBrowserHistory;

export const history = createHistory();

const client = new ApiClient();
const enhancers = [];
const middleware = [createMiddleware(client), thunk];

function saveToLocalStorage(state) {
  const serializedState = JSON.stringify(state);
  localStorage.setItem('state', serializedState);
}

function loadFromLocalStorage() {
const serializedState = localStorage.getItem('state');
if (serializedState === null) return undefined;
 return JSON.parse(serializedState);
}

if (process.env.NODE_ENV === "development") {
	const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window;
  
	if (typeof __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function") {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION_COMPOSE__());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const presistedState = loadFromLocalStorage();

const store = createStore(rootReducer, presistedState, composedEnhancers);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
