import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import clsx from "clsx";
import moment from "moment";
import { IconButton, TableBody, TableContainer, TableSortLabel } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@material-ui/icons/Done";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./VendorRequests.scss";
import { useHistory, useLocation } from "react-router-dom";
import { DropdownSelect } from "container/JobRequests/components/JobStatusSelect";
import { Empty } from "antd";
import { Tabs, Typography } from "antd";
import { formatTime, getDateFromUTC } from "Utils/DisplayTimeFormat";
import { FiltersDropDown } from "components/Filters/FiltersDropDown";
import NoAccess from "components/NoAccess/NoAccess";
import VendorRequestModal from "components/RequestModal/VendorRequestModal";
import SnackBarNotification from "components/SnackBar/SnackBar";
import AntdToast from "components/Toast/AntdToast";
import { useStyles } from "./VendorRequestsListing.styles";
import VendorFilter from "components/VendorFilter/VendorFilter";
import ROLES from "constants/RolesConstants";
import { JobStatusOptions, JOB_STATUS } from "container/JobRequests/Constants/constants";
import { getSharableJobLink, getCsvHeaders, jobReqListingHeaderNames, rfqListingHeaderNames, isRFQRoute, checkIsVendorRequest, getJobTitleFromDescp, checkIfSelectedCompanyIsVenwiz } from "container/JobRequests/jobRequest.helper";
import SessionStorage from "_services/SessionStorage";
import { ClientRequestActions } from "../actions";
import { checkHasAnyOfTheRole } from "shared/helpers/checkHasAccess";
import { mapSource } from "Utils/common";
const { TabPane } = Tabs;
const { Title } = Typography;

/*
  This component is used for both VR & RFQ/eRfx listing
*/
const VendorRequests = () => {
  const history = useHistory();
  const location = useLocation();
  const _lodash = require("lodash");
  const classes = useStyles();
  const dispatch = useDispatch();
  const sessionFilters = SessionStorage.get("job-request-date-filters");
  const [startDate, setStartDate] = useState(sessionFilters ? sessionFilters.startDate : moment().subtract(3, "months"));
  const [endDate, setEndDate] = useState(sessionFilters ? sessionFilters.endDate : moment(new Date()));
  const [displayJobReqForm, setDisplayJobReqForm] = useState(false);
  const [jobsListingData, setJobsListingData] = useState([]);
  const [open, setOpen] = useState(false);
  const [reloadUpdate, setReloadUpdate] = useState(false);
  const [snackbarState, setSnackBarState] = useState({ show: false, messege: "", severity: "error" });
  const [hasAccess, setHasAccess] = useState(true);
  const [reqModalData, setReqModaldata] = useState([]);
  const [order, setOrder] = useState<"desc" | "asc" | undefined>();
  const [orderBy, setOrderBy] = useState();
  const [isExportButtonVisible, setExportButtonVisible] = useState(false);
  const [hasUserCopiedShareableLink, setHasUserCopiedShareableLink] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");

  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filterDropdownVisibility, setFilterDropdownVisibility] = useState(false);

  const showExport = checkHasAnyOfTheRole([ROLES.CLIENT_VR_VIEW_EXPORT]);
  const hasVREditPublishAccess = checkHasAnyOfTheRole([ROLES.CLIENT_VR_EDIT_PUBLISH]);

  const { vendorRequestData, vendorRequestCSVData, selectedCompany, selectedProject } = useSelector((state: any) => state.ClientRequestReducer);

  const isRFQListing = isRFQRoute(location.pathname);

  useEffect(() => {
    dispatch(ClientRequestActions[isRFQListing ? "resetRFQListingPageData" : "resetVRListingPageData"]());

    return () => {
      dispatch(ClientRequestActions[isRFQListing ? "resetRFQListingPageData" : "resetVRListingPageData"]());
    };
  }, []);

  useEffect(() => {
    setOpen(true);
    const from = moment(startDate).format("YYYY-MM-DD");
    const to = moment(endDate).format("YYYY-MM-DD");
    if (isRFQListing) {
      dispatch(ClientRequestActions.getRFQForProject(selectedProject?.id, from, to))
      .then(() => setOpen(false), () => setOpen(false))
    } else {
      dispatch(ClientRequestActions.getVendorRequestForCompany(selectedCompany?.value, from, to))
      .then(() => setOpen(false), () => setOpen(false))
      dispatch(ClientRequestActions.getVendorRequestCsvDataForCompany(selectedCompany?.value, from, to));
    }
  }, [startDate, endDate, reloadUpdate, selectedProject, selectedCompany]);

  useEffect(() => {
   if(vendorRequestCSVData?.length) {
    // Job Request Date&Time - date to 24 Hour IST
    vendorRequestCSVData.map((request: any) => {
      request.requestedOn = formatTime(request.requestedOn, "YYYY-MM-DD HH:mm:ss")
    })
    setExportButtonVisible(true);
   }
  }, [vendorRequestCSVData]);

  useEffect(() => {
    setJobsListingData(vendorRequestData || []);
  }, [vendorRequestData]);

  const getCsvFileName = (startDate: Date, endDate: Date) => {
    return moment(startDate).format("YYYY-MM-DD") + "/" + moment(endDate).format("YYYY-MM-DD") + "-jobrequests.csv";
  };

  const renderTabTitle = (title: string) => (
    <Title level={4} style={{ margin: 0 }}>
      {title}
    </Title>
  );

  const handleFilterChange = (from: any, to: any) => {
    setStartDate(from);
    setEndDate(to);
  };

  const reload = () => {
    setReloadUpdate((itr) => !itr);
  };

  const handleAction = () => {
    setDisplayJobReqForm(false);
    setReqModaldata([]);
  };
  const sortBy = (keyName: any) => {
    setJobsListingData(_lodash.orderBy(jobsListingData, keyName, order === "desc" ? "desc" : "asc"));
  };

  const handleSortRequest = (cellId: any) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handleViewDetails = (rowId: any) => {
    const jobRequestFiltersObj = { startDate, endDate };
    SessionStorage.set("job-request-date-filters", jobRequestFiltersObj);
    history.push(`/job-requests/${rowId}`);
  };

  const handleShowSnackbar = (flag: boolean) => {
    setSnackBarState((prev) => ({ ...prev, show: flag }));
  };

  const handleCopySharableLink = (rowId: any) => {
    navigator.clipboard.writeText(getSharableJobLink(rowId));
    setHasUserCopiedShareableLink(true);
    setSelectedRowId(rowId);
  };

  const handleResetCopyIcon = () => {
    setHasUserCopiedShareableLink(false);
    setSelectedRowId("");
  };

  const filterData = (jobsListingData: any, appliedFilters: any) => {
    if (appliedFilters.length) {
      const filteredData = jobsListingData.filter(({ jobStatus }: { jobStatus: JOB_STATUS }) => appliedFilters.includes(jobStatus.toLowerCase()));
      return filteredData;
    } else {
      return jobsListingData;
    }
  };

  const filteredValue = useMemo(() => filterData(jobsListingData, appliedFilters), [jobsListingData, appliedFilters]);
  const onJobStatusChange = (jobStatus: any, requestId?: string) => {
    /** API - Job Requests status update */
    setOpen(true);
    dispatch(ClientRequestActions.updateJobStatus({ requestId, jobStatus }))
      .then(() => {
        AntdToast({ type: "success", message: "Success", description: "Job status updated" });
        setReloadUpdate(true);
      })
      .catch(() => {
        setOpen(false);
        AntdToast({ type: "error", message: "Error", description: "Failed to update Job status" });
      });
  };

  if (!hasAccess) {
    return <NoAccess />;
  }

  return (
    hasAccess && (
      <div>
        {displayJobReqForm && (
          <VendorRequestModal
            data={reqModalData}
            negativeAction={() => {
              handleAction();
            }}
            positiveAction={() => {
              handleAction();
              reload();
            }}
          />
        )}
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <SnackBarNotification
          openNotification={snackbarState.show}
          closeSnackbar={() => handleShowSnackbar(false)}
          severity={snackbarState.severity}
          message={snackbarState.messege}
        ></SnackBarNotification>
        <VendorFilter
          className="filter"
          startDate={startDate}
          endDate={endDate}
          handleFilterChange={handleFilterChange}
          showSearch={false}
          showCsv={showExport && isExportButtonVisible}
          csvHeaders={getCsvHeaders()}
          csvData={vendorRequestCSVData}
          csvFileName={getCsvFileName(startDate, endDate)}
          onHandleRequest={() => setDisplayJobReqForm(true)}
          enableRequestButton={!isRFQListing && hasVREditPublishAccess}
          width="50%"
          filterLocation="vendor_request"
        />
        {jobsListingData && (
          <Paper className={classes.root} elevation={2}>
            <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-label="sticky table">
                <TableHead>
                  <TableRow style={{ alignSelf: "flex-start", justifySelf: "flex-start" }}>
                    {(isRFQListing ? rfqListingHeaderNames : jobReqListingHeaderNames).map((item, indx) => {
                      return (
                        <TableCell key={indx} variant="head" style={{ verticalAlign: "top" }} align="center">
                          {item.isSortingEnabled ? (
                            <div className="job_status_wrapper">
                              <TableSortLabel
                                active={orderBy === item?.key}
                                direction={orderBy === item?.key ? order : "asc"}
                                onClick={() => {
                                  sortBy(item?.key);
                                  handleSortRequest(item?.key);
                                }}
                              >
                                {item?.label}
                              </TableSortLabel>
                              {item?.label === "Job Status" && (
                                <FiltersDropDown
                                  filterValues={appliedFilters}
                                  setShowDropDown={setFilterDropdownVisibility}
                                  showDropDown={filterDropdownVisibility}
                                  filterOptions={JobStatusOptions.map((opt) => ({
                                    filterType: opt,
                                    filterValue: opt,
                                  }))}
                                  onFiltering={setAppliedFilters}
                                  filterBackgroundColor="transparent"
                                  filterColor="#215EC9"
                                  width="16px"
                                  height="8px"
                                  marginBottom="1.5px"
                                  counterWidthNHeight="15px"
                                />
                              )}
                            </div>
                          ) : (
                            item.label
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredValue?.map((row: any, index: number) => (
                    <TableRow key={row._id} style={{ maxHeight: "50px" }}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={clsx(classes.extraWide, classes.JRIdCell)}
                      >
                        {/* Job Request Id */}
                        {row?.requestId}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ verticalAlign: "top" }}
                        className={clsx(classes.extraWide)}
                      >
                        {/* Job Status */}
                        {(checkIsVendorRequest(row?.requestType) ||  checkIfSelectedCompanyIsVenwiz(selectedCompany)) && hasVREditPublishAccess ? (
                          <DropdownSelect
                            selectedOption={row?.jobStatus}
                            onSelect={onJobStatusChange}
                            id={row?._id}
                            options={JobStatusOptions}
                          />
                        ) : (
                          row?.jobStatus
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.extraWide}
                        align="center"
                        style={{ verticalAlign: "top" }}
                      >
                        {/* Client Name */}
                        {row?.clientName}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.extraWide}
                        align="center"
                        style={{ verticalAlign: "top" }}
                      >
                        {/* Job Request Date&Time */}
                        {formatTime(row?.requestedOn, "YYYY-MM-DD HH:mm:ss")}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.extraWide}
                        align="center"
                        style={{ verticalAlign: "top" }}
                      >
                        {/* Job title */}
                        {row?.title
                          ? row.title.length > 50
                            ? row.title.slice(0, 48) + "..."
                            : row.title
                          : getJobTitleFromDescp(row?.description, 50)}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.extraWide}
                        align="center"
                        style={{ verticalAlign: "top" }}
                      >
                        {/* Admin Updated On */}
                        {getDateFromUTC(row?.lastSaved)}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.extraWide}
                        align="center"
                        style={{ verticalAlign: "top" }}
                      >
                        {/* Source */}
                        {mapSource(row?.source)}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                        style={{ verticalAlign: "top" }}
                      >
                        <span
                          className={
                            row?.status === "Published"
                              ? "status_published"
                              : row?.status == "InProgress"
                              ? "status_in_progress"
                              : row?.status == "Pending"
                              ? "status_pending"
                              : "status_modified"
                          }
                        >
                          {/* STATUS */}
                          {row?.status?.toUpperCase()}
                        </span>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={clsx(classes.extraWide, classes.widthForCellWithIcons)}
                        align="center"
                      >
                        {/* Shareble Link */}
                        <IconButton
                          color="primary"
                          onClick={() => handleCopySharableLink(row?.requestId)}
                          onMouseLeave={handleResetCopyIcon}
                        >
                          {hasUserCopiedShareableLink && selectedRowId === row?.requestId ? (
                            <DoneIcon />
                          ) : (
                            <ContentCopyIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={clsx(classes.extraWide, classes.widthForCellWithIcons)}
                        align="center"
                      >
                        {/* View Details Link */}
                        <IconButton color="primary" onClick={() => handleViewDetails(row._id)}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {filteredValue.length === 0 && (
                <div className="job_status_wrapper">
                  <Empty description={"No Job Requests Found"} />
                </div>
              )}
            </TableContainer>
          </Paper>
        )}
      </div>
    )
  );
};

export default VendorRequests;
