export const QUOTATION_FILES_SUPPORTED_TYPES: Array<string> = [
  "dwg",
  "vnd",
  "dwg",
  "autocad_dwg",
  "jpeg",
  "jpg",
  "png",
  "tiff",
  "bmp",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "txt",
  "pdf",
  "zip",
  "rar",
  "stp",
  "csv",
];

export const FORM_DATA_KEY = "files[]";