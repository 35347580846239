import { useSelector } from "react-redux";
import { IJobRequestData } from "../JobRequestDetails.interfaces";

export const useCheckVendorRemovedByClient = () => {
  const { jobRequestDetails }: { jobRequestDetails: IJobRequestData } = useSelector(
    (state: any) => state.ClientRequestReducer
  );

  const checkVendorRemovedByClient = (value: string): boolean =>
    jobRequestDetails.vendorRemovedByClient?.some(
      (vendorObj: any) => vendorObj?.email === value || vendorObj?.gstn === value || vendorObj?.companyName === value
    );

  return { checkVendorRemovedByClient };
};
