import {
  IMachine,
  IPlantMachineDetail,
} from "../ManufacturingTable/ManufacturingTable.interface";

/**
 * for Add/Edit Plant Form via modal
 */

export const FORMIK_PLANT = {
  INITIAL_STATE: {
    location: {
      city: "",
      state: "",
    },
    totalArea: {
      value: null,
      unit: "",
    },
    workshopArea: {
      value: null,
      unit: "",
    },
    capacity: {
      value: null,
      unit: "",
    },
    otherInfo: "",
    machines: []
  } as Partial<IPlantMachineDetail>,
  INITIAL_ERRORS: {
    location: {
      city: "",
      state: "",
    },
    totalArea: {
      value: "",
      unit: "",
    },
    workshopArea: {
      value: "",
      unit: "",
    },
    capacity: {
      value: "",
      unit: "",
    },
    otherInfo: "",
  },
  /** Add/Edit Plant Details Schema */
  IS_VALID_SCHEMA: (plant: IPlantMachineDetail) => {
    const isValidMeasurement = (measurement: {
      value: number | null;
      unit: string;
    }) => {
      if (measurement?.value && measurement?.unit) return true;
      else if (!measurement?.value && !measurement?.unit) return true;
      return false;
    };
    const IS_VALID_CITY = plant?.location?.city?.length > 0;
    const IS_VALID_TOTAL_AREA = isValidMeasurement(plant?.totalArea);
    const IS_VALID_WORKSHOP_AREA = isValidMeasurement(plant?.workshopArea);
    const IS_VALID_CAPACITY = isValidMeasurement(plant?.capacity);
    const IS_FORM_VALID =
      IS_VALID_CITY &&
      IS_VALID_TOTAL_AREA &&
      IS_VALID_WORKSHOP_AREA &&
      IS_VALID_CAPACITY;

    return {
      IS_FORM_VALID,
      IS_VALID_CITY,
      IS_VALID_TOTAL_AREA,
      IS_VALID_WORKSHOP_AREA,
      IS_VALID_CAPACITY,
    };
  },
};

/**
 * for Add/Edit Machine Form via modal
 */
export const FORMIK_MACHINE = {
  INITIAL_STATE: {
    process: "",
    name: "",
    number: null,
    modelName: "",
    make: "",
    otherInfo: "",
    specification: "",
  } as IMachine,
  INITIAL_ERRORS: {
    process: "",
    name: "",
  },

  /** Add/Edit Machine Details Schema */
  IS_VALID_SCHEMA: (machine: IMachine) => {
    const IS_VALID_MACHINE_NAME = Boolean(machine?.name?.length > 0);
    const IS_FORM_VALID = IS_VALID_MACHINE_NAME;

    return { IS_FORM_VALID, IS_VALID_MACHINE_NAME };
  },
};

/**
 * Machine Process and Name Relation dropdown options
 */

export const MACHINE_PROCESS_AND_NAME_RELATION = {
  /** "process_name": [ ...machine_names ] */
  Cutting: [
    "Plasma cutting machine(CNC)",
    "Plasma cutting machine(Non CNC)",
    "Laser cutting machine(CNC)",
    "Laser cutting machine(Non CNC)",
    "Waterjet cutting machine",
    "Shearing machine",
    "Band saws",
    "Profile cutting machine",
    "Power Hacksaw machine",
    "Planer/Shaper machine",
  ],
  "Bending/ Rolling": [
    "Press brake machine(CNC)",
    "Press brake machine(Non CNC)",
    "Tube and pipe bending machine",
    "Plate rolling machine",
    "Angle rolling machine",
  ],
  Welding: [
    "TIG/ Gas tungsten arc welding (GTAW) machines",
    "MIG/ Gas metal arc welding (GMAW) machines",
    "Shielded metal arc welding (SMAW) machine",
    "Flux-cored arc welding (FCAW) machine",
    "Spot welding machine",
    "Stud welding machine",
    "Submerged arc welding (SAW) machines",
    "Projection(Resistance) welding machine",
    "Column & Boom Welding Machine",
    "H beam welding machine",
  ],
  Drilling: [
    "Radial Drilling machine",
    "Magnetic Drilling machine",
    "Portable Drilling machine",
    "Hand drill machine",
    "Pillar drill machine",
  ],
  Machining: [
    "Lathe machine",
    "Milling machine",
    "Vertical Machining Center(VMC)",
    "Horizontal Machining Center(HMC)",
    "Grinding machine",
    "Boring machine",
    "Turning machine(CNC)",
    "Turning machine(Non CNC)",
    "Spinning machine",
    "Jig Boring machine",
    "EDM(Electrical Discharge Machining) machines",
  ],
  Stamping: ["Stamping Power Press", "Blanking Press", "Punching machine"],
  Polishing: [
    "Shot Blasting facility",
    "Magnetic Deburring machine",
    "Vibratory Finishing machine",
    "Sandblasting facility",
    "Buffing machine",
  ],
  Other: ["Painting facility", "Powder Coating facility"],
  "Auxiliary/ Non process": [
    "EOT/ Crane",
    "Welding Rotators",
    "Forklift",
    "Chain Pulley Blocks",
    "Mobile Cranes",
  ],
} as const;

export const ALL_AVAILABLE_MACHINE_NAMES =
  Object.values(MACHINE_PROCESS_AND_NAME_RELATION)
    ?.flat()
    ?.sort((a, b) => a.localeCompare(b)) || [];

export const SORTED_MACHINE_PROCESS_NAMES =
  Object.keys(MACHINE_PROCESS_AND_NAME_RELATION).sort((a, b) =>
    a.localeCompare(b)
  ) || [];

/**
 * Plant Dropdown Options
 */
export const PLANT_DROPDOWN_OPTIONS = {
  AREA: [
    { value: "sqft", label: "sqft" },
    { value: "sqm", label: "sqm" },
    { value: "sqkm", label: "sqkm" },
    { value: "acres", label: "acres" },
    { value: "hectares", label: "hectares" },
    { value: "other", label: "other" },
  ],
  CAPACITY: [
    { value: "MT/month", label: "MT/month" },
    { value: "MT/year", label: "MT/year" },
    { value: "other", label: "other" },
  ],
};

export const INDIAN_STATES = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
