import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLogin } from "../Utils/Storage";

const PrivateRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
