import { Types as LoaderTypes } from "../../../_types/LoaderTypes";

const initialState = {
  isLoading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LoaderTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
