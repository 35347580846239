export const tabTypes = ["NewEquipment", "NewService", "NewOEM"] as const;

export const tagsItems = [
  "equipmentName",
  "oemName",
  "serviceName",
  "synonyms",
  "createdDate",
  "updatedDate",
  "vendorsListCount",
] as const;

export const END_POINTS = {
  TAGS: {
    SERVICE_GROUPS_LIST: "/v1/tags/serviceGroupList",
    EQUIPMENT_GROUPS_LIST: "/v1/tags/equipGroupList",
    EQUIPMENT_FAMILY_LIST: "/v1/tags/equipFamilyList",
  },
  UPDATE_BULK_PROFILE: {
    TAGS: "/v1/tags/bulkUpdateTags",
    GROUPS: "/v1/tags/bulkUpdateGroups",
  },
} as const;

export const NEO4J_END_POINTS = {
  EQUIPMENT: {
    GROUPS: "/v1/tags/getEquipGroups",
    FAMILIES: "/v1/tags/getEquipFamilies",
    TAGS: "/v1/tags/getEquipTags",
  },
  SERVICE: {
    GROUPS: "/v1/tags/getServiceGroups",
    TAGS: "/v1/tags/getServiceTags",
  },
  OEM: {
    TAGS: "/v1/tags/getOemTags",
  },
} as const;

export const ADD_NEW_NEO4J_TAG_ENDPOINTS = {
  EQUIPMENT: "/v1/tags/addEquipTag",
  SERVICE: "/v1/tags/addServiceTag",
  OEM: "/v1/tags/addOemTag",
} as const;
