import { Table } from "antd";
import { formatTime, getDateFromUTC } from "Utils/DisplayTimeFormat";
import { IApprovalsData, IApproverDetails } from "../../JobRequestDetails.interfaces";
import NoAccess from "../../../../components/NoAccess/NoAccess";
import { RESOLUTION_COLUMNS, APPROVER_COLUMNS } from "./ApprovalsTabContent.constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { JobRequestActions } from "container/JobRequests/actions";
import { useParams } from "react-router-dom";

export interface IResolutionRowObj {
  key: number;
  resolutionDescription: string;
  resolutionStatus: string;
  createdBy: string;
  creationDate: string;
}

export interface IApproverPersonRowObj {
  key: number;
  approvers: string;
  approvalStatus: string;
  approvedOrRejected: string;
  note: string;
}

export const ApprovalsTabContent = (props: { hasAccess: boolean }) => {
  const { approvalsData }: { approvalsData: IApprovalsData | null } = useSelector(
    (state: any) => state.JobRequestReducers
  );
  const dispatch = useDispatch();
  const { _id } = useParams<{ _id: string }>();

  useEffect(() => {
    dispatch(JobRequestActions.getApprovalsData(_id));
  }, []);

  if (!props.hasAccess) {
    return <NoAccess />;
  }

  const expandedRowRender = (record: IResolutionRowObj) => {
    const rowsForApproversTable = (approvers: IApproverDetails[]) => {
      let rows: IApproverPersonRowObj[] = [];
      approvers.forEach((approverPersonObj, index) => {
        rows.push({
          key: index,
          approvers: approverPersonObj.email,
          approvedOrRejected: formatTime(approverPersonObj.updatedAt, "YYYY-MM-DD HH:mm:ss"),
          note: approverPersonObj.note,
          approvalStatus: approverPersonObj.status,
        });
      });
      return rows;
    };

    return (
      <>
        {approvalsData && approvalsData?.length > 0 && (
          <Table
            columns={APPROVER_COLUMNS}
            dataSource={rowsForApproversTable(approvalsData[record.key].approvers)}
            pagination={false}
          />
        )}
      </>
    );
  };

  const rowsForResolutionTable = (approvalsData: IApprovalsData) => {
    let rows: IResolutionRowObj[] = [];
    approvalsData.forEach((approvalsObj, index) => {
      rows.push({
        key: index,
        resolutionDescription: approvalsObj.note,
        createdBy: approvalsObj.creatorClientEmail,
        creationDate: formatTime(approvalsObj.createdAt, "YYYY-MM-DD HH:mm:ss"),
        resolutionStatus: approvalsObj.status,
      });
    });
    return rows;
  };

  return (
    <>
      {approvalsData && approvalsData?.length >= 0 && (
        <Table
          scroll={{ y: 450 }}
          pagination={false}
          columns={RESOLUTION_COLUMNS}
          dataSource={rowsForResolutionTable(approvalsData)}
          expandable={{ expandedRowRender }}
        />
      )}
    </>
  );
};
