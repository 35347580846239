import React from "react";
import { COMPANY_INFO_OPTION } from "../../constants/updateVendorConstants";
import UpdateVendorProfile from "../../container/UpdateVendorProfiles/UpdateVendorProfiles";
import { PromptProvider } from "components/Modal/PromptProvider";
const UpdateCompanyInfo = () => (
  <PromptProvider>
    <UpdateVendorProfile bodyContent={COMPANY_INFO_OPTION} />
  </PromptProvider>
);

export default UpdateCompanyInfo;