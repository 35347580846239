import React, { useEffect, useState } from "react";
import {
  IFile,
  IFolder,
  IQuotation,
  IQuotationRowTemplateProps,
  IVendorQuotationProps,
} from ".././../Interfaces/JobRequestInterfaces";
import {
  QuotationTabContainer,
  QuotationTableHeaderContainer,
  QuotationTableHeader,
  QuotationData,
  QuotationDataRow,
  DownloadLink,
  CheckBoxData,
  CompareQuotationContainer,
  QuotationsContainer,
} from "./Quotation.style";
import { Checkbox, Divider, Switch } from "antd";
import { FiltersDropDown } from "../../../../components/Filters/FiltersDropDown";
import { zipMultipleFilesAndDownload, zipMultipleFoldersAndDownload } from "../../file.handler";
import { ButtonWrapper } from "../JobRequestDetails.styles";
import { LoaderActions } from "../../../../components/Spinner/actions";
import { useDispatch, useSelector } from "react-redux";
import { IDMSFile, IJobRequestData } from "container/JobRequests/JobRequestDetails.interfaces";
import CompareQuotation from "./CompareQuotation/CompareQuotation";
import { popOverComponent } from "container/JobRequests/components/PopOverComponent/PopOverComponent";
import { JobRequestActions } from "container/JobRequests/actions";
import { Box, Flex } from "venwiz-ui";

const QuotationTab = () => {
  const { jobRequestDetails }: { jobRequestDetails: IJobRequestData } = useSelector((state: any) => state.ClientRequestReducer);
  const jobRequestId = jobRequestDetails?.requestId;
  const clientId = jobRequestDetails?.clientId;
  const [vendorQuotations, setVendorQuotations] = useState<IVendorQuotationProps[]>([]);
  const [hasTechnicalQuotationForCompare, setHasTechnicalQuotationForCompare] = useState(false);
  const [hasCommercialQuotationForCompare, setHasCommercialQuotationForCompare] = useState(false);
  const [selectedQuotations, setSelectedQuotations] = useState<Array<IQuotationRowTemplateProps>>([]);
  const [selectedQuotationFiles, setSelectedQuotationFiles] = useState<Array<any>>([]);
  const [quotationFilesForPublish, setQuotationFilesForPublish] = useState<Array<any>>([]);
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const [filterDropdownVisibility, setFilterDropdownVisibility] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const dispatch = useDispatch();

  const onQuotationTypeCheck = (event: boolean, props: any) => {
    if (event) {
      setSelectedQuotations([...selectedQuotations, props]);
    } else {
      setSelectedQuotations(selectedQuotations.filter((data) => props.keyIndex !== data.keyIndex));
    }
  };

  const onQuotationFileCheck = (status: boolean, fileName:string, quotations:any) => {
    const vendorIndex = vendorQuotations.findIndex((vendor) => (quotations.companyName === (vendor.technicalDocument?.companyName) || (quotations.companyName === vendor.commercialDocument?.companyName)))
    if(quotations.type === "technical") {
      vendorQuotations[vendorIndex]?.technicalDocument?.files.map((file) => {
          if(file.fileName === fileName) {
            file.publishFile =  status;
            if(file.publishFile === (file.isAdminPublished && file.publishVersion !== -1)) {
              file.modified = false
            } else {
              file.modified = true
            }
          }
      })
    } else {
      vendorQuotations[vendorIndex]?.commercialDocument?.files.map((file) => {
        if(file.fileName === fileName) {
          file.publishFile =  status;
          if(file.publishFile === (file.isAdminPublished && file.publishVersion !== -1)) {
            file.modified = false
          } else {
            file.modified = true
          }
        }
    })
    setVendorQuotations(vendorQuotations)
    }
  };

  const publishQuotes = () => {
    let files: { [x: string]: { [x: string]: any; };[x: number]: { [x: number]: any[]; }; };
    let gstns: string[] = [];

    vendorQuotations.map((vendor) => {
      const {commercialFiles, commercialGstns} =  setQuotes(files, vendor.commercialDocument.gstn, vendor.commercialDocument.type, gstns,  vendor.commercialDocument.files);
      files = commercialFiles;
      gstns = commercialGstns || [];
      const {technicalFiles, technicalGstns} =  setQuotes(files, vendor.technicalDocument.gstn, vendor.technicalDocument.type, gstns,  vendor.technicalDocument.files)
      files = technicalFiles;
      gstns = technicalGstns || [];
      
    })
    const payload = {
      gstns, 
      files,
      jobRequestId,
      docType: "quotation"
    }
    console.log(payload,"pay")
    dispatch(JobRequestActions.publishDocs(payload)).then(() =>{
       getQuotations()
    }
  );
  }

  const setQuotes = (files:any, gstn: string, fileType: string,gstns: string[], quotationFiles: IDMSFile[]) => {
    quotationFiles?.map((file:IDMSFile) => {
      if(file.modified){
        const updatedFile = {
            fileName: file.fileName,
            status: file.publishFile
        }
        if(files?.[gstn]){
          if(files?.[gstn][fileType]) {
            files?.[gstn]?.[fileType].push(updatedFile);
          } else {
            files[gstn][fileType] = [updatedFile]
          }
        } else{
          const item = {
            [fileType]: [updatedFile]
          }
          if(files) {
            files[gstn] = item
          } else{
            files = {
              [gstn]: item
            }
          }
          gstns.push(gstn)
        }
      }
    })
    return fileType === "commercial" ?  {commercialFiles: files, commercialGstns: gstns} : {technicalFiles: files, technicalGstns: gstns}
  }

  const downloadQuotations = (vendorQuotation: IQuotationRowTemplateProps) => {
    zipMultipleFilesAndDownload(
      `${vendorQuotation.companyName}-${vendorQuotation.quotation.type}`,
      `${jobRequestId}`,
      vendorQuotation?.quotation.files
    );
    dispatch(JobRequestActions.updateLastActivityDate(jobRequestId));
  };

  const downloadAll = () => {
    let downloadFolder: IFolder[] = [];
    vendorQuotations.map((vendorQuotation) => {
      const isVendorRemoved =
        checkIsVendorRemoved(vendorQuotation?.technicalDocument?.gstn) ||
        checkIsVendorRemoved(vendorQuotation?.commercialDocument?.gstn);
      if (!isVendorRemoved) {
        downloadFolder = appendToFolder(
          vendorQuotation.technicalDocument,
          vendorQuotation.technicalDocument.companyName,
          downloadFolder
        );
        downloadFolder = appendToFolder(
          vendorQuotation.commercialDocument,
          vendorQuotation.commercialDocument.companyName,
          downloadFolder
        );
      }
    });
    zipMultipleFoldersAndDownload(`${jobRequestId}`, downloadFolder, setIsDownloadingAll);
    dispatch(JobRequestActions.updateLastActivityDate(jobRequestId));
  };

  const downloadSelectedQuotations = () => {
    let downloadFolder: IFolder[] = [];
    selectedQuotations.map((selectedQuoation) => {
      downloadFolder = appendToFolder(selectedQuoation.quotation, selectedQuoation.companyName, downloadFolder);
    });
    zipMultipleFoldersAndDownload(`${jobRequestId}`, downloadFolder, setIsDownloading);
    dispatch(JobRequestActions.updateLastActivityDate(jobRequestId));
  };

  const appendToFolder = (quotation: IQuotation, companyName: string, downloadFolder: IFolder[]) => {
    if (quotation && Object.keys(quotation).length > 1) {
      const folder: IFolder = {
        folderName: `${companyName}-${quotation.type}`,
        files: quotation.files,
      };
      downloadFolder.push(folder);
    }
    return downloadFolder;
  };

  const getQuotations = async () => {
    try {
      dispatch(LoaderActions.setIsLoading(true));
      dispatch(JobRequestActions.getJobQuotations(jobRequestId)).then((data:any) => {
        setHasTechnicalQuotationForCompare(data?.hasTechnicalQuotationForCompare);
        setHasCommercialQuotationForCompare(data?.hasCommercialQuotationForCompare);
        data?.quotationFiles.map((vendor: any) => {
          vendor.commercialDocument?.files.map((commericialQuote: any) => {
            commericialQuote.publishFile = commericialQuote.isAdminPublished && commericialQuote.publishVersion !== -1;
          })
          vendor?.technicalDocument?.files.map((technicalQuote: any) => {
            technicalQuote.publishFile = technicalQuote.isAdminPublished && technicalQuote.publishVersion !== -1;
          })
        })
        setVendorQuotations(data?.quotationFiles);
        dispatch(LoaderActions.setIsLoading(false));
      })
    } catch (error) {
      dispatch(LoaderActions.setIsLoading(false));
      console.error("Unable to get Job Request Details", error);
    }
  };

  useEffect(() => {
    getQuotations();
  }, []);

  useEffect(() => {
    if (isDownloading || isDownloadingAll) {
      dispatch(LoaderActions.setIsLoading(true));
    } else {
      dispatch(LoaderActions.setIsLoading(false));
    }
  }, [isDownloading, isDownloadingAll]);

  const QuotationRowTemplate = (props: IQuotationRowTemplateProps) => {
    const { companyName, quotation, keyIndex } = props;
    const isVendorRemoved = checkIsVendorRemoved(quotation?.gstn);

    return (
      <Box>
        <QuotationDataRow key={keyIndex} >
        <CheckBoxData>
          <Checkbox
            key={keyIndex}
            checked={selectedQuotations.some((selectedQuotation) => selectedQuotation.keyIndex === keyIndex)}
            value={false}
            onChange={(e) => {
              onQuotationTypeCheck(e.target.checked, props);
            }}
            disabled={isVendorRemoved}
          />
        </CheckBoxData>
        <QuotationData width="20%" strikeThrough={isVendorRemoved} >{popOverComponent(companyName)}</QuotationData>
        <QuotationData width="15%" shouldCapitalize >{quotation.type}</QuotationData>
        <QuotationData width="40%">{popOverComponent(quotation.note)}</QuotationData>
        {!isVendorRemoved && (
          <DownloadLink onClick={() => downloadQuotations(props)}>
            {`Download Files (${quotation.files.length})`}
          </DownloadLink>
        )}       
        </QuotationDataRow>
        <Box padding="10px 100px" bgColor = {"#eceff7"}>
        {
          quotation.files.map((file) => {
            return (
              <Flex padding ={"10px"}>
                {file.fileName} 
                  <Switch defaultChecked={file.publishFile} style={{ marginLeft: "10px" }} onChange={(state) => {
                     onQuotationFileCheck(state, file.fileName, quotation);
                  }
                    }/>
              </Flex>
            )
          })
        }
        </Box>
     
      </Box>
    );
  };

  const checkIsVendorRemoved = (gstn: string) =>
    jobRequestDetails?.vendorRemovedByClient?.some((vendor) => vendor?.gstn === gstn);
  
  const activeVendorQuotations = vendorQuotations?.filter(
    (quotation) =>
      !(
        checkIsVendorRemoved(quotation?.technicalDocument?.gstn) ||
        checkIsVendorRemoved(quotation?.commercialDocument?.gstn)
      )
  );

  return (
    <>
      <QuotationTabContainer>
        <QuotationTableHeaderContainer>
          <QuotationTableHeader width="5%"></QuotationTableHeader>
          <QuotationTableHeader width="20%">Vendor Name</QuotationTableHeader>
          <QuotationTableHeader width="15%">
            File Type
            <FiltersDropDown
              filterValues={appliedFilters}
              setShowDropDown={setFilterDropdownVisibility}
              showDropDown={filterDropdownVisibility}
              filterOptions={[
                { filterType: "Technical", filterValue: "Technical" },
                { filterType: "Commercial", filterValue: "Commercial" },
              ]}
              onFiltering={setAppliedFilters}
              filterBackgroundColor="transparent"
              filterColor="#215EC9"
              width="16px"
              height="8px"
              marginBottom="1.5px"
              counterWidthNHeight="15px"
            />
          </QuotationTableHeader>
          <QuotationTableHeader width="40%">Note</QuotationTableHeader>
          <QuotationTableHeader style={{ marginRight: "5px" }}>
            <ButtonWrapper
              type="primary"
              onClick={() => downloadSelectedQuotations()}
              disabled={selectedQuotations.length === 0}
            >
              Download Selected ({selectedQuotations.length})
            </ButtonWrapper>
          </QuotationTableHeader>
          <QuotationTableHeader style={{ marginRight: "5px" }}>
            <ButtonWrapper
              type="primary"
              onClick={() => publishQuotes()}
              // disabled={selectedQuotationFiles.length === 0}
            >
              Publish Quotes
            </ButtonWrapper>
          </QuotationTableHeader>
          <QuotationTableHeader style>
            <ButtonWrapper type="primary" onClick={() => downloadAll()} disabled={vendorQuotations?.length === 0}>
              Download All
            </ButtonWrapper>
          </QuotationTableHeader>
        </QuotationTableHeaderContainer>
        <QuotationsContainer>
          {vendorQuotations?.length === 0 && "No Quotation Recieved"}
          {appliedFilters.length === 1
            ? vendorQuotations.map((vendor, index) => {
                if (appliedFilters?.includes(vendor.technicalDocument?.type?.toLowerCase()))
                  return (
                    <>
                      {!!vendor.technicalDocument?.files?.length && (
                        <QuotationRowTemplate
                          companyName={vendor.technicalDocument.companyName}
                          quotation={vendor.technicalDocument}
                          keyIndex={`T${index}`}
                          gstn = {vendor.technicalDocument.gstn}
                        ></QuotationRowTemplate>
                      )}
                    </>
                  );
                if (appliedFilters?.includes(vendor.commercialDocument?.type?.toLowerCase()))
                  return (
                    <>
                      {!!vendor.commercialDocument?.files?.length && (
                        <QuotationRowTemplate
                          companyName={vendor.commercialDocument.companyName}
                          quotation={vendor.commercialDocument}
                          keyIndex={`C${index}`}
                          gstn = {vendor.technicalDocument.gstn}
                        ></QuotationRowTemplate>
                      )}
                    </>
                  );
              })
            : vendorQuotations?.map((vendor, index) => {
                return (
                  <>
                    {!!vendor.technicalDocument?.files?.length && (
                      <QuotationRowTemplate
                        companyName={vendor.technicalDocument.companyName}
                        quotation={vendor.technicalDocument}
                        keyIndex={`T${index}`}
                        gstn = {vendor.technicalDocument.gstn}
                      ></QuotationRowTemplate>
                    )}
                    {!!vendor.commercialDocument?.files?.length && (
                      <QuotationRowTemplate
                        companyName={vendor.commercialDocument.companyName}
                        quotation={vendor.commercialDocument}
                        keyIndex={`C${index}`}
                        gstn = {vendor.technicalDocument.gstn}
                      ></QuotationRowTemplate>
                    )}
                  </>
                );
              })}
        </QuotationsContainer>
        {

          <CompareQuotationContainer>
            <Divider/>
              <CompareQuotation jobRequestId={jobRequestId} clientId={clientId} hasTechnicalQuotationForCompare={hasTechnicalQuotationForCompare} hasCommercialQuotationForCompare={hasCommercialQuotationForCompare}></CompareQuotation>
        </CompareQuotationContainer>
        }
       
       
      </QuotationTabContainer>
    </>
  );
};

export default QuotationTab;
