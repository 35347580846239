import { getUrlFromMediaObject } from "components/VendorCapabilitiesUpdate/components/GalleryModal/galleryModal.handler";
import { IEntityObj } from "./LinkAvailableGalleryItemsModal.interface";

export const isMediaAlreadyExistingInEntity = (currMediaUrl: string, currEntityObj: IEntityObj) => {
    if (!currEntityObj?.gallery || currEntityObj?.gallery?.length === 0) return false;
    for (let i = 0; i < currEntityObj?.gallery?.length; i++) {
      const existingEntityGalleryItem = currEntityObj?.gallery[i];
      if (getUrlFromMediaObject(existingEntityGalleryItem) === currMediaUrl) {
        return true;
      }
    }
    return false;
};