import { Types as VendorProfileTypes } from "_types/VendorProfileTypes";

const initialState = {
  loading: false,
  data: {},
  error: "",
  reviewData: {},
  formModal: false,
  reviewModal: false,
  errMess: "",
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case VendorProfileTypes.GET_VENDOR_PROFILE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewData: action.result,
        error: "",
      };

    case VendorProfileTypes.SET_OPEN_REVIEW_FORM_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        formModal: action?.payload,
      };

    case VendorProfileTypes.SET_OPEN_REVIEW_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewModal: action?.payload,
      };

    case VendorProfileTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errMess: action?.payload,
      };

    default:
      return state;
  }
};
