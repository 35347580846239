import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const DropDown = (props) => {
  return (
    <Autocomplete
      disabled={props?.disabled}
      style={{width: props?.width || 200}}
      value={props?.initialValue}
      options={props?.options}
      getOptionLabel={(option) => option }
      getOptionSelected={(option, value) => option === value }
      onChange={(e, value) => props?.handleOnChange(e, value) }
      renderInput={(params) => <TextField {...params} label={props.labelName} variant="outlined" />}
      size={props?.size | "medium"}
    />
  );
};

DropDown.propTypes = {
  labelName: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  size: PropTypes.oneOf(["medium",  "small"]),
  width: PropTypes.number,
  disabled: PropTypes.bool,
}

