import { useState } from "react";
import styled from "styled-components";
import QuotationComparisonTool from "container/JobRequests/JobRequestDetails/Quotations/QuoteComparisonTool/QuoteComparisonTool";
import UnstructuredComparison from "container/JobRequests/JobRequestDetails/Quotations/UnstructuredComparison/UnstructuredComparison";

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
`;

const Tab = styled.button<{ active: boolean }>`
  flex: 1;
  padding: 12px;
  cursor: pointer;
  background: ${({ active }) => (active ? "#e0e0e0" : "#fff")};
  border: none;
  border-bottom: ${({ active }) => (active ? "3px solid #007bff" : "none")};
  color: ${({ active }) => (active ? "#007bff" : "#555")};
  font-size: 16px;
  outline: none;
  transition: background 0.3s ease, color 0.3s ease;
  
  &:hover {
    background-color: #eaeaea;
  }
`;

const TabContent = styled.div`
  padding: 16px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

export default function Comparison() {
  const [activeTab, setActiveTab] = useState("commercial");

  return (
    <>
      <TabContainer>
        <Tab active={activeTab === "commercial"} onClick={() => setActiveTab("commercial")}>
          Commercial Quote Comparison
        </Tab>
        <Tab active={activeTab === "technical"} onClick={() => setActiveTab("technical")}>
          Technical Quote Comparison
        </Tab>
      </TabContainer>

      <TabContent>
        {activeTab === "commercial" && <QuotationComparisonTool />}
        {activeTab === "technical" && <UnstructuredComparison />}
      </TabContent>
    </>
  );
}
