import styled from "styled-components";

export const FinalVendorCard = styled.div`
  display: flex;
  height: 45px;
  border: 1px solid #d4e5fe;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.85);
  align-items: center;
  margin-bottom: 6px;
  padding-left: 20px;
`;

export const VendorCompanyName = styled.div`
  width: 40%;
`;

export const DataSharingConsent = styled.div`
  font-weight: 500;
  width: 30%;
`;

export const ProfileLink = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #215ec9;
  padding-left: 30px;
  cursor: pointer;
`;
