import { makeStyles } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";

export const VendorVerificationStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  editButton: {
    backgroundColor: green[400],
    color: "#fff",
    height: "3.25rem",
    "&:hover": {
      backgroundColor: green[600],
      color: "#fff",
    },
  },
  cancelButton: {
    backgroundColor: red[400],
    color: "#fff",
    height: "3.25rem",
    "&:hover": {
      backgroundColor: red[600],
      color: "#fff",
    },
  },
  submitButton: {
    backgroundColor: blue[400],
    color: "#fff",
    height: "3.25rem",
    "&:hover": {
      backgroundColor: blue[600],
      color: "#fff",
    },
  },
  submitButtonLoader: {
    padding: "0.75rem",
    color: "white",
    margin: 0,
    marginRight: "-0.5rem",
    marginLeft: "-0.75rem",
  },
}));
