import { ColumnsType } from "antd/es/table";
import { IVendorAddedByUserType, IVendorsWithInterestColumn } from "./TableVendorsAddedBy";
import { popOverComponent } from "container/JobRequests/components/PopOverComponent/PopOverComponent";
import { VendorNameDisplay } from "../VendorNameDisplay/VendorNameDisplay";

export const getColumnsForVendorsAddedByTable = (
  type: IVendorAddedByUserType,
): ColumnsType<IVendorsWithInterestColumn> => {
  return [
    {
      key: "interestedVendors",
      dataIndex: "interestedVendors",
      title: `Interested Vendors GST/Email - by ${type === "ADMIN" ? "Admin" : "Client"}`,
      children: [
        {
          key: "interestedEmailOrGstn",
          dataIndex: "interestedEmailOrGstn",
          title: "Interested GST/Email",
          width: "20%",
          render: (text: string) => VendorNameDisplay(text),
        },
        {
          key: "interestedVendorName",
          dataIndex: "interestedVendorName",
          title: "Interested Vendor Names",
          width: "20%",
          render: (text: string) => VendorNameDisplay(text),
        },
      ],
    },
    {
      key: "notInterestedVendors",
      dataIndex: "notInterestedVendors",
      title: `Not Interested Vendors GST/Email - by ${type === "ADMIN" ? "Admin" : "Client"}`,
      children: [
        {
          key: "notInterestedEmailOrGstn",
          dataIndex: "notInterestedEmailOrGstn",
          title: "Not Interested GST/Email",
          width: "20%",
          render: (text: string) => popOverComponent(text),
        },
        {
          key: "reasonToDecline",
          dataIndex: "reasonToDecline",
          title: "Reason to Decline",
          width: "20%",
          render: (text: string) => popOverComponent(text),
        },
      ],
    },
  ];
};
