import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ITabPanelProps } from "container/ClientRequests/interfaces/Company.interface";
import { UserRoles } from "../Tabs/UserRoles/UserRoles";
import {  useParams } from "react-router-dom";
import VendorRequests from "container/ClientRequests/VendorRequests/VendorRequests";
import Header from "container/ClientRequests/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { Reports } from "container/JobRequests/Reports/Reports";

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const ClientProjectDetails = () => {
  const { projectId }: { projectId: string } = useParams();
  const { selectedClientProjectTab } = useSelector((state: any) => state.ClientRequestReducer);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(ClientRequestActions.setSelectedClientProjectTab(newValue));
  };

  return (
    <>
      <Header/>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedClientProjectTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="User Roles" value={0} />
          <Tab label="RFQ Jobs" value={1} />
          <Tab label="Reports" value={2} />
        </Tabs>
      </Box>
      <TabPanel value={selectedClientProjectTab} index={0}>
        <UserRoles projectId={projectId} />
      </TabPanel>
      <TabPanel value={selectedClientProjectTab} index={1}>
        <VendorRequests></VendorRequests>
      </TabPanel>
      <TabPanel value={selectedClientProjectTab} index={2}>
       <Reports></Reports>
      </TabPanel>
    </>
  );
};
