import { JobRequestTypes } from "_types/JobRequestTypes";
import { IJobRequestData, IApprovalsData, ICompareQuotationRequest } from "../JobRequestDetails.interfaces";

interface IInitialState {
  jobRequestData: IJobRequestData | null;
  approvalsData: IApprovalsData | null;
  allCustomReportsData: null;
  loading: boolean;
  error: boolean;
  compareQuotationRequest: ICompareQuotationRequest | null;
}

const INITIAL_STATE: IInitialState = {
  allCustomReportsData: null,
  jobRequestData: null,
  approvalsData: null,
  loading: false,
  error: false,
  compareQuotationRequest: null,
};

const jobRequestReducer = (state = INITIAL_STATE, action: any): IInitialState => {
  const respData = action?.result;
  switch (action.type) {
    case JobRequestTypes.GET_JOB_REQUEST_DETAILS_REQUESTED:
      return { ...state, loading: true };
    case JobRequestTypes.GET_JOB_REQUEST_DETAILS_FAILURE:
      return { ...state, loading: false, error: true };

    case JobRequestTypes.GET_APPROVALS_DATA_REQUESTED:
      return { ...state, loading: true };
    case JobRequestTypes.GET_APPROVALS_DATA_FAILURE:
      return { ...state, loading: false, error: true };
    case JobRequestTypes.GET_APPROVALS_DATA_SUCCESS:
      return { ...state, loading: false, approvalsData: action.result };

    case JobRequestTypes.GET_COMPARE_QUOTATION_SUCCESS:
      return {
        ...state,
        compareQuotationRequest: action.result?.data,
      };
    case JobRequestTypes.UPDATE_CLIENT_REQUEST_SUCCESS:
      return {
        ...state,
      };

    case JobRequestTypes.GET_ALL_CUSTOM_REPORTS_SUCCESS:
      return {
        ...state,
        allCustomReportsData: action.result?.data,
      };
    case JobRequestTypes.RESET_CUSTOM_REPORTS: 
    return {
      ...state,
      allCustomReportsData: null
    }
    case JobRequestTypes.UPDATE_LAST_ACTIVITY_DATE: 
    return {
      ...state, 
    }
    default:
      return state;
  }
};

export default jobRequestReducer;
