import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { VerifStatusDropDown } from "./verifStatusDropDown";
import {
  IKeysWithArrTypes,
  IStatusTypes,
} from "../vendorVerification.interface";
import { Chip, TextField, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { verificationConstants } from "../constants";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green, red, lightBlue, grey } from "@mui/material/colors";
import axios from "axios";
import LocalStorage from "_services/LocalStorage";
import { Upload } from "@mui/icons-material";

export const createRowDataforArr = (
  stateKey: IKeysWithArrTypes,
  complianceArray: any[]
) => {
  return {
    stateKey,
    values: [...complianceArray],
  };
};

interface Props {
  row: ReturnType<typeof createRowDataforArr>;
  isEditMode: boolean;
  setGridState: (x?: any) => void;
  gridState: any;
}

export const RowForArrayValues = (props: Props) => {
  const { row, isEditMode, setGridState, gridState } = props;
  const [open, setOpen] = React.useState(false);
  const FIELD_NAME = verificationConstants[row.stateKey].fieldName;

  const areAllSubArraysVerified = () => {
    if (row?.values.length > 0) {
      const verifiedObjects = row?.values?.filter(
        (arrObj: any, index: number) =>
          arrObj?.verificationStatus === "verified"
      );
      return verifiedObjects.length > 0;
    }
    return false;
  };

  const uploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const data = new FormData();
    if (e.target.files) {
      data.append("file", e.target.files[0]);
    }
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/upload",
      data,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result.data.status === "SUCCESS") {
      if (e.target.files) {
        return await createPermanentLink(
          result.data.data,
          e.target.files[0].name,
          type
        );
      }
    } else {
    }
  };

  const createPermanentLink = async (
    payload: any,
    fileName: any,
    type: any
  ) => {
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/permanent",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result?.data?.status === "SUCCESS") {
      return result.data.data;
    } else {
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {FIELD_NAME}
        </TableCell>
        <TableCell />
        <TableCell align="right">
          <Chip
            style={{ backgroundColor: lightBlue[300] }}
            label={`Total Items - ${row?.values.length}`}
          />
        </TableCell>
        {/* <TableCell align="right">
          {areAllSubArraysVerified() 
            ? <Chip style={{ backgroundColor: green[300] }} icon={<CheckCircleIcon />} label="Verified"/> 
            : <Chip style={{ backgroundColor: red[300], color: grey[50] }} icon={<CancelIcon />} label="Not Verified"/>
          }
        </TableCell> */}
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row?.values?.length > 0 && (
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6" component="span">
                          Fields
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" component="span">
                          Value
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6" component="span">
                          Document
                        </Typography>
                      </TableCell>
                      {/* <TableCell align="right">
                        <Typography variant="h6" component="span">
                          Verification Category
                        </Typography>
                      </TableCell> */}
                      <TableCell align="right">
                        <Typography variant="h6" component="span">
                          Verification Status
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h6" component="span">
                          Note
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.values?.map((arrObj: any, idx: number) => {
                      return (
                        <TableRow key={idx}>
                          <TableCell component="th" scope="row">
                            {`${FIELD_NAME} - ${idx + 1}`}
                          </TableCell>
                          <TableCell>{arrObj?.value}</TableCell>
                          <TableCell align="center">
                            {(arrObj?.document && arrObj?.document?.file) ||
                            (arrObj?.document &&
                              arrObj?.document?.documentFile) ? (
                              <Chip
                                label="View"
                                onDelete={(e) => {
                                  const newValues = row?.values?.map(
                                    (arrObj: any, index: number) => {
                                      if (index === idx) {
                                        arrObj.removeDocument &&
                                          delete arrObj.removeDocument;
                                        arrObj.addDocument &&
                                          delete arrObj.addDocument;
                                        arrObj.document &&
                                          delete arrObj.document;
                                        return {
                                          ...arrObj,
                                          document: null,
                                          removeDocument: true,
                                        };
                                      }
                                      return arrObj;
                                    }
                                  );
                                  setGridState({
                                    ...gridState,
                                    [row.stateKey]: newValues,
                                  });
                                }}
                                onClick={(e) => {
                                  if (arrObj.document) {
                                    if (arrObj.document.file?.fileUrl) {
                                      window.open(arrObj.document.file.fileUrl);
                                    } else {
                                      window.open(
                                        arrObj.document.documentFile.fileUrl
                                      );
                                    }
                                  }
                                }}
                              />
                            ) : (
                              row.stateKey !== "tan" &&
                              row.stateKey !== "lin" &&
                              row.stateKey !== "urn" && (
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="label"
                                >
                                  <input
                                    hidden
                                    type="file"
                                    onChange={async (e) => {
                                      if (e.target.files) {
                                        const value = await uploadFile(
                                          e,
                                          e.target.files[0].name
                                        );
                                        const newValues = row?.values?.map(
                                          (arrObj: any, index: number) => {
                                            if (index === idx) {
                                              arrObj.removeDocument &&
                                                delete arrObj.removeDocument;
                                              arrObj.addDocument &&
                                                delete arrObj.addDocument;
                                              arrObj.document &&
                                                delete arrObj.document;
                                              return {
                                                ...arrObj,
                                                document: {
                                                  name: e.target.files[0].name,
                                                  file: value,
                                                },
                                                addDocument: true,
                                              };
                                            }
                                            return arrObj;
                                          }
                                        );
                                        setGridState({
                                          ...gridState,
                                          [row.stateKey]: newValues,
                                        });
                                      }
                                    }}
                                  />
                                  <Upload />
                                </IconButton>
                              )
                            )}
                          </TableCell>
                          {/* <TableCell align="right">{row?.verificationCategory}</TableCell> */}
                          <TableCell align="right">
                            <VerifStatusDropDown
                              disableSelect={!isEditMode}
                              statusValue={arrObj?.verificationStatus}
                              setStatusValue={(e) => {
                                const selectedRowValue = arrObj?.value;
                                const selectedStateKey = row.stateKey;
                                setGridState((prev: any) => {
                                  return {
                                    ...prev,
                                    [selectedStateKey]: prev[row.stateKey].map(
                                      (obj: any, index: number) =>
                                        obj?.value !== selectedRowValue
                                          ? obj
                                          : { ...obj, verificationStatus: e }
                                    ),
                                  };
                                });
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {!isEditMode ? (
                              arrObj?.note
                            ) : (
                              <TextField
                                variant="filled"
                                id="filled-basic"
                                value={arrObj?.note}
                                onChange={(e) => {
                                  const selectedRowValue = arrObj?.value;
                                  const selectedStateKey = row.stateKey;
                                  setGridState((prev: any) => {
                                    return {
                                      ...prev,
                                      [selectedStateKey]: prev[
                                        row.stateKey
                                      ].map((obj: any, index: number) =>
                                        obj?.value !== selectedRowValue
                                          ? obj
                                          : { ...obj, note: e.target.value }
                                      ),
                                    };
                                  });
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
