import { Backdrop, Button, CircularProgress, IconButton, InputLabel, TextField, Typography } from "@material-ui/core";
import { useStyles } from "./VendorCertificatesUpdate.stytles";
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PublishIcon from '@material-ui/icons/Publish';
import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ChipsArray from "../ChipsArray/ChipsArray";
import './VendorCertificatesUpdate.scss';
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../NoAccess/NoAccess";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";

const VendorCertificatesUpdate = () => {
  const [loader, enableLoader] = React.useState(false);
  const [reloadUpdate, setReloadUpdate] = React.useState(false)
  const [techDocs, setTechDocs] = React.useState({})
  const [compDocs, setCompDocs] = React.useState({})
  const [basicDocs, setBasicDocs] = React.useState({})
  const [basicFile, setBasicFile] = React.useState({ fileType: '', fileName: '' })
  const [techFile, setTechFile] = React.useState({ fileType: '', fileName: '' })
  const [compFile, setCompFile] = React.useState({ fileType: '', fileName: '' })
  const [showBasicAddButton, setBasicAddButton] = React.useState(false)
  const [showTechAddButton, setTechAddButton] = React.useState(false)
  const [showCompAddButton, setCompAddButton] = React.useState(false)
  const [basicfileObj, setBasicfileObj] = React.useState({})
  const [compfileObj, setCompfileObj] = React.useState({})
  const [techfileObj, setTechfileObj] = React.useState({})
  const { gstn } = useParams()
  const [hasAccess, setHasAccess] =React.useState(true);


  const updateVendor = (gstn, basicdocuments, compdocuments, techdocuments) => {
    let payload = {
      gstn: gstn,
      basic: basicdocuments.map(itr => ({ name: itr.label, certificationFile: itr.filePayload })),
      compliance: compdocuments.map(itr => ({ name: itr.label, certificationFile: itr.filePayload })),
      technical: techdocuments.map(itr => ({ name: itr.label, certificationFile: itr.filePayload })),
    }

    updateDocumentInfo(gstn, payload)
  }

  const updateDocumentInfo = (async (gstn, payload) => {
    const result = await axios.put(process.env.REACT_APP_BASE_URL + '/vendor-certificates/' + gstn, payload,
    {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`
      }
    })
    if (result.data.status === "SUCCESS") {
      setReloadUpdate(true)
      leadSquaredTrigger(gstn);
    }
    else {
      setReloadUpdate(true)
    }
  })

  const uploadFile = (async (e, type) => {
    const data = new FormData()
    data.append('file', e.target.files[0])
    const result = await axios.post(process.env.REACT_APP_BASE_URL + '/file-storage/upload', data,
    {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`
      }
    })
    if (result.data.status === "SUCCESS") {
      createPermanentLink(result.data.data, e.target.files[0].name, type)
    }
    else {
      if (type === 'basic') {
        setBasicFile(file => ({ ...file, fileName: "" }))
        setBasicfileObj({})
      }
      if (type === 'comp') {
        setCompFile(file => ({ ...file, fileName: "" }))
        setCompfileObj({})
      }
      if (type === 'tech') {
        setTechFile(file => ({ ...file, fileName: "" }))
        setTechfileObj({})
      }
    }
  })

  const createPermanentLink = (async (payload, fileName, type) => {
    const result = await axios.post(process.env.REACT_APP_BASE_URL + '/file-storage/permanent', payload,
    {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`
      }
    })
    if (result.data.status === "SUCCESS") {
      if (type === 'basic') {
        setBasicFile(file => ({ ...file, fileName: fileName || "" }))
        setBasicfileObj(result.data.data)
        setBasicAddButton(true)
      }
      if (type === 'comp') {
        setCompFile(file => ({ ...file, fileName: fileName || "" }))
        setCompfileObj(result.data.data)
        setCompAddButton(true)
      }
      if (type === 'tech') {
        setTechFile(file => ({ ...file, fileName: fileName || "" }))
        setTechfileObj(result.data.data)
        setTechAddButton(true)
      }
    }
    else {
      if (type === 'basic') {
        setBasicFile(file => ({ ...file, fileName: "" }))
        setBasicfileObj({})
        setBasicAddButton(false)
      }
      if (type === 'comp') {
        setCompFile(file => ({ ...file, fileName: "" }))
        setCompfileObj({})
        setCompAddButton(false)
      }
      if (type === 'tech') {
        setTechFile(file => ({ ...file, fileName: "" }))
        setTechfileObj({})
        setTechAddButton(false)
      }
    }
  })

  const removeTechDocuments = (chipop) => {
    setTechDocs(itr => itr.filter(val => val.value !== chipop.value))
  }

  const removeCompDocuments = (chipop) => {
    setCompDocs(itr => itr.filter(val => val.value !== chipop.value))
  }
  const removeBasicDocuments = (chipop) => {
    setBasicDocs(itr => itr.filter(val => val.value !== chipop.value))
  }

  const openDocument = (chipvalue) => {
    const win = window.open(chipvalue.value, "_blank");
    win.focus();
  }


  useEffect(async () => {
    enableLoader(true)
    await axios.get(process.env.REACT_APP_BASE_URL + '/vendor-certificates/' + gstn,
    {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`
      }
    }).then((result) => {
      onVendorCertificatesApiSuccess(result);
    })
    .catch((error) => {
      if(error.response.status === 403){
        setHasAccess(false);
      } 
    });
    
  }, [reloadUpdate]);

  const onVendorCertificatesApiSuccess = (result) => {
    if (result.data.status == "SUCCESS") {

      let techdocs = result.data?.data?.technical?.map(itr => ({ ...itr, label: itr?.name, value: itr?.certificationFile?.fileUrl }))
      setTechDocs(techdocs)

      let compDocs = result.data?.data?.compliance?.map(itr => ({ ...itr, label: itr?.name, value: itr?.certificationFile?.fileUrl }))
      setCompDocs(compDocs)

      let basicDocs = result.data?.data?.basic?.map(itr => ({ ...itr, label: itr?.name, value: itr?.certificationFile?.fileUrl }))
      setBasicDocs(basicDocs)

      setCompFile({ fileType: '', fileName: '', })
      setBasicFile({ fileType: '', fileName: '', })
      setTechFile({ fileType: '', fileName: '', })
      setReloadUpdate(false)
      setTechAddButton(false)
      setBasicAddButton(false)
      setCompAddButton(false)
    }
    else {
      setTechDocs([])
      setCompDocs([])
      setBasicDocs([])
      setCompFile({ fileType: '', fileName: '', })
      setBasicFile({ fileType: '', fileName: '', })
      setTechFile({ fileType: '', fileName: '', })
      setReloadUpdate(false)
      setTechAddButton(false)
      setBasicAddButton(false)
      setCompAddButton(false)
    }

    enableLoader(false)
  }
  const classes = useStyles();
  return (
    hasAccess?
    <div className="container-fluid">
      <div className={classes.peopleInfoContainer}>
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="row mt-4">
          <div className="col text-center">
            <Typography variant="h5" className={classes.title}>
              Basic Documents
            </Typography>
          </div>
        </div>
        <div className="row mt">
          <div className="col text-center">
            <TextField
              id="basic"
              value={basicFile?.fileType || ''}
              variant="outlined"
              InputProps={{
                style: { width: '110%', textAlign: 'center' },
              }}
              onChange={(e) => {
                setBasicFile(data => ({ ...data, fileType: e.target.value }))
              }}
              placeholder="Enter type of Document"
            />
          </div>
        </div>
        <div className="row justify-content-center mt-1">
          <Typography variant="h7" className={classes.content}>
            Please add basic certificates like GST, MSME, CIN etc (GST Registration Certification required for the profile to be published).
          </Typography>
        </div>
        <div className="row mt-4">
          <div className="col text-center uploadButton">
            <TextField
              id='uploadbasic'
              type='text'
              variant="outlined"
              value={basicFile?.fileName || ""}
              InputProps={{
                style: { textAlign: 'center' },
              }}
            />
            <input
              accept="application/pdf,image/jpeg"
              style={{ display: 'none' }}
              id="basicidinput"
              onChange={(e) => uploadFile(e, 'basic')}
              type="file"
              multiple='false'
              className={classes.fileInput}
            />
            <label htmlFor="basicidinput">
              <IconButton color="primary" component="span">
                <PublishIcon />
              </IconButton>
            </label>
          </div>
        </div>
        {
          showBasicAddButton && basicFile.fileType !== "" ? (
            <div className="row mt-4">
              <div className="col text-center">
                <Button variant="contained" color="add_button"
                  className="ml-2 add_button mb-2" startIcon={<AddIcon />}
                  onClick={() => {
                    setBasicDocs(itr => {
                      let obj = {
                        label: basicFile.fileType,
                        value: basicfileObj.fileUrl,
                        filePayload: basicfileObj
                      }
                      return [...itr, obj]
                    });
                    setBasicFile({ fileType: '', fileName: '' });
                    setBasicAddButton(false)
                  }
                  }>
                  Add Document
                </Button>
              </div>
            </div>
          ) : null
        }
        <div className="row mt-2">
          <InputLabel className={classes.subHeader}>Uploaded Basic Documents</InputLabel>
          <ChipsArray ChipsArray={basicDocs} icon={true} deletable onDelete={removeBasicDocuments} onClick={openDocument}></ChipsArray>
        </div>


        {/* Comliance Documents */}
        <hr className={classes.splitLine}></hr>
        <div className="row mt-4">
          <div className="col text-center">
            <Typography variant="h5" className={classes.uploadedTitle}>
              Compliance Documents
          </Typography>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-center">
            <TextField
              id="comp"
              value={compFile?.fileType || ''}
              variant="outlined"
              InputProps={{
                style: { width: '110%', textAlign: 'center' },
              }}
              onChange={(e) => {
                setCompFile(data => ({ ...data, fileType: e.target.value }))
              }}
              placeholder="Enter type of Document"
            />
          </div>
        </div>
        <div className="row justify-content-center mt-1">
          <Typography variant="h7" className={classes.content}>
            Please add compliance certificates like ISO, OSHA etc.
          </Typography>
        </div>
        <div className="row mt-4">
          <div className="col text-center uploadButton">
            <TextField
              id='uploadcomp'
              type='text'
              variant="outlined"
              value={compFile?.fileName || ""}
              InputProps={{
                style: { textAlign: 'center' },
              }}
            />
            <input
              accept="application/pdf,image/jpeg"
              style={{ display: 'none' }}
              id="compuploadid"
              onChange={(e) => uploadFile(e, 'comp')}
              type="file"
              multiple='false'
              className={classes.fileInput}
            />
            <label htmlFor="compuploadid">
              <IconButton color="primary" component="span">
                <PublishIcon />
              </IconButton>
            </label>
          </div>
        </div>
        {
          showCompAddButton && compFile.fileType !== "" ? (
            <div className="row mt-4">
              <div className="col text-center">
                <Button variant="contained" color="add_button"
                  className="ml-2 add_button mb-2" startIcon={<AddIcon />}
                  onClick={() => {
                    setCompDocs(itr => {
                      let obj = {
                        label: compFile.fileType,
                        value: compfileObj.fileUrl,
                        filePayload: compfileObj
                      }
                      return [...itr, obj]
                    });
                    setCompFile({ fileType: '', fileName: '' });
                    setCompAddButton(false)
                  }
                  }>
                  Add Document
                </Button>
              </div>
            </div>
          ) : null
        }
        <div className="row mt-2">
          <InputLabel className={classes.subHeader}>Uploaded Compliance Documents</InputLabel>
          <ChipsArray ChipsArray={compDocs} icon={true} deletable onDelete={removeCompDocuments} onClick={openDocument}></ChipsArray>
        </div>


        {/* Technical Docs */}
        <hr className={classes.splitLine}></hr>
        <div className="row mt-4">
          <div className="col text-center">
            <Typography variant="h5" className={classes.uploadedTitle}>
              Technical Documents
            </Typography>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-center">
            <TextField
              id="tech"
              value={techFile?.fileType || ''}
              variant="outlined"
              InputProps={{
                style: { width: '110%', textAlign: 'center' },
              }}
              onChange={(e) => {
                setTechFile(data => ({ ...data, fileType: e.target.value }))
              }}
              placeholder="Enter type of Document"
            />
          </div>
        </div>
        <div className="row justify-content-center mt-1">
          <Typography variant="h7" className={classes.content}>
            Please add technical certificates like ASME etc.
          </Typography>
        </div>
        <div className="row mt-4">
          <div className="col text-center uploadButton">
            <TextField
              id='uploadtech'
              type='text'
              variant="outlined"
              value={techFile?.fileName || ""}
              InputProps={{
                style: { textAlign: 'center' },
              }}
            />
            <input
              accept="application/pdf,image/jpeg"
              style={{ display: 'none' }}
              id="techidupload"
              onChange={(e) => uploadFile(e, 'tech')}
              type="file"
              multiple='false'
              className={classes.fileInput}
            />
            <label htmlFor="techidupload">
              <IconButton color="primary" component="span">
                <PublishIcon />
              </IconButton>
            </label>
          </div>
        </div>
        {
          showTechAddButton && techFile.fileType !== "" ? (
            <div className="row mt-4">
              <div className="col text-center">
                <Button variant="contained" color="add_button"
                  className="ml-2 add_button mb-2" startIcon={<AddIcon />}
                  onClick={() => {
                    setTechDocs(itr => {
                      let obj = {
                        label: techFile.fileType,
                        value: techfileObj.fileUrl,
                        filePayload: techfileObj
                      }
                      return [...itr, obj]
                    });
                    setTechFile({ fileType: '', fileName: '' });
                    setTechAddButton(false)
                  }
                  }>
                  Add Document
                </Button>
              </div>
            </div>
          ) : null
        }
        <div className="row mt-2">
          <InputLabel className={classes.subHeader}>Uploaded Technical Documents</InputLabel>
          <ChipsArray ChipsArray={techDocs} icon={true} deletable onDelete={removeTechDocuments} onClick={openDocument}></ChipsArray>
        </div>



        <div className="row mt-4">
          <div className="col text-right">
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<CloudUploadIcon />} 
              onClick={() => updateVendor(gstn, basicDocs, compDocs, techDocs)}
            >
              Save
            </Button>
          </div>
        </div>

      </div>
    </div>
  :
  <NoAccess></NoAccess>
  )
}


export default VendorCertificatesUpdate;


