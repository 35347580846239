import { Button } from "antd";
import { TagAntd } from "components/CardTag/CardTag.styles";
import styled from "styled-components";

export const UserRolesLayout = styled.div`
  position: relative;
  background-color: #ffffff;
  height: calc(100vh - 200px);
`;

export const UserRolesSection = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const UserRolesConatinerDiv = styled.div<any>`
  margin: 20px 40px 0px;
  padding-bottom: 20px;
  border-bottom: ${(props) => props?.showBottomBorder && "1px solid #D4E5FE"};

  ${TagAntd} {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #215ec9;
    height: 30px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;

export const UserRolesLayoutDivider = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  border-left: 1px solid #d4e5fe;
`;

export const RoleName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 170%;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #011759;
`;

export const AddUserButton = styled(Button)`
  background: #215ec9;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  width: 100px;
  height: 30px;
  margin-bottom: 8px;
  font-family: "Roboto";
`;
