import React from "react";
import { IOtherDocumentsKeyNamesTypeWithoutGstn, IReportDocumentsKeyNamesType, OTHER_DOCUMENTS_KEY_NAMES_ENUM, REPORTS_KEY_NAMES_ENUM } from "./vendorDocumentsUpdate.interface";

export const COLUMNS = (tableColumnName: (x: string) => React.ReactNode, isViewOpen: boolean) => isViewOpen ? [
  { name: tableColumnName("documents"), width: "20%" },
  { name: tableColumnName("Source Field"), width: "20%" },
  { name: tableColumnName("Source Value"), width: "20%" },
  { name: tableColumnName("Destination Field"), width: "15%" },
  { name: tableColumnName("Destination Value"), width: "15%" },
] : [
  { name: tableColumnName("documents"), width: "20%" },
  { name: tableColumnName("Source Field"), width: "20%" },
  { name: tableColumnName("Source Value"), width: "20%" },
];

export const DOCUMENT_NAMES: { [key in string]: string } = {
  "brochure": "Brochure",
  "addressProof": "Address Proof",
  "productCatalog": "Product Catalog",
  "customersListFile": "Customers List File",
  "gst": "GST",
  "pan": "PAN",
  "cin": "CIN",
  "cancelledCheque": "Cancelled Cheque",
  "experience": "Experience",
  "epf": "EPF",
  "esic": "ESIC",
  "technical": "Technical",
  "oem": "OEM",
  "otherDocuments": "Other Documents",
};

export const COMMON_DOCUMENTS_UPLOAD_OPTIONS: {
  value: IOtherDocumentsKeyNamesTypeWithoutGstn;
  label: string;
}[] = [
  {
    value: OTHER_DOCUMENTS_KEY_NAMES_ENUM.pan,
    label: "PAN"
  },
  {
    value: OTHER_DOCUMENTS_KEY_NAMES_ENUM.incorpCertif,
    label: "Incorporation Certificate"
  },
  {
    value: OTHER_DOCUMENTS_KEY_NAMES_ENUM.financialStatements,
    label: "Financial Statements"
  },
  {
    value: OTHER_DOCUMENTS_KEY_NAMES_ENUM.itr,
    label: "ITR"
  },
  {
    value: OTHER_DOCUMENTS_KEY_NAMES_ENUM.gstr3b,
    label: "GSTR-3B"
  },
  {
    value: OTHER_DOCUMENTS_KEY_NAMES_ENUM.netProfitStatements,
    label: "Net Profit Statements"
  },
  {
    value: OTHER_DOCUMENTS_KEY_NAMES_ENUM.manPowerSplit,
    label: "Man Power Split"
  },
  {
    value: OTHER_DOCUMENTS_KEY_NAMES_ENUM.MSME,
    label: "MSME"
  },
  {
    value: OTHER_DOCUMENTS_KEY_NAMES_ENUM.others,
    label: "Others"
  },
];

export const REPORTS_UPLOAD_OPTIONS: {
  value: IReportDocumentsKeyNamesType;
  label: string;
}[] = [
  {
    value: REPORTS_KEY_NAMES_ENUM.VCB,
    label: "VCB"
  },
  {
    value: REPORTS_KEY_NAMES_ENUM.TPI,
    label: "TPI"
  },
  {
    value: REPORTS_KEY_NAMES_ENUM.VCR,
    label: "VCR"
  },
  {
    value: REPORTS_KEY_NAMES_ENUM.VIR,
    label: "VIR"
  },
  {
    value: REPORTS_KEY_NAMES_ENUM.financialAnalysis,
    label: "Financial Analysis"
  },
  {
    value: REPORTS_KEY_NAMES_ENUM.pastJobFeedback,
    label: "Past Job Feedback"
  },
  {
    value: REPORTS_KEY_NAMES_ENUM.others,
    label: "others"
  }
];

export const SENSITIVE_DOC_UPLOAD_TYPE_OPTIONS = [
  {
    value: "nda",
    label: "NDA"
  }
]

export const SENSITIVE_DOC_UPLOAD_CLIENT_OPTIONS = [
  {
    value: "primetals",
    label: "Primetals"
  }
]
