const NoAccess = () => {
    return (
        <div>
            Sorry, you do not have access to this page. Please contact admin.
        </div>

    )

}

export default NoAccess;
