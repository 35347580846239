import { Autocomplete, Backdrop, Box, Button, CircularProgress, FormControl, Input, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { useStyles } from "components/VendorCapabilitiesUpdate/constant";
import { JobRequestActions } from "container/JobRequests/actions";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Flex, Typography } from "venwiz-ui";
import { FORM_DATA_KEY } from "../../jobRequest.constants";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ComparisonDetails = () => {
  const { _id } = useParams<{ _id: string }>();
  const dispatch = useDispatch();
  const [comparisonData, setComparisonData] = useState<any>();
  const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [showBOQHistory, setShowBOQHistory] = useState<boolean>(false); // State to handle BOQ history view
  const [revisedQuote, setRevisedQuote] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [vendorFiles, setVendorFiles] = useState<any>()
  const [vendorFileName, setVendorFileName] = useState<any>({});
  const [comparisonHistoryType, setComparisonHistoryType] = useState();
  const [comparisonHistory, setComparisonHistory] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState<any>([]);
  const [comparisonJobId, setComparisonJobId] = useState("")
  const [jobIds, setJobIds] = useState<string[]>([]);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  useEffect(() => {
    getJRIDs();
  }, []);

  const getJRIDs = () => {
    return dispatch(JobRequestActions.getAllJobIds()
    ).then((data: any) => {
      setJobIds(["None", ...data.map((d: any) => d.requestId)])
    });
  }

  const handleCheckboxChange = (e: any, vendorId: string, versionId: string) => {
    const checkbox = e.target;
    const action = e.target.checked ? "include this quote version and add to comparison" :
      "remove this quote version from comparisons"
    const confirmed = window.confirm(`Are you sure you want to ${action}?`);
    if (e.target.checked && confirmed) {
      setIsLoading(true);
      dispatch(JobRequestActions.includeVersion(_id, vendorId, versionId)
      ).then(() => {
        refresh();
        setIsLoading(false);
        closeRightContainer()
      });
      setSelectedVersions([...selectedVersions, versionId]);

    } else if (confirmed) {
      checkbox.checked = !checkbox.checked;
      setIsLoading(true);
      dispatch(JobRequestActions.excludeVersion(_id, vendorId, versionId)
      ).then(() => {
        refresh();
        setIsLoading(false);
        closeRightContainer()
      });
    }
    setSelectedVersions(selectedVersions.filter((id: any) => id !== versionId));

  };
  const handleArchive = (id: string) => {
    setIsLoading(true);
    dispatch(JobRequestActions.archiveVendor(_id, id)
    ).then(() => {
      refresh();
      setIsLoading(false);
    });
  };

  const handleUnArchive = (id: string) => {
    setIsLoading(true);
    dispatch(JobRequestActions.unArchiveVendor(_id, id)
    ).then(() => {
      refresh();
      setIsLoading(false);
      closeRightContainer()
    });
  };

  const filterHistory = (e: any) => {
    setComparisonHistoryType(e?.target?.value);
    const type = e?.target?.value == "versions" ? "versions" : "latest"
  }
  const toggleAccordion = (index: number) => {
    setOpenRowIndex(openRowIndex === index ? null : index);
  };

  const handleRowClick = (vendor: any) => {
    setSelectedVendor(vendor);
    setShowBOQHistory(false); // Reset BOQ history view when vendor is selected
  };

  const closeRightContainer = () => {
    setSelectedVendor(null);
    setShowBOQHistory(false); // Close BOQ history view when container is closed
  };

  const addRevisedQuote = (e: any) => {
    const files = e.target.files;
    const updatedFiles = Array.from(files).map((file: any) => {
      const timestamp = Date.now(); // Generate the timestamp
      const uniqueFileName = `${file.name.split('.')[0]}_@_${timestamp}.${file.name.split('.').pop()}`; // Append timestamp to filename
      return new File([file], uniqueFileName, { type: file.type });
    });
    setRevisedQuote(updatedFiles[0]);
  };

  const submitRevisedQuote = (vendor: any) => {
    const formData = new FormData();
    formData.append(FORM_DATA_KEY, revisedQuote);
    formData.append("payload", JSON.stringify({ ...vendor, id: _id }));
    setIsLoading(true);
    dispatch(JobRequestActions.updateVendorQuote(formData, { ...vendor, id: _id })
    ).then(() => {
      refresh();
      setIsLoading(false);
      setRevisedQuote({});
      closeRightContainer()
    });
  };

  const addNewVendorQuote = () => {
    setIsModalOpen(true)
  }

  useEffect(() => {
    dispatch(JobRequestActions.getComparisonById(_id))
      .then((data: any) => {
        setComparisonJobId(data.jobId)
        setComparisonData(data);
        setComparisonHistory(data.previous_comparisons)
      });
  }, []);

  const refresh = () => {
    dispatch(JobRequestActions.getComparisonById(_id))
      .then((data: any) => {
        setComparisonData(data);
        setComparisonHistory(data.previous_comparisons)
      });
  };

  const handleBOQHistoryClick = () => {
    setSelectedVendor(null); // Reset vendor view
    setShowBOQHistory(true); // Show BOQ history
  };

  const editSheet = (url: string) => {
    setIsLoading(true)
    dispatch(JobRequestActions.makeSheetEditable({ url, id: _id }))
      .then((data: any) => {
        setTimeout(() => {
          setIsLoading(false); // Hide loading after 10 seconds
          window.open(url, '_blank');
          refresh()
        }, 1000);
      });
  }

  const submitVendorFiles = (e: any) => {
    const files = e.target.files;
    const updatedFiles = Array.from(files).map((file: any) => {
      const timestamp = Date.now(); // Generate the timestamp
      const uniqueFileName = `${file.name.split('.')[0]}_@_${timestamp}.${file.name.split('.').pop()}`; // Append timestamp to filename
      return new File([file], uniqueFileName, { type: file.type });
    });
    setVendorFiles(updatedFiles)
  }

  const handleVendorNameChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedName = { ...vendorFileName };
    updatedName[vendorFiles[index]?.name?.replace(".xlsx", '')] = e.target.value;
    setVendorFileName(updatedName);
  };

  const submitVendorQuotes = () => {
    const formData = new FormData();
    Array.from(vendorFiles).map((file: any) => {
      formData.append(FORM_DATA_KEY, file);
    })
    formData.append("payload", JSON.stringify({ id: _id, vendorFileName }));
    setIsLoading(true);
    dispatch(JobRequestActions.addNewVendorQuotes(formData, { id: _id, vendorFileName }))
      .then((data: any) => {
        setTimeout(() => {
          clearModal();
          setIsLoading(false); // Hide loading after 10 seconds
        }, 1000);
      });
  }
  const getVendorName = (quote: any) => {
    const fileKey = Object.keys(comparisonData?.comparison_constant?.[0]?.vendorFileName)?.find(v => v === quote?.name?.replace(".xlsx", ''));
    return fileKey ? comparisonData?.comparison_constant?.[0]?.vendorFileName?.[fileKey] : ""

  }
  const clearModal = () => {
    setVendorFiles([])
    setIsModalOpen(false)
    setVendorFileName([])
  }

  const recreateComparison = () => {
    setIsLoading(true);
    dispatch(JobRequestActions.recreateComparison({ id: _id, url: comparisonData.subcategory_sheet }))
      .then((data: any) => {
        setTimeout(() => {
          setIsLoading(false);
          refresh()
        }, 1000);
      });
  }

  const updateComparisonId = (jobId: string) => {
    setComparisonJobId(jobId);
    dispatch(JobRequestActions.updateComparisonJobId(_id, { jobId, })).then((data: any) => {
      setTimeout(() => {
        setIsLoading(false);
        refresh()
      }, 1000);
    });

  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      < Modal open={isModalOpen} onClose={(event, reason) => {
        // Prevent closing on clicking the backdrop or pressing the 'Escape' key
        if (reason === "backdropClick") {
          return;
        }
        setIsModalOpen(false);
      }}>
        <Box
          style={{
            width: "500px",
            margin: "100px auto",
            backgroundColor: "white",
            maxHeight: "80vh", // Limit height to 80% of the viewport
            overflowY: "auto", // Enable vertical scrolling
            padding: "20px",
            textAlign: "left",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            position: "relative",
            justifyItems: "center",
            display: "grid"
          }}
        >
          <Box style={{ width: "100%", padding: "30px" }}>
            <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
              <Button variant="contained" style={styles.button} component="label">

                Upload Vendor Offers
                <input type="file" multiple={true} hidden onChange={submitVendorFiles} />
              </Button>
            </div>
            {!!vendorFiles?.length && <Typography>No. of Vendor Quotes uploaded: {vendorFiles?.length}</Typography>
              &&
              <Box padding={"10px"}>
                <Typography padding={"10px"}> Please enter vendor names corresponding to file: </Typography>
                {Array.from(vendorFiles)?.map((file: any, index: number) => (
                  <Flex padding={"10px"}>
                    <Typography>{file.name.split("_@_")[0]}</Typography>
                    <Input
                      style={{ padding: "0", margin: "0px 30px", width: "40%" }}
                      onChange={(e: any) => handleVendorNameChange(e, index)}
                      value={file.vendorName}></Input>
                  </Flex>
                ))}
              </Box>
            }
          </Box>
          {!!vendorFiles?.length && <Button style={styles.button} onClick={submitVendorQuotes}>
            Add Vendor Quotes and update Comparison
          </Button>}
        </Box>

      </Modal >
      <div style={styles.container}>
        {/* Left Container */}
        <div style={{ ...styles.column, width: selectedVendor || showBOQHistory ? '48%' : '100%' }}>
          <h3>Comparison details: </h3>
          {/* <a target="_blank" style={styles.viewChip} href={comparisonData?.result}>View LATEST Versions Comparison</a>
          {!!comparisonData?.result_with_versions && <a target="_blank" style={styles.viewChip} href={comparisonData?.result_with_versions}>View All Versions Comparison</a>}
 */}

          <p>
            <FormControl variant="outlined" style={{ marginTop: "5px", width: "150px" }}>
              <Autocomplete
                options={jobIds}
                getOptionLabel={(option: any) => option || ""}
                value={comparisonJobId || null}
                onChange={(event, newValue: any) => {
                  // Update selected jobId in state
                  updateComparisonId(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job ID"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>

          </p>
          <p>BOQ:
            <a target="_blank" style={styles.viewChip} href={comparisonData?.boq?.url}>View</a>
          </p>
          {!!comparisonData?.subcategory_sheet && <p>Category Sheet: <a target="_blank" style={styles.viewChip} href={comparisonData?.subcategory_sheet}>View</a>
            <a target="_blank" style={styles.editChip} onClick={() => { editSheet(comparisonData?.subcategory_sheet) }}>Edit</a>
            {!!comparisonData?.subcategory_edit && <a target="_blank" style={styles.editChip} onClick={() => { recreateComparison() }}>Freeze Edit &  Re-create Comparison</a>}
          </p>}

          {comparisonData?.comparison_constant?.map((sheet: any) => (
            <div key={sheet?.name} style={{ fontSize: "12px" }}>
              <p style={{ fontWeight: 600 }}>Sheet Name: {sheet.name}</p>
              <p>Header Row Number: {sheet.headerRow}</p>
              <p>Columns to fill by Vendor:</p>
              <div style={styles.chipContainer}>
                {sheet.vendorToFill.map((chip: string, index: number) => (
                  <div key={index} style={styles.chip}>
                    {chip}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {/* Button to show BOQ history */}
          <Flex style={{ justifyContent: "space-between", maxWidth: "425px" }}>
                <Button style={styles.button} onClick={handleBOQHistoryClick}>
                  Show Comparison History
                </Button>


                <Button style={styles.button} onClick={addNewVendorQuote}>
                  Add new Vendor Quote
                </Button>
          </Flex>

          <hr />
          <h3>Vendor Quotes: </h3>

          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>File Name</th>
                <th style={styles.th}>Vendor Name</th>
                <th style={styles.th}>Quote Version</th>
                <th style={styles.th}>Quote Link</th>
                <th style={styles.th}>Uploaded At</th>
                <th style={styles.th}></th> {/* For three dots */}
              </tr>
            </thead>
            <tbody>
              {comparisonData?.vendor_quotes_name?.map((quote: any, index: number) => (
                <tr key={index} onClick={() => handleRowClick(quote)}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                  style={{
                    ...styles.row,
                    backgroundColor: quote.isDeleted ? '#e0e0e0' : hoveredRow === index ? '#f0f0f0' : 'transparent',
                  }}
                >
                  <td style={styles.td}>{quote.name.split("_@_")[0]}</td>
                  <td style={styles.td}>{getVendorName(quote)}</td>
                  <td style={styles.td}>{quote?.versions?.length + 1 || 1}</td>
                  <td style={styles.td}>
                    <a href={quote.url} target="_blank">View Quote</a>
                  </td>
                  <td style={styles.td}>{moment(quote.updatedAt).format("DD-MM-YYYY - hh:mm A")}</td>
                  <td style={styles.td}>
                    {!quote.isDeleted && <button
                      style={{
                        ...styles.threeDotsButton,
                        cursor: quote.isDeleted ? 'not-allowed' : 'pointer',
                        color: quote.isDeleted ? '#aaa' : '#00796B',
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        quote.isDeleted && handleArchive(quote._id);
                        handleArchive(quote._id);
                      }}
                      disabled={quote.isDeleted}
                    >
                      Archive Vendor
                    </button>}

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Right Container for Vendor or BOQ History */}
        {(selectedVendor || showBOQHistory) && (
          <div style={styles.rightColumn}>
            <button style={styles.closeButton} onClick={closeRightContainer}>X</button>
            {showBOQHistory ? (
              <>
                <div style={styles.headerContainer}>
                  <h2>Comparison History</h2>
                  <select style={styles.dropdown} onChange={(e) => { filterHistory(e) }}>
                    <option value="latest"> Latest quotes </option>
                    <option value="versions"> All versions</option>
                  </select>
                </div>
                <table style={styles.table}>
                  <thead>
                    <tr>
                      <th style={styles.th}>Comparison Version</th>
                      <th style={styles.th}>View</th>
                      <th style={styles.th}>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {((comparisonHistoryType === "versions" && !!comparisonData?.result_with_versions) || (comparisonHistoryType !== "versions" && !!comparisonData?.result)) && <tr
                      key={'FIRST-ROW'}
                      style={
                        styles.row
                      }
                    >

                      <td style={styles.td}>LATEST</td>
                      <td style={styles.td}>
                        <a
                          href={comparisonHistoryType === "versions" ? comparisonData?.result_with_versions : comparisonData?.result}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </td>
                      <td style={styles.td}>{comparisonHistoryType === "versions" ? moment(comparisonData.updatedAt_versions).format("DD-MM-YYYY - hh:mm A") : moment(comparisonData.updatedAt_latest).format("DD-MM-YYYY - hh:mm A")}</td>

                    </tr>}
                    {comparisonHistory
                      .filter((c: any) => comparisonHistoryType === "versions" ? c.type === comparisonHistoryType : c.type === "latest").sort(
                        (a: any, b: any) =>
                          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                      ).map((c: any, index: number) => (
                        < tr key={index} style={styles.row} >
                          <td style={styles.td}>Version {comparisonHistory.filter((c: any) => comparisonHistoryType === "versions" ? c.type === comparisonHistoryType : c.type === "latest").length - index}</td>
                        <td style={styles.td}>
                            <a href={c.url} target="_blank" rel="noopener noreferrer">View</a>
                          </td>
                          <td style={styles.td}>{moment(c.createdAt).format("DD-MM-YYYY - hh:mm A")}</td>
                      </tr>

                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                {/* Vendor Details Section (existing) */}
                  <div style={{ display: "flex", justifyContent: "space-between", width: "95%" }}>

                    <h2>Vendor Details</h2>
                    {!!selectedVendor?.isDeleted && <Button
                      variant="contained"
                      style={styles.button}
                      onClick={() => handleUnArchive(selectedVendor._id)}
                    >
                      Unarchive Vendor
                    </Button>}
                  </div>

                  <p>Quote Details for {selectedVendor.name.split("_@_")[0]}</p>

                  <div style={styles.buttonContainer}>
                    <Button variant="contained" component="label" style={selectedVendor.isDeleted ? {} : styles.button}
                      disabled={selectedVendor.isDeleted}>
                      Add Revised Quote
                      <input type="file" hidden onChange={addRevisedQuote} />
                    </Button>
                    {!!revisedQuote?.name && (
                      <>
                        <Typography style={styles.typography}>{revisedQuote.name.split("_@_")[0]}</Typography>
                        <button style={styles.delButton} onClick={() => setRevisedQuote({})}>X</button>
                      </>
                    )}
                    <Button
                      disabled={!revisedQuote?.name}
                      variant="contained"
                      style={!revisedQuote?.name ? {} : styles.button}
                      onClick={() => submitRevisedQuote(selectedVendor)}
                    >
                      Submit
                    </Button>
                  </div>

                  {/* Vendor Quote History */}
                  <Accordion style={{ marginTop: "10px" }} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography>View Vendor Quote History</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table style={styles.table}>
                        <thead>
                          <tr>
                            <th style={styles.th}>Version</th>
                            <th style={styles.th}>View</th>
                            <th style={styles.th}>Uploaded At</th>
                            <th style={styles.th}>Include version?</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            key={'FIRST-ROW'}
                            style={
                              styles.row
                            }
                          >

                            <td style={styles.td}>LATEST</td>
                            <td style={styles.td}>
                              <a
                                href={selectedVendor.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View
                              </a>
                            </td>
                            <td style={styles.td}>
                              {moment(selectedVendor.updatedAt).format('DD-MM-YYYY - hh:mm A')}
                            </td>
                            <td style={styles.td}>
                              <input
                                type="checkbox"
                                checked={true} disabled={true}
                              />
                            </td>
                          </tr>
                          {selectedVendor?.versions
                            ?.sort(
                              (a: any, b: any) =>
                                new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
                            )
                            ?.map((version: any, versionIndex: number) => (
                              <tr
                                key={versionIndex}
                                style={
                                  !version.include
                                    ? { ...styles.row, ...styles.greyedOut }
                                    : styles.row
                                }
                              >
                                <td style={styles.td}>{selectedVendor?.versions.length - versionIndex}</td>
                                <td style={styles.td}>
                                  <a
                                    href={version.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View
                                  </a>
                                </td>
                                <td style={styles.td}>
                                  {moment(version.updatedAt).format('DD-MM-YYYY - hh:mm A')}
                                </td>
                                <td style={styles.td}>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => handleCheckboxChange(e, selectedVendor._id, version.version_id,)}
                                    checked={version.include || selectedVersions.includes(version.version_id)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </AccordionDetails>
                </Accordion>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px',
    // height: '100vh',
    boxSizing: 'border-box',
    overflowY: 'auto', /* Vertical scrolling */
    overflowX: 'auto', /* Horizontal scrolling (if needed) */

  },
  greyedOut: {
    backgroundColor: '#d3d3d3',
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
    tableLayout: 'fixed',
    overflowX: 'auto',
    maxHeight: '100%',
  },
  th: {
    borderBottom: '2px solid #ddd',
    textAlign: 'left',
    padding: '12px',
    backgroundColor: '#f2f2f2',
    fontSize: '14px',
    color: '#00796B',
    fontWeight: 'bold',
    overflow: 'hidden',
  },
  td: {
    borderBottom: '1px solid #ddd',
    padding: '12px',
    fontSize: '12px',
    color: '#4A4A4A',
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
  row: {
    cursor: 'pointer',
    color: '#00796B',
    fontSize: '12px',
    transition: 'background-color 0.3s ease',
  },
  threeDotsButton: {
    fontSize: '16px',
    color: '#00796B',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '2px',
    backgroundColor: '#fff',
  },
  archiveOption: {
    cursor: 'pointer',
    color: '#00796B',
    fontSize: '12px',
  },
  column: {
    backgroundColor: '#f9f9f9',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    boxSizing: 'border-box',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
  chip: {
    padding: '4px 8px',
    backgroundColor: '#E0F7FA',
    borderRadius: '8px',
    color: '#00796B',
    fontSize: '10px',
    fontWeight: 'bold',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    display: 'inline-block',
    marginBottom: '10px',
  },
  viewChip: {
    padding: '5px 30px',
    backgroundColor: '#DAF0FF',
    borderRadius: '3px',
    color: '#0077B6',
    fontSize: '12px',
    fontWeight: 'bold',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    display: 'inline-block',
    margin: '0px 0px 10px 10px',
  },
  editChip: {
    padding: '5px 30px',
    backgroundColor: '#CEFAD0',
    borderRadius: '3px',
    color: 'green',
    fontSize: '12px',
    fontWeight: 'bold',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    display: 'inline-block',
    margin: '0px 0px 0px 10px',
  },
  button: {
    padding: '6px 12px',
    backgroundColor: '#00796B',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    fontSize: '12px',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
  },
  typography: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#4A4A4A',
    margin: '10px 0',
  }, accordion: {
    backgroundColor: '#f0f0f0',
    padding: '16px',
    border: '1px solid #ddd',
    textAlign: 'center' as 'center',
    color: '#4A4A4A',
    fontSize: '12px',
  },

  rightColumn: {
    width: '48%',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    // position: 'relative',
    overflowY: 'auto',
    // height: '100%', // Full viewport height
    boxSizing: 'border-box', // Ensure padding is included in height
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '18px',
    color: '#00796B',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '4px 8px',
  },

  delButton: {
    backgroundColor: 'red',
    border: 'none',
    borderRadius: "8px",
    fontSize: '8px',
    color: '#fff',
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  dropdown: {
    padding: '6px 12px',
    borderRadius: '4px',
    border: '1px solid #00796B',
    backgroundColor: '#fff',
    color: '#00796B',
    fontSize: '14px',
    textDecoration: "bold",
    width: "200px",
    cursor: 'pointer',
  },

};

export default ComparisonDetails;
