import React from "react";
import { CAPABILITIES_INFO_OPTION } from "../../constants/updateVendorConstants";
import UpdateVendorProfile from "../../container/UpdateVendorProfiles/UpdateVendorProfiles";
import { PromptProvider } from "components/Modal/PromptProvider";
const UpdateCapabilities = () => (
  <PromptProvider>
    <UpdateVendorProfile bodyContent={CAPABILITIES_INFO_OPTION} />
  </PromptProvider>
);

export default UpdateCapabilities;