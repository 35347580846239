import { Tag } from "antd";
import styled from "styled-components";
import { CardTagProps } from "./CardTag";


export const TagAntd = styled(Tag)<CardTagProps>`
  display: flex;
  justify-content: space-between;
  background: ${props => `${props.$bgColor || "#F4F6FF"}`};
  padding: ${props =>
    `${
      props?.$usedFor === "vendorList"
        ? "0.7rem 0rem 0.7rem 1rem"
        : "0.87rem 0rem 0.87rem 1rem"
    }`};
  border-radius: 0.5rem;

  border: ${props =>
    `${
      props?.$usedFor === "vendorList" ? "border: 1px solid #d4e5fe" : "none"
    }`};
  align-items: center;
  &.ant-tag {
    margin-right: 0px;
  }
  &.ant-tag-close-icon {
    margin-left: 10px;
  }
`;
export const Div = styled.div<CardTagProps>`
  cursor: ${props => `${props.cursorType}`};
  display: flex;
  align-items: center;
  overflow: hidden;
`;
export const LogoWrapper = styled.span<CardTagProps>`
  width: 2rem;
  height: 2rem;
  cursor: ${props => `${props.cursorType}`};
  padding-right: 0.63rem;
`;
export const Text = styled.div<CardTagProps>`
  cursor: ${props => `${props.cursorType}`};
  color: ${props =>
    `${
      props?.$usedFor === "compareVendor"
        ? "#011759"
        : props.theme.colors?.[`${props.fontColor}`] || props.theme.colors?.primary
    }`};
  font-size: ${props =>
    props?.$usedFor === "compareVendor"
      ? "12px"
      : props.theme?.fontSizes?.body?.fontSize};
  font-weight: ${props =>
    `${
      props?.$usedFor === "compareVendor"
        ? "normal"
        : props.theme?.fontWeights?.bold
    }`};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const CloseIconWrap = styled.div`
  padding-right: 1.2rem;
`;
export const CardSizeText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #011759;
`;
export const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;