import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Backdrop, CircularProgress } from "@material-ui/core";
import { JobRequestActions } from "container/JobRequests/actions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Flex } from "venwiz-ui";
import { useStyles } from "../CompareQuotation/CompareQuotation";

const ComparisonAutomation = () => {
  const [rows, setRows] = useState<any>({})
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();


  useEffect(() => {
    dispatch(JobRequestActions.getAutomationData()
    ).then((data: any) => {
      console.log(data)
      setRows(data)
    });
  }, [])

  const runAutomation = () => {
    setIsLoading(true)
    dispatch(JobRequestActions.runAutomation()
    ).then((data: any) => {
      setIsLoading(false)
    });
  }
  return <>
    <Flex style={{ justifyContent: "center", alignItems: "center", width: "100%" }}>
      <Button onClick={runAutomation} style={{ margin: "50px" }}
        disabled={isLoading}
        variant={"primary"} colorSchema={"blue"} width={"20%"}>
        Run automation
      </Button>
    </Flex>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell>View Comparison </TableCell>
            <TableCell>Success % </TableCell>
            <TableCell>Failed Rows Info (Hover for row numbers)</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {!!rows && Object.keys(rows)?.map((row: any, index: number) => (
            <TableRow key={row}>
              <TableCell>{new Date(rows[row][0].createdAt).toLocaleString()}</TableCell>
              <TableCell>{rows[row][0].status}</TableCell>
              <TableCell>
                {rows[row].map((r: any) => (
                  <> <Flex>
                    <Box>
                      {r.boq} -
                    </Box>
                    <a target="_blank" rel="noopener noreferrer" href={r?.url}>
                      view
                    </a>
                  </Flex>
                  </>
                ))}
              </TableCell>
              <TableCell>
                {rows[row].map((r: any) => (
                  <> <Flex>
                    <Box>
                      {r.boq} -
                    </Box>
                    <Box>
                      {r.result.map((sheet: any) => (
                        <Flex>
                          <Box>
                            {sheet.sheetName} -
                          </Box>
                          <Box color="green">
                            {Math.round(((sheet.totalRows - sheet.unmatchedRows) * 100) / sheet.totalRows)} %
                          </Box>
                        </Flex>
                      ))}
                    </Box>
                  </Flex>
                  </>
                ))}
              </TableCell>

              <TableCell>
                {rows[row].map((r: any) => (
                  <> <Flex>
                    <Box>
                      {r.boq} -
                    </Box>
                    <Box>
                      {r.result.map((sheet: any) => (
                        <Tooltip title={JSON.stringify(sheet?.unmatchedRowNumbers)}>
                          <Flex>
                            <Box>
                              {sheet.sheetName} -
                            </Box>
                            <Box color={sheet.unmatchedRows === 0 ? "green" : "red"}>
                              {sheet.unmatchedRows} / {sheet.totalRows} failed Rows
                            </Box>
                          </Flex>
                        </Tooltip>
                      ))}
                    </Box>
                  </Flex>
                  </>
                ))}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

export default ComparisonAutomation;