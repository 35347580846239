
import { Types as ClientCommunicationTypes } from "../../../_types/ClientCommunicationTypes";
export const ActionCreators: any = {
  getSelectedChat: (data: any) => ({
      type: ClientCommunicationTypes.GET_SELECTED_CHAT_ID,
      payload: data,
    }),
    getMultiSelectedChats: (data: any) => ({
      type: ClientCommunicationTypes.GET_MULTI_SELECTED_CHAT_ID,
      payload: data,
    }),
  clearInitiateChat: (dispatch: any, data: any) => {
    dispatch({
      type: ClientCommunicationTypes.CLEAR_SELECTED_CHAT_ID,
      payload: data,
    });
  },

  getChatList: (params: any) => ({
    types: [
      ClientCommunicationTypes.CHAT_LIST_REQUESTED,
      ClientCommunicationTypes.CHAT_LIST_SUCCESS,
      ClientCommunicationTypes.CHAT_LIST_FAILURE,
    ],
    promise: (client: any) =>
      client.get(
        "admin_chatlist_get_data",
        {
          params,
        },
        "communication"
      ),
  }),

  getChatMessages: (params: any) => ({
    types: [
      ClientCommunicationTypes.CHAT_MESSAGES_REQUESTED,
      ClientCommunicationTypes.CHAT_MESSAGES_SUCCESS,
      ClientCommunicationTypes.CHAT_MESSAGES_FAILURE,
    ],
    promise: (client: any) =>
      client.get(
        `chat_get_messages`,
        {
          params,
        },
        "communication"
      ),
  }),

  getOldChatMessages: (params: any) => ({
    types: [
      ClientCommunicationTypes.CHAT_OLD_MESSAGES_REQUESTED,
      ClientCommunicationTypes.CHAT_OLD_MESSAGES_SUCCESS,
      ClientCommunicationTypes.CHAT_OLD_MESSAGES_FAILURE,
    ],
    promise: (client: any) =>
      client.get(
        `chat_get_old_messages`,
        {
          params,
        },
        "communication"
      ),
  }),

  sendMessage: (data: any) => ({
    types: [
      ClientCommunicationTypes.CHAT_SEND_MESSAGE_REQUESTED,
      ClientCommunicationTypes.CHAT_SEND_MESSAGE_SUCCESS,
      ClientCommunicationTypes.CHAT_SEND_MESSAGE_FAILURE,
    ],
    promise: (client: any) =>
      client.post(
        "send_message",
        {
          data,
        },
        "communication"
      ),
  }),
  setInitialMessage: (data: any) => ({
    types: [
      ClientCommunicationTypes.CHAT_INITIATE_REQUESTED,
      ClientCommunicationTypes.CHAT_INITIATE_SUCCESS,
      ClientCommunicationTypes.CHAT_INITIATE_FAILURE,
    ],
    promise: (client: any) =>
      client.post(
        "chat_initiate",
        {
          data,
        },
        "communication"
      ),
  }),

  deleteAllChats: (params: any) => ({
    types: [
      ClientCommunicationTypes.DELETE_ALL_CHAT_REQUESTED,
      ClientCommunicationTypes.DELETE_ALL_CHAT_SUCCESS,
      ClientCommunicationTypes.DELETE_ALL_CHAT_FAILURE,
    ],
    promise: (client: any) =>
      client.get(
        "delete_all_chats",
        {
          params,
        },
        "communication"
      ),
  }),
  resetHasMoreMessages: (data: any) => ({
      type: ClientCommunicationTypes.RESET_HAS_MORE_MESSAGES,
      payload: data
  }),

  setIsScrollingActive: (data: any) => ({
      type: ClientCommunicationTypes.SET_IS_SCROLLING_ACTIVE,
      payload: data,
   }),

   ///admin messaging actions

   getAdminSelectedUserData: (data: any) => ({
    type: ClientCommunicationTypes.GET_SELECTED_CHAT_ID,
    payload: data,
  }),

getVendorChatList: (params: any) => ({
  types: [
    ClientCommunicationTypes.CHAT_LIST_REQUESTED,
    ClientCommunicationTypes.CHAT_LIST_SUCCESS,
    ClientCommunicationTypes.CHAT_LIST_FAILURE,
  ],
  promise: (client: any) =>
    client.get(
      "get_vendor_list",
      {
        params,
      },
    ),
}),
getClientChatList: (params: any) => ({
  types: [
    ClientCommunicationTypes.CHAT_LIST_REQUESTED,
    ClientCommunicationTypes.CHAT_LIST_SUCCESS,
    ClientCommunicationTypes.CHAT_LIST_FAILURE,
  ],
  promise: (client: any) =>
    client.get(
      "get_client_list",
      {
        params,
      },
    ),
}),
  adminInitiateSingleChat: (data: any) => ({
  types: [
    ClientCommunicationTypes.ADMIN_CHAT_INITIATE_REQUESTED,
    ClientCommunicationTypes.ADMIN_CHAT_INITIATE_SUCCESS,
    ClientCommunicationTypes.ADMIN_CHAT_INITIATE_FAILURE,
  ],
  promise: (client: any) =>
    client.post(
      "single_chat_initiate",
      {
        data,
      },
      "communication"
    ),
}),
adminSingleChat: (data: any) => ({
  types: [
    ClientCommunicationTypes.CHAT_SEND_MESSAGE_REQUESTED,
    ClientCommunicationTypes.CHAT_SEND_MESSAGE_SUCCESS,
    ClientCommunicationTypes.CHAT_SEND_MESSAGE_FAILURE,
  ],
  promise: (client: any) =>
    client.post(
      "send_single_chat_message",
      {
        data,
      },
      "communication"
    ),
}),

deleteAdminChat: (data: any) => ({
  types: [
    ClientCommunicationTypes.DELETE_CHAT_REQUESTED,
    ClientCommunicationTypes.DELETE_CHAT_SUCCESS,
    ClientCommunicationTypes.DELETE_CHAT_FAILURE,
  ],
  promise: (client: any) =>
    client.put(
      "delete_chat",
      {
        data,
      },
      "communication"
    ),
}),
uploadFile: (data: any) => ({
  types:
  [ClientCommunicationTypes.CHAT_FILES_UPLOAD_REQUEST,
    ClientCommunicationTypes.CHAT_FILES_UPLOAD_SUCCESS,
    ClientCommunicationTypes.CHAT_FILES_UPLOAD_FAILURE
  ],
  promise: (client: any) =>
    client.post(
      "upload_file",
      {
        files: [{ key: "file", value: data }],
      },
      "communication"
    ),
}),
getSharedFiles: (params: any) => ({
  types: [
    ClientCommunicationTypes.GET_CHAT_SHARED_FILES_REQUESTED,
    ClientCommunicationTypes.GET_CHAT_SHARED_FILES_SUCCESS,
    ClientCommunicationTypes.GET_CHAT_SHARED_FILES_FAILURE,
  ],
  promise: (client: any) =>
    client.get(
      `get_shared_files`,
      {
        params,
      },
      "communication"
    ),
})
};



export default ActionCreators;
