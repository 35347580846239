import{ useState } from "react";
import styled from "styled-components";
import ChatList from "./ChatList/ChatList";
import ChatSection from "./ChatSection/ChatSection";
import ChatLayout from "../../components/ChatLayout/ChatLayout";
import SharedFiles from "./SharedFiles/SharedFiles";

const FlexContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
  width: 100%;
`;

const ChatModule = () => {
  const [gstn, setGstn] = useState("");
  const [multiChat, setMultiChat] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [recipientType,setRecipientType]=useState("vendor");
  const [initiateChatStatus,setInitiateChatStatus]=useState(false)

 
  if (gstn !== "") {
    return (
      <ChatLayout title="Dashboard" scrollable>
        {/* <ClientVendorView
          gstn={gstn}
          goBack={() => {
            setGstn("");
          }}
        /> */}
      </ChatLayout>
    );
  }

  return (
    <ChatLayout title="Communication">
      <FlexContainer>
        <div style={{ width: "330px", height: "100%" }}>
          {" "}
          <ChatList
          initiateChatStatus={initiateChatStatus}
            isCancelled={isCancelled}
            onSelectMultiChat={value => {
              setMultiChat(value);
              setIsCancelled(value)
            }}
            getRecipientType={(value)=>{
              setRecipientType(value)
            }}
          ></ChatList>
        </div>
        <ChatSection
          isMultiChatSelected={multiChat}
          onInitiateChat={(value:boolean)=>{setInitiateChatStatus(value)}}
          onCancel={() => {
            setIsCancelled(false);
          }}
          handleProfileView={gstnValue => {
            setGstn(gstnValue);
          }}
          recipient={recipientType}
        ></ChatSection>
        <SharedFiles></SharedFiles>
      </FlexContainer>
    </ChatLayout>
  );
};

export default ChatModule;
