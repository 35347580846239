import {
  Backdrop,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import PublishIcon from "@material-ui/icons/Publish";
import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { dropDownData } from "../../constants/updateVendorConstants";
import ChipsArray from "../ChipsArray/ChipsArray";
import "./VendorUpdateScopeOfWork.scss";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../NoAccess/NoAccess";

const useStyles = makeStyles((theme) => ({
  sowContainer: {
    padding: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  subHeader: {
    color: "#66759c",
    fontWeight: 600,
  },
  chipCard: {
    position: "relative",
    display: "block",
    border: "0.5px solid #c4c4c4",
    borderRadius: "4px",
    height: "100px",
    overflow: "auto",
    width: "100%",
    paddingRight: "10px",
    paddingBottom: "10px",
    marginTop: "10px",
  },
  chips: {
    float: "left",
    marginLeft: "10px",
    marginTop: "10px",
  },
  editAddFields: {
    marginTop: "15px",
    color: "#8fc9f9",
    fontWeight: 400,
    fontSize: "13px",
  },
  editButton: {
    backgroundColor: "#06c396",
    color: "#fff",
  },
  cancelButton: {
    backgroundColor: "#dc3545",
    color: "#fff",
    marginLeft: "15px",
  },
  dropDown: {
    width: "57vw",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#17a2b8",
    color: "#fff",
  },
  splitLine: {
    borderTop: "1px solid #17a2b8",
    width: "112%",
    position: "relative",
    right: "44px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VendorUpdateScopeOfWork = () => {
  const classes = useStyles();
  const [vendorSow, setVendorSow] = React.useState({});
  const [vendorEditable, setVendorEditable] = React.useState(false);
  const [industriesServed, setIndustriesServed] = React.useState([]);
  const [keyCustomers, setKeyCustomers] = React.useState([]);
  const [customersListFile, setCustomersListFile] = React.useState({});
  const [uploadCustomersListFile, setUploadCustomersListFile] = React.useState({ file: {}, fileName: "" });
  const [customersListAddButton, setCustomersListAddButton] = React.useState(false);
  const [keyCustomerValue, setKeyCustomerValue] = React.useState([]);
  const [industriesServedOthers, setIndustriesServedOthers] = React.useState(
    []
  );
  const [locationServed, setLocationServed] = React.useState([]);
  const [aboutUs, updateAboutUs] = React.useState("");
  const [reloadUpdate, setReloadUpdate] = React.useState(false);
  const [loader, enableLoader] = React.useState(false);
  const [industriesServedDropdown, setIndustriesServedDropdown] =
    React.useState([]);
  const [gstnValue, setGstnValue] = React.useState("");
  const [documents, setDocuments] = React.useState([]);
  const [uploadBrochureFile, setUploadBrochureFile] = React.useState({ file: {}, fileName: "" });
  const [brochureAddButton, setBrochureAddButton] = React.useState(false);
  const [indianStates, setIndianStates] = React.useState([]);
  const [hasAccess, setHasAccess] =React.useState(true);
  const { gstn } = useParams();

  const enableEdit = () => {
    setVendorEditable(true);
  };

  const updateVendor = (
    aboutUs,
    customers,
    industries,
    otherIndustries,
    gstn,
    updateId,
    documents,
    locations,
    customersListFile
  ) => {
    let payload = {
      aboutUs: aboutUs,
      gstn: gstn,
      keyCustomers: customers?.map((itr) => itr.value),
      industriesServed: industries,
      industriesServedOthers: otherIndustries,
      companyBrochure: documents?.map((itr) => ({
        name: itr.label,
        file: itr.filePayload,
      })),
      locationServed: locations,
      customersListFile: { name: customersListFile.name,
        file: customersListFile.file }
    };
    updateSow(updateId, payload);
  };

  const createVendor = (
    aboutUs,
    customers,
    industries,
    otherIndustries,
    gstn,
    documents,
    locations,
    customersListFile
  ) => {
    let industry = {
      name: industries,
      others: otherIndustries,
    };
    let payload = {
      aboutUs: aboutUs,
      gstn: gstn,
      keyCustomers: customers?.map((itr) => itr.value),
      industryServed: industry,
      locationServed: [],
      companyBrochure: documents?.map((itr) => ({
        name: itr.label,
        file: itr.filePayload,
      })),
      locationServed: locations,
      customersListFile: { name: customersListFile.name, 
        file: customersListFile.file }
    };
    createSow(payload);
  };

  const createSow = async (payload) => {
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/sow",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    if (result.data.status === "SUCCESS") {
      setReloadUpdate(true);
    } else {
      setReloadUpdate(true);
    }
  };

  const updateSow = async (updateId, payload) => {
    const result = await axios.put(
      process.env.REACT_APP_BASE_URL + "/sow/" + updateId,
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    if (result.data.status === "SUCCESS") {
      setReloadUpdate(true);
    } else {
      setReloadUpdate(true);
    }
  };

  const cancelEdit = () => {
    setReloadUpdate((update) => !update);
    setVendorEditable(false);
  };

  const customerValueBind = (e) => {
    setKeyCustomerValue(e.target.value);
  };

  const addCustomers = (e, customersList) => {
    if (e.key === "Enter") {
      addKeyCustomersToList(customersList);
      setKeyCustomerValue("");
    }
  };

  const addCustomersonAdd = (cusList, inputValue) => {
    if (inputValue != "") addKeyCustomersToList(cusList);
  };

  const addKeyCustomersToList = (cusList) => {
    let keyCustomerList = keyCustomerValue.split(", ");
    let customers = cusList;

    keyCustomerList.forEach((itr) => {
      let obj = {
        label: "",
        value: "",
      };
      obj.label = itr;
      obj.value = itr;
      customers.push(obj);
    });
    setKeyCustomers(customers);
  };

  const removeCustomerChips = (chip) => {
    setKeyCustomers((chips) => chips.filter((itr) => itr.value !== chip.value));
  };

  const removeIndustriesServed = (chip) => {
    setIndustriesServed((chips) =>
      chips.filter((itr) => itr.value !== chip.value)
    );
    setIndustriesServedDropdown((selected) =>
      selected.filter((itr) => itr !== chip.label)
    );
  };

  const removeOtherIndustriesServed = (chip) => {
    setIndustriesServedOthers((chips) =>
      chips.filter((itr) => itr.value !== chip.value)
    );
  };

  const handleDropDownIndustries = (val, object) => {
    const dropDownValues = val.target.value;
    let endRes = dropDownData.industries.filter((itr) =>
      dropDownValues.find((selected) => selected === itr.label)
    );
    setIndustriesServed(endRes);
    setIndustriesServedDropdown(val.target.value);
  };

  const handleStatesDropdown = (val, locationsServed) => {
    setIndianStates(val.target.value);
    let countryExists = locationsServed.map((itr) => itr.country);
    let statesData = [];
    if (countryExists.includes("India" || "INDIA" || "india")) {
      locationsServed.forEach((itr) => {
        if (itr.country.toLowerCase() == "india") {
          itr.state = val.target.value;
        }
      });
      setLocationServed(locationsServed);
    } else {
      let obj = {
        country: "India",
        state: val.target.value,
      };
      setLocationServed((itr) => [...itr, obj]);
    }
  };

  const UploadFileType = { BROCHURE: "BROCHURE", CUSTOMERS_LIST: "CUSTOMERS_LIST" }

  const uploadFile = async (e, type) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/upload",
      data,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    if (result.data.status === "SUCCESS") {
      if(type === UploadFileType.BROCHURE){
        setUploadBrochureFile({ file: result.data.data, fileName: e.target.files[0].name || "" });
        setBrochureAddButton(true)
      } 
      if(type === UploadFileType.CUSTOMERS_LIST){
        setUploadCustomersListFile({ file: result.data.data, fileName: e.target.files[0].name || "" });
        setCustomersListAddButton(true)
      } 
    }
  };

  const createPermanentLinkAndAdd = async (payload, type) => {
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/permanent",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    if (result.data.status === "SUCCESS") {
      const permanentFile = result.data.data;
      if(type === UploadFileType.BROCHURE){
        setDocuments((itr) => {
          let obj = {
            label: uploadBrochureFile.fileName,
            value: permanentFile.fileUrl,
            filePayload: permanentFile,
          };
          return [...itr, obj];
        });
        setUploadBrochureFile({ file: "", fileName: "" });
        setBrochureAddButton(false);
      } 
      if(type === UploadFileType.CUSTOMERS_LIST){
        setCustomersListFile({ 
              label: uploadCustomersListFile.fileName,
              value: permanentFile.fileUrl,
              name: uploadCustomersListFile.fileName,
              file: permanentFile
        });  
        setUploadCustomersListFile({ file: "", fileName: "" });
        setCustomersListAddButton(false)
      } 
    }
  };

  const removeDocuments = (chipop) => {
    setDocuments((itr) => itr.filter((val) => val.value !== chipop.value));
  };

  const openDocument = (chipvalue) => {
    const win = window.open(chipvalue.value, "_blank");
    win.focus();
  };

  const deleteStateChip = (stateChip, country, locationServed) => {
    let indianStates = [];
    let data = locationServed.map((val) => {
      if (val.country === country) {
        val.state = val.state.filter((itr2) => itr2 !== stateChip);
        indianStates = val.state;
      }
      return val;
    });
    if (country === ("India" || "INDIA" || "india"))
      setIndianStates(indianStates);
    setLocationServed(data);
  };

  useEffect(async () => {
    enableLoader(true);
    setGstnValue(gstn);
      await axios.get(
      process.env.REACT_APP_BASE_URL + "/sow/" + gstn,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    ).then((result) =>{
      OnGetSowApiSuccess(result);
    })
    .catch((error) => {
      if(error.response.status === 403){
        setHasAccess(false);
      } 
    });
  
  }, [reloadUpdate]);


  const OnGetSowApiSuccess = (result) => {
    if (result.data.status == "SUCCESS") {
      let customersList = [];
      let customers = result.data.data?.keyCustomers;
      customers.forEach((itr) => {
        let obj = {
          label: itr,
          value: itr,
        };
        obj.label = itr;
        obj.value = itr;
        customersList.push(obj);
      });
      setReloadUpdate(false);
      setVendorSow(result.data.data);
      setKeyCustomers(customersList);
      const customersListFile = result.data.data?.customersListFile
      setCustomersListFile(customersListFile 
        ?  {...customersListFile, 
            label: customersListFile?.name,
            value: customersListFile?.file.fileUrl
          }
        : {}
      );
      setUploadCustomersListFile({file:{}, fileName:""})
      setCustomersListAddButton(false)
      updateAboutUs(result.data.data?.aboutUs);
      setLocationServed(
        result.data.data?.locationServed ? result.data.data.locationServed : []
      );
      setIndustriesServed(
        result.data.data?.industryServed
          ? result.data.data.industryServed?.name
          : []
      );
      let industries = result.data.data?.industryServed
        ? result.data.data.industryServed?.name
        : [];
      setIndustriesServedDropdown(industries.map((itr) => itr.label));
      setIndustriesServedOthers(
        result.data.data?.industryServed
          ? result.data.data.industryServed?.others
          : []
      );

      // set documents
      let docs = result.data.data?.companyBrochure?.map((itr) => ({
        ...itr,
        label: itr?.name,
        value: itr?.file?.fileUrl,
        filePayload: itr?.file,
      }));
      setDocuments(docs);
      setUploadBrochureFile({file:{}, fileName:""})
      setVendorEditable(false);
      setBrochureAddButton(false);

      // set states
      let states = result.data.data?.locationServed?.filter(
        (itr) => itr.country?.toLowerCase() === "india"
      );
      setIndianStates(states?.length ? states[0].state : []);
    } else {
      setReloadUpdate(false);
      setVendorSow({});
      setKeyCustomers([]);
      setCustomersListFile({})
      setUploadCustomersListFile({file:{}, fileName:""})
      setCustomersListAddButton(false);
      updateAboutUs("");
      setIndustriesServed([]);
      setIndustriesServedDropdown([]);
      setIndustriesServedOthers([]);
      setDocuments([]);
      setUploadBrochureFile({file:{}, fileName:""})
      setIndianStates([]);
      setVendorEditable(false);
      setBrochureAddButton(false);
    }

    enableLoader(false);
  }
  return (
    hasAccess ? 
    <div className="container-fluid">
      <div className={classes.sowContainer}>
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="row">
          <div className="col">
            <div className="row">
              <InputLabel className={classes.subHeader}>About Us</InputLabel>
              <TextField
                id="outlined-basic"
                multiline
                rows={6}
                value={aboutUs || ""}
                variant="outlined"
                disabled={!vendorEditable}
                fullWidth
                onChange={(e) => {
                  updateAboutUs(e.target.value);
                }}
              />
            </div>
            <div className="row mt-4">
              <InputLabel className={classes.subHeader}>
                Key Customers
              </InputLabel>
              <ChipsArray
                ChipsArray={keyCustomers ? keyCustomers : []}
                deletable={vendorEditable}
                onDelete={removeCustomerChips}
              ></ChipsArray>
              {vendorEditable ? (
                <div className="edit_fields">
                  {/* <InputLabel className={classes.editAddFields}>Add Key Customers</InputLabel> */}
                  <TextField
                    id="outlined-basic"
                    label="Add Key Customer and Press Enter"
                    value={keyCustomerValue}
                    variant="outlined"
                    className="customer_text mt-4"
                    disabled={!vendorEditable}
                    onChange={(e) => customerValueBind(e)}
                    onKeyPress={(e) => addCustomers(e, keyCustomers)}
                  />
                  {/* <Button variant="contained" className="mt-3 ml-2 add_button" color="primary" startIcon={<AddIcon />} onClick={() => addCustomersonAdd(keyCustomers, keyCustomerValue)}>
                    Add
                   </Button> */}
                </div>
              ) : null}
            </div>
            <div className="row mt-4">
              <InputLabel className={classes.subHeader}>Customers List File</InputLabel>
              <ChipsArray
                ChipsArray={ customersListFile.name ? [customersListFile] : []}
                icon={true}
                deletable={vendorEditable}
                onDelete={() => setCustomersListFile({})}
                onClick={openDocument}
              ></ChipsArray>
            </div>
            { vendorEditable && <div className="row mt-4">                
              <TextField
                id="customersListFileUpload"
                type="text"
                variant="outlined"
                placeholder={ customersListFile.name ? "Update the list file" : "Upload a list file"}
                value={uploadCustomersListFile?.fileName || ""}
                disabled={!vendorEditable}
                size="small"
                InputProps={{
                  style: { textAlign: "center" },
                }}
              />
              <input
                accept="application/pdf,image/jpeg"
                style={{ display: "none" }}
                id="customersListFile"
                onChange={(e) => uploadFile(e, UploadFileType.CUSTOMERS_LIST)}
                type="file"
                multiple="false"
              />
              <label htmlFor="customersListFile">
                <IconButton
                  color="primary"
                  component="span"
                >
                  <PublishIcon />
                </IconButton>
              </label>
              {customersListAddButton && (
                  <Button
                    variant="contained"
                    className="ml-2 add_button mb-2"
                    startIcon={<AddIcon />}
                    onClick={() => createPermanentLinkAndAdd(uploadCustomersListFile.file, UploadFileType.CUSTOMERS_LIST) }
                  >
                    Update
                  </Button>)
                }
            </div>}
            <div className="row mt-4">
              <InputLabel className={classes.subHeader}>
                Industries Served
              </InputLabel>
              <ChipsArray
                ChipsArray={industriesServed ? industriesServed : []}
                deletable={vendorEditable}
                onDelete={removeIndustriesServed}
              ></ChipsArray>
            </div>
            {vendorEditable ? (
              <div className="row mt-4">
                <InputLabel id="demo-mutiple-checkbox-label">
                  Industries Served
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={industriesServedDropdown}
                  onChange={handleDropDownIndustries}
                  input={<Input />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((chip) => (
                        <Chip
                          key={chip}
                          label={chip}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  className={classes.dropDown}
                >
                  {dropDownData.industries.map((itr) => (
                    <MenuItem key={itr.value} value={itr.label}>
                      <Checkbox
                        color="primary"
                        checked={
                          industriesServedDropdown.indexOf(itr.label) > -1
                        }
                      />
                      <ListItemText primary={itr.label} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ) : null}

            <div className="row mt-4">
              <InputLabel className={classes.subHeader}>
                Other Industries Served
              </InputLabel>
              <ChipsArray
                ChipsArray={
                  industriesServedOthers ? industriesServedOthers : []
                }
                deletable={vendorEditable}
                onDelete={removeOtherIndustriesServed}
              ></ChipsArray>
            </div>
            <hr className={classes.splitLine}></hr>
            <div className="row mt-4">
              <InputLabel className={classes.subHeader}>
                Uploaded Brochures
              </InputLabel>
              <ChipsArray
                ChipsArray={documents}
                icon={true}
                deletable={vendorEditable}
                onDelete={removeDocuments}
                onClick={openDocument}
              ></ChipsArray>
            </div>
            { vendorEditable && 
              <div className="row mt-4">
                <TextField
                  id="upload"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Add Brochure"
                  value={uploadBrochureFile?.fileName || ""}
                  disabled={!vendorEditable}
                />
                <input
                  accept="application/pdf,image/jpeg"
                  style={{ display: "none" }}
                  id="icon-button-photo"
                  onChange={(e) => uploadFile(e, UploadFileType.BROCHURE)}
                  type="file"
                  multiple="false"
                  disabled={!vendorEditable}
                  className={classes.fileInput}
                />
                <label htmlFor="icon-button-photo">
                  <IconButton
                    color="primary"
                    component="span"
                    disabled={!vendorEditable}
                  >
                    <PublishIcon />
                  </IconButton>
                </label>
                {brochureAddButton && (
                      <Button
                        variant="contained"
                        color="add_button"
                        className="ml-2 add_button mb-2"
                        startIcon={<AddIcon />}
                        onClick={() => createPermanentLinkAndAdd(uploadBrochureFile.file, UploadFileType.BROCHURE)}
                        >
                        Add Document
                      </Button> )
                }
              </div> 
            }

            <hr className={classes.splitLine}></hr>
            {vendorEditable ? (
              <div className="row mt-4">
                <InputLabel id="demo-mutiple-checkbox-label">
                  Indian States Served
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={indianStates}
                  onChange={(val) => handleStatesDropdown(val, locationServed)}
                  input={<Input />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((chip) => (
                        <Chip
                          key={chip}
                          label={chip}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  className={classes.dropDown}
                >
                  {dropDownData.allRegisteredStates.map((itr) => (
                    <MenuItem key={itr.value} value={itr.label}>
                      <Checkbox
                        color="primary"
                        checked={indianStates?.indexOf(itr.label) > -1}
                      />
                      <ListItemText primary={itr.label} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ) : null}
            <div className="row mt-4">
              <div className="col">
                <InputLabel className={classes.subHeader}>
                  Countries Served
                </InputLabel>
                {locationServed?.length
                  ? locationServed?.map((itr, index) => {
                      return (
                        <div key={index} className={classes.chipCard}>
                          <Chip
                            className={classes.chips}
                            size="small"
                            label={itr.country}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="col">
                <InputLabel className={classes.subHeader}>
                  States Served
                </InputLabel>
                {locationServed?.length
                  ? locationServed?.map((itr, ind) => {
                      return (
                        <div key={ind} className={classes.chipCard}>
                          {itr.state?.length
                            ? itr.state.map((chip, index) => {
                                return (
                                  <Chip
                                    key={index}
                                    className={classes.chips}
                                    size="small"
                                    label={chip}
                                    onDelete={() =>
                                      vendorEditable &&
                                      deleteStateChip(
                                        chip,
                                        itr.country,
                                        locationServed
                                      )
                                    }
                                    color="primary"
                                    disabled={!vendorEditable}
                                  />
                                );
                              })
                            : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col text-right">
                {vendorEditable ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    onClick={() =>
                      Object.keys(vendorSow).length
                        ? updateVendor(
                            aboutUs,
                            keyCustomers,
                            industriesServed,
                            industriesServedOthers,
                            vendorSow.gstn,
                            vendorSow._id,
                            documents,
                            locationServed,
                            customersListFile
                          )
                        : createVendor(
                            aboutUs,
                            keyCustomers,
                            industriesServed,
                            industriesServedOthers,
                            gstnValue,
                            documents,
                            locationServed,
                            customersListFile
                          )
                    }
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="editButton"
                    startIcon={<EditIcon />}
                    onClick={enableEdit}
                  >
                    Edit
                  </Button>
                )}
                <Button
                  variant="contained"
                  className="ml-2 cancelButton"
                  startIcon={<ClearIcon />}
                  onClick={cancelEdit}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  :
    <NoAccess></NoAccess>);
};

export default VendorUpdateScopeOfWork;
