import React from "react";
import { PEOPLE_INFO_OPTION } from "../../constants/updateVendorConstants";
import UpdateVendorProfile from "../../container/UpdateVendorProfiles/UpdateVendorProfiles";
import { PromptProvider } from "components/Modal/PromptProvider";
const UpdatePeople = () => (
  <PromptProvider>
    <UpdateVendorProfile bodyContent={PEOPLE_INFO_OPTION} />
  </PromptProvider>
);

export default UpdatePeople;