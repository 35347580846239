import React from 'react';
import styled from 'styled-components';

interface IconProps {
  className: string,
  color?: 'primary' | 'black' | 'blackLight' | 'secondary'
  | 'green' | 'red' | 'orange' | 'white' | 'whiteFifty' | 'Gray' | 'GrayLight' | 'GrayDark',
  size?: 'small' | 'middle' | 'large' | undefined,
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const IconBase = styled.i<IconProps>`
  font-size: ${(props) => `${props?.theme?.input?.sizes[props?.size || 'middle']?.iconSize}`};
  &:before {
    color: ${(props) => `${props?.color ? props?.color : props?.theme?.input?.color}`};
  }
`;

const Icon = (props: IconProps) => (
  <IconBase {...props} />
);

export default Icon;
