import { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { BackButton, JobRequestDetailsTabs } from "./JobRequestDetails.styles";
import { useHistory, useParams } from "react-router-dom";
import { JobOverview } from "./JobOverview/JobOverview";
import { VendorsAddedByClient } from "./VendorsAddedByClient/VendorsAddedByClient";
import { ApprovalsTabContent } from "./ApprovalsTabContent/ApprovalsTabContent";
import { RequestForQuotations } from "./RequestForQuotations/RequestForQuotations";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "../../../components/Spinner";
import QuotationTab from "./Quotations/QuotationTab";
import { JobRequestActions } from "../actions";
import { IJobRequestData } from "../JobRequestDetails.interfaces";
import ROLES from "constants/RolesConstants";
import { VendorsSuggestedByAdmin } from "./VendorsSuggestedByAdmin/VendorsSuggestedByAdmin";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { checkIsVendorRequest, getSharableJobLink } from "../jobRequest.helper";
import AntdToast from "components/Toast/AntdToast";
import { checkHasAnyOfTheRole } from "shared/helpers/checkHasAccess";
import { VendorsAwardedByClient } from "./VendorsAwardedByClient/VendorsAwardedByClient";
import { FormControlLabel, Switch } from "@material-ui/core";
import axios from "axios";
import { getLoggedInUsers } from "Utils/Storage";
import { Box, Flex, Typography } from "venwiz-ui";
import LocalStorage from "_services/LocalStorage";

/*
  This component is used for both VR & RFQ/eRfx details page
*/
export function JobRequestDetails() {
  const { TabPane } = Tabs;
  const history = useHistory();
  const dispatch = useDispatch();
  const { _id, tabIndex } = useParams<{ _id: string; tabIndex?: string }>();
  const [selectedTab, setSelectedTab] = useState(tabIndex || "1");
  const [showLoader, setShowLoader] = useState(false);
  const hasAccess = checkHasAnyOfTheRole([
    ROLES.SUPER_ADMIN,
    ROLES.CLIENT_VR_VIEW_EXPORT,
    ROLES.CLIENT_PROJECTS,
  ]);
  const [reloadUpdate, setReloadUpdate] = useState(false);
  const { jobRequestDetails, errorMessage, successMessage } = useSelector(
    (state: any) => state.ClientRequestReducer
  );
  const { isLoading } = useSelector((state: any) => state.LoaderReducers);
  const { selectedProject } = useSelector(
    (state: any) => state.ClientRequestReducer
  );
  const isVendorRequest = checkIsVendorRequest(jobRequestDetails?.requestType);
  const adminUser = getLoggedInUsers() ? getLoggedInUsers() : "";
  const hasAddedSuggestedVendor = jobRequestDetails?.suggestedGstns?.length;
  const showPublish = checkHasAnyOfTheRole([
    ROLES.CLIENT_VR_EDIT_PUBLISH,
    ROLES.CLIENT_PROJECTS,
  ]) &&
  jobRequestDetails?.status !== "Pending" &&
  !!hasAddedSuggestedVendor ;

  useEffect(() => {
    return () => {
      dispatch(ClientRequestActions.resetVRDetailsPageData());
    };
  }, []);

  useEffect(() => {
    (() => {
      setShowLoader(true);
      dispatch(ClientRequestActions.getJobRequestData(_id)).then(
        (resp: any) => {
          setShowLoader(false);
        }
      );
    })();
  }, [reloadUpdate]);

  useEffect(() => {
    if (errorMessage || successMessage) {
      AntdToast({
        type: successMessage ? "success" : "error",
        message: successMessage ? "Success" : "Error",
        description: successMessage || errorMessage,
      });
      dispatch(ClientRequestActions.resetSuccessErrorMessage());
    }
  }, [errorMessage, successMessage]);

  const onChange = (key: any) => {
    setSelectedTab(key);
  };

  const handleOnBackClick = () => {
    if (isVendorRequest) {
      dispatch(ClientRequestActions.setSelectedClientRequestTab(0));
      history.push("/client-requests");
    } else {
      dispatch(ClientRequestActions.setSelectedClientProjectTab(1));
      history.push(`/client-project/${selectedProject?.id}`);
    }
  };

  const renderBackToJRButton = () => {
    return (
      <BackButton
        size="small"
        shape="round"
        type="primary"
        icon={<ArrowLeftOutlined />}
        onClick={handleOnBackClick}
        style={{
          background: "transparent",
          color: "#215EC9",
          border: "none",
          boxShadow: "none",
        }}
      ></BackButton>
    );
  };

  const publishGstnToSuggestVendors = async (_id: string, payload: any) => {
    try {
      setShowLoader(true);
      const { status, data }: { status: string; data: any } = await axios.put(
        process.env.REACT_APP_BASE_URL + `/batch-requests/publish/${_id}`,
        payload,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      );
      if (status === "SUCCESS") {
        setReloadUpdate((prev) => !prev);
      } else {
        setReloadUpdate((prev) => !prev);
      }
    } catch (error) {
      setReloadUpdate((prev) => !prev);
    }
    setShowLoader(false);
  };

  const publishGstnToRequest = (
    _id: string,
    publishFlag: boolean,
    jobRequestData: any
  ) => {
    let payload = {
      updateFlag: !publishFlag,
      updatedBy: adminUser.email,
      clientName: jobRequestData.clientName,
      natureOfWork: jobRequestData.natureOfWork,
      clientEmail: jobRequestData.clientEmail,
      jobTitle: jobRequestData?.title,
      jobId: jobRequestData.requestId,
      shareableRequestVendorlink: getSharableJobLink(jobRequestData?.requestId),
      comparisonLink:
        jobRequestData.suggestedGstns.length > 1
          ? jobRequestData.suggestedGstns.map((vendor: any) => vendor.gstn)
          : [],
    };
    publishGstnToSuggestVendors(_id, payload);
  };

  return (
    <Box>
      {showLoader && <Spinner></Spinner>}
      <Flex
        justifyContent={"right"}
        width="100%"
        padding={showPublish ? "0 20px" : "20px"}
        alignItems="center"
      >
        <Typography
          variant="span"
          fontSize={"16px"}
          fontWeight={600}
          marginRight="5px"
        >
          {jobRequestDetails?.requestId}
        </Typography>
        {!!showPublish && (
            <>
              <Typography
                variant="span"
                fontSize={"40px"}
                fontWeight={400}
                color="#779c79"
              >
                |
              </Typography>
              <FormControlLabel
                style={{ margin: 0 }}
                control={
                  <Switch
                    size="small"
                    checked={jobRequestDetails?.isPublished}
                    onChange={() =>
                      publishGstnToRequest(
                        jobRequestDetails?._id,
                        jobRequestDetails?.isPublished || false,
                        jobRequestDetails
                      )
                    }
                    name="isActive"
                    color="primary"
                  />
                }
                label="Publish"
              />
            </>
          )}
      </Flex>
      <JobRequestDetailsTabs
        defaultActiveKey="1"
        activeKey={selectedTab}
        onChange={onChange}
        height={"calc(100% - 100px)"}
        tabBarExtraContent={{ left: renderBackToJRButton() }}
        style={{ padding: "0 20px 20px" }}
      >
        {jobRequestDetails && (
          <>
            <TabPane tab="Job Overview" key="1" destroyInactiveTabPane>
              <JobOverview
                hasAccess={hasAccess}
                showLoader={showLoader}
                setShowLoader={setShowLoader}
                setReloadUpdate={setReloadUpdate}
              />
            </TabPane>
            <TabPane
              tab="Vendors Suggested by Admin"
              key="2"
              destroyInactiveTabPane
            >
              <VendorsSuggestedByAdmin hasAccess={hasAccess} />
            </TabPane>
            <TabPane
              tab="Vendors Added / Removed by Client"
              key="3"
              destroyInactiveTabPane
            >
              <VendorsAddedByClient hasAccess={hasAccess} />
            </TabPane>
            {jobRequestDetails?.finalVendors?.length > 0 && (
              <TabPane
                tab="Vendors Awarded by client"
                key="4"
                destroyInactiveTabPane
              >
                <VendorsAwardedByClient />
              </TabPane>
            )}
            <TabPane
              tab={"RFQ Docs"}
              key="5"
              destroyInactiveTabPane
            >
              <RequestForQuotations />
            </TabPane>
            <TabPane tab="Quotations" key="6" destroyInactiveTabPane>
              <QuotationTab />
            </TabPane>
            {!isVendorRequest && (
              <TabPane tab="Approvals" key="7" destroyInactiveTabPane>
                <ApprovalsTabContent hasAccess={hasAccess} />
              </TabPane>
            )}
          </>
        )}
      </JobRequestDetailsTabs>
    </Box>
  );
}
