export const TotalManPowerTypes = {
  total: "Man Power",
  fullTime: "Full Time",
  partTime: "Part Time",
};

export const FullTimeBreakUpFields = {
  management: "Management",
  adminStaff: "AdminStaff",
  others: "Others",
};

export const FullTimeBreakUpManagementAndService = {
  inServicing: "Servicing",
  inManufacturing: "Manufacturing"
}

export const PartTimeBreakUpFields = {
  manufacturing: "Manufacturing",
  services: "Services",
  others: "Others",
};

export const PeopleDbKeys = {
  keyManagement: "keyManagement",
  keyContacts: "keyContacts",
};

export const KeyManagementInfoFields = {
  firstName: "First Name",
  lastName: "Last Name",
  designation: "Designation",
  qualification: "Qualification",
  experience: "Experience",
  email: "Email",
  contact: "Contact"
};

export const KeyContactInfoFields = {
  name: "Full Name",
  designation: "Designation",
  email: "Email",
  contact: "Contact",
};

export const NewKeyManagement = Object.keys(KeyManagementInfoFields).reduce(
  (acc, key) => ({ ...acc, [key]: "" }),
  {}
);

export const NewKeyContact = Object.keys(KeyContactInfoFields).reduce(
  (acc, key) => ({ ...acc, [key]: "" }),
  {}
);
