import { Button } from "@material-ui/core";
import { Divider } from "@mui/material";
import AntdToast from "components/Toast/AntdToast";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "Utils/DisplayTimeFormat";
import { useHistory } from "react-router-dom";
import { ClientRequestActions } from "../actions";
import { IPlantOption } from "../interfaces/Company.interface";
import {
  HeaderContainer,
  HeaderWrapper,
  Header,
  ClientProjectData,
  ClientProjectsContainer,
  ClientProjectsWrapper,
} from "./ClientProjects.styles";
import CreateProjectModal from "./CreateProjectModal";

const ClientProjects = () => {
  const dispatch = useDispatch();
  const { projects, plants, projectCreationSuccess, errorMessage, successMessage, selectedCompany } = useSelector((state: any) => state.ClientRequestReducer);
  const [showCreateProjectPopup, setShowCreateProjectPopup] = useState(false);
  const initialSelectedPlant:IPlantOption = {id:"",label:"",value:""}
  const [selectedPlant, setSelectedPlant] = useState<IPlantOption>();
  const [projectName, setProjectName] = useState("");
  const history = useHistory();

  useEffect(() => {
    return(() =>
    {
      dispatch(ClientRequestActions.resetProjectPageData());
    })
  }, []);

  useEffect(() => {
    dispatch(ClientRequestActions.getAllProjectsOfCompany(selectedCompany?.id));
  }, [selectedCompany]);

  useEffect(() => {
    if(projectCreationSuccess) {
      dispatch(ClientRequestActions.getAllProjectsOfCompany(selectedCompany?.id)).then(() => {
        dispatch(ClientRequestActions.resetProjectSuccess())
      });
    }
  }, [projectCreationSuccess]);

  useEffect(() => {
    if(errorMessage || successMessage) {
      AntdToast({ type: successMessage ? "success" : "error", message: successMessage? "Success" : "Error", description: successMessage || errorMessage });
      dispatch(ClientRequestActions.resetSuccessErrorMessage());
    }
    if(errorMessage){
     onModalClose();
    }
  }, [errorMessage,successMessage]);

  const onCreateNewClick = () => {
    dispatch(ClientRequestActions.getAllPlantsOfCompany(selectedCompany?.value)).then (() => {
      setShowCreateProjectPopup(true);
    });
  };

  const onModalClose = () => {
    setShowCreateProjectPopup(false);
    setProjectName("");
    setSelectedPlant(initialSelectedPlant);
  }

  const createProjectClick = () => {
    const paylod ={
      plant: {
        id: selectedPlant?.id,
        name: selectedPlant?.label
      },
      projectName: projectName,
      company: {
        id: selectedCompany.id,
        name: selectedCompany.label
      }
    }
    dispatch(ClientRequestActions.createProject(paylod)).then (() => {
      onModalClose();
    });
  };

  const handleOnProjectClick = (project: any) => {
    const selectedProject = {
      _id: project?._id,
      id: project?.projectId,
      label: project?.projectName,
      value: project?.projectId,
    };
    dispatch(ClientRequestActions.setSelectedProject(selectedProject));
    history.push(`/client-project/${project?.projectId}`);
  };

  return (
    <>
      {showCreateProjectPopup && (
        <CreateProjectModal
          plants={plants}
          isModalVisible={showCreateProjectPopup}
          onCancel={() => onModalClose()}
          createProjectClick={createProjectClick}
          selectedPlantName={selectedPlant || initialSelectedPlant}
          setSelectedPlant={setSelectedPlant}
          projectName={projectName}
          setProjectName={setProjectName}
        ></CreateProjectModal>
      )}

      <ClientProjectsContainer>
        <HeaderContainer>
          {projects?.length ? (
            <HeaderWrapper>
              <Header width="35%">Project ID</Header>
              <Header width="25%">Created</Header>
              <Header width="40%">Project name</Header>
            </HeaderWrapper>
          ) : (
            <div>No projects created</div>
          )}
          <div>
            <Button variant="contained" color="primary" onClick={onCreateNewClick}>
              + Create New Project
            </Button>
          </div>
        </HeaderContainer>
        {projects?.map((project: any) => {
          return (
            <>
              <Divider
                sx={{
                  borderColor: "#D4E5FE",
                }}
              />
              <ClientProjectsWrapper onClick={() => handleOnProjectClick(project)}>
                <ClientProjectData width="35%">{project.projectId}</ClientProjectData>
                <ClientProjectData width="25%">{formatTime(project.createdAt, "DD-MM-YYYY")}</ClientProjectData>
                <ClientProjectData width="40%">{project?.projectName}</ClientProjectData>
              </ClientProjectsWrapper>
            </>
          );
        })}
      </ClientProjectsContainer>
    </>
  );
};

export default ClientProjects;
