import { IJobRequestData } from "container/JobRequests/JobRequestDetails.interfaces";
import { CLIENT_REQUEST_TYPES } from ".";

interface IInitialState {
  companies: object,
  plants: object,
  projectCreationSuccess: boolean,
  projects: object,
  errorMessage: string,
  successMessage: string,
  vendorRequestData: null | object,
  vendorRequestCSVData: object,
  selectedCompany: null | object,
  projectNames: object,
  jobRequestDetails: null | IJobRequestData,
  selectedProject: null | object,
  selectedProjectDetails: null | object,
  selectedClientRequestTab: number,
  selectedClientProjectTab: number
}

const INITIAL_STATE: IInitialState = {
 companies: [],
 plants: [],
 projectCreationSuccess: false,
 projects: [],
 errorMessage: "",
 successMessage: "",
 vendorRequestData: null,
 vendorRequestCSVData: [],
 selectedCompany: null,
 projectNames: [],
 jobRequestDetails: null,
 selectedProject: null,
 selectedProjectDetails: null,
 selectedClientRequestTab: 1,
 selectedClientProjectTab: 0
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {

    case CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST: {
      return {
        ...state,
      }
    }
    case CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR: {
        return {
          ...state,
         errorMessage: action.error.message
        }
    }
    case CLIENT_REQUEST_TYPES.CLIENT_REQUEST_GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: action.result
      }
    }
    case CLIENT_REQUEST_TYPES.CLIENT_REQUEST_GET_COMPANYS_PLANTS_SUCCESS: {
      return {
        ...state,
        plants: action.result
      }
    }
    case CLIENT_REQUEST_TYPES.CLIENT_REQUEST_CREATE_PROJECT_SUCCESS: {
      return {
        ...state,
        projectCreationSuccess: action.result?.projectCreationSuccess,
        successMessage: action.result?.message
      }
    }
    case CLIENT_REQUEST_TYPES.CLIENT_REQUEST_GET_PROJECTS_SUCCESS: {
      return {
        ...state,
        projects: action.result
      }
    }
    case CLIENT_REQUEST_TYPES.CLIENT_REQUEST_GET_PROJECT_NAMES_SUCCESS: {
      return {
        ...state,
        projectNames: action.result
      }
    }

    case CLIENT_REQUEST_TYPES.SET_SELECTED_COMPANY: {
      return {
        ...state,
        selectedCompany: action.payload,
        selectedProject: null,
        selectedProjectDetails: null
      }
    }
    case CLIENT_REQUEST_TYPES.SET_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProject: action.payload,
        selectedProjectDetails: null
      }
    }
    case CLIENT_REQUEST_TYPES.GET_PROJECT_DETAILS_SUCCESS: {
      return{
        ...state,
        selectedProjectDetails: action.result?.data
      }
    }
    case CLIENT_REQUEST_TYPES.ADD_OR_REMOVE_USER_FROM_ROLE_SUCCESS: {
      return{
        ...state,
        selectedProjectDetails: action.result?.data,
        successMessage: "User Roles Updated"
      }
    }
    case CLIENT_REQUEST_TYPES.GET_RFQ_FOR_PROJECT_SUCCESS: {
      return {
        ...state,
        vendorRequestData: action.result?.data
      }
    }

    //Vendor Request cases
    case CLIENT_REQUEST_TYPES.GET_VENDOR_REQUEST_FOR_COMPANY_SUCCESS: {
      return {
        ...state,
        vendorRequestData: action.result?.jobLists
      }
    }
    case CLIENT_REQUEST_TYPES.UPDATE_JOB_REQUEST_SUCCESS:
    case CLIENT_REQUEST_TYPES.GET_JOB_REQUEST_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        jobRequestDetails: action?.result?.jobRequests[0],
      }
    }
    case CLIENT_REQUEST_TYPES.GET_VENDOR_REQUEST_CSV_DATA_FOR_COMPANY_SUCCESS: {
      return {
        ...state,
        vendorRequestCSVData: action.result?.jobRequests
        
      }
    }
    case CLIENT_REQUEST_TYPES.SET_JOB_REQUEST_DATA:
      return {
        ...state,
        jobRequestDetails:
          state.jobRequestDetails !== null
            ? {
                ...state.jobRequestDetails,
                [action.payload.key]: action.payload.value,
              }
            : null,
      };
    case CLIENT_REQUEST_TYPES.ADD_RFQ_DOCS_SUCCESS:
    case CLIENT_REQUEST_TYPES.REMOVE_RFQ_DOCS_SUCCESS:
      return {
        ...state,
        loading: false,
        jobRequestDetails: state.jobRequestDetails && { ...state.jobRequestDetails, jobRfq: action.result?.data?.jobRfq || [] },
      };
    
    //*** Job Monetization Types ***//
    case CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_VALUE:
      let updateMonetization = state.jobRequestDetails?.jobMonetizations;
      if (updateMonetization)
        updateMonetization[action.payload.idx] = { ...updateMonetization[action.payload.idx], ...action.payload.data };
      return {
        ...state,
        jobRequestDetails: { ...state.jobRequestDetails, jobMonetizations: updateMonetization },
      };

    case CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.result?.message
      };

    case CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action?.result?.message || action?.error?.message,
      }

    //reset data
    case CLIENT_REQUEST_TYPES.RESET_CLIENT_REQUEST_STORE: {
      return {
        ...state,
        companies: [],
        plants: [],
        projectCreationSuccess: false,
        projects: [],
        errorMessage: "",
        vendorRequestData: null,
        vendorRequestCSVData: [],
        projectNames: [],
        selectedClientRequestTab: 1,
        selectedClientProjectTab: 0
      };
    }
    case CLIENT_REQUEST_TYPES.RESET_CLIENT_PROJECTS_PAGE_DATA: {
      return {
        ...state,
        projects: [],
      };
    }
    case CLIENT_REQUEST_TYPES.RESET_VENDOR_REQUEST_LIST_PAGE_DATA: {
      return {
        ...state,
        vendorRequestData: null,
        vendorRequestCSVData: [],
        projectNames: [],
      };
    }
    case CLIENT_REQUEST_TYPES.RESET_RFQ_LIST_PAGE_DATA: {
      return {
        ...state,
        vendorRequestData: null,
        vendorRequestCSVData: [],
      };
    }
    case CLIENT_REQUEST_TYPES.RESET_CLIENT_REQUEST_STORE: {
      return {
        ...state,
        jobRequestDetails: null
      };
    }
    case CLIENT_REQUEST_TYPES.RESET_CREATE_PROJECT_SUCCESS: {
      return {
        ...state,
        projectCreationSuccess: false
      }
    }
    case CLIENT_REQUEST_TYPES.RESET_SUCCESS_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      }
    }
    case CLIENT_REQUEST_TYPES.RESET_SELECTED_PROJECT_DETAILS_DATA: {
      return {
        ...state,
        selectedProjectDetails: null
      }
    }
    case CLIENT_REQUEST_TYPES.ADD_VR_TO_PROJECT_SUCCESS: {
      return {
        ...state,
        successMessage: action.result?.message
      }
    }
    case CLIENT_REQUEST_TYPES.SET_SELECTED_CLIENT_REQUEST_TAB: {
      return {
        ...state,
        selectedClientRequestTab: action.payload
      }
    }
    case CLIENT_REQUEST_TYPES.SET_SELECTED_CLIENT_PROJECT_TAB: {
      return {
        ...state,
        selectedClientProjectTab: action.payload
      }
    }
    case CLIENT_REQUEST_TYPES.SEND_AGREEMENT_TO_VENDOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        successMessage: action.result?.message
      };
    }
    case CLIENT_REQUEST_TYPES.GENERATE_PRICING_AGREEMENT_SUCCESS:
      const currMonetizations = state?.jobRequestDetails?.jobMonetizations;
      let updatedMonetizations = [];
      if (currMonetizations?.length && action?.result?.data) {
        updatedMonetizations = currMonetizations.map(itr =>
          itr?._id === action.result.data._id ? action.result.data : itr
        );
      } else {
        updatedMonetizations.push(action.result.data);
      }
      return {
        ...state,
        loading: false,
        successMessage: action.result?.message,
        jobRequestDetails: { ...state.jobRequestDetails, jobMonetizations: updatedMonetizations },
      };
    default:
      return state;
  }
};


