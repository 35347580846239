import { Button, Checkbox, Input, Space } from "antd";
import styled from "styled-components";

interface ButtonProps {
    selectedType: string;
  }

export const Header = styled.div`
  font-family: Poppins;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #215ec9;
  margin-bottom: 20px;
  float: left;
`;

export const ChatHeadWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
`;
export const SearchBarWrapper = styled.div`
  width: 100%;
  height: 40px;
`;
export const ImgSrc = styled.img`
  margin: 5px 2px;
`;
export const SearchBar = styled(Input)`
  padding: 10px !important;
  height: ${props =>
    `${
      (props.size === "small" && "40px") || (props.size === "middle" && "60px")
    }`};
  border-radius: 8px;
  display: flex;
  align-items: center;
  & .ant-input-prefix {
    margin-right: 20px;
    margin-left: -10px;
  }
  & .ant-input-affix-wrapper > input.ant-input {
    width: 100%;
  }
  & .ant-input-suffix {
    div {
      padding-right: 10px;
    }
  }
  @media screen and (max-height: 800px) {
    height: 40px;
    & .ant-input-suffix {
      div {
        padding-right: 5px;
      }
    }
  }
`;
export const Suffix = styled.div`
  padding-right: 20px;
  cursor: pointer;
`;
export const ImgWrap = styled.img`
  width: 100%;
  height: 100%;
`;

export const ListContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 5px;
  min-width: 280px;
  overflow-y: scroll;
  height: calc(100vh - 355px);
`;

export const UserChatHead = styled.div`
  display: flex;
  width: 100%;
  margin: 5px 0px;
  height: 60px;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
`;

export const UserChatHeadActive = styled(UserChatHead)`
  background-color: #f4f6ff;
  margin-right:10px;
`;
export const ProfileImg = styled.div`
  border-radius: 12px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background-color: #215ec9;
  text-align: center;
  font-weight: 800;
  color: #ffffff;
`;
export const UserInfo = styled.div`
  display: flex;
  width: 69%;

  flex-direction: column;
  margin-left: 15px;
`;
export const UserName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #011759;
`;
export const TextMessage = styled.div`
  width: 90%;

  // height:17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  color: #011759;
`;
export const TimeStamp = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 12px;
  /* identical to box height */
  color: #67749c;
`;
export const UnreadMessages = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height */

  color: #215ec9;
`;

export const TotalUnreadMessages = styled.div`
  float: right;
  color: #ffff;
  width: 80px;
  background-color: #be000b;
  border-radius: 15px;
  height: 26px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Notification = styled(UnreadMessages)`
  font-weight: 800;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 50%;
  }
`;

export const BttnWrapper = styled.div`
  width: auto;
  height: auto;
  margin: 15px 0px;
`;
export const ClientBttn = styled(Button)<ButtonProps>`
  font-family: Poppins;
  font-style: normal;
  width: 50%;
  height: 40px;
  border-radius: 40px 0px 0px 40px;
  &:active,
  &:focus {
    background: ${props =>
      props.selectedType === "client" ? "#215ec9" : "#ffffff"};
    color: ${props =>
      props.selectedType === "client" ? "#ffffff" : "#215ec9"};
  }
  background: ${props =>
    props.selectedType === "client" ? "#215ec9" : "#ffffff"};
  color: ${props => (props.selectedType === "client" ? "#ffffff" : "#215ec9")};
`;

export const VendorBttn = styled(Button)<ButtonProps>`
  font-family: Poppins;
  font-style: normal;
  width: 50%;
  height: 40px;
  border-radius: 0px 40px 40px 0px;
  &:active,
  &:focus {
    background: ${props =>
      props.selectedType === "vendor" ? "#215ec9" : "#ffffff"};
    color: ${props =>
      props.selectedType === "vendor" ? "#ffffff" : "#215ec9"};
  }
  background: ${props =>
    props.selectedType === "vendor" ? "#215ec9" : "#ffffff"};
  color: ${props => (props.selectedType === "vendor" ? "#ffffff" : "#215ec9")};
`;

export const SendMessageText = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 90%;
  font-family: Poppins;
  font-style: normal;
  margin: 10px 0px 10px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #215ec9;
`;

export const AntdCheck = styled(Checkbox)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #215ec9;
`;

export const PopOverContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 8px;
  color: #011759;
  padding: 10px 30px 10px 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #011759;
  cursor: pointer;
  &:hover {
    background: #f4f6ff;
  }
`;

export const SpaceDiv = styled(Space)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.25);
  // border-radius: 12px;
  // border: 1px solid rgba(255, 255, 255, 0.125);
`;
export const UserCompany = styled.div`
  font-size: 10px;
`;