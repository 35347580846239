import { Backdrop, CircularProgress, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import DataGridComp from "../../DataGrid/DataGrid";
import { GridColDef } from "@material-ui/data-grid";
import LocalStorage from "../../../../../_services/LocalStorage";
import { renderCellExpand } from "../../../../CellExpandPopup/CellExpandPopup";
import ROLES from "../../../../../constants/RolesConstants";
import { getUserRole } from "../../../../../Utils/Storage";
import { formatTime, getFormattedTimeStamp } from "Utils/DisplayTimeFormat";

interface AllLogsProps {
  startDate: any;
  endDate: any;
  setAccessStatus: (status: boolean) => void;
}
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const csvHeaders = [
  { label: "Date", key: "date" },
  { label: "Email Id", key: "email" },
  { label: "Action", key: "action" },
  { label: "Searched Keyword", key: "input" },
  { label: "Search Results", key: "searchResults" },
  { label: "Profile Viewed", key: "profileView" },
  { label: "Email Viewed", key: "emailView" },
  { label: "Phone Number Viewed", key: "phoneNumberView" },
  { label: "Brochure Viewed", key: "brochureView" },
  { label: "Shortlisted", key: "shortlist" },
];

const AllLogsList = (props: AllLogsProps) => {
  const { startDate, endDate } = props;
  const classes = useStyles();
  const history = useHistory();
  const [showLoader, setShowLoader] = React.useState(false);
  const userRole = getUserRole()?.split(',') || [];
  const [showExport, setShowExport] = React.useState(
    userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_EXPORT).length > 0
  );  
  const [clients, setClients] = React.useState([]);
  const [csvReport, setCsvReport] = React.useState({
    data: clients,
    headers: csvHeaders,
    filename: "AllLogs.csv",
  });

  const navigateToClientDashboard = (clientId: any) => {
    history.push(`/analytics-clients/${clientId}/dashboard`);
  };

  const dateTo24HourIST = (date:string) => {
    return formatTime(date, "YYYY-MM-DD HH:mm:ss")
  }

  const columns: GridColDef[] = [
    {
      field: "lastLogTime",
      headerName: "Last Login Time",
      width: 200,
      align: "left",
      resizable: true,
      valueFormatter: params => dateTo24HourIST(params?.value as string),
      valueGetter: params => dateTo24HourIST(params?.value as string),
      renderCell: params => renderCellExpand({value: dateTo24HourIST(params?.value as string)})
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: (params) => (
        <Link
          onClick={() => {
            navigateToClientDashboard(params.id);
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "email",
      headerName: "Email Id",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "noOfRecords",
      headerName: "Number of Records",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
  ];

  const getCsvFileName = () => {
    return (
      moment(startDate).format("YYYY-MM-DD") +
      "/" +
      moment(endDate).format("YYYY-MM-DD") +
      "-AllLogs.csv"
    );
  };

  useEffect(() => {
    setShowLoader(true);
    const getClientList = async () => {
       await axios.get(
        process.env.REACT_APP_BASE_URL + "/analytics/client",
        {
          params: {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
            action: "all",
          },
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      ).then((result)=>{
        if (result?.data?.displayData) {
          const output = result?.data?.displayData?.map((field: any) => {
            return {
              id: field.id,
              lastLogTime: field.lastLogTime,
              firstName: field.user.firstName,
              lastName: field.user.lastName,
              email: field.user.email,
              noOfRecords: field.noOfRecords,
            };
          });
          setClients(output);
        } else {
          setClients([]);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        if(error.response.status === 403){
          props.setAccessStatus(false);
        } 
      });
    };
    getClientList();
  }, [startDate, endDate]);


  useEffect(() =>{
    const getCsvData = async () => {
      await axios.get(
        process.env.REACT_APP_BASE_URL + "/analytics/client/export",
        {
          params: {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
            action: "all",
          },
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      ).then((res) => {
        if (res?.data?.csvData) {
          const CSV_DATA = res?.data?.csvData?.map((respObj: any) => ({
            ...respObj,
            date: dateTo24HourIST(respObj?.date), // change date to 24hours IST format
          }));
          setCsvReport({
            data: CSV_DATA,
            headers: csvHeaders,
            filename: getCsvFileName(),
          })
        }
      })
      .catch((error) => {
        if(error.response.status === 403){
          props.setAccessStatus(false);
        } 
      });
    }
    if(showExport) {
      getCsvData();
    }
  },[startDate, endDate])
 
  return (
    <>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {
        showExport && csvReport.data.length && 
        <CSVLink
        {...csvReport}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "30px",
        }}
      >
        Export Full Report
      </CSVLink>
      }
     
      {
        <DataGridComp
          hideExport={!showExport}
          col={columns}
          rows={clients}
          sortField="date"
        ></DataGridComp>
      }
    </>
  );
};

export default AllLogsList;
