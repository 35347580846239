import { Card, CardContent, List, ListItem, ListItemText, TextField } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

interface TabPanelProps {
  children?: React.ReactNode;
  data: any[];
  index: any;
  value: any;
}

interface ClientVendorListProps{
  clients: any[],
  vendors: any[],
  communication: any[]
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  coloredListItem: {
    backgroundColor: '#f1f1f1',
    cursor:'pointer',
    color: 'blue'
  },

  listItem: {
    cursor:'pointer',
    color: 'blue'
  },
  transparentButton: {
    border: 'none',
    background: 'transparent',
    color: 'blue'
},
flexContainer: {
  display:'flex'
},
backdrop: {
  zIndex: theme.zIndex.drawer + 1,
  color: "#fff",
}
}));

const TabPanel = (props: TabPanelProps) => {
  const {data, value, index, ...other } = props;
  const classes = useStyles();
  const history = useHistory();
  const [searchString, setSearchString] = useState('');

  const navigateToCommunicatedPage = (id:any) => {
    const url = `/client-vendor-messages/${index === 0? 'client':'vendor'}/${id}`;
    sessionStorage.setItem('perviousUrl',url);
    history.push(url)
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {!!data && (
        <>
        <div className={classes.flexContainer}>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField id="outlined-basic" placeholder="Search by username/company name"
          variant="outlined" style={{width: '90%',margin: '20px'}}
          onChange={e => {
            setSearchString(e.target.value);
          }}
          />
        </form>
        {/* <CSVLink {...csvReport} style={{display:'flex', justifyContent:'flex-end'}}>Export to CSV</CSVLink> */}
      </div>
        <List>
        {searchString.length ? 
          data.map((user: any, index: any) => {
            if(user.firstName?.toLowerCase().includes(searchString.toLowerCase()) || user.companyName?.toLowerCase().includes(searchString.toLowerCase()))
              return (
                <ListItem key={user.id} className={(index%2 !== 0)? classes.coloredListItem: classes.listItem} onClick={() => {navigateToCommunicatedPage(user.id)}}>
                  <ListItemText
                    primary= {`${user?.firstName} ${user?.lastName}`}
                    secondary= {user?.companyName}
                  />
              </ListItem>
            )
          }) :
          data.map((user: any, index: any) => {
            return (
              <ListItem key={user.id} className={(index%2 !== 0)? classes.coloredListItem: classes.listItem} onClick={() => {navigateToCommunicatedPage(user.id)}}>
                <ListItemText
                  primary= {`${user?.firstName} ${user?.lastName}`}
                  secondary= {user?.companyName}
                />
              </ListItem>
            )
          })
        }
      </List>
      </>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const csvHeaders = [
  {label: 'Client Name', key:'clientName'},
  {label: 'Vendor Name', key:'vendorName'},
  {label: `Vendor's Company`, key:'vendorCompany'},
  {label: 'Total Messages By Client', key:'clientMessagesCount'},
  {label: 'Total Messages By Vendor', key: 'vendorMessagesCount'},
  {label: `Client's Last Message`, key: 'clientsLastMessage'},
  {label:  `Vendor's Last Message`, key:'vendorsLastMessage'},
];

const ClientVendorList = (props: ClientVendorListProps) => {
  const {clients, vendors, communication} = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [csvReport, setCsvReport] =  React.useState({
    data: communication,
    headers: csvHeaders,
    filename: 'communications.csv'
  });

  useEffect(() => {
    setCsvReport({
      data: communication,
    headers: csvHeaders,
    filename: 'communications.csv'
    });
  },[communication])

  

  return (
    <Card className={classes.root} variant="outlined">
      <CSVLink {...csvReport} style={{display:'flex', justifyContent:'flex-end', paddingRight:'30px'}}>Export to CSV</CSVLink>
    <CardContent>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Client" {...a11yProps(0)} />
          <Tab label="Vendor" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} data={clients}>
      </TabPanel>
      <TabPanel value={value} index={1} data={vendors}>
      </TabPanel>
    </CardContent>
   
  </Card>
    // <div className={classes.root}>
      
    // </div>
  );
}

export default ClientVendorList;
