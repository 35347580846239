import React, { useEffect, useState } from "react";
import { Button, Paper } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { Link, useHistory, useParams, Outlet } from "react-router-dom";
import { SOW_INFO_OPTION, VendorUpdateData } from "../../constants/updateVendorConstants";
import './UpdateVendorProfiles.scss';
import { PromptProvider } from "components/Modal/PromptProvider";
import { usePrompt } from "components/Modal/usePrompt";
const UpdateVendorProfile = ({ bodyContent }) => {
  const [active, activateSection] = React.useState(bodyContent || SOW_INFO_OPTION)
  const { gstn } = useParams();
  const history = useHistory();
  const prompt = usePrompt();
  
  const navigateToVendors = () => {
    history.replace('/vendors')
  }

  const clearFilters = () => {
    sessionStorage.removeItem('searchParams');
    history.replace('/vendors')
  }

  return (
    <PromptProvider>
    <div className="row update_vendor_container">

      <Paper className="col-3 collections_card" elevation={2}>
        <Button variant="contained" className="back_button mb-4" color="primary" onClick={navigateToVendors}>
          <ArrowBackIcon className="back_arrow"></ArrowBackIcon>
          Back
        </Button>
        {VendorUpdateData ? VendorUpdateData.map(menu => (
          <Link
            key={menu.id}
            to={menu.linkTo + "/" + gstn}
            className={
              active && active.linkTo === menu.linkTo ? "active" : ""
            }
            style={{
              display: "block",
              padding: "15px 13px",
              background:
                active && active.linkTo === menu.linkTo
                  ? "#F4F6FF"
                  : "transparent",
            }}
          >
            <div className="selector_area" onClick={() => {
              prompt.isDataChanged && activateSection(menu)
            }}>
              {menu.cardName}
            </div>
          </Link>
        )) : null}
        <Button variant="contained" className="clear_filter mt-4" color="primary" onClick={clearFilters}>
          <BackspaceIcon className="back_arrow"></BackspaceIcon>
           (Back) Without Filters
        </Button>
      </Paper>
      <Paper className="col-8 collections_card ml-5 d-flex" style={{flexDirection: "column"}} elevation={2}>
        {active ? active.render() : null}
      </Paper>
    </div>
    </PromptProvider>
  )
}

export default UpdateVendorProfile;