import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import LocalStorage from "_services/LocalStorage";

interface IAxiosHandler {
  withAuth?: boolean;
  axiosConfig: AxiosRequestConfig;
  failureCallback: (err: any) => void;
  successCallback: (successResp: AxiosResponse) => void;
}

export const axiosHandler = async ({ axiosConfig, withAuth, failureCallback, successCallback }: IAxiosHandler) => {
  if (withAuth) axiosConfig.headers = { authorization: `Bearer ${LocalStorage.get("token")}` };

  try {
    const resp: AxiosResponse = await axios(axiosConfig);
    successCallback(resp);
  } catch (err) {
    console.error("axios error", err);
    failureCallback(err);
  }
};
