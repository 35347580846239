import React from 'react';
import { Spin, Space } from 'antd';
import styles from "./Spinner.module.scss"

const Spinner = () => {
  return (
    <div className={styles.SpinnerContainer}>
      <Space size="middle">
        <Spin size="large"  />
      </Space>
    </div>
  )
}

export default Spinner;