import { Button, Paper } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  ClientUpdateData,
  CLIENT_USER_INFO,
} from "../../constants/updateClientContants";
import "./UpdateClientProfilesWrapper.scss";

const UpdateClientProfilesWrapper = ({ bodyContent }) => {
  const [active, activateSection] = React.useState(
    bodyContent || CLIENT_USER_INFO
  );
  const { clientId } = useParams();
  const history = useHistory();

  const navigateToClients = () => {
    history.replace("/clients");
  };

  return (
    <div className="row update_vendor_container">
      <Paper className="col-3 collections_card" elevation={2}>
        <Button
          variant="contained"
          className="back_button mb-4"
          color="primary"
          onClick={navigateToClients}
        >
          <ArrowBackIcon className="back_arrow"></ArrowBackIcon>
          Back
        </Button>
        {ClientUpdateData
          ? ClientUpdateData.map((menu) => (
              <Link
                key={menu.id}
                to={menu.linkTo + "/" + clientId}
                className={
                  active && active.linkTo === menu.linkTo ? "active" : ""
                }
                style={{
                  display: "block",
                  padding: "15px 13px",
                  background:
                    active && active.linkTo === menu.linkTo
                      ? "#F4F6FF"
                      : "transparent",
                }}
              >
                <div
                  className="selector_area"
                  onClick={() => {
                    activateSection(menu);
                  }}
                >
                  {menu.cardName}
                </div>
              </Link>
            ))
          : null}
      </Paper>
      <Paper
        className="col-8 collections_card ml-5 d-flex justify-content-center"
        elevation={2}
      >
        {active ? active.render() : null}
      </Paper>
    </div>
  );
};

export default UpdateClientProfilesWrapper;
