import { IFile } from "container/JobRequests/Interfaces/JobRequestInterfaces";

export enum VerificationStatus {
  "notApplicable" = "notApplicable",
  "verified" = "verified", 
  "inProgress" = "inProgress",
  "documentPending" = "documentPending",
  "notVerified" = "notVerified",
}

export interface IUserInfo {
  _id?: string;
  gstn?: string;
  auth0UserId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  primaryUser?: boolean;

  gstnCertificate?: IFile;
  companyRole?: string;
  gstnVerificationStatus?: keyof typeof VerificationStatus;
  newUserApproved?: boolean;

  // keys for payload
  /** send only when email is edited  */
  emailUpdated?: boolean;
  /** send only when phone Number is edited */
  phoneNumberUpdated?: boolean;
  /** send only when user is deleted */
  deleteUser?: boolean;
  /** send true only when a new user is created */
  newUser?: boolean;
}

export type IKeyOfUserInfo = "firstName" | "lastName" | "email" | "phoneNumber" | "companyRole";

export type IUserEditModeType = "" | "CREATE_NEW_USER" | "UPDATE_EXISTING_USER";

export type IUserInfoWithoutPrimaryUserKey = Omit<IUserInfo, "primaryUser">;

export interface IUserInfoPostPayload {
  currentUser: IUserInfoWithoutPrimaryUserKey;
  primaryUserChange?: {
    from: {} | IUserInfo;
    to: IUserInfo;
  };
}
