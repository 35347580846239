import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from "moment";
import React from "react";
import { getFormattedTimeStamp } from "../../../../../Utils/DisplayTimeFormat";

interface SearchPanelProps {
    data: any;
  }

const headers = [
    'Date',
    'Category Clicked',
    'Sub-Category Clicked',
    'Keywords searched',
    'No. of Results Returned'
];

const collapseHeader = [
'Searched Results',
'Profile Viewed',
'Email Viewed',
'Phone Number Viewed',
'Brochure Viewed',
'Shortlisted'];


const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableContainer: {
        margin: 'auto'
    },
    transparentButton: {
        border: 'none',
        background: 'transparent',
        color: 'blue'
    },
    tableCell: {
      fontSize:'11px',
      lineHeight: '2'
    },


});

const CategorySearchPanel = (props: SearchPanelProps) => {
  const { data} = props;
  const classes = useStyles();
  const Row = (props: { clientData: any, index: number }) => {
  const { clientData } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
      <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{width:"600px"}}>
          { getFormattedTimeStamp(clientData?.date) }
        </TableCell>
        <TableCell style={{width:"500px"}}>{clientData?.category || clientData?.input}</TableCell>
        <TableCell style={{width:"500px"}}>{clientData?.subcategory}</TableCell>
        <TableCell style={{width:"500px"}}>{clientData?.input}</TableCell>
        <TableCell style={{width:"500px"}}>{clientData?.numberOfResultReturned}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {collapseHeader?.map((collapseHeader, index) => {
                     return(<TableCell key={index}  style={{ fontWeight: 700}} >{collapseHeader}</TableCell>)
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {clientData?.results?.map((result:any, index:number) => {return (<div key={index} className={classes.tableCell}>{result?.toUpperCase()}</div>)})}
                      </TableCell>
                      <TableCell>
                        {clientData?.profileView?.map((result:any, index:number) => {return (<div key={index} className={classes.tableCell}>{result?.toUpperCase()}</div>)})}
                      </TableCell>
                      <TableCell >
                        {clientData?.emailView?.map((result:any, index:number) => {return (<div key={index} className={classes.tableCell}>{result?.toUpperCase()}</div>)})}
                      </TableCell>
                      <TableCell >
                        {clientData?.phoneNumberView?.map((result:any, index:number) => {return (<div key={index} className={classes.tableCell}>{result?.toUpperCase()}</div>)})}
                      </TableCell>
                      <TableCell >
                        {clientData?.brochureView?.map((result:any, index:number) => {return (<div key={index} className={classes.tableCell}>{result?.toUpperCase()}</div>)})}
                      </TableCell>
                      <TableCell >
                        {clientData?.shortlist?.map((result:any, index:number) => {return (<div key={index} className={classes.tableCell}>{result?.toUpperCase()}</div>)})}
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow >
              <TableCell />
              {
                headers?.map((header, index) => {
                  return (
                    <TableCell style={{ fontWeight: 700}} key={index} >
                      {header}
                    </TableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((searchData: any, index: number) => (
                <Row key={index} clientData={searchData} index={index} />
            ))}  
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
} 

export default CategorySearchPanel;