import VendorCapabilitiesUpdate from "../components/VendorCapabilitiesUpdate/VendorCapabilitiesUpdate";
import VendorTags from "../components/VendorTagsUpdate/VendorTagsUpdate";
import VendorCertificatesUpdate from "../components/VendorCertificatesUpdate/VendorCertificatesUpdate";
import VendorsDocumentsUpdate from "../components/VendorDocumentsUpdate/VendorDocumentsUpdate";
import VendorExperienceUpdate from "../components/VendorExperienceUpdate/VendorExperienceUpdate";
import VendorsCompanyInfoUpdate from "../components/VendorsCompanyInfoUpdate/VendorsCompanyInfoUpdate";
import VendorUpdatePeopleInfo from "../components/VendorUpdatePeopleInfo/VendorUpdatePeopleInfo";
import VendorUpdateScopeOfWork from "../components/VendorUpdateScopeOfWork/VendorUpdateScopeOfWork";
import VendorVerificationUpdate from "../components/VendorVerificationUpdate/VendorVerificationUpdate";
import VendorUserInfoUpdate from "../components/VendorUserInfoUpdate/VendorUserInfoUpdate";
import VendorReviews from "../container/UpdateVendorProfiles/components/VendorReviews/VendorReviews";

export const SOW_INFO_OPTION = {
  id: 1,
  key: "sow",
  cardName: "Scope of Work",
  linkTo: "/updateSow",
  render: () => <VendorUpdateScopeOfWork />,
};

export const PEOPLE_INFO_OPTION = {
  id: 2,
  key: "people",
  cardName: "People",
  linkTo: "/updatePeopleinfo",
  render: () => <VendorUpdatePeopleInfo />,
};
export const CAPABILITIES_INFO_OPTION = {
  id: 3,
  key: "capabilties",
  cardName: "Capabilities",
  linkTo: "/updateCapabilties",
  render: () => <VendorCapabilitiesUpdate />,
};

export const EXPERIENCES_INFO_OPTION = {
  id: 4,
  key: "experiences",
  cardName: "Experiences",
  linkTo: "/updateExperiences",
  render: () => <VendorExperienceUpdate />,
};

export const COMPANY_INFO_OPTION = {
  id: 5,
  key: "companyInfo",
  cardName: "Company Info",
  linkTo: "/updateCompanyInfo",
  render: () => <VendorsCompanyInfoUpdate />,
};

export const COMPANY_CERTIFICATIONS = {
  id: 6,
  key: "certificates",
  cardName: "Certificates",
  linkTo: "/updateCertificates",
  render: () => <VendorCertificatesUpdate />,
};

export const COMPANY_DOCUMENTS = {
  id: 7,
  key: "documents",
  cardName: "Documents",
  linkTo: "/updateDocuments",
  render: () => <VendorsDocumentsUpdate />,
};

export const VERIFICATION_OPTION = {
  id: 8,
  key: "verification",
  cardName: "Verification",
  linkTo: "/updateVerification",
  render: () => <VendorVerificationUpdate />,
};

export const TAGS_OPTION = {
  id: 9,
  key: "tags",
  cardName: "Tags",
  linkTo: "/updateTags",
  render: () => <VendorTags />,
};

export const USER_INFO_OPTION = {
  id: 10,
  key: "userInfo",
  cardName: "User Info",
  linkTo: "/updateUserInfo",
  render: () => <VendorUserInfoUpdate />,
};

export const VENDOR_REVIEWS = {
  id: 11,
  key: "reviews",
  cardName: "Reviews",
  linkTo: "/updateReviews",
  render: () => <VendorReviews />,
};

export const VendorUpdateData = [
  COMPANY_INFO_OPTION,
  //SOW_INFO_OPTION,
  PEOPLE_INFO_OPTION,
  CAPABILITIES_INFO_OPTION,
  EXPERIENCES_INFO_OPTION,
  COMPANY_DOCUMENTS,
  VERIFICATION_OPTION,
  TAGS_OPTION,
  USER_INFO_OPTION,
  VENDOR_REVIEWS,
];

export const VendorDateType = {
  published: "published",
  admin: "adminUpdated",
  vendor: "vendorUpdated",
  registration: "",
};

export const dropDownData = {
  industries: [
    {
      id: 1,
      label: "Oil & Gas",
      value: "Oil & Gas",
    },
    { id: 2, label: "Power & Energy", value: "Power & Energy" },
    {
      id: 3,
      label: "Metals & Mining",
      value: "Metals & Mining",
    },
    {
      id: 4,
      label: "Chemicals",
      value: "Chemicals",
    },
    {
      id: 5,
      label: "Civil & Construction",
      value: "Civil & Construction",
    },
    {
      id: 6,
      label: "Industrial Machinery & Tools",
      value: "Industrial Machinery & Tools",
    },
    { id: 7, label: "Aerospace & Defence", value: "Aerospace & Defence" },
    {
      id: 8,
      label: "Automotive, Farm and Earth moving equipments",
      value: "Automotive, Farm and Earth moving equipments",
    },
    {
      id: 9,
      label: "Transporation",
      value: "Transporation",
    },
    {
      id: 10,
      label: "Electronics",
      value: "Electronics",
    },
    {
      id: 11,
      label: "Agriculture",
      value: "Agriculture",
    },
    {
      id: 12,
      label: "Pharma, Life Sciences & Medical Devices",
      value: "Pharma, Life Sciences & Medical Devices",
    },
    {
      id: 13,
      label: "Paper & Packaging materials",
      value: "Paper & Packaging materials",
    },
    { id: 14, label: "Consumer Goods", value: "Consumer Goods" },
    { id: 15, label: "Telecom & IT", value: "Telecom & IT" },
    {
      id: 16,
      label: "Textile, Apparel & Luggage",
      value: "Textile, Apparel & Luggage",
    },
    {
      id: 17,
      label: "Food Processing & Beverage",
      value: "Food Processing & Beverage",
    },
    { id: 18, label: "Others", value: "Others" },
  ],
  allRegisteredStates: [
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
    {
      label: "Andaman and Nicobar Islands",
      value: "Andaman and Nicobar Islands",
    },
    { label: "Chandigarh", value: "Chandigarh" },
    { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
    { label: "Daman and Diu", value: "Daman and Diu" },
    { label: "Delhi", value: "Delhi" },
    { label: "Lakshadweep", value: "Lakshadweep" },
    { label: "Ladakh", value: "Ladakh" },
    { label: "Puducherry", value: "Puducherry" },
    { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
  ],
  natureOfWork: [
    {
      label: "Design & Engineering",
      value: "Design & Engineering",
    },
    {
      label: "Product development & Prototyping",
      value: "Product development & Prototyping",
    },
    { label: "Supply", value: "Supply" },
    { label: "Custom Manufacturing", value: "Custom Manufacturing" },
    {
      label: "Machining & Fabrication",
      value: "Machining & Fabrication",
    },
    { label: "Rentals & Logistics", value: "Rentals & Logistics" },
    { label: "Civil & Construction", value: "Civil & Construction" },
    {
      label: "Installation & Commissioning",
      value: "Installation & Commissioning",
    },
    { label: "Turnkey Solution", value: "Turnkey Solution" },
    {
      label: "Repair & Overhauling",
      value: "Repair & Overhauling",
    },
    { label: "Inspection & Audit", value: "Inspection & Audit" },
    { label: "Maintenance & AMC", value: "Maintenance & AMC" },
    {
      label: "Testing & Calibration",
      value: "Testing & Calibration",
    },
    { label: "Software Services", value: "Software Services" },
    { label: "Consultancy services", value: "Consultancy services" },
    {
      label: "Automation & Industrial IoT",
      value: "Automation & Industrial IoT",
    },
    {
      label: "Mechanical, Electrical & Plumbing Works",
      value: "Mechanical, Electrical & Plumbing Works",
    },
    { label: "Others", value: "Others" },
  ],
  disciplines: [
    { label: "Mechanical", value: "Mechanical" },
    { label: "Automation", value: "Automation" },
    { label: "Electrical", value: "Electrical" },
    { label: "Architecture", value: "Architecture" },
    { label: "Structural/Civil", value: "Structural/Civil" },
    { label: "Electronics", value: "Electronics" },
    { label: "Process/Chemical", value: "Process/Chemical" },
    { label: "Instrumentation", value: "Instrumentation" },
    { label: "others", value: "others" },
  ],
  turnOverSlab: [
    { id: 1, value: "0 to 40 lakhs" },
    { id: 2, value: "40 lakhs to 1.5 Cr." },
    { id: 3, value: "1.5 Cr. to 5 Cr." },
    { id: 4, value: "5 Cr. to 25 Cr." },
    { id: 5, value: "25 Cr. to 100 Cr." },
    { id: 6, value: "100 Cr. to 500 Cr." },
    { id: 6, value: "500 Cr. and above" },
  ],
  ProjectCost: [
    { id: 1, label: "less than ₹ 1 lakh", value: "less than ₹ 1 lakh"},
    { id: 2, label: "₹ 1- 10 lakh", value: "₹ 1- 10 lakh"},
    { id: 3, label: "₹ 10- 25 lakh", value: "₹ 10- 25 lakh"},
    { id: 4, label: "₹ 25 lakh- 50 lakh", value: "₹ 25 lakh- 50 lakh"},
    { id: 5, label: "₹ 50 lakh- 1 Cr", value: "₹ 50 lakh- 1 Cr"},
    { id: 6, label: "₹ 1- 5 Cr", value: "₹ 1- 5 Cr"},
    { id: 7, label: "greater than ₹ 5 Cr", value: "greater than ₹ 5 Cr"}
  ]
};
