import {
  AppBar,
  Backdrop,
  CircularProgress,
  Fab,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchPanel from "../../../../../components/analytics/client/User/Search/SearchPanel";
import CategorySearchPanel from "../../../../../components/analytics/client/User/CategorySearch/CategorySearchPanel";
import ViewProfilePanel from "../../../../../components/analytics/client/User/ViewProfile/ViewProfile";
import { CSVLink } from "react-csv";
import VendorFilter from "../../../../../components/VendorFilter/VendorFilter";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsActions } from "../../../actions";
import { getUserRole } from "../../../../../Utils/Storage";
import LocalStorage from "../../../../../_services/LocalStorage";
import NoAccess from "../../../../../components/NoAccess/NoAccess";
import ROLES from "../../../../../constants/RolesConstants";

interface SubsequentEventsModel {
  id: any;
  action: string;
  input: string;
  vendorId: string;
  vendor_name: string;
}
interface ClientModel {
  id: string;
  user: { email?: string; firstName: string; lastName: string };
  profileView?: SubsequentEventsModel[];
  emailView?: SubsequentEventsModel[];
  phoneNumberView?: SubsequentEventsModel[];
  shortlist?: SubsequentEventsModel[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const client: ClientModel[] = [
  {
    id: "1",
    user: {
      firstName: "",
      lastName: "",
    },
  },
];

const DashboardContainer = styled.div`
  padding: 30px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: space-between;
`;

const ClientName = styled.span`
  padding: 0 10px;
  font-size: 18px;
  font-weight: 700;
`;

const tabData = [
  {
    id: 1,
    label: "Search",
    actionValue: "search",
  },
  {
    id: 2,
    label: "Category Search",
    actionValue: "category_search",
  },
  {
    id: 3,
    label: "Profile View",
    actionValue: "profile_view",
  },
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const csvHeaders = [
  { label: "Date", key: "date" },
  { label: "Email Id", key: "email" },
  { label: "No. of Results Returned", key: "numberOfResultReturned" },
  { label: "Searched Keyword", key: "input" },
  { label: "Search Results", key: "results" },
  { label: "Profile Viewed", key: "profileView" },
  { label: "Email Viewed", key: "emailView" },
  { label: "Phone Number Viewed", key: "phoneNumberView" },
  { label: "Brochure Viewed", key: "brochureView" },
  { label: "Shortlisted", key: "shortlist" },
];

const ClientAnalyticsDashboard = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const clientAnalyticsData = useSelector(
    (state: any) => state.AnalyticsReducers
  );
  const { startDate, endDate } = clientAnalyticsData;
  const [analyticsData, setClientAnalyticsData] = React.useState(client);
  const [user, setUser] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const { clientId } = useParams<{ clientId: string }>();
  const [selectedAction, setSelectedAction] = React.useState("search");
  const [showLoader, setShowLoader] = React.useState(false);
  const [csvReport, setCsvReport] = React.useState({
    data: analyticsData,
    headers: csvHeaders,
    filename: "ClientAnalytics.csv",
  });
  const dispatch = useDispatch();
  const userRole = getUserRole()?.split(',') || [];
  const [hasAccess, setHasAccess] =React.useState(true);
  const [showExport, setShowExport] = React.useState(
    userRole?.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_EXPORT).length > 0
  ); 

  const getCsvFileName = (email: any) => {
    return (
      moment(startDate).format("YYYY-MM-DD") +
      "/" +
      moment(endDate).format("YYYY-MM-DD") +
      email +
      "-client-analytics.csv"
    );
  };

  useEffect(() => {
      getClientData();
  }, [startDate, endDate, selectedAction]);

  const getClientData = async () => {
    setShowLoader(true);
    await axios.get(
      process.env.REACT_APP_BASE_URL + `/analytics/client/${clientId}`,
      {
        params: {
          from: moment(startDate).format("YYYY-MM-DD"),
          to: moment(endDate).format("YYYY-MM-DD"),
          action: selectedAction,
        },
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    ).then((result) => {
      if (result.data) {
        setClientAnalyticsData(result.data.data);
        setUser(result.data.user);
        setCsvReport({
          data: result.data.data,
          headers: csvHeaders,
          filename: getCsvFileName(result.data?.user?.email),
        });
      }
      setShowLoader(false);
    } )
    .catch((error) => {
      if(error.response.status === 403){
        setHasAccess(false);
      } 
    });
  };
  const navigateBackToList = () => {
    history.push(`/analytics-clients/list`);
  };
  const handleFilterChange = (from: any, to: any) => {
    dispatch(AnalyticsActions.setStartDate(from));
    dispatch(AnalyticsActions.setEndDate(to));
  };

  const a11yProps = (index: number) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        setSelectedAction("search");
        break;
      case 1:
        setSelectedAction("category_search");
        break;
      case 2:
        setSelectedAction("profile_view");
        break;
      case 3:
        setSelectedAction("login");
        break;
      default:
        break;
    }
  };

  return (
    hasAccess ?
    <DashboardContainer>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header>
        <div>
          <Fab
            color="primary"
            aria-label="edit"
            onClick={navigateBackToList}
            style={{ width: "35px", height: "35px" }}
          >
            <ArrowBackIcon />
          </Fab>
          <ClientName>
            {`${user?.firstName?.toUpperCase() || ""} ${
              user?.lastName?.toUpperCase() || ""
            }`}
          </ClientName>
        </div>
        {showExport && selectedAction === "search" && (
          <CSVLink {...csvReport} style={{ paddingRight: "30px" }}>
            Export to CSV
          </CSVLink>
        )}
      </Header>
      <VendorFilter
        className="filter"
        startDate={startDate}
        endDate={endDate}
        handleFilterChange={handleFilterChange}
      ></VendorFilter>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabData.map((tab: any, index) => (
            <Tab label={tab.label} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {<SearchPanel data={analyticsData}></SearchPanel>}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {<CategorySearchPanel data={analyticsData}></CategorySearchPanel>}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {<ViewProfilePanel data={analyticsData}></ViewProfilePanel>}
      </TabPanel>

    </DashboardContainer>
  :
  <NoAccess></NoAccess>
  );
};

export default ClientAnalyticsDashboard;
