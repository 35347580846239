import React from "react";
import styles from "./label.module.scss";

interface labelProps {
  text: string;
  type: string;
  isError?: boolean;
}

const Label = (props: labelProps) => {
  const labelClass = `${props?.type}`;

  return (
    <label style={{ color: `${props?.isError ? "#BE000B" : "#215EC9"}` }} className={styles[labelClass]}>
      {props?.text}
    </label>
  );
};

export default Label;
