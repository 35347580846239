import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import PublishIcon from "@material-ui/icons/Publish";
import axios from "axios";
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ClientCompanyInfoUpdate.scss";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../NoAccess/NoAccess";
import * as _ from "lodash";
import {AdminTeams} from "./Teams.enum"

const useStyles = makeStyles((theme) => ({
  peopleInfoContainer: {
    padding: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  subHeader: {
    color: "#66759c",
    fontWeight: 600,
    float: "left",
  },
  editAddFields: {
    marginTop: "15px",
    color: "#8fc9f9",
    fontWeight: 400,
    fontSize: "13px",
  },
  editButton: {
    backgroundColor: "#06c396",
    color: "#fff",
  },
  splitLine: {
    borderTop: "1px solid #17a2b8",
    width: "112%",
    position: "relative",
    right: "44px",
  },
}));

const ClientCompanyInfoUpdate = () => {
  const [loader, enableLoader] = React.useState(false);
  const [vendorEditable, setVendorEditable] = React.useState(false);
  const [reloadUpdate, setReloadUpdate] = React.useState(false);
  const [clearbitFields, setClearbitFields] = React.useState(false);
  const [plantNames, setPlantNames] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState({});
  const [pocDropdownValues, setPocDropdownValues] = React.useState([]);

  const { clientId } = useParams();
  const [hasAccess, setHasAccess] =React.useState(true);

  const updateClient = (id, companyInfo) => {
    // updateCompanyInfo(gstn, companyInfo);
    let payload = {
      companyLogo: companyInfo?.companyLogo,
      companyName: companyInfo?.companyName,
      turnOver: companyInfo?.turnOver,
      totalEmployees: companyInfo?.totalEmployees,
      venwizPocEmail: companyInfo?.venwizPocEmail,
      venwizPoc: companyInfo?.venwizPoc,
      foundedYear: companyInfo.foundedYear
        ? Number(companyInfo.foundedYear)
        : 0,
    };
    updateCompanyInfo(id, payload);
  };

  const updateCompanyInfo = async (updateId, payload) => {
    try {
      const result = await axios.put(
        process.env.REACT_APP_BASE_URL +
          "/client-onboarding/updateComapany/" +
          updateId,
        payload,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      );
      if (result.data.status === "SUCCESS") {
        setReloadUpdate(true);
        setVendorEditable(false);
      } else {
        setReloadUpdate(true);
        setVendorEditable(false);
      }
    } catch (err) {
      setReloadUpdate(true);
      setVendorEditable(false);
    }
  };

  const enableEdit = () => {
    setVendorEditable(true);
  };

  const cancelEdit = () => {
    setReloadUpdate((update) => !update);
    setVendorEditable(false);
  };

  const getInfoFromClearbit = (domain) => {
    if (domain.length) {
      let companyEmail = domain.includes("https")
        ? domain
        : "https://" + domain;
      getClearbitCompanyInfo(domain, companyEmail);
    }
  };

  const getClearbitCompanyInfo = async (domain, companyEmail) => {
    enableLoader(true);
    try {
      await axios.get(
        process.env.REACT_APP_BASE_URL + "/client-onboarding/clearbit",
        {
          params: {
            email: domain,
            companyEmail: companyEmail,
          },
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      ).then((result) => {
        if (result.data.status === "SUCCESS") {
          enableLoader(false);
          const dataSet = result.data?.data?.companyInfo;
          let totalEmployees = dataSet?.totalEmployees;
          let turnOver = dataSet?.turnOver;
          let companyName = dataSet?.companyName;
          let foundedYear = dataSet?.foundedYear;
          let venwizPocEmail = dataSet?.venwizPocEmail;
          let venwizPoc = dataSet?.venwizPoc;
          let companyLogo = {
            fileName: dataSet?.companyLogo,
            fileUrl: dataSet?.companyLogo,
          };
          setUserInfo((itr) => ({
            ...itr,
            foundedYear: foundedYear,
            companyName: companyName,
            turnOver: turnOver,
            totalEmployees: totalEmployees,
            companyLogo: companyLogo,
            venwizPocEmail: venwizPocEmail,
            venwizPoc: venwizPoc
          }));
          setVendorEditable(true);
          setPlantNames(dataSet?.plant);
        } else {
          loader(false);
          setVendorEditable(false);
        }
      })
      .catch((error) => {
        if(error.response.status === 403){
          setHasAccess(false);
        } 
      });
    } catch (err) {
      enableLoader(false);
      setReloadUpdate(true);
    }
  };

  useEffect(async() => {
    enableLoader(true);
    try {
      await axios.get(
        process.env.REACT_APP_BASE_URL + `/api/v1/client-request/team-list?team=${AdminTeams.CustomerSuccess}`,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      ).then((result) => {
        if (result.status === 200) {
         setPocDropdownValues(result.data);
        } else {
          loader(false);
          setVendorEditable(false);
        }
      })
      .catch((error) => {
        if(error.response.status === 403){
          setHasAccess(false);
        } 
      });
    } catch (err) {
      enableLoader(false);
      setReloadUpdate(true);
    }
  },[]);

  const addCompanyForClient = (clientData, clientId) => {
    let logo = {
      fileName: clientData.companyLogo?.fileName,
      fileUrl: clientData.companyLogo?.fileUrl,
    };
    let plants = [];
    plants.push(clientData.selectedPlant);
  };

  const uploadFile = async (e) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/upload",
      data,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    if (result.data.status === "SUCCESS") {
      createPermanentLink(result.data.data, e.target.files[0].name);
    }
  };

  const createPermanentLink = async (payload, fileName, fileUrl) => {
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/permanent",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );

    if (result.data.status === "SUCCESS") {
      let file = {
        fileName: fileName,
        fileUrl: result.data?.data?.fileUrl || "",
      };
      setUserInfo((itr) => ({ ...itr, companyLogo: file }));
    }
  };

  useEffect(async () => {
    try {
      enableLoader(true);
      await axios.get(
        process.env.REACT_APP_BASE_URL +
          "/client-onboarding/company/" +
          clientId,
          {
            headers: {
              authorization: `Bearer ${LocalStorage.get("token")}`
            }
          }
      ).then((result) => {
        if (result.data.status == "SUCCESS") {
          const resultData = result.data.data;
          const fullName =
            (resultData?.firstName || "") + " " + (resultData?.lastName || "");
          setUserInfo({ ...resultData, fullName: fullName });
          setReloadUpdate(false);
          setClearbitFields(false);
          setPlantNames([]);
        }
        if (result.data.status == "ERROR") {
          setClearbitFields(true);
          setReloadUpdate(false);
        }
  
        enableLoader(false);
      })
      .catch((error) => {
        if(error.response.status === 403){
          setHasAccess(false);
        } 
      });
    } catch (err) {
      setClearbitFields(false);
      setUserInfo({});
      enableLoader(false);
    }
  }, [reloadUpdate]);

  const classes = useStyles();
  return (
    hasAccess ?
    <div className="container-fluid">
      <div className={classes.peopleInfoContainer}>
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Fragment>
          {clearbitFields ? (
            <Fragment>
              <div className="row mb-4">
                <div className="col">
                  <InputLabel className={classes.subHeader}>
                    Company Domain (Clearbit)
                  </InputLabel>
                  <TextField
                    id="19"
                    value={userInfo?.clearbitEmail || ""}
                    variant="outlined"
                    placeholder="Enter company domain"
                    disabled={false}
                    onChange={(e) => {
                      setUserInfo((data) => ({
                        ...data,
                        clearbitEmail: e.target.value,
                      }));
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              getInfoFromClearbit(userInfo?.clearbitEmail)
                            }
                          >
                            <PlayForWorkIcon
                              style={{ color: "#28a745" }}
                            ></PlayForWorkIcon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col">
                  <InputLabel className={classes.subHeader}>
                    Select Plant
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={userInfo?.selectedPlant}
                    variant="outlined"
                    onChange={(e) => {
                      setUserInfo((data) => ({
                        ...data,
                        selectedPlant: e.target.value,
                      }));
                    }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {plantNames?.length
                      ? plantNames?.map((itr, key) => (
                          <MenuItem value={itr} key={key}>
                            {itr}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </div>
                <div className="col">
                  <InputLabel className={classes.subHeader}>
                    Plant Name
                  </InputLabel>
                  <TextField
                    id="136"
                    value={userInfo?.selectedPlant || ""}
                    variant="outlined"
                    disabled={!vendorEditable}
                    onChange={(e) => {
                      setUserInfo((data) => ({
                        ...data,
                        selectedPlant: e.target.value,
                      }));
                    }}
                    fullWidth
                  />
                </div>
              </div>
            </Fragment>
          ) : null}
          <div className="row">
            <div className="col">
              <InputLabel className={classes.subHeader}>
                Company Name
              </InputLabel>
              <TextField
                id="1"
                value={userInfo?.companyName || ""}
                variant="outlined"
                disabled={!vendorEditable}
                onChange={(e) => {
                  setUserInfo((data) => ({
                    ...data,
                    companyName: e.target.value,
                  }));
                }}
                fullWidth
              />
            </div>
            <div className="col">
              <InputLabel className={classes.subHeader}>
                Founded Year
              </InputLabel>
              <TextField
                id="21"
                value={userInfo?.foundedYear || ""}
                variant="outlined"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 9999 } }}
                disabled={!vendorEditable}
                onChange={(e) => {
                  setUserInfo((itr) => ({
                    ...itr,
                    foundedYear: e.target.value,
                  }));
                }}
                fullWidth
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <InputLabel className={classes.subHeader}>
                Company Email
              </InputLabel>
              <TextField
                id="5"
                value={userInfo?.companyEmail || ""}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </div>
            <div className="col">
              <InputLabel className={classes.subHeader}>
                Total Employees
              </InputLabel>
              <TextField
                id="21"
                value={Number(userInfo?.totalEmployees) || ""}
                variant="outlined"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 999999 } }}
                disabled={!vendorEditable}
                onChange={(e) => {
                  setUserInfo((itr) => ({
                    ...itr,
                    totalEmployees: e.target.value,
                  }));
                }}
                fullWidth
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <InputLabel className={classes.subHeader}>Turnover</InputLabel>
              <TextField
                id="212"
                value={userInfo?.turnOver || ""}
                variant="outlined"
                disabled={!vendorEditable}
                onChange={(e) => {
                  setUserInfo((itr) => ({
                    ...itr,
                    turnOver: e.target.value,
                  }));
                }}
                fullWidth
              />
            </div>
            <div className="col">
              <InputLabel className={classes.subHeader}>Venwiz POC </InputLabel>
              <Select
               variant="outlined"
                 value={[userInfo?.venwizPoc?.email]}
                 disabled={!vendorEditable}
                 onChange={(e) => {
                  const vPoc =pocDropdownValues.find((poc) => poc?.email ===  e.target.value)
                  setUserInfo((itr) => ({
                    ...itr,
                    venwizPocEmail: e.target.value,
                    venwizPoc:vPoc,
                  }));
                }}
                id="342"
                fullWidth>
                     {pocDropdownValues.map((itr) => (
              <ListItem value={itr.email} key={itr.email} >
                    <ListItemText primary={`${itr.firstName} ${itr.lastName}`} />
                    </ListItem>
                ))}
                </Select>

            
             </div>
          </div>
          <div className="row img_section">
            <div className="col">
              <div className="image_wrapper text-left">
                <img
                  className="image_wrapper"
                  alt={userInfo?.companyLogo?.fileName}
                  src={userInfo?.companyLogo?.fileUrl}
                ></img>
              </div>
              {vendorEditable ? (
                <div className="upload_icon">
                  <input
                    accept="image/jpeg, image/png"
                    style={{ display: "none" }}
                    id="icon-button-photo"
                    onChange={uploadFile}
                    type="file"
                    multiple="false"
                    disabled={false}
                    className={classes.fileInput}
                  />
                  <label htmlFor="icon-button-photo">
                    <IconButton
                      className="text-left"
                      color="primary"
                      component="span"
                      disabled={false}
                    >
                      <PublishIcon />
                    </IconButton>
                  </label>
                </div>
              ) : null}
            </div>
            <div className="col"></div>
          </div>

          <div className="row mt-4">
            <div className="col text-right">
              {vendorEditable ? (
                <Fragment>
                  {clearbitFields ? (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      onClick={() => addCompanyForClient(userInfo, clientId)}
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      onClick={() => updateClient(userInfo._id, userInfo)}
                    >
                      Update
                    </Button>
                  )}
                </Fragment>
              ) : (
                <Button
                  variant="contained"
                  className="editButton"
                  startIcon={<EditIcon />}
                  onClick={enableEdit}
                >
                  Edit
                </Button>
              )}
              <Button
                variant="contained"
                className="ml-2 cancelButton"
                startIcon={<ClearIcon />}
                onClick={cancelEdit}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
    :
    <NoAccess></NoAccess>
  );
};

export default ClientCompanyInfoUpdate;
