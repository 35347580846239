import { VENDOR_REVIEWS } from "../../../../constants/updateVendorConstants";
import UpdateVendorProfile from "../../UpdateVendorProfiles";
import { PromptProvider } from "components/Modal/PromptProvider";
const VendorReviews = () => (
  <PromptProvider>
    <UpdateVendorProfile bodyContent={VENDOR_REVIEWS} />
  </PromptProvider>
);

export default VendorReviews;
