import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  splitLine: {
    borderTop: "1px solid #17a2b8",
    width: "112%",
    position: "relative",
    right: "44px",
  },
  familiesLabel: { marginTop: 8 },
  fullWidth: { width: "100%" },
  tagsContainer: {
    border: "1px solid #e0e0e0",
    padding: "10px",
    borderRadius: "4px",
  },
  tagsActionButtons: { margin: "10px 0" },
  tagChip: { 
    margin: "2px !important", 
    color: "#000", 
    fontSize: 14, 
  },
  customAutocomplete: {
    marginTop: "20px",
    marginBottom: "20px",
    position: "relative",
  },
}));