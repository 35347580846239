import React from "react";
import { COMPANY_CERTIFICATIONS } from "../../constants/updateVendorConstants";
import UpdateVendorProfile from "../../container/UpdateVendorProfiles/UpdateVendorProfiles";
import { PromptProvider } from "components/Modal/PromptProvider";
const UpdateCertificates = () => (
  <PromptProvider>
    <UpdateVendorProfile bodyContent={COMPANY_CERTIFICATIONS} />
  </PromptProvider>
);

export default UpdateCertificates;