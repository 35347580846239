import { Chip, CircularProgress, Paper } from "@material-ui/core";
import { AccordianContainer } from "components/VendorTagsUpdate/AccordionContainer";
import React, { useState } from "react";
import { Button, Typography, Box } from "@material-ui/core";
import { SelectDropdown } from "./SelectDropdown";
import {
  OTHER_DOCUMENTS_KEY_NAMES_ENUM,
  IFileDoc,
  IVendorDocumentAccordionProps,
  IDocumentsData,
  IDocumentsKeyNamesType,
  IDocumentsKeyNamesTypeWithoutGstn,
} from "./vendorDocumentsUpdate.interface";
import { FileOperationActions } from "container/common/FileOperations/actions";
import { useDispatch } from "react-redux";
import { ONE_FIFTY_MB_IN_BYTES } from "constants/fileConstants";
import { IFiles } from "container/JobRequests/JobRequestDetails.interfaces";
import { FORM_DATA_KEY } from "container/JobRequests/JobRequestDetails/jobRequest.constants";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

export const VendorDocumentAccordion = (
  props: IVendorDocumentAccordionProps
) => {
  const {
    gstn,
    title,
    defaultExpanded,
    isViewOpen,
    docType,
    handleChangeDocType,
    isDocUploading,
    setIsDocUploading,
    DocumentsData,
    DOCUMENTS_UPLOAD_OPTIONS,
    DOCUMENTS_KEY_NAMES_ENUM,
    hiddenInputRef,
    postDoc,
    setShowNotification,
    setNotificationMessage,
  } = props;

  const dispatch = useDispatch();

  const handleMediaUpload = ({
    e,
    TYPE,
  }: {
    e: React.FormEvent<HTMLInputElement>;
    TYPE: IDocumentsKeyNamesType;
  }) => {
    e.stopPropagation();
    if (!TYPE) return;

    const files = Object.entries(((e?.target as any)?.files || {}) as FileList);
    let filesWithError: File[] = [];
    let newFilesToBeUploaded: File[] = [];
    if (files?.length) {
      setIsDocUploading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const [_, file] = files[i];
        const isFileTypeUndefined =
          file.type === "undefined" || file.type === "null";
        const isFileSizeExceedingLimit = file.size > ONE_FIFTY_MB_IN_BYTES;
        const isDuplicateFile = DocumentsData?.[TYPE]
          ? (DocumentsData?.[TYPE] as IFiles[])?.find(
              (existingFile) => existingFile.name === file.name
            )
          : false;

        if (
          isDuplicateFile ||
          isFileSizeExceedingLimit ||
          isFileTypeUndefined
        ) {
          filesWithError.push(file);
        } else {
          newFilesToBeUploaded.push(file);
        }
      }

      if (filesWithError?.length) {
        setShowNotification(true);
        setNotificationMessage(
          `Uploaded file is either undefined, exceeds limit or already exists. ${filesWithError
            ?.map((file) => file?.name)
            .join(", ")}`
        );
        if (!newFilesToBeUploaded?.length) setIsDocUploading(false);
      }

      if (newFilesToBeUploaded?.length) {
        newFilesToBeUploaded.forEach((file) =>
          formData.append(FORM_DATA_KEY, file)
        );

        /** Multiple file upload */
        dispatch(
          FileOperationActions.uploadFileToPermanentFolder(formData)
        ).then(
          (res: IFiles[]) => {
            if (res?.length) {
              const newUploadedFiles =
                res.map((file) => ({ name: file.name, documentFile: file })) ||
                [];
              const allFilesToBeUploaded =
                DocumentsData?.[TYPE] && DocumentsData?.[TYPE]?.length
                  ? DocumentsData?.[TYPE]?.concat(newUploadedFiles)
                  : newUploadedFiles;
              const reports: IDocumentsData = { [TYPE]: allFilesToBeUploaded };

              /** proceed with uploading docs */
              if (reports?.[TYPE]?.length) postDoc(reports);
            }
          },
          (err: any) => {
            setShowNotification(true);
            setIsDocUploading(false);
            setNotificationMessage("Error in file upload");
          }
        );
      } else {
        setIsDocUploading(false);
      }

      /** reset input value for same file upload */
      (e.target as any).value = "";
    }
  };

  const handleDeleteDoc = ({
    file,
    TYPE,
  }: {
    file: IFileDoc;
    TYPE: IDocumentsKeyNamesTypeWithoutGstn;
  }) => {
    const filteredFiles =
      DocumentsData?.[TYPE] && DocumentsData?.[TYPE]?.length
        ? DocumentsData?.[TYPE]?.filter(
            (existingFile) =>
              existingFile?.documentFile?.fileUrl !==
              file?.documentFile?.fileUrl
          )
        : [];
    const payload: IDocumentsData = { [TYPE]: filteredFiles };
    postDoc(payload);
  };

  return (
    <Box className="mb-4">
      <Paper elevation={3}>
        <AccordianContainer
          defaultExpanded={defaultExpanded}
          name={title}
          accordionContent={
            <Box width={"100%"}>
              {isViewOpen && (
                <Box
                  marginBottom={1}
                  display={"flex"}
                  width={"max-content"}
                  flexDirection={"row"}
                >
                  <Box marginRight={4}>
                    <SelectDropdown
                      label="Document Type"
                      value={docType}
                      style={{ width: "250px" }}
                      handleOnChange={handleChangeDocType}
                      options={DOCUMENTS_UPLOAD_OPTIONS as any}
                    />
                  </Box>
                  <>
                    <input
                      multiple
                      type="file"
                      ref={hiddenInputRef}
                      style={{ display: "none" }}
                      disabled={isDocUploading}
                      onInput={(e) => handleMediaUpload({ e, TYPE: docType })}
                    />
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={isDocUploading}
                      startIcon={
                        isDocUploading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <CloudUploadIcon />
                        )
                      }
                      onClick={() => hiddenInputRef?.current?.click()}
                    >
                      Upload
                    </Button>
                  </>
                </Box>
              )}
              {/* Uploaded Docs */}
              {DocumentsData && Object?.keys(DocumentsData)?.length !== 0 && (
                <Box width={"100%"} overflow={"auto"} maxHeight={"150px"}>
                  {DocumentsData && Object?.keys(DocumentsData)?.length &&
                    Object?.keys(DocumentsData)
                      ?.sort((a, b) => a.localeCompare(b))
                      ?.map((keyName) => {
                        if (
                          !(
                            DOCUMENTS_KEY_NAMES_ENUM && Object.values(DOCUMENTS_KEY_NAMES_ENUM) as string[]
                          ).includes(keyName) ||
                          [
                            OTHER_DOCUMENTS_KEY_NAMES_ENUM?.gstn,
                            OTHER_DOCUMENTS_KEY_NAMES_ENUM?.document,
                            OTHER_DOCUMENTS_KEY_NAMES_ENUM?.oemDocuments,
                          ].includes(keyName as any)
                        ) {
                          return <></>;
                        } else {
                          /** Rendering Common Docs with specific type */
                          const typeOfDoc = keyName as IDocumentsKeyNamesType;
                          const DocLabel = DOCUMENTS_UPLOAD_OPTIONS?.find(
                            (doc) => doc?.value === typeOfDoc
                          )?.label;
                          const values = DocumentsData?.[typeOfDoc] || [];

                          if (!values?.length) return <></>;

                          return (
                            <Box
                              padding={1}
                              marginRight={1}
                              marginBottom={1}
                              key={typeOfDoc}
                              borderTop={"1px solid #ccc"}
                            >
                              <Box marginBottom={1}>
                                <Typography>{DocLabel}</Typography>
                              </Box>
                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                flexWrap={"wrap"}
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                gridGap={2}
                              >
                                {values?.map((file, index) => {
                                  return (
                                    <Box
                                      marginRight={1}
                                      marginBottom={1}
                                      key={file?.documentFile?.fileUrl || index}
                                    >
                                      <Chip
                                        clickable
                                        icon={
                                          <Box
                                            px={1}
                                            borderRadius={"50%"}
                                            bgcolor={"#eeeeee"}
                                            height={20}
                                            width={20}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                          >
                                            {index + 1}
                                          </Box>
                                        }
                                        label={
                                          <>
                                            <Typography
                                              style={{ fontSize: "14px" }}
                                              onClick={() =>
                                                window.open(
                                                  file?.documentFile?.fileUrl,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              {file?.name}
                                            </Typography>
                                          </>
                                        }
                                        onDelete={() => {
                                          handleDeleteDoc({
                                            file,
                                            TYPE: typeOfDoc as IDocumentsKeyNamesTypeWithoutGstn,
                                          });
                                        }}
                                      />
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          );
                        }
                      })}
                </Box>
              )}
            </Box>
          }
        >
          <></>
        </AccordianContainer>
      </Paper>
    </Box>
  );
};
