import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientRequestActions } from "./actions";
import ClientProjects from "./ClientProjects/ClientProjects";
import { ITabPanelProps } from "./interfaces/Company.interface";
import Box from "@mui/material/Box";
import VendorRequests from "./VendorRequests/VendorRequests";
import Header from "./Header/Header";
import ROLES from "constants/RolesConstants";
import { checkHasAnyOfTheRole } from 'shared/helpers/checkHasAccess';
import { Button } from '@material-ui/core';
import { textAlign } from '@mui/system';
import AntdToast from 'components/Toast/AntdToast';

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ClientRequests = () => {
  const dispatch = useDispatch();
  const { companies, selectedCompany, selectedClientRequestTab } = useSelector((state: any) => state.ClientRequestReducer);
  const showVendorRequestsTab = checkHasAnyOfTheRole([ROLES.CLIENT_VR_VIEW_EXPORT]);
  const showJobRequestEmailButton =  checkHasAnyOfTheRole([ROLES.SUPER_ADMIN, ROLES.CLIENT_PROJECTS]);
  const showProjectsTab = checkHasAnyOfTheRole([ROLES.CLIENT_PROJECTS]);
  const [emailIds, setEmailIds] = React.useState('')

  useEffect(() => {
    dispatch(ClientRequestActions.setSelectedProject(null));
    if(showVendorRequestsTab) handleChange(null, 0)
    return(() => {
      dispatch(ClientRequestActions.resetStore());
    })
  }, []);
  const handleSendEmail = ()=>{
    const clientEmails = emailIds.split(',').map(s => s.trim());;
    const templateName = "Request Job Form";
    dispatch(ClientRequestActions.sendJobRequestFormEmail({clientEmails,templateName})).then((resp:any)=>{
      AntdToast({ type: "success", message: "Success", description: "Email sent to client" });
    })
    .catch(() => {
      AntdToast({ type: "error", message: "Error", description: "Failed to send Email to client" });
    });
  }
  const handleChange = (event: React.SyntheticEvent | null, newlySelectedTab: number) => {
    dispatch(ClientRequestActions.setSelectedClientRequestTab(newlySelectedTab));
  };
  return (
    <>
    <Box style={{display:"inline-flex"}}>
      {companies &&
          <Header />}
       {selectedCompany && showJobRequestEmailButton && <Box style={{ display: "inline-flex", textAlign:"right" }}>
          <input style={{ margin: "10px 20px", width: "500px" }} key="input-emails"
            placeholder='Enter emails (comma separated)' onChange={(e) => setEmailIds(e.target.value)}
          ></input>
          <Button style={{ margin: "10px 20px" }} variant="contained"
            color="primary"
            onClick={handleSendEmail}>
            Send Job Request Form Email
          </Button>
        </Box>}
      </Box>
      {selectedCompany && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={selectedClientRequestTab} onChange={handleChange}>
              {showVendorRequestsTab && <Tab label="Vendor Requests" value={0} />}
              {showProjectsTab && <Tab label="Projects" value={1} />}
            </Tabs>
          </Box>
          {showVendorRequestsTab && (
            <TabPanel value={selectedClientRequestTab} index={0}>
              <VendorRequests/>
            </TabPanel>
          )}
          {showProjectsTab && (
            <TabPanel value={selectedClientRequestTab} index={1}>
              <ClientProjects/>
            </TabPanel>
          )}
        </>
      )}
    </>
  );
};

export default ClientRequests;
