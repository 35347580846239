interface ApiInterface {
  hostname: string;
  hosttype: object;
  communication_hostname: string;
  admin_backend_hostname: string;
  endpoints: {
    [key: string]: string;
  };
}

const ApiConfig: ApiInterface = {
  hostname: process.env.REACT_APP_API_URL ?? "",
  hosttype: {
    vendor: "vendor",
    communication: "communication",
  },
  communication_hostname: process.env.REACT_APP_COMMUNICATION_API_URL ?? "",
  admin_backend_hostname: process.env.REACT_APP_BASE_URL ?? "",
  endpoints: {
    // chatList api
    chat_initiate: "v1/communication/initiate-chat",
    chatlist_get_data: "/v1/communication/message-list",
    // chat_get_messages:"/v1/communication/fetch",
    // chat_get_old_messages:"/v1/communication/fetch-old",
    send_message: "/v1/communication/send-message",
    delete_all_chats: "/v1/communication/clear-chat",
    // delete_chat:'/v1/communication/delete-admin-message',

    //admin apis
    get_client_list: "/client",
    get_vendor_list: "/vendor",
    admin_chat_initiation: "/v1/communication/initiate-admin-chat",
    admin_chatlist_get_data: "/v1/communication/admin-message-list",
    single_chat_initiate: "/v1/communication/initiate-admin-chat",
    send_single_chat_message: "/v1/communication/send-admin-message",
    chat_get_messages: "/v1/communication/fetch-admin-messages",
    chat_get_old_messages: "/v1/communication/fetch-old-admin",
    delete_chat: "/v1/communication/delete-admin-message",
    upload_file: "/v1/communication/upload-file",
    get_shared_files: "/v1/communication/get-admin-shared-files",
    send_job_request_form_email: "/v1/request-vendor/send-job-request-form-email",
    // Analytics
    log_event: "api/v1/analytics/save",
    email_settings: "/settings/email",
    // job request details
    get_job_request_details: "/v1/request-vendor/get-job",
    post_boq_to_compare: "/v1/job-request/compare-boq",
    update_comparison_job_id: "/v1/comparison/update-comparison-job-id",
    inspect_boq: "/v1/comparison/inspect-boq",
    quote_comparison: "/v1/comparison/quote-comparison",
    get_comparison_by_id: "/v1/comparison/comparison-details",
    update_vendor_quote: "/v1/comparison/update-vendor-quote",
    archive_vendor: "/v1/comparison/archive-vendor",
    unarchive_vendor: "/v1/comparison/unarchive-vendor",
    include_version: "/v1/comparison/include-version",
    exclude_version: "/v1/comparison/exclude-version",
    make_sheet_editable: "/v1/comparison/make-sheet-editable",
    add_new_vendor_quotes: "/v1/comparison/add-new-vendor-quotes",
    recreate_comparison: "/v1/comparison/recreate-comparison",
    post_unstructured_files_to_compare: "/v1/comparison/compare-unstructed-quotes",
    get_downloadable_link: "/file/download-xlsx",
    get_comparison_table: "/v1/job-request/get-comparison-table",
    get_all_job_ids: "/v1/job-request/get-all-job-ids",
    get_columns: "/v1/comparison/get-columns",
    get_automation_history: "/v1/comparison/get-automation-history",
    quote_comparison_automation: "/v1/comparison/quote-comparison-automation",
    get_approvals_data: "/batch-requests/get-resolution",
    update_job_status: "/batch-requests/update-job-status",
    // update_job_request: "/batch-requests/update",
    update_job_request: "v1/request-vendor/edit-admin-job-rfq",
    create_vendor_request: "/v1/request-vendor/create-admin-job-rfq",
    add_rfq_docs: "v1/request-vendor/add-admin-job-documents",
    remove_rfq_docs: "v1/request-vendor/remove-admin-job-document",

    //CLIENT_REQUEST
    get_compare_quotation: "api/v1/client-request/get-request-by-job",
    update_client_request: "api/v1/client-request/update-request",
    upload_quote_comparison: "v1/comparison/upload-quote-comparison",
    get_all_custom_reports:
      "api/v1/client-request/get-request-all",
    get_all_companies: "v1/client-project/get-client-companies",
    get_all_plants_of_company: "v1/client-project/get-client-company-plants",
    create_project: "v1/client-project/create-project",
    get_project_of_company: "v1/client-project/get-company-projects",
    get_project_details: "v1/client-project/list-of-roles",
    get_all_users_list: "v1/request-vendor/get-all-user-list",
    add_remove_users_from_roles: "v1/client-project/add-remove-project-user-roles",
    get_project_names_of_company: "v1/client-project/get-company-project-names",
    get_vendor_request_for_company: "v1/request-vendor/get-vendor-request",
    get_vendor_request_csv_data_for_company: "v1/request-vendor/get-vendor-request-csv",
    get_rfq_for_project: "v1/request-vendor/jobs-list-by-project-id",
    add_vr_to_project: "v1/request-vendor/add-vr-to-project",
    update_last_activity_date: "v1/request-vendor/update-last-activity-date",

    //file operation
    upload_file_to_permanent_folder: "file-storage/upload-permanent-files",

    //vendor profile review apis
    get_all_review: "v1/vendor-review/all",
    create_review: "v1/vendor-review/create",
    update_review: "v1/vendor-review/update",

    //job monetization
    update_job_monetization: "v1/job-monetization/update",
    send_agreement_to_vendor: "v1/job-monetization/send-agreement",
    generate_agreement: "v1/job-monetization/generate-agreement",

    //PUBLISH DOCS
    admin_publish_docs: "v1/request-vendor/admin-publish-docs",
    get_job_quotations: "v1/request-vendor/get-admin-quotation",
    get_job_docs: "v1/job-request/get-job-related-docs"
  },
};
export default ApiConfig;
