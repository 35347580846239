import { Button, Tabs } from "antd";
import styled from "styled-components";

export const JobRequestDetailsTabs = styled(Tabs)<any>`
  width: 100%;
  padding: 20px ;
  &.ant-tabs {
    height: ${(props) => props.height};
  }
  .ant-tabs-nav {
    background: #f4f6ff;
    border-radius: 8px;
  }
  .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-tab-btn {
    color: rgba(1, 23, 89, 0.7);
    font-weight: 600;
    font-size: 14px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #215ec9;
    font-weight: 600;
  }
  .ant-tabs-ink-bar {
    background-color: #215ec9;
  }
  .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-content-holder {
    position: relative;
  }
`;

export const ButtonWrapper = styled(Button)`
  background-color: #215ec9;
`;

export const BackButton = styled(ButtonWrapper)`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
`;

export const TabContentContainer = styled.div`
  margin-right: 1rem;
  overflow: scroll;
`;

export const QuoteTable = styled.table `
  width: 100%;
  display: block;
  overflow: scroll;
  height: calc(100vh - 82px);
`