import { Tooltip } from "antd";
import { useCheckVendorRemovedByClient } from "container/JobRequests/hooks/useCheckVendorRemovedByClient";
import React, { useState } from "react";

export const VendorNameDisplay = (text: string) => {
  const { checkVendorRemovedByClient } = useCheckVendorRemovedByClient();
  const isVendorRemoved = checkVendorRemovedByClient(text);
  const [tooltipVisibility, setTooltipVisibility] = useState(false);

  return (
    <>
      {text ? (
        <Tooltip title={"Client has removed this vendor"} visible={isVendorRemoved ? tooltipVisibility : false}>
          <>
            <div
              style={{ textDecorationLine: isVendorRemoved ? "line-through" : "", position: "relative" }}
              onMouseEnter={() => setTooltipVisibility(true)}
              onMouseLeave={() => setTooltipVisibility(false)}
            >
              {text}
            </div>
          </>
        </Tooltip>
      ) : (
        <>-</>
      )}
    </>
  );
};
