import axios from "axios";
import React, { useEffect } from "react";
import {Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import ClientVendorList from '../../components/clientVendorCommunication/ClientVendorList/ClientVendorList'
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../../components/NoAccess/NoAccess";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  }
}));
const ClientVendorCommunication = (() => {
  const [vendors, setVendors] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [communication, setCommunication] = React.useState([]);
  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);
  const [hasAccess, setHasAccess] =React.useState(true);
  
  useEffect(() => {
      const getClientAndVendorList =  async () =>{
        await axios.get(process.env.REACT_APP_BASE_URL + '/client-vendor-communication/',
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }).then((result)=> {
          if (result) {
            setClients(result.data.data.clients);
            setVendors(result.data.data.vendors);
            setCommunication(result.data.csvData)
          }
          else{
            setClients([])
            setVendors([])
          }
        })
      .catch((error) => {
        if(error.response.status === 403){
          setHasAccess(false);
        } 
      });
    }
      getClientAndVendorList();
  },[]);

  return (
    hasAccess ? 
    <>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ClientVendorList clients = {clients} vendors= {vendors} communication={communication}></ClientVendorList>
    </>
    :
    <NoAccess></NoAccess>
  )
})

export default ClientVendorCommunication;