import { Switch } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/EditOutlined";
import axios from "axios";
import * as _ from "lodash";
import * as moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getUserRole } from "../../Utils/Storage";
import "./ClientProfiles.scss";
import DataGridComp from "../../components/analytics/client/DataGrid/DataGrid";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../../components/NoAccess/NoAccess";
import { renderCellExpand } from "../../components/CellExpandPopup/CellExpandPopup";
import ROLES from "../../constants/RolesConstants";
import { setValueInParamsToRenderInCell } from "shared/helpers/setValueInParamsToRenderInCell";
import { formatTime } from "Utils/DisplayTimeFormat";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "85vh",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {},
  container: {
    maxHeight: "70vh",
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  csvLink: { textAlign: "right" },
  csvLinkContainer: { display: "flex", justifyContent: "flex-end" },
}));

export default function ClientProfiles() {
  const classes = useStyles();
  const history = useHistory();
  const userRole = getUserRole().split(",") || [];
  const [open, setOpen] = React.useState(false);
  const [startDate, handlefromDateChange] = React.useState(moment(new Date()));
  const [endDate, handleToDateChange] = React.useState(moment(new Date()));
  const [clientProfiles, setClientProfles] = React.useState([]);
  const [reloadUpdate, setReloadUpdate] = React.useState(false);
  const [showPublish, setShowPublish] = React.useState(
    userRole.filter(
      (role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_PUBLISH
    ).length > 0
  );
  const [showEdit, setShowEdit] = React.useState(
    userRole.filter(
      (role) =>
        role === ROLES.SUPER_ADMIN ||
        role === ROLES.CLIENT_EDIT ||
        role === ROLES.CLIENT_PUBLISH
    ).length > 0
  );
  const [showExport, setShowExport] = React.useState(
    userRole.filter(
      (role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_EXPORT
    ).length > 0
  );

  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
  const [activeProfile, setActiveProfile] = React.useState({
    _id: "",
    isActive: null,
    isCompanyActive: null,
    companyName: null,
    phoneNumber: null,
  });
  const [hasAccess, setHasAccess] = React.useState(true);

  const activateUser = (
    id,
    userStatus,
    companyStatus,
    companyName,
    phoneNumber
  ) => {
    let payload = {
      userActive: !userStatus,
      companyActive: "",
      companyName: companyName,
      phoneNumber: phoneNumber,
    };
    if (companyStatus === null) {
      payload.companyActive = "NOT_APPLICABLE";
    } else {
      payload.companyActive = companyStatus ? "ACTIVE" : "NOT_ACTIVE";
    }
    publishUser(id, payload);
  };

  const publishUser = async (id, payload) => {
    try {
      setOpen(true);
      const result = await axios.put(
        process.env.REACT_APP_BASE_URL + "/client-onboarding/activate/" + id,
        payload,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      );
      if (result.data.status === "SUCCESS") {
        setReloadUpdate((itr) => !itr);
      } else {
        setReloadUpdate((itr) => !itr);
      }
    } catch (error) {
      setReloadUpdate((itr) => !itr);
    }
  };

  const activateCompany = (clientId, companyStatus) => {
    let payload = {
      companyActive: !companyStatus,
    };
    publishCompanyForFirsttUser(clientId, payload);
  };

  const publishCompanyForFirsttUser = async (clientId, payload) => {
    try {
      setOpen(true);
      const result = await axios.put(
        process.env.REACT_APP_API_URL +
          "/v1/client-onboarding/activateCompany/" +
          clientId,
        payload,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      );
      if (result.data.status === "SUCCESS") {
        setReloadUpdate((itr) => !itr);
      } else {
        setReloadUpdate((itr) => !itr);
      }
    } catch (error) {
      setReloadUpdate((itr) => !itr);
    }
  };

  const onToggleEditMode = (clientId) => {
    history.replace("/client-company/" + clientId);
  };

  const handleClose = () => {
    setOpenWarningDialog(false);
  };

  const dateTo24HourIST = (date) => {
    return date ? formatTime(date, "YYYY-MM-DD HH:mm:ss") : "";
  };

  const columns = [
    {
      field: "",
      headerName: "Update",
      width: 80,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Fragment>
          {showEdit && (
            <IconButton
              aria-label="delete"
              onClick={() => onToggleEditMode(params?.row?._id)}
            >
              <EditIcon />
            </IconButton>
          )}
        </Fragment>
      ),
    },
    {
      field: "createdAt",
      type: "string",
      headerName: "Date",
      width: 150,
      headerAlign: "center",
      valueFormatter: (params) => dateTo24HourIST(params?.row?.createdAt),
      renderCell: (params) => renderCellExpand({ value: dateTo24HourIST(params?.row?.createdAt) }),
    },
    {
      field: "fullName",
      type: "string",
      headerName: "Name",
      width: 150,
      headerAlign: "center",
      sortable: true,
      valueFormatter: (params) => params?.row?.fullName,
      renderCell: renderCellExpand,
    },
    {
      field: "userEmail",
      type: "string",
      headerName: "Email",
      width: 250,
      headerAlign: "center",
      sortable: true,
      valueFormatter: (params) => params?.row?.userEmail,
      renderCell: renderCellExpand,
    },
    {
      field: "isActive",
      type: "string",
      headerName: "Publish User",
      width: 200,
      headerAlign: "center",
      sortable: true,
      valueFormatter: (params) => params?.row?.isActive,
      renderCell: (params) => (
        <Fragment>
          {showPublish ? (
            <Switch
              checked={params?.row?.isActive}
              onChange={() => {
                if (params.row.isActive === true) {
                  setOpenWarningDialog(true);
                  let activateUser = {
                    _id: params?.row?._id,
                    isActive: params.row.isActive,
                    isCompanyActive: params?.row?.isCompanyActive,
                    companyName: params?.row?.companyName || "",
                    phoneNumber: params?.row?.phoneNumber || "N/P",
                  };
                  setActiveProfile(activateUser);
                } else {
                  activateUser(
                    params?.row?._id,
                    params.row.isActive,
                    params?.row?.isCompanyActive,
                    params?.row?.companyName || "",
                    params?.row?.phoneNumber || "N/P"
                  );
                }
              }}
              name="isActive"
              color="primary"
            />
          ) : null}
        </Fragment>
      ),
      // valueFormatter: (params) => params?.row?.salesOrder?.order_status,
    },
    {
      field: "isCompanyActive",
      type: "string",
      headerName: "Publish Company",
      width: 200,
      headerAlign: "center",
      sortable: true,
      valueFormatter: (params) => params?.row?.isCompanyActive,
      renderCell: (params) => (
        <Fragment>
          {showPublish &&
          (params.row?.isCompanyActive == true ||
            params.row?.isCompanyActive === false) ? (
            <div>
              <Tooltip title="Toggle to Deactivate subsequent users of same company">
                <Switch
                  checked={params?.row?.isCompanyActive}
                  onChange={() =>
                    activateCompany(params.row._id, params.row.isCompanyActive)
                  }
                  name="isActive"
                  color="primary"
                />
              </Tooltip>
              First User
            </div>
          ) : null}
        </Fragment>
      ),
    },
    {
      field: "phoneNumber",
      type: "string",
      headerName: "Phone Number",
      width: 200,
      headerAlign: "center",
      sortable: true,
      valueFormatter: (params) => params?.row?.phoneNumber,
      renderCell: (params) =>
        setValueInParamsToRenderInCell(params, params?.row?.phoneNumber),
      // renderCell: (params) => <div></div>,
      // valueFormatter: (params) => params?.row?.salesOrder?.order_status,
    },
    {
      field: "roleInCompany",
      type: "string",
      headerName: "Role in Company",
      width: 200,
      headerAlign: "center",
      sortable: true,
      valueFormatter: (params) => params?.row?.roleInCompany,
      renderCell: renderCellExpand,
    },
    {
      field: "plantInfo.companyEmail",
      type: "string",
      headerName: "Company Email",
      width: 200,
      headerAlign: "center",
      sortable: true,
      valueFormatter: (params) => params?.row?.plantInfo?.companyEmail,
      renderCell: (params) =>
        setValueInParamsToRenderInCell(
          params,
          params?.row?.plantInfo?.companyEmail
        ),
    },
    {
      field: "plantInfo.plantName",
      type: "string",
      headerName: "Plant",
      width: 150,
      headerAlign: "center",
      sortable: true,
      valueFormatter: (params) => params?.row?.plantInfo?.plantName,
      renderCell: (params) =>
        setValueInParamsToRenderInCell(
          params,
          params?.row?.plantInfo?.plantName
        ),
    },
    {
      field: "plantInfo.industries",
      type: "string",
      headerName: "Industries",
      headerAlign: "center",
      sortable: false,
      width: 250,
      valueFormatter: (params) => params?.row?.plantInfo?.industries,
      renderCell: (params) =>
        setValueInParamsToRenderInCell(
          params,
          params?.row?.plantInfo?.industries
        ),
    },
    {
      field: "plantInfo.plantProducts",
      type: "string",
      headerName: "Plant Products",
      width: 250,
      headerAlign: "center",
      sortable: false,
      valueFormatter: (params) => params?.row?.plantInfo?.plantProducts,
      renderCell: (params) =>
        setValueInParamsToRenderInCell(
          params,
          params?.row?.plantInfo?.plantProducts
        ),
    },
    {
      field: "plantInfo.address.pinCode",
      type: "string",
      headerName: "Pin Code",
      width: 250,
      headerAlign: "center",
      sortable: false,
      valueFormatter: (params) => params?.row?.plantInfo?.address?.pinCode,
      renderCell: (params) =>
        setValueInParamsToRenderInCell(
          params,
          params?.row?.plantInfo?.address?.pinCode
        ),
    },
  ];

  useEffect(async () => {
    setOpen(true);
    let params = {
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
    };
    try {
      await axios
        .get(process.env.REACT_APP_BASE_URL + "/client-onboarding/filter", {
          params: params,
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        })
        .then((result) => {
          const data =
            result?.data?.map((itr) => ({ ...itr, id: itr._id })) || [];

          setClientProfles(data);
          setOpen(false);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setHasAccess(false);
          }
        });
    } catch (err) {
      setClientProfles([]);
      setOpen(false);
      setOpenWarningDialog(false);
    }
    setOpen(false);
  }, [startDate, endDate, reloadUpdate]);

  return hasAccess ? (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openWarningDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to de-activate profile?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              activateUser(
                activeProfile._id,
                activeProfile.isActive,
                activeProfile.isCompanyActive,
                activeProfile.companyName || "",
                activeProfile.phoneNumber || "N/P"
              );
              setOpenWarningDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Paper className={classes.root}>
        <DataGridComp
          col={columns}
          rows={clientProfiles}
          sortField="date"
          hideExport={!showExport}
        />
      </Paper>
    </div>
  ) : (
    <NoAccess></NoAccess>
  );
}
