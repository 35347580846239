import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TITLE } from "./constant";
import { sortKeysByValue } from "./helper";

const AdvancedVendorFilter = (props: any) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  const handleChange = (e: any, selectedOption: any, reason: string, key: string) => {
    props?.handleAdvancedFilter(selectedOption, key);
  };
  return (
    <div className="advanced-filter-area">
      <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" wrap="wrap">
        {Object.entries(props.filterOption).map((filter: any, index: number) => {
          const keyName: string = filter[0];
          let options = filter[1];
          const valuesWithCount = props.filterCount[keyName];
          if (["equipment", "oem", "service", "statesServed"].includes(keyName)) {
            const sortedFilterValues = sortKeysByValue(valuesWithCount);
            options = Object.keys(sortedFilterValues);
          }
          return (
            <Autocomplete
              key={`${keyName}` + `${index}`}
              filterOptions={filterOptions}
              multiple
              limitTags={2}
              id="multi-select-filter"
              options={options}
              disableCloseOnSelect
              value={props?.selectedAdvancedFilter?.[keyName]}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => {
                let countText = valuesWithCount?.[option] ? ' (' + valuesWithCount[option] + ')' : '';
                return (
                  <li {...props} key={`${keyName}` + `${option}`}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option + countText}
                  </li>
                );
              }}
              style={{
                width: "13%",
                margin: "0px 1px 5px 0px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    `${TITLE?.[props?.titleKey]?.[keyName]}` +
                    `${props?.showTotalTags ? " " + `(` + options?.length + " " + `values)` : ""}`
                  }
                  placeholder="Search..."
                />
              )}
              onChange={(e, value, reason) => handleChange(e, value, reason, keyName)}
            />
          );
        })}
      </Grid>
    </div>
  );
};

export default AdvancedVendorFilter;
