import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, TextField } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import { CSVLink } from "react-csv";
import "./VendorFilter.scss";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import AdditionalTagsFilters from "./AdditionalTagsFilters";
import { Collapse } from "antd";
import AdditionalTagsFiltersComp from "./AdditionalFiltersUI";
const VendorFilter = (props) => {
  const [startDate, setFromDate] = React.useState(props.startDate);
  const [endDate, setToDate] = React.useState(props.endDate);
  const [searchValue, setSearchValue] = React.useState(props.searchField);
  const [vendorName, setVendorName] = React.useState(props.vendorName);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const handleChange = (e, type) => {
    if (type === "from") {
      setFromDate(e);
    } else if (type === "to") {
      setToDate(e);
    } else if (type === "gst") {
      setSearchValue(e.target.value);
    } else {
      setVendorName(e.target.value);
    }
  };
  const renderButton = () => {
    return (
      <Button
        className={props?.filterOption ? "filter-button-with-option" : "filter-button"}
        variant="contained"
        color="primary"
        style={{width: "5rem"}}
        size="small"
        onClick={() => props.handleFilterChange(startDate, endDate, searchValue, vendorName)}
      >
        Apply
      </Button>
    );
  };
  return (
    <div className="filter_area">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around" direction={props.filterLocation ==="vendor_request" ? "row" :"column"}>
          <div className="filter_area_flex_row" style={{width: props.width || "100%"}}>
            {props?.filterOption && (
              <Autocomplete
                key={`DateTypefilter`}
                disableCloseOnSelect
                limitTags={2}
                id="multi-select-filter"
                options={props?.dateTypeOptions?.["dateType"]}
                value={props?.dateTypeSelected?.["dateType"]}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={`${"dateType"}` + `${option}`}>
                    {option}
                  </li>
                )}
                style={{
                  width: props?.width || "13%",
                  margin: "0px 1px 5px 0px",
                }}
                renderInput={(params) => <TextField {...params} label={"Date Type"} placeholder="Search..." />}
                onChange={(e, value, reason) => props?.onHandleDateTypeFiltersApplied(value, "dateType")}
              />
            )}

            <KeyboardDatePicker
              style={{margin: "0px" }}
              margin="normal"
              id="date-picker-inline"
              label="Start Date"
              format="dd/MM/yyyy"
              value={startDate}
              onChange={(e) => handleChange(e, "from")}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              style={{ margin: "0px" }}
              margin="normal"
              id="date-picker-dialog"
              label="End Date"
              format="dd/MM/yyyy"
              value={endDate}
              onChange={(e) => handleChange(e, "to")}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            {props.showSearch && (
              <TextField
                style={{ margin: "0px !important" }}
                className="search_field"
                id="standard-search"
                value={searchValue}
                disabled={vendorName}
                onChange={(e) => handleChange(e, "gst")}
                label="GST Number"
                type="search"
              />
            )}
            {props.showSearch && (
              <TextField
                style={{ margin: "0px !important" }}
                className="search_field"
                id="standard-search"
                value={vendorName}
                disabled={searchValue}
                onChange={(e) => handleChange(e, "name")}
                label="Vendor Name"
                type="search"
              />
            )}
            {renderButton()}
          </div>
          {props?.filterOption && (
            <>
              <Collapse style={{width: "100%"}}>
                <Collapse.Panel header="Additional FIlters" key="1">
                  <AdditionalTagsFiltersComp
                    width="15%"
                    filterOption={props?.filterOption}
                    handleAdvancedFilter={props?.onHandleFiltersApplied}
                    selectedAdvancedFilter={props?.selectedFilter}
                    titleKey={"initialFilters"}
                    showTotalTags
                  />
                </Collapse.Panel>
              </Collapse>
            </>
          )}
          <div className="flex">
            {props.enableRequestButton && (
                <Button
                  className="filter-button"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={props?.onHandleRequest}
                >
                  Create Request
                </Button>
              )}
            {/* <div className="filter-button-wrapper">{props?.filterOption}</div> */}
            {/* <AdancedVendorFilter/> */}
            {props.showCsv ? (
              <div>
                <CSVLink data={props.csvData} headers={props.csvHeaders} filename={props.csvFileName}>
                  {/* <GetAppIcon></GetAppIcon>Export */}
                  <Button className="filter-button"
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{minWidth:"auto", padding:"3px"}}> <GetAppIcon/></Button>
                </CSVLink>
              </div>
            ) : null}
          </div>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default VendorFilter;
