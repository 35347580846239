import AntdToast from "components/Toast/AntdToast";
import { VendorProfileActions } from "container/UpdateVendorProfiles/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  InitialClientDetails,
  overallDummyData,
  parameterDummyData,
} from "../constants";
import { IClientDetails, IReviewModals } from "../interfaces/interfaces";
import { FormBttnWrapper, FormBttn, ModalDiv } from "../VendorReviews.styles";
import Form from "./Form";

const ReviewsModal = (props: IReviewModals) => {
  const [overallData, setOverallData] = useState<any>(overallDummyData);
  const [parametersData, setParametersData] = useState<any>(parameterDummyData);
  const [clientDetails, setClientDetails] =
    useState<IClientDetails>(InitialClientDetails);
  const dispatch = useDispatch();
  const reviewDetails = useSelector(
    (state: any) => state.VendorProfileReducers
  );
  const { errMess } = reviewDetails;
  const { gstn } = useParams<{ gstn: string }>();

  const onHandleOverallRatingData = (value: any, keyName: string) => {
    if (keyName === "overallRating") {
      dispatch(VendorProfileActions.setReviewErrorMess({ message: "" }));
    }
    setOverallData({ ...overallData, [keyName]: value });
  };

  const onHandleParameterValue = (
    value: any,
    keyName: string,
    parameterType: string
  ) => {
    setParametersData({
      ...parametersData,
      [parameterType]: { ...parametersData[parameterType], [keyName]: value },
    });
  };

  const onHandleClientDetailsValue = (value: any, keyName: string) => {
    setClientDetails({ ...clientDetails, [keyName]: value });
  };

  useEffect(() => {
    if (Object.keys(props?.data).length) {
      setParametersData(props?.data?.detailedReview || parameterDummyData);
      setOverallData({
        overallRating:
          props?.data?.overallRating || overallDummyData.overallRating,
        overallReview:
          props?.data?.overallReview || overallDummyData.overallReview,
      });
      if (props?.data?.clientDetails?.name)
        setClientDetails(props?.data?.clientDetails);
    }
  }, [props?.data]);

  const updateReview = (data: any) => {
    dispatch(VendorProfileActions.getVendorProfileReviewData(gstn));
  };

  const postUserForm = (payload: any) => {
    const successToast = (desc: string) => {
      props.onCancel(false);
      AntdToast({
        type: "success",
        message: "Success",
        description: desc,
      });
    };
    if (props?.data?._id) {
      dispatch(
        VendorProfileActions.setVendorProfileReview("update", payload)
      ).then((res: any) => {
        updateReview(res?.data);
        successToast("Review Updated.");
      });
    } else {
      dispatch(VendorProfileActions.setVendorProfileReview("create", payload))
        .then((res: any) => {
          updateReview(res?.data);
          successToast("Review Submitted.");
        })
        .catch((error: any) => {
          AntdToast({
            type: "error",
            message: "Failed",
            description:
              error?.message === "Client review present for this vendor"
                ? "This client has already added a review"
                : error?.message || "Error while creating review",
          });
        });
    }
  };

  const onSaveChanges = () => {
    if (!clientDetails?.name || !clientDetails?.company) {
      dispatch(
        VendorProfileActions.setReviewErrorMess({
          message: "Please enter client details",
        })
      );
    } else if (overallData?.overallRating === 0) {
      dispatch(
        VendorProfileActions.setReviewErrorMess({
          message: "Please select a star rating",
        })
      );
    } else {
      dispatch(VendorProfileActions.setReviewErrorMess({ message: "" }));
      const payload = {
        _id: props?.data?._id,
        gstn: gstn, //must
        clientDetails: {
          //must
          name: clientDetails.name,
          company: clientDetails.company,
          designation: clientDetails.designation,
          email: clientDetails.email,
          logo: clientDetails.logo,
        },
        overallRating: overallData?.overallRating, //must
        overallReview: overallData?.overallReview,
        detailedReview: parametersData,
      };
      postUserForm(payload);
    }
  };
  const renderButton = () => {
    return (
      <FormBttnWrapper>
        <FormBttn
          $color={"#FFFFFF"}
          $border={"1px solid #215EC9"}
          $backgroundColor={"#215EC9"}
          onClick={onSaveChanges}
        >
          {props?.data?._id ? "Save Changes" : "Submit Review"}
        </FormBttn>
        <FormBttn
          $color={"#215EC9"}
          $border={"1px solid #215EC9"}
          $backgroundColor={"#FFFFFF"}
          onClick={props?.onCancel}
        >
          Cancel
        </FormBttn>
      </FormBttnWrapper>
    );
  };
  return (
    <ModalDiv
      closeIcon={<img src="/images/x_blue_bg.svg" alt="close-icon" />}
      title={"Rating and Review"}
      visible
      onCancel={props.onCancel}
      footer={renderButton()}
      centered
      width={"894px"}
      $height={"50vh"}
    >
      <Form
        data={props?.data}
        clientDetails={clientDetails}
        parametersData={parametersData}
        overallData={overallData}
        onHandleOverallRatingData={onHandleOverallRatingData}
        onHandleParameterValue={onHandleParameterValue}
        onHandleClientDetailsValue={onHandleClientDetailsValue}
        errMess={errMess}
      />
    </ModalDiv>
  );
};

export default ReviewsModal;
