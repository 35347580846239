import { ColumnsType } from "antd/es/table";
import { popOverComponent } from "container/JobRequests/components/PopOverComponent/PopOverComponent";
import { IApproverPersonRowObj, IResolutionRowObj } from "./ApprovalsTabContent";

// for resolution table(outer-table)
export const RESOLUTION_COLUMNS: ColumnsType<IResolutionRowObj> = [
  {
    key: "resolutionStatus",
    dataIndex: "resolutionStatus",
    title: "Resolution Status",
    width: "22%",
  },
  {
    key: "createdBy",
    dataIndex: "createdBy",
    title: "Created By",
    width: "22%",
  },
  {
    key: "resolutionDescription",
    dataIndex: "resolutionDescription",
    title: "Resolution Description",
    width: "22%",
    render: (text: string, row, index) => popOverComponent(text),
  },

  {
    key: "creationDate",
    dataIndex: "creationDate",
    title: "Creation Date",
    width: "22%",
  },
];

// for approver table(inner-table)
export const APPROVER_COLUMNS: ColumnsType<IApproverPersonRowObj> = [
  {
    title: "Approvers",
    dataIndex: "approvers",
    key: "approvers",
    width: "20%",
  },
  {
    key: "approvalStatus",
    dataIndex: "approvalStatus",
    title: "Approval Status",
    width: "10%",
  },
  {
    key: "approvedOrRejected",
    dataIndex: "approvedOrRejected",
    title: "Approved/Rejected",
    width: "15%",
  },
  {
    key: "note",
    dataIndex: "note",
    title: "Note",
    width: "50%",
    render: (text: string) => popOverComponent(text),
  },
];
