import { ColumnsType } from "antd/es/table";
import { IExistingClientVendorRowObj, IVendorAddedByClientRowObj } from "./VendorsAddedByClient";

export const VENDORS_ADDED_BY_CLIENT_COLUMN: ColumnsType<IVendorAddedByClientRowObj> = [
  { title: "Vendor Company Name", dataIndex: "companyName", key: "companyName" },
  { title: "Email", dataIndex: "email", key: "email" },
  { title: "Contact Number", dataIndex: "phone", key: "phone" },
];

export const EXISTING_CLIENT_VENDOR_COLUMNS: ColumnsType<IExistingClientVendorRowObj> = [
  { title: "Vendor Company Name", dataIndex: "companyName", key: "companyName" },
  { title: "Email", dataIndex: "email", key: "email" },
  { title: "Contact Number", dataIndex: "phoneNumber", key: "phoneNumber" },
];

export const VENDORS_REMOVED_BY_CLIENT_COLUMN: ColumnsType<IVendorAddedByClientRowObj> = [
  ...VENDORS_ADDED_BY_CLIENT_COLUMN,
  { title: "Timestamp", dataIndex: "date", key: "date" },
  { title: "Reason for Deletion", dataIndex: "reasonForDeletion", key: "reasonForDeletion" },
];