import { Types as FileOperationTypes } from "../../../../_types/FileOperationTypes"

const initialState = {
  fileUploadData: null,
  error: null
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FileOperationTypes.FILE_OPERATION_REQUESTED:
      return {
        ...state,
      };
    case FileOperationTypes.FILE_OPERATION_FAILURE:
      return {
        ...state,   
        error: action?.error
      };
      case FileOperationTypes.FILE_PERMANENT_UPLOAD_SUCCESS:
        return {
          ...state, 
          fileUploadData: action.result   
        };
    default:
      return state;
  }
};