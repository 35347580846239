import React from "react";
import { IReviewModals } from "../interfaces/interfaces";
import { ModalDiv } from "../VendorReviews.styles";
import ViewReviews from "./ViewReviews";
const ViewReviewModal = (props: IReviewModals) => {
  return (
    <ModalDiv
      closeIcon={<img src="/images/x_blue_bg.svg" alt="close-icon" />}
      title={" "}
      visible
      onCancel={props.onCancel}
      footer={<></>}
      centered
      width={"80vw"}
      $height={"80vh"}
      $footerHidden
      $headerHidden
      $overflow={"scroll"}
      $padding={"30px 24px 10px 0px"}
      $marginTop={"0px"}
    >
      <ViewReviews data={props?.data} />
    </ModalDiv>
  );
};

export default ViewReviewModal;
