import { Autocomplete, TextField } from "@mui/material";
import { ICreateProjectModalProps } from "../interfaces/Company.interface";
import { InputBox, ModalBody } from "./ClientProjects.styles";
import AntdModal from "components/Modal/AntdModal/AntdModal";

const modalBody = (props: ICreateProjectModalProps) => {
  return (
    <ModalBody>
      <InputBox>
        <TextField
          id="outlined-basic"
          label="Enter Project Name"
          variant="outlined"
          sx={{ width: "100%", border: "1px solid #D4E5FE", borderRadius: "8px", color: "#011759" }}
          onChange={(event) => {
            props.setProjectName(event.target.value);
          }}
        />
      </InputBox>
      <InputBox>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={props.plants}
          sx={{ width: "100%", border: "1px solid #D4E5FE", borderRadius: "8px", color: "#011759" }}
          renderInput={(params) => <TextField {...params} label="Select Plant Name" />}
          onChange={(e, value) => {
            props.setSelectedPlant(value);
          }}
        />
      </InputBox>
    </ModalBody>
  );
};

const CreateProjectModal = (props: ICreateProjectModalProps) => {
  return (
    <AntdModal
      usedFor="deleteMessage"
      variant={"white"}
      positiveButtonColor={"#53DB79"}
      positiveButtonBorderColor={!props.projectName || !props.selectedPlantName.id ? "#B7C8E6" :"#215EC9"}
      buttonDirectionColumn
      disabled={!props.projectName || !props.selectedPlantName.id}
      positiveActionText={"CREATE"}
      isFooterAvailable={true}
      header={"Create Project"}
      positiveAction={props.createProjectClick}
      closable={true}
      body={modalBody(props)}
      negativeAction={() => {
        props.onCancel();
      }}
      maskClosable
    />
  );
};

export default CreateProjectModal;
