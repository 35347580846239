import { RFQ_FILES_SUPPORTED_TYPES } from "components/RequestModal/constants";
import { LoaderActions } from "components/Spinner/actions";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { FileOperationActions } from "container/common/FileOperations/actions";
import { IDMSFile, IFiles } from "container/JobRequests/JobRequestDetails.interfaces";
import { FORM_DATA_KEY } from "container/JobRequests/JobRequestDetails/jobRequest.constants";
import { validateUploadedFiles } from "shared/handler/fileUpload/fileUpload.handler";

export const getPermanentFilesAndUploadRFQ = (
  formData: FormData,
  jobId: string,
  dispatch: any,
  postUploadAction?: (id?: string) => void,
) => {
  // get permanent urls
  dispatch(FileOperationActions.uploadFileToPermanentFolder(formData)).then((res: IFiles[]) => {
    const newRFQFiles = res?.map((file) => ({
      file,
      fileName: file?.name,
      isAutoPublish: true
    }));

    const payload = {
      jobId,
      files: newRFQFiles,
    };

    // update Job Request
    dispatch(ClientRequestActions.addRfqDocs(payload))
      .then(() => {
        postUploadAction && postUploadAction(jobId);
        dispatch(LoaderActions.setIsLoading(false));
      })
      .catch(() => {
        postUploadAction && postUploadAction(jobId);
        dispatch(LoaderActions.setIsLoading(false));
      });
  })
    .catch(() => {
      dispatch(LoaderActions.setIsLoading(false));
    });
};

export const handleUploadRFQFiles = (
  files: any,
  jobId: string,
  uploadedRfqFiles: IDMSFile[],
  dispatch: any,
  postUploadAction?: (id?: string) => void,
) => {
  dispatch(LoaderActions.setIsLoading(true));

  const newFiles: any[] = Object.values(files);

  // validate uploaded files
  const validFiles = validateUploadedFiles({
    newFiles,
    existingFiles: uploadedRfqFiles,
    supportedFileTypes: RFQ_FILES_SUPPORTED_TYPES,
  });

  if (validFiles?.length > 0) {
    const formData = new FormData();
    for (const file of validFiles) {
      formData.append(FORM_DATA_KEY, file);
    }
    getPermanentFilesAndUploadRFQ(formData, jobId, dispatch, postUploadAction);
  } else {
    dispatch(LoaderActions.setIsLoading(false));
  }
};
