import { combineReducers } from "redux";
import { AnalyticsReducers } from "../container/analytics/actions";
import { ClientCommunicationReducers } from "../container/ChatModule/actions";
import { LoaderReducers } from "../components/Spinner/actions";
import { JobRequestReducers } from "container/JobRequests/actions";
import { VendorProfileReducers } from "../container/UpdateVendorProfiles/actions";
import { FileOperationReducers } from "../container/common/FileOperations/actions"
import {ClientRequestReducer} from "../container/ClientRequests/actions"

const appReducer = combineReducers({
  AnalyticsReducers,
  ClientCommunicationReducers,
  LoaderReducers,
  JobRequestReducers,
  VendorProfileReducers,
  FileOperationReducers,
  ClientRequestReducer
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
