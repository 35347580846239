import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

interface ProfileInputHeading {
  name: string;
  info?: any;
  position?: "right" | "left" | "top" | "bottom";
  isStar: boolean;
  isPaddingNotRequired?: boolean;
}
const Container = styled.div<any>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.paddingNotRequired ? "0px" : "10px")};
`;
const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.1em;
  color: #011759;
  opacity: 0.7;
  margin-right: 7px;
`;
const InfoTooltip = styled(Tooltip)``;
const Star = styled.span`
  color: red;
`;

const TooltipDiv = styled(Tooltip)`
  cursor: pointer;
`;

const ProfileInputHeading = (props: ProfileInputHeading) => {
  return (
    <Container paddingNotRequired={props.isPaddingNotRequired}>
      <Name>
        {props.name}
        {props.isStar && <Star>*</Star>}
      </Name>
      <TooltipDiv
        color="#ffffff"
        placement={props.position ? props.position : "right"}
        title={props.info}
        overlayInnerStyle={{
          fontSize: "12px",
          color: "#011759",
          padding: "18px 20px",
          borderRadius: "12px",
          width: "max-content",
          maxWidth: "416px",
          whiteSpace: "normal",
        }}
        zIndex={99999}
        // getPopupContainer={(trigger: any) => trigger.parentNode}
      >
        {props?.info ? <InfoCircleTwoTone style={{ fontSize: "14px" }} /> : <div></div>}
      </TooltipDiv>
    </Container>
  );
};

export default ProfileInputHeading;
