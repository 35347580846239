import { SelectValue } from "antd/lib/select";
import React from "react";
import { SelectBox } from "./StateSelect.styles";

export interface SelectProps {
  value: string | undefined,
  options: Array<Object>,
  onChange: (value: SelectValue) => void,
  allowClear: boolean,
  placeholder: string,
}

export function StateSelect({value, options, onChange, allowClear, ...rest  }: SelectProps){
  
  const optionsFormat = options.map((curr: any)=>({label: curr.state, value: curr.state }))
  const sortedOptions = optionsFormat.sort((a,b)=>{
    if(a.value < b.value) { return -1; }
    if(a.value > b.value) { return 1; }
    return 0;
  })
  return(
    <SelectBox
    dropdownStyle={{zIndex:10000}}
      value={value}
      allowClear={allowClear}
      options={optionsFormat}
      onChange={onChange}
      showSearch
      {...rest}
    >
    </SelectBox>
  )
}