import React from 'react';
import AntInput, { InputProps as AntInputProps } from 'antd/es/input';
import styled from 'styled-components';
import Icon from './Icon';

export type TextboxStyleType = 'regular' | 'underline';

export interface InputProps extends AntInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  state?: 'default' | 'success' | 'failure',
  size?: 'small' | 'middle' | 'large' | undefined,
  flexible?: boolean,
  label?: string,
  helpText?: string,
  canClear?: boolean,
  prefixIconClass?: string,
  onClear?: () => void,
  styleType?: TextboxStyleType
}

const TextBoxBase = styled(AntInput) <InputProps>`
  background-color: ${(props) => `${props?.disabled ? props?.theme?.colors?.secondary : 'transparent'}`};
  border: ${(props) => `1px solid ${props?.theme?.input?.states[props?.state || 'default']}`};
  border-radius: ${(props) => `${props?.theme?.input?.borderRadius}`};
  color: ${(props) => `${props?.theme?.input?.color}`};
  font-size: ${(props) => `${props?.theme?.input?.sizes[props?.size || 'middle']?.fontSize}`};
  font-weight: ${(props) => `${props?.theme?.input?.sizes[props?.size || 'middle']?.fontWeight}`};
  height: ${(props) => `${props?.theme?.input?.sizes[props?.size || 'middle']?.height}`};
  line-height: ${(props) => `${props?.theme?.input?.sizes[props?.size || 'middle']?.lineHeight}`};
  padding: ${(props) => `${props?.theme?.input?.sizes[props?.size || 'middle']?.padding}`};
  width: ${(props) => `${props?.width ? props?.width : '100%'}`};

  input {
    background-color: transparent;
    color: ${(props) => `${props?.theme?.input?.color}`};
  }

  input[disabled] {
    color: ${(props) => `${props?.theme?.input?.color}`};
  }

  &:hover {
    border-color:  ${(props) => `${props?.theme?.input?.states?.hover}`};
    color: ${(props) => `${props?.theme?.input?.color}`};
  }

  &:active, &:focus {
    border-color: ${(props) => `${props?.theme?.input?.states?.active}`};
    color: ${(props) => `${props?.theme?.input?.color}`};
    outline: 0;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: ${(props) => `${props?.theme?.colors?.white}`};
    border-color: ${(props) => `${props?.theme?.input?.states?.disabled}`};
    color: ${(props) => `${props?.theme?.input?.color}`};
  }
`;

const TextBox = ({
  label, helpText, canClear, onClear, onChange, suffix, styleType, value, ...rest
}: InputProps) => {
  const handleClear = () => {
    // eslint-disable-next-line no-unused-expressions
    onClear?.();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };
  return (
    <>
      <TextBoxBase
        {...rest}
        value={value}
        suffix={
          canClear ? <Icon className={`icon icon-close-basic ${value ? '' : 'd-none'}`} onClick={handleClear} /> : suffix
        }
        onChange={handleChange}
      />
    </>
  );
};

export default TextBox;
