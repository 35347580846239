import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Backdrop, Button, CircularProgress, Input, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Box, Flex, Typography } from "venwiz-ui";
import { useEffect, useState } from "react";
import { colors } from "Theme";
import { useDispatch } from "react-redux";
import { JobRequestActions } from "container/JobRequests/actions";
import { FORM_DATA_KEY } from "../../jobRequest.constants";
import { useHistory } from "react-router-dom";
import AntdToast from "components/Toast/AntdToast";
import { TablePagination } from "@mui/material";

const UnstructuredComparison = () => {
  const [templateFile, setTemplateFile] = useState<any>()
  const [filledFiles, setFilledFiles] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quotesTable, setQuotesTable] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    loadQuotes();
  }, [page, rowsPerPage]);

  const loadQuotes = async () => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    const { quotes, total } = await fetchQuotes(start, end); // API call to fetch quotes
    setQuotesTable(quotes);
    setTotalRows(total);
  };


  const submitTemplateFile = (e: any) => {
    setTemplateFile(e.target.files[0])
  }

  const submitFilledVendorQuotes = (e: any) => {
    setFilledFiles(e.target.files)
  }
  const fetchQuotes = (start: number, end: number) => {
    return dispatch(JobRequestActions.getComparisonFile({ start, end, type: "technical" })
    ).then((data: any) => {
      return data;
    });
  }
  const submitTemplateForComparison = () => {
    const formData = new FormData();
    ([templateFile]).concat(...filledFiles).forEach(file => {
      formData.append(FORM_DATA_KEY, file);
    })
    // setIsLoading(true)
    AntdToast({ type: "success", message: "Success", description: "Comparison is being generated. Please come back in 5 minutes" });
    dispatch(JobRequestActions.postFilesForUnstructuredComparison(formData)
    ).then((data: any) => {
      // setIsLoading(false)
    });
  }

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when changing rows per page
  };

  return (<>
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
    <Box>
      <Flex margin={"30px"}>
        <Button
          variant="contained"
          component="label"
          className="upload-button ml-3"
          startIcon={<FileUploadIcon />}
          style={{ width: '250px', background: '#47c882', color: '#ffffff' }}
        >
          Upload Template File
          <input
            type="file"
            id="template-xlsx"
            hidden={true}
            accept=".xlsx"
            onInput={(e) => submitTemplateFile(e)}
          />
        </Button>
        <Typography>
          <p style={{ marginLeft: "30px" }}>{templateFile?.name}</p>
        </Typography>
      </Flex>
      {!!templateFile &&
        <>
          <Button
            variant="contained"
            component="label"
            className="upload-button ml-3"
            startIcon={<FileUploadIcon />}
            style={{ width: '350px', background: '#47c882', color: '#ffffff' }}
          >
            Upload Filled Vendor Quotes
            <input
              type="file"
              multiple={true}
              id="filled-templates"
              hidden={true}
              onInput={(e) => submitFilledVendorQuotes(e)}
            />
          </Button>
          <Typography>
            {filledFiles && filledFiles.length
              && Object.values(filledFiles)?.map((file: any) => (
                <p style={{ marginLeft: "30px" }}>{file?.name}</p>
              ))
            }
          </Typography>
        </>
      }
      {!!filledFiles && filledFiles.length
        &&
        <Button variant="contained"
          component="label"
          className="upload-button ml-3"
          onClick={() => submitTemplateForComparison()}
          style={{ width: '350px', background: colors.primary, color: '#ffffff' }}>
          Submit and create comparison
        </Button>
      }
      <Box className={classes.tableContainer}>
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">LHS</TableCell>
                <TableCell align="center">Vendor Quote files</TableCell>
                <TableCell align="center">Comparison file</TableCell>
                <TableCell align="left">Status </TableCell>
          </TableRow>
        </TableHead>
            <TableBody>
          {quotesTable?.length > 0 &&
                quotesTable.filter((q: any) => q.type === "technical").map((row: any) => (
                  <TableRow key={row?._id} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={row?.boq?.url}
                        className={classes.link}
                      >
                        LHS Link
                  </a>
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {row?.vendor_quotes_name?.length > 0 &&
                    row?.vendor_quotes_name?.map((vendor: any) => (
                      <> <a target="_blank" rel="noopener noreferrer" href={vendor?.url}>
                        {vendor?.name}
                      </a><br /> </>
                    ))
                  }
                </TableCell>
                    <TableCell className={classes.tableCell}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={row?.result}
                        className={classes.link}
                      >
                        Link
                  </a>
                </TableCell>
                <TableCell>
                  {row?.status}
                </TableCell>
              </TableRow>
                ))}
        </TableBody>
      </Table>

          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </>
      </Box>

    </Box>


  </>)
}
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    margin: "40px",

  },
  tableHead: {
    backgroundColor: "#f5f5f5",
  },
  tableCellHead: {
    fontWeight: 600,
    padding: "12px",
    fontSize: "14px",
    color: "#333",
    textAlign: "center"
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fafafa",
    },
    "&:hover": {
      backgroundColor: "#f0f0f0",
      cursor: "pointer",
    },
  },
  tableCell: {
    padding: "10px",
    fontSize: "12px",
    color: "#555",
    textAlign: "center"
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default UnstructuredComparison;
