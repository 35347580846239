import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { VerifStatusDropDown } from "./verifStatusDropDown";
import {
  IKeysWithObjTypes,
  IStatusTypes,
} from "../vendorVerification.interface";
import { TextField } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { verificationConstants } from "../constants";
import { Upload } from "@mui/icons-material";
import { Chip } from "@mui/material";
import axios from "axios";
import LocalStorage from "../../../_services/LocalStorage";

export const createRowDataForObj = (
  stateKey: IKeysWithObjTypes,
  verificationStatus: IStatusTypes,
  note: string,
  value: string,
  document?: any,
  removeDocument?: boolean,
  addDocument?: boolean
) => {
  return {
    document,
    value,
    verificationStatus,
    note,
    stateKey,
    removeDocument,
    addDocument,
  };
};
interface Props {
  row: ReturnType<typeof createRowDataForObj>;
  isEditMode: boolean;
  setGridState: (x?: any) => void;
  gridState: any;
}

export const RowForObjValues = (props: Props) => {
  const { row, isEditMode, setGridState, gridState } = props;
  const FIELD_NAME = verificationConstants[row.stateKey].fieldName;

  const uploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const data = new FormData();
    if (e.target.files) {
      data.append("file", e.target.files[0]);
    }
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/upload",
      data,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result.data.status === "SUCCESS") {
      if (e.target.files) {
        return await createPermanentLink(
          result.data.data,
          e.target.files[0].name,
          type
        );
      }
    } else {
    }
  };

  const createPermanentLink = async (
    payload: any,
    fileName: any,
    type: any
  ) => {
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/permanent",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result?.data?.status === "SUCCESS") {
      return result.data.data;
    } else {
    }
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell />
        <TableCell component="th" scope="row">
          {FIELD_NAME}
        </TableCell>
        <TableCell component="th" scope="row" align="right">
          {row?.value}
        </TableCell>
        <TableCell align="right">
          {row?.document ? (
            <Chip
              label="View"
              onDelete={(e) => {
                setGridState({
                  ...gridState,
                  [row.stateKey]: {
                    ...gridState[row.stateKey],
                    document: undefined,
                    removeDocument: true,
                  },
                });
              }}
              onClick={(e) => {
                if (row.document) {
                  window.open(row?.document.file?.fileUrl);
                }
              }}
            />
          ) : (
            row.stateKey !== "yoc" &&
            row.stateKey !== "turnOver" && (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  type="file"
                  onChange={async (e) => {
                    if (e.target.files) {
                      const value = await uploadFile(e, e.target.files[0].name);
                      setGridState({
                        ...gridState,
                        [row.stateKey]: {
                          ...gridState[row.stateKey],
                          document: {
                            name: e.target.files[0].name,
                            file: value,
                          },
                          addDocument: true,
                        },
                      });
                      if (gridState[row.stateKey]?.removeDocument) {
                        delete gridState[row.stateKey]?.removeDocument;
                      }
                    }
                  }}
                />
                <Upload />
              </IconButton>
            )
          )}
        </TableCell>
        {/* <TableCell align="right">{row.verificationCategory}</TableCell> */}
        <TableCell align="right">
          <VerifStatusDropDown
            disableSelect={!isEditMode}
            statusValue={row?.verificationStatus}
            setStatusValue={(e) => {
              const userSelectedKey = row.stateKey;
              setGridState((prev: any) => ({
                ...prev,
                [userSelectedKey]: {
                  ...prev[userSelectedKey],
                  verificationStatus: e,
                },
              }));
            }}
          />
        </TableCell>
        <TableCell align="right">
          {!isEditMode ? (
            row?.note
          ) : (
            <TextField
              variant="filled"
              id="filled-basic"
              value={row?.note}
              onChange={(e) => {
                const userSelectedKey = row.stateKey;
                setGridState((prev: any) => ({
                  ...prev,
                  [userSelectedKey]: {
                    ...prev[userSelectedKey],
                    note: e.target.value,
                  },
                }));
              }}
            />
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
