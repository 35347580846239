import { makeStyles } from "@material-ui/core";

export const useManufacturingtableStyles = makeStyles((theme) => ({
  paper: {
    margin: 0,
    marginTop: 20,
  },
  table: {
    minWidth: 650,
  },
  plantTable: {
    marginBottom: "2rem",
    border: "1px solid blue",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    fontSize: "0.8rem",
    border: "1px solid #e0e0e0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "2rem",
  },
  plantTableRowCell: {
    height: "5rem",
    border: "1px solid #e0e0e0",
    overflowY: "auto",
    overflowX: "hidden",
    wordBreak: "break-word",
  },
  // plantTableHeader width
  plantActionButtonsCell: {
    width: "8%",
  },
  plantLocationCell: {
    width: "18%",
  },
  plantTotalAreaCell: {
    width: "13%",
  },
  plantWorkshopAreaCell: {
    width: "15%",
  },
  plantCapacityCell: {
    width: "12.5%",
  },
  plantOtherInfoCell: {
    width: "33.5%",
  },

  machineTableRowCell: {
    height: "5rem",
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    border: "1px solid #e0e0e0",
    wordBreak: "break-word",
  },
  // machineTableHeader width
  machineActionButtonsCell: {
    width: "8%",
  },
  machineProcessCell: {
    width: "11%",
  },
  machineNameCell: {
    width: "20%",
  },
  machineNumberCell: {
    width: "8%",
  },
  machineModelNameCell: {
    width: "11%",
  },
  machineModelMakeCell: {
    width: "20%",
  },
  machineSpecificationCell: {
    width: "22%",
  },
  // machineOtherInformationCell: {
  //   width: "15%",
  // },

  hoverableButton: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      cursor: "pointer",
    },
    borderRadius: "50%",
    height: "1.4rem",
    width: "1.4rem",
    padding: "0.2rem",
  },
  popperContent: {
    gap: "16px",
    display: "flex",
    padding: "8px 16px",
    background: "white",
    borderRadius: "4px",
    alignItems: "center",
    border: "1px solid black",
  },
}));
