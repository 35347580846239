import { makeStyles } from "@material-ui/core";

export const useManufacturingModalStyles = makeStyles((theme) => ({
  // modal styles
  modalBody: {
    position: "absolute",
    width: 750,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginBottom: "2rem",
    marginTop: "2rem",
  },

  textField: {
    width: "100%",
  },
  autocomplete: {
    width: "100%",
  },
  inputWithAutocompleteContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  inputWithAutocomplete: {
    width: "100%",
    paddingRight: "0px",
  },
  autocompleteConatinerWithinInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  autocompleteWithinInput: {
    width: "150px",
  },

  errorText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "0.75rem",
    marginTop: "3px",
    fontWeight: 400,
    paddingLeft: "14px",
    color: "rgb(196, 75, 67)",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },

  // link gallery modal styles
  modalBodyForLinkGallery: {
    width: 850,
    height: 600,
  },

}));
