import { Button, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SendOutlined,LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { LinkOutlined } from "@material-ui/icons";
import { AntdModal } from "../../../components/Modal";
import { ClientCommunicationActions } from "../actions";
import SnackBarNotification from "../../../components/SnackBar/SnackBar";
import { FORM_DATA_KEY } from "container/JobRequests/JobRequestDetails/jobRequest.constants";
import { FileOperationActions } from "container/common/FileOperations/actions";
const { TextArea } = Input;

interface ChatBoxProps {
  onSend: (message: any, file: any) => void;
  selectedWord?: string;
}

const ChatWrapper = styled.div`
  border: none;
  width: 95%;
  margin: auto;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 6px 12px rgb(159 179 248 / 20%);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const ChatInput = styled(Input)`
  border: none;
  outline: none;
  border-radius: 12px;

  &.ant-input:hover {
    border: none;
    border-right-width: 0px !important;
    outline: none;
  }
  &.ant-input:focus {
    box-shadow: none;
  }
`;

const SendButton = styled(Button)`
  width: 55px;
  height: 50px;
  margin: auto 10px;
  background: #215ec9;
  border-radius: 8px;
  border: none;
  &.ant-btn:hover {
    background: #215ec9;
  }
  &.ant-btn:focus {
    background: #215ec9;
  }
`;
const TextAreaChat=styled(TextArea)`

`
const AttachDocumentIcon = styled.label`
  border: none;
  align-self: center;
  cursor: pointer;
  position: relative;
`;

const FileInput = styled(Input)`
  display: none;
`;

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
`;
const SharedFilesDisplay = styled.span`
  color: #011759;
`;

const ChatBox = (props: ChatBoxProps) => {
  const chatInfo = useSelector(
    (state: any) => state.ClientCommunicationReducers
  );
  const [files, setFiles] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const enterkeyPress = document.getElementById("onEnter");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const spinIcon = (
    <LoadingOutlined style={{ fontSize: 24, margin: "0 20px" }} spin />
  );
  
  const onChange = (e: any) => {
    setNewMessage(e.target.value?.trimStart());
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.selectedWord) {
      setNewMessage(newMessage+" "+props.selectedWord);
    }
  }, [props.selectedWord]);

  const handleChange = (e: any) => {
    setShowSpinner(true);
    if (e.target.files[0].size / 1024 / 1024 > 20) {
      setShowNotification(true);
      setNotificationMessage("File size exceeds the 15 mb limit. Please try uploading a smaller file size.");
      return;
    }
    const file = e.target.files[0];
    setFiles(file);
    const formData = new FormData();
    formData.append(FORM_DATA_KEY, file);
    dispatch(FileOperationActions.uploadFileToPermanentFolder(formData)).then(
      (res: any) => {
        setShowSpinner(false);
      },
      (err: any) => {
        setShowNotification(true);
        setNotificationMessage("Error in attaching file");
        setFiles(undefined);
      }
    );
  };

  const modalBody = () => {
    return (
      <ModalBody>
        <SharedFilesDisplay>{files?.["name"]}</SharedFilesDisplay>
        {showSpinner && (
          <Spin
            indicator={spinIcon}
            style={{ display: "flex", alignItems: "center" }}
          />
        )}
      </ModalBody>
    );
  };


  const sendFileModal = () => {
    return (
      <AntdModal
        usedFor="deleteMessage"
        variant={"white"}
        positiveButtonColor={"#53DB79"}
        positiveButtonBorderColor={"#67749C"}
        buttonDirectionColumn
        disabled={showSpinner}
        positiveActionText={"SEND"}
        isFooterAvailable={true}
        header={" "}
        positiveAction={() => {
          if (files) {
            props.onSend(newMessage, files);
            setNewMessage("");
            setFiles(undefined);
          }
        }}
        closable={true}
        body={modalBody()}
        negativeAction={() => {
          setFiles(undefined);
        }}
      />
    );
  };

  return (
    <ChatWrapper>
       <SnackBarNotification
        openNotification={showNotification}
        closeSnackbar={() => {
          setShowNotification(false);
        }}
        severity={"error"}
        message={notificationMessage}
      ></SnackBarNotification>
      <ChatInput
        type="text"
        value={newMessage}
        id="onEnter"
        onChange={e => {
          onChange(e);
        }}
        onKeyPress={e => {
          if (newMessage !== "" && e.key === "Enter") {
            props.onSend(newMessage, files);
            setNewMessage("");
            setFiles(undefined);
          }
        }}
      ></ChatInput>
       {files && sendFileModal()}
      <AttachDocumentIcon htmlFor="sharedFiles">
        <LinkOutlined style={{ color: "#8CA8D1", fontSize: "20px" }} />
      </AttachDocumentIcon>
      <FileInput
        type="file"
        id="sharedFiles"
       onInput={handleChange}
        accept=".doc,.docx,application/msword,application/pdf,image/png,image/jpg,image/jpeg"
      />

      <SendButton
        icon={
          <SendOutlined
            rotate={315}
            style={{ color: "#ffffff", fontSize: "20px" }}
            onClick={() => {
              if (newMessage !== "") {
                props.onSend(newMessage, files);
                setNewMessage("");
                setFiles(undefined);
              }
            }}
          />
        }
      ></SendButton>
    </ChatWrapper>
  );
};

export default ChatBox;
