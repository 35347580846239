export const modules = {
  toolbar: [
    [{ header: [3,false] }],
    ["bold", "italic", "underline", "strike", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
  clipboard: { matchVisual: false },
};

export const BLOCK_TAG_NAME = "DIV";

export const EMPTY_TEXT_VALUES = ["\n", "", " ", " \n", "\n "];

export const VISUALLY_EMPTY_STRINGS = [
  "<div><br></div>",
  "<h3><br></h3>",
  "<p><br></p>",
];

/**
 * patterns include: 
 * 1. "<div> </div>"
 * 2. "<div>  </div>"
 * 3. "<div>   </div>"
 * etc...
 */
export const DIV_WITH_EMPTY_SPACES_PATTERN = /<div>\s*<\/div>/;