import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  peopleInfoContainer: {
    padding: '15px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  subHeader: {
    color: '#66759c',
    fontWeight: 600,
    float: 'left'
  },
  editAddFields: {
    marginTop: '15px',
    color: '#8fc9f9',
    fontWeight: 400,
    fontSize: '13px'
  },
  editButton: {
    backgroundColor: '#06c396',
    color: '#fff'
  },
  title: {
    textAlign: 'center',
    color: '#15a3b8',
    fontWeight: '600',
    position: 'relative',
    bottom: '40px'
  },
  uploadedTitle: {
    textAlign: 'center',
    color: '#15a3b8',
    fontWeight: '600',
  },
  content: {
    textAlign: 'center',
    width: '50%',
    fontWeight: '400',
  },
  splitLine: {
    borderTop: '1px solid #17a2b8',
    width: '112%',
    position: 'relative',
    right: '44px',
  }
}));