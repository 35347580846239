import NoAccess from "../../../../components/NoAccess/NoAccess";
import { Divider, Table, Typography } from "antd";
import { useSelector } from "react-redux";
import { ExistingClientVendors, IJobRequestData, IVendorAddedByClient, IVendorRemovedByClient } from "../../JobRequestDetails.interfaces";
import { EXISTING_CLIENT_VENDOR_COLUMNS, VENDORS_ADDED_BY_CLIENT_COLUMN, VENDORS_REMOVED_BY_CLIENT_COLUMN } from "./VendorsAddedByClient.constants";
import { TableVendorsAddedBy } from "container/JobRequests/components/TableVendorsAddedBy/TableVendorsAddedBy";
import { TabContentContainer } from "container/JobRequests/JobRequestDetails/JobRequestDetails.styles";
import { getDateFromUTC } from "Utils/DisplayTimeFormat";

const { Text } = Typography;

export interface IVendorAddedByClientRowObj extends IVendorAddedByClient {
  key: number;
}
export interface IExistingClientVendorRowObj extends ExistingClientVendors {
  key: number;
}
export interface IVendorRemovedByClientRowObj extends IVendorRemovedByClient {
  key: number;
  date: string;
}

export const VendorsAddedByClient = (props: { hasAccess: boolean }) => {
  const { jobRequestDetails: JOB_REQUEST_DATA }: { jobRequestDetails: IJobRequestData } = useSelector(
    (state: any) => state.ClientRequestReducer
  );

  const getRowsForVendorsAddedByClients = () => {
    let rows: IVendorAddedByClientRowObj[] = [];
    JOB_REQUEST_DATA?.vendorAddedByClient.forEach((arrObj, index: number) => {
      rows.push({ key: index, ...arrObj });
    });
    return rows;
  };

  const getRowsForExistingClientVendors = () => {
    let rows: IExistingClientVendorRowObj[] = [];
    if(JOB_REQUEST_DATA.existingsVendorsOfClient){
    JOB_REQUEST_DATA.existingsVendorsOfClient.forEach((arrObj, index: number) => {
      rows.push({ key: index, ...arrObj });
    });
  }
    return rows;
  };


  const getRowsForVendorsRemovedByClients = () => {
    let rows: IVendorRemovedByClientRowObj[] = [];
    JOB_REQUEST_DATA?.vendorRemovedByClient?.forEach((arrObj, index: number) => {
      rows.push({ key: index, ...arrObj, date: getDateFromUTC(String(arrObj?.removedAt)) });
    });
    return rows;
  };

  if (!props.hasAccess) {
    return <NoAccess />;
  }

  return (
    <TabContentContainer>
       {!!JOB_REQUEST_DATA?.existingsVendorsOfClient?.length && <>
        <Table
          title={() => <Text strong>Existing Vendors of Client</Text>}
          bordered
          scroll={{ y: 200 }}
          pagination={false}
          columns={EXISTING_CLIENT_VENDOR_COLUMNS}
          dataSource={getRowsForExistingClientVendors()}
        />
      <Divider />
      </>
    }

      {JOB_REQUEST_DATA && (
        <Table
          title={() => <Text strong>Vendors added by Client</Text>}
          bordered
          scroll={{ y: 200 }}
          pagination={false}
          columns={VENDORS_ADDED_BY_CLIENT_COLUMN}
          dataSource={getRowsForVendorsAddedByClients()}
        />
      )}
      <Divider />
      {/* Vendors Removed by Client */}
      {JOB_REQUEST_DATA?.vendorRemovedByClient?.length > 0 && (
        <Table
          title={() => <Text strong>Vendors removed by Client</Text>}
          bordered
          scroll={{ y: 200 }}
          pagination={false}
          columns={VENDORS_REMOVED_BY_CLIENT_COLUMN}
          dataSource={getRowsForVendorsRemovedByClients()}
        />
      )}
      <Divider />
      <TableVendorsAddedBy type="CLIENT" />
    </TabContentContainer>
  );
};
