import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { Fragment } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./CardsShuffler.scss";

function CardsShuffler(props) {
  // But in this example everything is just done in one place for simplicity

  const grid = 7;

  const getItemStyle = (isDragging, draggableStyle,showDeleteButton) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: showDeleteButton? `0 0 0 ${grid * 2}px`:`10px 10px 10px ${grid * 2}px`,
    margin: `0 0 ${grid}px 0`,
    color: "#6c757d",
    fontWeight: 600,
    borderRadius: "5px",
    lineHeight: "0px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    border: isDragging ? "1px solid #28a745" : "1px solid rgb(223, 225, 230)",
    // change background colour if dragging
    background: "#fff",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#15a3b8" : "#fff",
    padding: grid,
    width: 285,
  });

  return (
    <Tooltip title="Drag and Drop GSTNs to change rank">
      <DragDropContext onDragEnd={props.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {props?.items?.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  className="d-flex justify-content-center"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        props.showDeleteButton
                      )}
                    >
                      <Fragment>
                        <div>
                          <div className="rank_circle">{index + 1}</div>
                          {item.gstn}
                          {props.showDeleteButton &&
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              style={{
                                fontSize: "14px",
                                transform: "scale(0.8)",
                              }}
                              onClick={() => props.onDelete(item.gstn)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          }
                        </div>
                      </Fragment>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Tooltip>
  );
}

export default CardsShuffler;
