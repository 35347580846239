import React from "react";
import { Tooltip } from "antd";
import LogoImage from "../LogoImage/LogoImage";
import { CardSpinner } from "../Spinner";
import { TagAntd, CloseIconWrap, Div, LogoWrapper, FlexDiv, CardSizeText, Text } from "./CardTag.styles";
export interface CardTagProps {
  $usedFor?: string;
  cardText?: string;
  onClose?: (e: any) => void;
  closable?: boolean;
  imgType?: "loading" | "document" | string;
  cursorType?: "pointer" | "default";
  fontColor?: "primary" | "secondary";
  $bgColor?: string;
  fileSize?: number;
  loginModal?: boolean;
  loginStatus?: boolean;
}

const CardTag = (props: CardTagProps) => {
  const truncateText = (text: string) => {
    if (props?.$usedFor === "compareVendor" && text?.length > 22) {
      return text.substring(0, 22) + "..." + text.substring(-4, -1);
    }
    return text;
  };
  return (
    <TagAntd
      style={{
        padding: `${props?.$usedFor === "compareVendor" &&
          "0.76rem 0rem 0.76rem 1rem"}`,
      }}
      closable={props.closable}
      onClose={props.onClose}
      closeIcon={
        <CloseIconWrap>
          {["galleryOpen", "galleryClose"].includes(props?.imgType!) ? (
            props?.imgType === "galleryClose" ? (
              <img src="/images/arrowRight.svg" alt="right-arrow" />
            ) : (
              <img src="/images/arrowLeft.svg" alt="left-arrow" />
            )
          ) : (
            <img src="/images/close.svg" alt="close-icon" />
          )}
        </CloseIconWrap>
      }
      $bgColor={props?.$bgColor}
      $usedFor={props?.$usedFor}
    >
      <Div cursorType={props.cursorType}>
        {props.imgType && (
          <LogoWrapper cursorType={props.cursorType}>
            {props.imgType === "document" && (
              <LogoImage imageSrc="/images/document.svg" altMesg="File icon" />
            )}
            {props.imgType === "loading" && <CardSpinner />}
            {["galleryOpen", "galleryClose"].includes(props?.imgType!) && (
              <LogoImage imageSrc="/images/gallery.svg" altMesg="File icon" />
            )}
          </LogoWrapper>
        )}
        <FlexDiv>
          <Tooltip title={props?.cardText!}>
              <Text
                cursorType={props.cursorType}
                fontColor={props.fontColor}
                $usedFor={props?.$usedFor}
              >
                {/* {props.cardText} */}
                {truncateText(props?.cardText!)}
              </Text>
          </Tooltip>
          {props.fileSize && <CardSizeText>{props.fileSize} MB</CardSizeText>}
        </FlexDiv>
      </Div>
    </TagAntd>
  );
};
export default CardTag;
