import {
  Backdrop,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { dropDownData } from "../../constants/updateVendorConstants";
import ChipsArray from "../ChipsArray/ChipsArray";
import "./ClientPlantInfoUpdate.scss";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../NoAccess/NoAccess";

const useStyles = makeStyles((theme) => ({
  sowContainer: {
    padding: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  subHeader: {
    color: "#66759c",
    fontWeight: 600,
  },
  chipCard: {
    position: "relative",
    display: "block",
    border: "0.5px solid #c4c4c4",
    borderRadius: "4px",
    height: "100px",
    overflow: "auto",
    width: "100%",
    paddingRight: "10px",
    paddingBottom: "10px",
    marginTop: "10px",
  },
  chips: {
    float: "left",
    marginLeft: "10px",
    marginTop: "10px",
  },
  editAddFields: {
    marginTop: "15px",
    color: "#8fc9f9",
    fontWeight: 400,
    fontSize: "13px",
  },
  editButton: {
    backgroundColor: "#06c396",
    color: "#fff",
  },
  cancelButton: {
    backgroundColor: "#dc3545",
    color: "#fff",
    marginLeft: "15px",
  },
  dropDown: {
    width: "57vw",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#17a2b8",
    color: "#fff",
  },
  splitLine: {
    borderTop: "1px solid #17a2b8",
    width: "112%",
    position: "relative",
    right: "44px",
  },
}));

const ClientPlantInfoUpdate = () => {
  const [loader, enableLoader] = React.useState(false);
  const [showCompanyMsg, setCompanyMsg] = React.useState(false);
  const [vendorEditable, setVendorEditable] = React.useState(false);
  const [reloadUpdate, setReloadUpdate] = React.useState(false);
  const [plantInfo, setPlantInfo] = React.useState({});
  const [products, setProducts] = React.useState({});
  const [productValue, setProductValue] = React.useState([]);
  const [industries, setIndustries] = React.useState({});
  const { clientId } = useParams();
  const [hasAccess, setHasAccess] =React.useState(true);

  const [industriesServedDropdown, setIndustriesServedDropdown] =
    React.useState([]);

  const updatePlant = (updateId, plantInfo) => {
    let address = {};
    if (plantInfo.address) {
      address = plantInfo.address;
      address.country = "India";
    } else {
      address.country = "India";
    }
    let payload = {
      address: address,
      industries: industries,
      plantProducts: products.map((itr) => itr.value),
    };
    updateCompanyInfo(updateId, payload);
  };

  const handleDropDownIndustries = (val, object) => {
    const dropDownValues = val.target.value;
    let endRes = dropDownData.industries.filter((itr) =>
      dropDownValues.find((selected) => selected === itr.label)
    );
    setIndustries(endRes);
    setIndustriesServedDropdown(val.target.value);
  };

  const customerValueBind = (e) => {
    setProductValue(e.target.value);
  };

  const addProducts = (e, customersList) => {
    if (e.key === "Enter") {
      addProducstsToList(customersList);
      setProductValue("");
    }
  };

  const addProducstsToList = (productsList) => {
    let prods = productValue.split(", ");
    let customers = productsList;

    prods.forEach((itr) => {
      let obj = {
        label: "",
        value: "",
      };
      obj.label = itr;
      obj.value = itr;
      customers.push(obj);
    });
    setProducts(customers);
  };

  const updateCompanyInfo = async (id, payload) => {
    try {
      const result = await axios.put(
        process.env.REACT_APP_BASE_URL + "/client-onboarding/updatePlant/" + id,
        payload,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      );
      if (result.data.status === "SUCCESS") {
        setReloadUpdate(true);
        setVendorEditable(false);
      } else {
        setReloadUpdate(true);
        setVendorEditable(false);
      }
    } catch (err) {
      setReloadUpdate(true);
      setVendorEditable(false);
    }
  };

  const enableEdit = () => {
    setVendorEditable(true);
  };

  const cancelEdit = () => {
    setReloadUpdate((update) => !update);
    setVendorEditable(false);
  };

  const removeIndustriesServed = (chip) => {
    setIndustries((chips) => chips.filter((itr) => itr.value !== chip.value));
    setIndustriesServedDropdown((selected) =>
      selected.filter((itr) => itr !== chip.label)
    );
  };

  const removeProductChips = (chip) => {
    setProducts((chips) => chips.filter((itr) => itr.value !== chip.value));
  };

  const setAddress = (e, type, address) => {
    const value = e.target.value;
    if (!address) {
      address = {};
    }
    if (type === "address") {
      address.fullAddress = value;
      setPlantInfo((itr) => ({ ...itr, address: address }));
    }
    if (type === "pinCode") {
      address.pinCode = value;
      setPlantInfo((itr) => ({ ...itr, address: address }));
    }
  };

  useEffect(async () => {
    try {
      enableLoader(true);
      await axios.get(
        process.env.REACT_APP_BASE_URL + "/client-onboarding/plant/" + clientId,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      ).then((result) => {
        if (result.data.status == "SUCCESS") {
          let plantProducts = [];
          let prods = result.data.data?.plantProducts;
          prods.forEach((itr) => {
            let obj = {
              label: itr,
              value: itr,
            };
            obj.label = itr;
            obj.value = itr;
            plantProducts.push(obj);
          });
          setPlantInfo(result.data.data);
          setReloadUpdate(false);
          let industries = result.data.data?.industries || [];
          setIndustries(industries);
          setProducts(plantProducts);
          setIndustriesServedDropdown(industries.map((itr) => itr.label));
          setCompanyMsg(false);
        }
        if (result.data.status == "ERROR") {
          setCompanyMsg(true);
        }
        enableLoader(false);
      })
      .catch((error) => {
        if(error.response.status === 403){
          setHasAccess(false);
        } 
      });
    } catch (error) {
      setCompanyMsg(false);
      enableLoader(false);
    }
  }, [reloadUpdate]);

  const classes = useStyles();
  return (
    hasAccess ?
    <div className="container-fluid">
      {!showCompanyMsg ? (
        <div className={classes.peopleInfoContainer}>
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="row">
            <div className="col">
              <InputLabel className={classes.subHeader}>Plant Name</InputLabel>
              <TextField
                id="1"
                value={plantInfo?.plantName || ""}
                variant="outlined"
                disabled={!vendorEditable}
                onChange={(e) => {
                  setPlantInfo((data) => ({
                    ...data,
                    plantName: e.target.value,
                  }));
                }}
                fullWidth
              />
            </div>
            <div className="col">
              <InputLabel className={classes.subHeader}>
                company Email
              </InputLabel>
              <TextField
                id="2"
                value={plantInfo?.companyEmail || ""}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </div>
          </div>
          <div className="row mt-4">
            <InputLabel className={classes.subHeader}>Products</InputLabel>
            <ChipsArray
              ChipsArray={products ? products : []}
              deletable={vendorEditable}
              onDelete={removeProductChips}
            ></ChipsArray>
            {vendorEditable ? (
              <div className="edit_fields">
                <TextField
                  id="outlined-basic"
                  label="Add Products and Press Enter"
                  value={productValue}
                  variant="outlined"
                  className="customer_text mt-4"
                  disabled={!vendorEditable}
                  onChange={(e) => customerValueBind(e)}
                  onKeyPress={(e) => addProducts(e, products)}
                />
              </div>
            ) : null}
          </div>
          <div className="row mt-4">
            <InputLabel className={classes.subHeader}>
              Industries Served
            </InputLabel>
            <ChipsArray
              ChipsArray={industries ? industries : []}
              deletable={vendorEditable}
              onDelete={removeIndustriesServed}
            ></ChipsArray>
          </div>
          {vendorEditable ? (
            <div className="row mt-4">
              <InputLabel id="demo-mutiple-checkbox-label">
                Industries Served
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={industriesServedDropdown}
                onChange={handleDropDownIndustries}
                input={<Input />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((chip) => (
                      <Chip key={chip} label={chip} className={classes.chip} />
                    ))}
                  </div>
                )}
                className={classes.dropDown}
              >
                {dropDownData.industries.map((itr) => (
                  <MenuItem key={itr.value} value={itr.label}>
                    <Checkbox
                      color="primary"
                      checked={industriesServedDropdown.indexOf(itr.label) > -1}
                    />
                    <ListItemText primary={itr.label} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          ) : null}
          <hr className={classes.splitLine}></hr>
          {/* <div className="row mt-4">
            <div className="col">
              <InputLabel className={classes.subHeader}>Street Name</InputLabel>
              <TextField
                id="fulladdress"
                value={plantInfo?.address?.streetName || ""}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </div>
            <div className="col">
              <InputLabel className={classes.subHeader}>Sub Premise</InputLabel>
              <TextField
                id="city"
                value={plantInfo?.address?.subPremise || ""}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </div>
          </div> */}
          {/* <div className="row mt-4">
            <div className="col">
              <InputLabel className={classes.subHeader}>City</InputLabel>
              <TextField
                id="state"
                value={plantInfo?.address?.city || ""}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </div>
            <div className="col">
              <InputLabel className={classes.subHeader}>Pincode</InputLabel>
              <TextField
                id="pincode"
                value={plantInfo?.address?.pinCode || ""}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </div>
          </div> */}
          <div className="row mt-4">
            <div className="col">
              <InputLabel className={classes.subHeader}>
                Full Address
              </InputLabel>
              <TextField
                id="state"
                value={plantInfo?.address?.fullAddress || ""}
                variant="outlined"
                disabled={!vendorEditable}
                onChange={(e) => setAddress(e, "address", plantInfo?.address)}
                fullWidth
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <InputLabel className={classes.subHeader}>Pin Code</InputLabel>
              <TextField
                id="state"
                value={plantInfo?.address?.pinCode || ""}
                variant="outlined"
                disabled={!vendorEditable}
                onChange={(e) => setAddress(e, "pinCode", plantInfo?.address)}
                fullWidth
              />
            </div>
            <div className="col">
              <InputLabel className={classes.subHeader}>Country</InputLabel>
              <TextField
                id="pincode"
                value={plantInfo?.address?.country || "India"}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col text-right mb-2">
              {vendorEditable ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => updatePlant(plantInfo?._id, plantInfo)}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="editButton"
                  startIcon={<EditIcon />}
                  onClick={enableEdit}
                >
                  Edit
                </Button>
              )}
              <Button
                variant="contained"
                className="ml-2 cancelButton"
                startIcon={<ClearIcon />}
                onClick={cancelEdit}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>Please Add Company Info to Update Plants</div>
      )}
    </div>
      :
    <NoAccess></NoAccess>
  );
};

export default ClientPlantInfoUpdate;
