import { Spinner } from "components/Spinner";
import NoAccess from "components/NoAccess/NoAccess";
import { useSelector } from "react-redux";
import { TableVendorsAddedBy } from "container/JobRequests/components/TableVendorsAddedBy/TableVendorsAddedBy";
import { TabContentContainer } from "container/JobRequests/JobRequestDetails/JobRequestDetails.styles";

import { AdminActionsTable } from "./AdminActionsTable";
import { Divider } from "antd";
import { JobMonetizationTable } from "./JobMonetizationTable";

export const VendorsSuggestedByAdmin = (props: { hasAccess: boolean }) => {
  const { isLoading } = useSelector((state: any) => state.LoaderReducers);

  if (!props.hasAccess) {
    return <NoAccess />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <TabContentContainer>
      <AdminActionsTable />
      <Divider />
      <JobMonetizationTable/>
      <Divider />
      <TableVendorsAddedBy type="ADMIN" />
    </TabContentContainer>
  );
};
