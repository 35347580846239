import moment, { MomentFormatSpecification } from "moment";

export const getFormattedTimeStamp = (date: any) => {
  //from BE date is in IST format & by default moment converts to local time format
  return date ? moment(moment.utc(date))?.format("MMMM Do YYYY, h:mm:ss a") : date;
};

export const getDateFromUTC = (date: string) => {
  return date ? moment(date)?.format("MMMM Do YYYY, h:mm:ss a") : date;
};

export const formatTime = (dateString: string, toFormat: string, fromFormat?: MomentFormatSpecification) => {
  return dateString ? moment(dateString, fromFormat).format(toFormat) : "-";
};
