import { Button, Divider, Modal } from "antd";
import styled from "styled-components";

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ReviewContainer = styled(FlexColumnDiv)``;

//overall rating status styles
export const OverallRatingsStatusContainer = styled.div`
  display: flex;
  height: auto;
`;

export const OverallRatingContentWrapper = styled(FlexColumnDiv)``;

export const OverallRatingHeadertext = styled.div<any>`
  font-weight: 700;
  font-size: 18px;
  color: #170f49;
  margin-bottom: 16px;
  margin-left: ${(props) => props?.$marginLeft};
`;

export const OverallRatingContentDiv = styled(FlexColumnDiv)`
  border-top: 1px solid #e7e7e9;
  padding-top: 24px;
  border-top: 1px solid #e7e7e9;
  padding-right: 40px;
  width: max-content;
`;

export const OverallRatingDiv = styled.div`
  display: flex;
  align-items: center;
  min-width: 382px;
  /*size of the star*/
  span {
    font-weight: 600;
    font-size: 52px;
  }
`;

export const Line = styled.div`
  width: 1px;
  height: 100%;
  hr {
    border-top: 1px solid #e7e7e9;
    height: 100%;
    opacity: 0.6;
  }
`;

export const ParameterDiv = styled.div<any>`
  display: flex;
  border-top: 1px solid #e7e7e9;
  padding-top: 24px;
  overflow: hidden;
  position: relative;
  min-height: 160px;
  a {
    font-weight: 700;
    font-size: 14px;
    color: #215ec9;
    margin-left: 3.472vw;
    position: absolute;
    bottom: -4px;
  }
`;

export const OverallRatingNum = styled.div`
  font-weight: 600;
  font-size: 74px;
  color: #011759;
  margin-right: 2.22vw;
`;

export const OverallRatingBasedOnStatus = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #8d9095;
`;

export const ParametersBttn = styled.div`
  display: flex;
  padding: 3px 22px;
  background: #f9f9f9;
  border: 1px solid #ececec;
  border-radius: 20px;
  margin-right: 1.667vw;
  margin-bottom: 24px;
  align-items: baseline;
  width: max-content;
  height: max-content;
  div {
    font-weight: 600;
    font-size: 16px;
    color: #25282b;
    opacity: 0.6;
  }
  img {
    width: 20px;
    height: 14px;
    margin-left: 2px;
  }
  span {
    font-weight: 500;
    font-size: 18px;
    color: #8d9095;
    opacity: 0.6;
    margin-left: 24px;
  }
`;

export const ParametersBttnWrapper = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #e7e7e9;
  padding-left: 3.472vw;
  //card height : 34px , marginbottom:24px, (34+34+24=24)
  max-height: ${(props) => (props?.$viewMore ? "auto" : "162px")};
  overflow: hidden;
  margin-bottom: 8px;
`;

//customer rating  status styles
export const CustomerRatingsContainer = styled(FlexColumnDiv)`
  width: 100%;
  background: #fafafa;
  padding: 43px 5.2vw;
  margin-top: 80px;
`;

export const CustomerRatingsHeadingWrapper = styled(FlexColumnDiv)`
  p {
    font-weight: 700;
    font-size: 12px;
    color: #4a3aff;
    text-transform: uppercase;
    display: flex;
    div {
      max-width: 20px;
      margin-right: 8px;
    }
  }
  div {
    font-weight: 700;
    font-size: 18px;
    line-height: 41px;
    color: #170f49;
  }
  hr {
    width: 20px;
    height: 2px;
    background: #4a3aff;
    border: 1px solid #4a3aff;
  }
`;

export const CustomerRatingsCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
`;

export const CustomerRatingsCard = styled.div`
  width: 38vw;
  cursor: pointer;
  min-height: 325px;
  max-height: 325px;
  padding: 36px 3.005vw;
  background: #ffffff;
  border: 1px solid #eff0f7;
  box-shadow: 0px 4px 12px rgba(8, 15, 52, 0.04);
  border-radius: 18px;
  margin-bottom: 24px;
  :hover {
    -webkit-box-shadow: -2px 0px 12px 6px rgba(192, 196, 219, 1);
    -moz-box-shadow: -2px 0px 12px 6px rgba(192, 196, 219, 1);
    box-shadow: -2px 0px 12px 6px rgba(192, 196, 219, 1);
  }
`;

export const CompanyDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  img {
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }
  div {
    font-weight: 700;
    font-size: 14px;
    color: #215ec9;
  }
`;

export const RatingWrapper = styled.div`
  /*size of the star*/
  padding-bottom: 13px;
  border-bottom: 1px solid #f1f1f1;
  span {
    font-weight: 600;
    font-size: 16px;
  }
`;

export const CardTextWrapper = styled(FlexColumnDiv)`
  margin-top: 28px;
`;
export const CardEllipsisDiv = styled(FlexColumnDiv)<any>`
  display: flex;
  flex-direction: column;
  display: -webkit-box;
  max-height: ${(props) => props?.$maxHeight};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ViewMoreWrapper = styled.div`
  background: #ffffff;
  width: 100px;
  margin-top: 16px;
  a {
    font-weight: 600;
    font-size: 14px;
    margin-left: 1px;
    color: #215ec9;
  }
`;

//form styles

export const ReviewFormContainer = styled(FlexColumnDiv)``;

export const ParameterRatingWrapper = styled.div`
  display: flex;
  width: 377px;
  align-items: center;
  height: auto;
`;

export const RatingSection = styled(FlexColumnDiv)`
  margin-bottom: 21px;
  span {
    font-weight: 500;
    font-size: 14px;
    color: #8d9095;
    opacity: 0.6;
  }
`;

export const RatingSectionHeader = styled(OverallRatingHeadertext)`
  margin-bottom: 8px;
  color: #25282b;
  font-weight: 600;
`;

export const ErrMessage = styled.div`
  color: #d00;
  font-size: 14px;
  margin-top: 16px;
`;

export const RatingSectionStars = styled(OverallRatingHeadertext)`
  .ant-rate-star {
    margin-right: 4.611vw !important;
  }
  span {
    font-size: 74px;
  }
  margin-bottom: 24px;
  @media screen and (min-width: 1800px) {
    .ant-rate-star {
      margin-right: 3.5vw !important;
    }
  }
`;

export const CustomerReviewAndParameterWrapper = styled(FlexColumnDiv)`
  padding-top: 16px;
  border-top: 1px solid #f4f6ff;
`;

export const CustomerReviewTextarea = styled.textarea`
  width: 80%;
  height: 100px;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  margin-bottom: 21px;
`;

export const CustomerParameterWrapper = styled(FlexColumnDiv)`
  margin-top: 16px;
`;

export const ViewMoreTextWrapper = styled.a`
  font-weight: 600;
  font-size: 16px;
  color: #215ec9 !important;
  margin-top: 16px;
`;

export const ParameterItemWrapper = styled.div`
  display: flex;
  align-items: center;
  .ant-rate-star {
    margin-right: 1vw;
    span {
      font-size: 18px;
    }
  }
  margin-bottom: 21px;
  @media screen and (min-width: 1920px) {
    .ant-rate-star {
      margin-right: 0.6vw;
    }
  }
`;

export const ParameterSectionHeading = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #8d9095;
  opacity: 0.8;
`;
export const ParameterItemHeading = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #25282b;
  margin-right: 21px;
  min-width: 167px;
  max-width: 167px;
`;

export const ParameterItemInput = styled.input`
  margin-left: 21px;
  width: 362px;
  height: 24px;
  border: 1px solid #cacbcc;
  border-radius: 6px;
  :focus {
    outline: none;
    border: 1px solid #cacbcc;
  }
`;

export const ClientDetailsInput = styled(ParameterItemInput)`
  width: 220px;
`;

export const ParameterItemsContainer = styled(FlexColumnDiv)<any>`
  height: ${(props) => (props?.$viewMore ? "auto" : "185px")};
  overflow: hidden;
`;

export const ModalDiv = styled(Modal)<any>`
  .ant-modal-title {
    width: 90%;
  }
  .ant-modal-footer {
    display: ${(props) => (props?.$footerHidden ? "none" : "block")};
    border-top: 1px solid #cacbcc;
    margin: 0px 42px;
    padding: 32px 0px;
  }
  .ant-modal-body {
    padding: ${(props) => props?.$margin || "0px 40px 20px 40px"};
    overflow: ${(props) => props?.$overflow || "auto"};
    height: auto;
    max-height: ${(props) => props?.$height};
    margin-right: 10px;
    margin-top: ${(props) => props?.$marginTop || "20px"};
  }
  .ant-modal-header {
    border-bottom: ${(props) => (props?.$headerHidden ? "none" : "1px solid #cacbcc")};
    padding: ${(props) => props?.$padding || "40px 24px 10px 0px"};
    padding-left: 0px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #011759;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0px 37px;
  }
  .ant-modal-close-x {
    width: auto;
    height: auto;
    line-height: 0px;
    margin-top: 41px;
    margin-right: 30px;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #011759;
  }
`;

export const FormBttn = styled(Button)<any>`
  color: ${(props) => props?.$color};
  background: ${(props) => props?.$backgroundColor};
  border: ${(props) => props?.$border};
  border-radius: 200px;
  width: 200px;
  height: 46px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 24px;
`;

export const FormBttnWrapper = styled.div`
  display: flex;
`;

export const ClientDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

//view review card

export const ViewReviewsContainer = styled(FlexColumnDiv)``;

export const ModalRatingContainer = styled.div`
  min-width: 242px;
`;

export const ViewReviewsCompDetails = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 56px;
    height: 56px;
  }
  span {
    font-size: 28px;
    font-weight: 600;
  }
  margin-bottom: 20px;
`;

export const ViewReviewsCompanyName = styled.div`
  margin-right: 16px;
  font-size: 22px;
  line-height: 40px;
  font-weight: 600;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 27%;
`;

export const ViewParameterContainer = styled.div`
  .parameters-heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 14px 0px 8px 0px;
  }
`;

export const ViewReviewsParameterContainer = styled.div`
  display: flex;
  flex: 1 1 50%;
  flex-wrap: wrap;
  max-height: 34vh;
  flex-direction: row;
  justify-content: space-between;
`;

export const ViewReviewsPostDetails = styled.div`
  display: flex;
  margin-right: 16px;
  align-items: center;
  p {
    font-weight: 500;
    font-size: 12px;
    color: #8d9095;
    opacity: 0.6;
    margin-bottom: 0px;
  }
  span {
    font-weight: 700;
    font-size: 12px;
    color: #514f6e;
    opacity: 1 !important;
    margin-left: 4px;
  }
`;

export const ViewReviewsOverallRating = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0px 14px 0px;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  .ant-rate-star {
    span {
      font-size: 42px;
    }
  }
`;

export const ViewReviewsDesc = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #514f6e;
  margin-left: 2vw;

`;

export const ViewReviewsParameterWrapper = styled(FlexColumnDiv)`
width: 44%;
`;

export const ViewReviewsParameterItems = styled(FlexColumnDiv)`
  width: 100%;
  .ant-rate-star {
    span {
      font-size: 12px;
    }
  }
  p {
    font-weight: 400;
    font-size: 14px;
    color: #514f6e;
    height: auto;
    margin-top: 8px;
  }
`;

export const ViewReviewsParameterText = styled(FlexColumnDiv)`
  font-weight: 600;
  font-size: 14px;
  color: #215ec9;
  margin-right: 16px;
`;

export const ViewReviewsParameterItemHeaderContent = styled.div`
  display: flex;
  align-items: baseline;
`;

//no reviews found ui css
export const NoReviewsContainer = styled.div`
  width: 99%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 44px 7.43vw;
  img {
    margin-right: 88px;
  }
  -webkit-box-shadow: 0px 4px 2px 4px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 4px 2px 4px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.06);
  margin: 4px 4px;
`;

export const NoReviewsDetailsWrapper = styled(FlexColumnDiv)``;

export const NoReviewsDetailsText = styled.div<any>`
  font-weight: ${(props) => props?.$fontWeight};
  font-size: ${(props) => props?.$fontSize};
  color: ${(props) => props?.$color};
  margin-bottom: ${(props) => props?.$marginBottom};
`;

export const ButtonWrapper = styled(Button)`
  background-color: #215ec9;
  color: #ffffff;
`;

export const DividerLine = styled(Divider)<any>`
  height: ${(props) => props?.$height || "100%"};
  margin: 0px 2.857vw;
  border-left: 1px solid #d4e5fe;
  position: sticky;
`;
