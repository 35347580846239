import React from "react";
import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoAccess from "../../components/NoAccess/NoAccess";
import ROLES from "../../constants/RolesConstants";
import { getUserRole } from "../../Utils/Storage";
import CSVReader from "react-csv-reader";
import * as _ from "lodash";
import "./VendorRefreshTOS.scss";
import axios from "axios";
import LocalStorage from "../../_services/LocalStorage";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  peopleInfoContainer: {
    padding: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const VendorRefreshTOS = () => {
  const classes = useStyles();
  const userRole = getUserRole().split(",") || [];
  const hasAccess =
    userRole.filter(
      (role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_PUBLISH
    ).length > 0;

  const [loader, enableLoader] = React.useState(false);
  const [inputData, setInputData] = React.useState([]);
  const [resultData, setResultData] = React.useState([]);

  const handleFileUpload = (data, fileInfo) => {
    const inputData = data
      ?.filter((value) => value?.gst && validateGST(value.gst))
      ?.map((element) => element.gst.trim());
    setInputData(inputData);
  };

  function validateGST(gstn) {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      gstn
    );
    if (regTest) {
      // let a=65,b=55,c=36;
      // return Array['from'](g).reduce((i,j,k,g)=>{
      // p=(p=(j.charCodeAt(0)<a?parseInt(j):j.charCodeAt(0)-b)*(k%2+1))>c?1+(p-c):p;
      // return k<14?i+p:j==((c=(c-(i%c)))<10?c:String.fromCharCode(c+b));
      // },0);
    }
    return regTest;
  }

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const refreshTurnOverSlabs = async () => {
    if (inputData.length) {
      enableLoader(true);
      const payload = {
        gstns: inputData,
      };
      await axios
        .post(
          process.env.REACT_APP_API_URL +
            "/v1/user-info/refresh-turn-over-details",
          payload,
          {
            headers: {
              authorization: `Bearer ${LocalStorage.get("token")}`,
            },
          }
        )
        .then((result) => {
          if (result?.data) {
            setResultData(result.data);
          }
          enableLoader(false);
        })
        .catch((error) => {
          console.log("Error", error);
          enableLoader(false);
        });
    }
  };

  return hasAccess ? (
    <>
      <h4>Bulk Refresh Turn Over Slabs</h4>
      <div className={classes.peopleInfoContainer}>
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="container">
        <CSVReader
          cssClass="react-csv-input"
          label="Upload the CSV file"
          onFileLoaded={handleFileUpload}
          parserOptions={parseOptions}
        />
        <Button
          className="create-button"
          variant="contained"
          color="success"
          onClick={() => refreshTurnOverSlabs()}
        >
          REFRESH
        </Button>
      </div>

      {resultData?.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>GST Number</TableCell>
                  <TableCell align="left">STATUS</TableCell>
                  <TableCell align="left">Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultData.map((row) => (
                  <TableRow
                    key={row.gstn}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.gstn}
                    </TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">
                      {row.status === "ERROR"
                        ? "Refresh Turn over Failed"
                        : "Refresh Turn over Published"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  ) : (
    <NoAccess></NoAccess>
  );
};

export default VendorRefreshTOS;
