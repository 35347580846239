import React from "react";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { IVerifStatusDropDownProps } from "../vendorVerification.interface";

export const VerifStatusDropDown = ({
  disableSelect,
  statusValue,
  setStatusValue,
}: IVerifStatusDropDownProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setStatusValue(event.target.value);
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
        <InputLabel>Status</InputLabel>
        <Select
          disableUnderline
          value={statusValue}
          onChange={handleChange}
          disabled={disableSelect}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"verified"}>Verified</MenuItem>
          <MenuItem value={"notVerified"}>Not Verified</MenuItem>
          {/* <MenuItem value={"notApplicable"}>Not Applicable</MenuItem> */}
          <MenuItem value={"inProgress"}>In Progress</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
