export const getYoutubeIdFromUrl = (url: string) => {
  const YOUTUBE_URL_REGEX = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const REGEX_MATCH = url.match(YOUTUBE_URL_REGEX);
  return (REGEX_MATCH&&REGEX_MATCH[7].length==11)? REGEX_MATCH[7] : "";
};

export const getYoutubeThumbnailFromUrl = (url: string, thumbnailSize?: number) => {
  const THUMB_SIZE = thumbnailSize || 0;
  const youtubeId = getYoutubeIdFromUrl(url);
  return `https://img.youtube.com/vi/${youtubeId}/${THUMB_SIZE}.jpg`;
};
