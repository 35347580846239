import { AppBar, Backdrop, Box, CircularProgress, makeStyles, Switch, Tab, Tabs, Typography } from "@material-ui/core";
import { FlexContainer } from "components/utils";
import { useStyles } from "container/JobRequests/JobRequestDetails/Quotations/CompareQuotation/CompareQuotation";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUsers } from "Utils/Storage";
import { SettingsActions } from "./actions";
import { EmailSettings } from "./Settings.interface";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>

      )}
    </div>
  );
}

export const disabledStyle = makeStyles((theme) => ({
  parent: {
    position: "relative",
    width: '100%',
    height: '50%',
    zIndex: 0,
  },
  backdrop: { position: "absolute", zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));


export default function Settings() {
  const disabled = disabledStyle()
  const classes = useStyles();
  const [tabIndexValue, setTabIndexValue] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [reloader, setReloader] = useState(false)
  const dispatch = useDispatch();
  const user = getLoggedInUsers() ? getLoggedInUsers() : "";
  const checkForProd = process.env.REACT_APP_ENVIRONMENT === 'production'
  const initialEmailSettings = {
    vendor: { vendorEmail: true },
    client: { clientEmail: true },
    retool: { retoolEmail: true },
    admin: { adminEmail: true }
  };
  const [emailSettings, setEmailSettings] = useState<EmailSettings>(initialEmailSettings)
  const [id, setId] = useState<string>('');
  const handleChange = (event: any, newValue: any) => {
    setTabIndexValue(newValue);
  };
  const [toggle, setToggle] = useState<Boolean>(false);

  useEffect(() => {
    //get current settings
    setisLoading(true)
    dispatch(SettingsActions.getEmailSettings()).then((resp: any) => {
      setId(resp.id)
      delete resp.id;
      setisLoading(false)
      setEmailSettings(resp)
      setReloader(false)
    }).catch(() => {
      setisLoading(false)
    })
  }, [reloader])

  useEffect(() => {
    //get current settings
    if (toggle) {
      setisLoading(true)
      dispatch(SettingsActions.updateEmailSettings(id, emailSettings)).then((resp: any) => {
        setToggle(false)
        setisLoading(false)
        setReloader(true)
      })
        .catch(() => {
          setisLoading(false)
        })
    }
  }, [emailSettings])

  return (<Box>
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
    <AppBar position="static">
      <Tabs
        value={tabIndexValue}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Email" value={0} />
      </Tabs>
    </AppBar>
    <Box className={disabled.parent}>
      <Backdrop open={checkForProd} className={disabled.backdrop}>
        This feature is not available for production environment
      </Backdrop>
      <TabPanel value={tabIndexValue} index={0}>
        <Typography style={{ paddingBottom: "20px", fontWeight: 600, color: 'red' }}> Switching this toggle ON will enable emails to go from respective portals </Typography>
        <FlexContainer width={"100%"} >
          <Typography style={{ width: "100px" }}>Vendor</Typography>
          <Switch
            checked={emailSettings?.vendor?.vendorEmail}
            onChange={(e, checked) => {
              setEmailSettings({ ...emailSettings, vendor: { vendorEmail: checked, vendorEmailUpdatedBy: user?.email, vendorEmailUpdatedAt: new Date() } });
              setToggle(true)
            }}
            name="isActive"
            color="primary"
          />
          <Typography style={{ width: "100%", paddingLeft: "20px", fontStyle: "italic" }}>Last updated by : {emailSettings?.vendor?.vendorEmailUpdatedBy} at {emailSettings?.vendor?.vendorEmailUpdatedAt ? moment(emailSettings?.vendor?.vendorEmailUpdatedAt).format("DD/MM/yyyy hh:mm") : ""}</Typography>
        </FlexContainer>
        <FlexContainer>
          <Typography style={{ width: "100px" }}>Client</Typography>
          <Switch
            checked={emailSettings?.client?.clientEmail}
            onChange={(e, checked) => {
              setEmailSettings({ ...emailSettings, client: { clientEmail: checked, clientEmailUpdatedBy: user?.email, clientEmailUpdatedAt: new Date() } });
              setToggle(true)
            }}
            name="isActive"
            color="primary"
          />
          <Typography style={{ paddingLeft: "20px", fontStyle: "italic" }}>Last updated by : {emailSettings?.client?.clientEmailUpdatedBy} at {emailSettings?.client?.clientEmailUpdatedAt ? moment(emailSettings?.client?.clientEmailUpdatedAt).format("DD/MM/yyyy hh:mm") : ""}</Typography>
        </FlexContainer>
        <FlexContainer>
          <Typography style={{ width: "100px" }}>Admin</Typography>

          <Switch
            checked={emailSettings?.admin?.adminEmail}
            onChange={(e, checked) => {
              setEmailSettings({ ...emailSettings, admin: { adminEmail: checked, adminEmailUpdatedBy: user?.email, adminEmailUpdatedAt: new Date() } });
              setToggle(true)
            }}
            name="isActive"
            color="primary"
          />
          <Typography style={{ paddingLeft: "20px", fontStyle: "italic" }}>Last updated by : {emailSettings?.admin?.adminEmailUpdatedBy} at {emailSettings?.admin?.adminEmailUpdatedAt ? moment(emailSettings?.admin?.adminEmailUpdatedAt).format("DD/MM/yyyy hh:mm") : ""}</Typography>
        </FlexContainer>
        <FlexContainer>
          <Typography style={{ width: "100px" }}>Retool</Typography>


          <Switch
            checked={emailSettings?.retool?.retoolEmail}
            onChange={(e, checked) => {
              setEmailSettings({ ...emailSettings, retool: { retoolEmail: checked, retoolEmailUpdatedBy: user?.email, retoolEmailUpdatedAt: new Date() } });
              setToggle(true)
            }}
            name="isActive"
            color="primary"
          />
          <Typography style={{ paddingLeft: "20px", fontStyle: "italic" }}>Last updated by : {emailSettings?.retool?.retoolEmailUpdatedBy} at {emailSettings?.retool?.retoolEmailUpdatedAt ? moment(emailSettings?.retool?.retoolEmailUpdatedAt).format("DD/MM/yyyy hh:mm") : ""}</Typography>
        </FlexContainer>
      </TabPanel>
    </Box>
  </Box>
  )
}
