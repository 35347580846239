import React, { useEffect } from "react";
import { checkOverflow } from "shared/handlers/elementOverflow.handler";
import { parametersConstants } from "../constants";
import { IParameterContent } from "../interfaces/interfaces";
import { ParametersBttn, ParametersBttnWrapper } from "../VendorReviews.styles";

const ParameterContent = (props: IParameterContent) => {
  const { data } = props;
  const { expandContent } = props;
  const ref: any = React.createRef();

  useEffect(() => {
    if (ref?.current) props?.onViewMoreCheck(checkOverflow(ref));
  }, [ref, window.innerHeight]);

  return (
    <ParametersBttnWrapper ref={ref} $viewMore={expandContent}>
      {parametersConstants?.map((item: any) => {
        return (
          <>
            {data?.averageDetailedRating?.[item.keyName] ? (
              <ParametersBttn key={item.keyName}>
                <div>{item.bttnText}</div>
                <span>{data?.averageDetailedRating?.[item?.keyName]}</span>
                <img src="/images/star-new.svg" alt="star img" />
              </ParametersBttn>
            ) : (
              <></>
            )}
          </>
        );
      })}
    </ParametersBttnWrapper>
  );
};

export default ParameterContent;
