
import { IncomingMessage, OutgoingMessage } from "../Chat.styles";
import Link from "./Link";

interface TextProps {
  message: string;
  isLinkAvailable: boolean;
  message_type: "incoming" | "outgoing";
  linkData?: any;
}

const Text = (props: TextProps) => {
  if (props?.message_type === "incoming") {
    return (
      <IncomingMessage>
        {props?.isLinkAvailable ? (
          <Link
            changeBackground={true}
            message={props?.message}
            linkData={props?.linkData}
          />
        ) : (
          props?.message
        )}
      </IncomingMessage>
    );
  }

  return (
    <OutgoingMessage>
      {props?.isLinkAvailable ? (
        <Link
          changeBackground={false}
          message={props?.message}
          linkData={props?.linkData}
        />
      ) : (
        props?.message
      )}
    </OutgoingMessage>
  );
};

export default Text;
