import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { RxCross1 } from "react-icons/rx";

import { Modal } from "antd";

export function ReactPrompt(props: any) {
  const { when, onOK, onConfirm, title, okText, confirmText } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);  
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleConfirm = useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onConfirm]);

  return showPrompt ? (
    <Modal
      title={title}
      visible={showPrompt}
      onOk={handleConfirm}
      okText={confirmText}
      onCancel={handleOK}
      cancelText={okText}
      closable={false}
      maskClosable={false}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          You have unsaved changes on this page.
        </div>
        <button
          onClick={handleConfirm}
          style={{ border: "none", background: "transparent" }}
        >
          <RxCross1 />
        </button>
      </div>
    </Modal>
  ) : null;
}
