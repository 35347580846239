import { message, notification } from "antd";
interface ToastProps {
  type: "error" | "info" | "success" | "warning";
  message: string;
  description?: string;
}
const AntdToast = ({ type, message, description }: ToastProps) => {
  notification[type]({
    message: message,
    description,
    top: 70, //to display below the nav bar
  });
};

export default AntdToast;
