import styled from "styled-components";

export const TabContainerLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const RequestForQuotationsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;
`;

export const FileDetailsHeader = styled.p`
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #011759;
  padding-left: 20px;
  opacity: 0.7;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RFQFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 480px;
  padding-right: 1rem;
`;

export const RFQFileCard = styled.div`
  min-height: 60px;
  border: 1px solid #d4e5fe;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
`;

export const FileStatsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FileDate = styled.div`
  font-size: 12px;
  color: #011759;
  opacity: 0.4;
  margin-right: 95px;
`;

export const FileSizeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FileSize = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #011759;
  margin-right: 55px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #215ec9;
  padding-right: 1rem;
`;

export const DownloadAllButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DownloadImg = styled.img<{marginRight?: string}>`
  cursor: pointer;
  margin-right: ${props => props?.marginRight};
`;

export const RequestForQuotationsFooter = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #011759;
`;
export const LinkText = styled.span`
  color: #215ec9;
  text-decoration-line: underline;
  cursor: pointer;
`;
