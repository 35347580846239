import { Types as VendorProfileTypes } from "_types/VendorProfileTypes";

export const ActionCreators: any = {
  /* Vendor Review Actions */
  getVendorProfileReviewData: (gstn: any) => ({
    types: [
      VendorProfileTypes.GET_VENDOR_PROFILE_REVIEW_REQUESTED,
      VendorProfileTypes.GET_VENDOR_PROFILE_REVIEW_SUCCESS,
      VendorProfileTypes.GET_VENDOR_PROFILE_REVIEW_FAILURE,
    ],
    promise: (client: any) => client.get(`get_all_review/${gstn}`, {}),
  }),

  setVendorProfileReview: (apiType: any, data: any) => ({
    types: [
      VendorProfileTypes.GET_VENDOR_PROFILE_REVIEW_REQUESTED,
      VendorProfileTypes.GET_VENDOR_PROFILE_REVIEW_SUCCESS,
      VendorProfileTypes.GET_VENDOR_PROFILE_REVIEW_FAILURE,
    ],
    promise: (client: any) =>
      apiType === "create"
        ? client.post(`create_review`, { data })
        : client.patch(`update_review`, { data }),
  }),

  setOpenReviewFormModal: (data: any) => ({
    type: VendorProfileTypes.SET_OPEN_REVIEW_FORM_MODAL_SUCCESS,
    payload: data?.status,
  }),

  setOpenReviewModal: (data: any) => ({
    type: VendorProfileTypes.SET_OPEN_REVIEW_MODAL_SUCCESS,
    payload: data?.status,
  }),

  setReviewErrorMess: (data: any) => ({
    type: VendorProfileTypes.SET_ERROR_MESSAGE,
    payload: data?.message,
  }),
};

export default ActionCreators;
