import {
  Box,
  Modal,
  Typography,
  Button,
} from "@material-ui/core";
import { MdFileUpload } from "react-icons/md";
import { useManufacturingModalStyles } from "../VendorCapabilitiesUpdate/components/ManufacturingModals/manufModal.styles";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import clsx from "clsx";
import { getYoutubeThumbnailFromUrl } from "shared/handlers/youtube.handler";
import { IGalleryImage, ICompanyVideo, IMediaItem } from "../VendorCapabilitiesUpdate/components/GalleryModal/GalleryModal.interface"
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getUrlFromMediaObject } from "../VendorCapabilitiesUpdate/components/GalleryModal/galleryModal.handler";
import { IEntityObj, ILinkAvailableGalleryItemsModalProps } from "./LinkAvailableGalleryItemsModal.interface";
import { isMediaAlreadyExistingInEntity } from "./LinkAvailableGalleryItemsModal.helper";

export const LinkAvailableGalleryItemsModal = (props: ILinkAvailableGalleryItemsModalProps) => {
  const {
    title,
    showModal,
    handleCloseModal,
    currEntityObj,
    associatedMediaItems,
    handleRemoveMediaItemFromCurrEntityObj,
    handleAddMediaItemToCurrEntityObj,
    setIsDataChanged,
    handleImageUpload
  } = props;
  const classes = useManufacturingModalStyles();

  const handleResetModalState = () => {
    handleCloseModal();
  };

  return (
    <Modal open={showModal}>
      <Box 
        className={clsx(classes.modalBody, classes.modalBodyForLinkGallery)}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Typography>{title}</Typography>
          </Box>

            <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <input
                multiple
                type="file"
                accept="image/*"
                id="image-experience-upload"
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload(e)}
              />
              <label htmlFor="image-experience-upload">
                <Button
                  size={"small"}
                  variant={"outlined"}
                  component="span"
                  style={{ border: "1px solid #4050B5", color: "#4050B5" }}
                  startIcon={<MdFileUpload style={{ color: "#4050B5" }} />}
                >
                  Upload
                </Button>
              </label>

              <CancelRoundedIcon
                style={{ cursor: "pointer" }}
                onClick={handleResetModalState}
              />
            </Box>
        </Box>

        {/* Modal Content */}
        <Box
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          flexDirection={"column"}
          gridGap={"16px"}
          pt={"0.5rem"}
          pb={"1rem"}
          pr={"0.5rem"}
          mt={1}
          style={{ overflowY: "auto", maxHeight: "90%" }}
        >
          {associatedMediaItems?.map((mediaItemObj, index) => {
            const SELECTED_IMG_OR_VIDEO_OBJ = mediaItemObj || {};
            const CURR_MEDIA_URL = getUrlFromMediaObject(SELECTED_IMG_OR_VIDEO_OBJ)
            const IS_YOUTUBE_URL = CURR_MEDIA_URL?.includes("you");
            const VIDEO_THUMBNAIL_IMG_SRC = IS_YOUTUBE_URL
              ? getYoutubeThumbnailFromUrl(CURR_MEDIA_URL, 0)
              : "/images/video-thumbnail.png";
            const isCurrMediaItemAlreadyPresentInCurrentEntity = isMediaAlreadyExistingInEntity(
              CURR_MEDIA_URL,
              currEntityObj
            );

            return (
              <Box
                key={CURR_MEDIA_URL}
                display={"flex"}
                alignItems={"center"}
                gridGap={"0.5rem"}
                bgcolor={"#dfddea"}
                borderRadius={"8px"}
                width={"100%"}
                pl={"0.5rem"}
              >
                {/* Image/video */}
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius={"12px"}
                  style={{ cursor: "pointer" }}
                  width={"25%"}
                >
                  <>{(mediaItemObj as IGalleryImage)?.imageName ? (
                    <img
                      height={"70px"}
                      width={"150px"}
                      src={CURR_MEDIA_URL}
                      style={{ 
                        objectFit: "cover", 
                        borderRadius: "12px",
                        border: "1px solid black",
                      }}
                      alt={(mediaItemObj as IGalleryImage)?.imageName || mediaItemObj?.file?.fileName || `media-${index}`}
                    />
                  ): (
                    <video
                      height={"70px"}
                      width={"auto"}
                      poster={VIDEO_THUMBNAIL_IMG_SRC}
                      style={{
                        aspectRatio: "16/9",
                        padding: "0",
                        margin: "0",
                        objectFit: "cover", 
                        borderRadius: "8px",
                        border: "1px solid black",
                      }}
                    />
                  )}</>
                </Box>

                {/* Card Content */}
                <Box
                  p={"1rem"}
                  gridGap={"0.5rem"}
                  width={"100%"}
                  borderRadius={"0 8px 8px 0"}
                >
                  <Typography variant={"subtitle2"}>
                    Name: 
                    <>
                      {(mediaItemObj as ICompanyVideo)?.name
                        || (mediaItemObj as IGalleryImage)?.imageName
                        || mediaItemObj?.file?.fileName || "N/A"
                      }
                    </>
                  </Typography>
                  {mediaItemObj?.caption && (
                    <Typography variant={"subtitle2"}>
                      Caption: {mediaItemObj?.caption}
                    </Typography>
                  )}
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    gridGap={"0.5rem"}
                  >
                    <Button
                      size={"small"}
                      variant={"outlined"}
                      onClick={() => {
                        if (CURR_MEDIA_URL) window.open(CURR_MEDIA_URL, "_blank");
                      }}
                      startIcon={<VisibilityIcon />}
                    >
                      View
                    </Button>
                    {isCurrMediaItemAlreadyPresentInCurrentEntity ? (
                      <Button
                        size={"small"}
                        variant={"contained"}
                        onClick={() => {
                          setIsDataChanged(true)
                          handleRemoveMediaItemFromCurrEntityObj(mediaItemObj);
                        }}
                        startIcon={<RemoveCircleIcon />}
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        size={"small"}
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                          setIsDataChanged(true)
                          handleAddMediaItemToCurrEntityObj(mediaItemObj);
                        }}
                        startIcon={<AddCircleIcon />}
                      >
                        Add
                      </Button>
                    )}
                  </Box>
                </Box>

              </Box>
            );
          })}
        </Box>
        
      </Box>
    </Modal>
  )
}
