import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ClientRequestActions } from "../actions";
import { DropdownContainer, HeaderContainer } from "../ClientRequests.styles";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { companies, selectedCompany, selectedProject, projectNames } = useSelector(
    (state: any) => state.ClientRequestReducer
  );

  useEffect(() => {
    dispatch(ClientRequestActions.getAllCompanies());
  }, [])

  useEffect(() => {
    selectedProject && dispatch(ClientRequestActions.getCompanyProjectNames(selectedCompany?.id));
  }, [selectedCompany])

  return (
    <HeaderContainer>
      {companies && (
        <DropdownContainer bgColor="#215EC9" color="#ffffff">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={companies}
            color="#ffffff"
            sx={{ width: "100%", color: "#ffffff" }}
            value={selectedCompany}
            renderInput={(params) => <TextField {...params} label="Select Company" />}
            onChange={(e, value) => {
              dispatch(ClientRequestActions.setSelectedCompany(value));
              history.push("/client-requests");
            }}
          />
        </DropdownContainer>
      )}
      {selectedProject && (
        <DropdownContainer bgColor="#D4E5FE" color="#000000">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={projectNames}
            color="#000000"
            sx={{ width: "100%", color: "#000000" }}
            value={selectedProject?.label}
            renderInput={(params) => <TextField {...params} label="Select Project" />}
            onChange={(e, opt) => {
              if (opt?.label !== selectedProject?.label) {
                if (opt?.value) {
                  history.push(`/client-project/${opt?.id}`);
                  dispatch(ClientRequestActions.setSelectedProject(opt));
                } else {
                  history.push("/client-requests");
                  dispatch(ClientRequestActions.setSelectedProject(null));
                }
              }
            }}
          />
        </DropdownContainer>
      )}
    </HeaderContainer>
  );
};

export default Header;
