import React, { useEffect, useState } from "react";
import axios from "axios";
import LocalStorage from "../../../../_services/LocalStorage";
import NoAccess from "../../../../components/NoAccess/NoAccess";
import { useStyles } from "../../jobRequestsListing.styles";
import EditIcon from "@material-ui/icons/EditOutlined";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Switch,
} from "@material-ui/core";
import "../../JobRequests.scss";
import { green } from "@mui/material/colors";
import ROLES from "../../../../constants/RolesConstants";
import { getLoggedInUsers } from "../../../../Utils/Storage";
import SnackBarNotification from "../../../../components/SnackBar/SnackBar";
import { getSharableJobLink, checkIsVendorRequest, jobReqDetailRows, rfqDetailRows, checkIfSelectedCompanyIsVenwiz } from "../../jobRequest.helper";
import { RenderJobReqCellValue } from "../../components/RenderJobReqCellValue";
import VendorRequestModal from "../../../../components/RequestModal/VendorRequestModal";
import { useDispatch, useSelector } from "react-redux";
import { IJobRequestData } from "container/JobRequests/JobRequestDetails.interfaces";
import { JobStatusOptions, JOB_STATUS } from "container/JobRequests/Constants/constants";
import { DropdownSelect } from "container/JobRequests/components/JobStatusSelect";
import AntdToast from "components/Toast/AntdToast";
import { ClientRequestActions } from "container/ClientRequests/actions";
import {  checkHasAnyOfTheRole } from "shared/helpers/checkHasAccess";

export const JobOverview = (props: {
  showLoader: boolean;
  setShowLoader: React.Dispatch<boolean>;
  setReloadUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  hasAccess: boolean;
}) => {
  const { showLoader, setShowLoader, setReloadUpdate, hasAccess } = props;
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = React.useState("");
  const user = getLoggedInUsers() ? getLoggedInUsers() : "";
  const [reqModalData, setReqModaldata] = React.useState<any>([]);
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [gstnObj, setGstnObj] = useState({ _id: "", gstnValue: "" });
  const [displayJobReqForm, setDisplayJobReqForm] = React.useState(false);
  const { jobRequestDetails }: { jobRequestDetails: IJobRequestData } = useSelector((state: any) => state.ClientRequestReducer);
  const hasAddedSuggestedVendor = jobRequestDetails?.suggestedGstns?.length;
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state: any) => state.ClientRequestReducer);

  /** All user are considered as Admin User now */
  const adminUser = user;
  const showPublish = checkHasAnyOfTheRole([ROLES.CLIENT_VR_EDIT_PUBLISH, ROLES.CLIENT_PROJECTS]);
  const hasVREditAccess =
    (checkIsVendorRequest(jobRequestDetails?.requestType) || checkIfSelectedCompanyIsVenwiz(selectedCompany)) && checkHasAnyOfTheRole([ROLES.CLIENT_VR_EDIT_PUBLISH]);
  
  const publishGstnToSuggestVendors = async (_id: string, payload: any) => {
    try {
      setShowLoader(true);
      const { status, data }: { status: string; data: any } = await axios.put(
        process.env.REACT_APP_BASE_URL + `/batch-requests/publish/${_id}`,
        payload,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      );
      if (status === "SUCCESS") {
        setReloadUpdate((prev) => !prev);
      } else {
        setReloadUpdate((prev) => !prev);
      }
    } catch (error) {
      setReloadUpdate((prev) => !prev);
    }
    setShowLoader(false);
  };

  const publishGstnToRequest = (_id: string, publishFlag: boolean, jobRequestData: any) => {
    let payload = {
      updateFlag: !publishFlag,
      updatedBy: adminUser.email,
      clientName: jobRequestData.clientName,
      natureOfWork: jobRequestData.natureOfWork,
      clientEmail: jobRequestData.clientEmail,
      jobTitle: jobRequestData?.title,
      jobId: jobRequestData.requestId,
      shareableRequestVendorlink: getSharableJobLink(jobRequestData?.requestId),
      comparisonLink: jobRequestData.suggestedGstns.length>1? jobRequestData.suggestedGstns.map((vendor:any)=> vendor.gstn) :[]
    };
    publishGstnToSuggestVendors(_id, payload);
  };

  const handleAction = () => {
    setDisplayJobReqForm(false);
    setReqModaldata([]);
  };

  const onJobStatusChange = (jobStatus: JOB_STATUS) => {
    /** API - Job Requests status update */
    setShowLoader(true);
    dispatch(ClientRequestActions.updateJobStatus({ requestId: jobRequestDetails?._id, jobStatus }))
      .then((resp: any) => {
        AntdToast({ type: "success", message: "Success", description: "Job status updated" });
        dispatch(ClientRequestActions.getJobRequestData(jobRequestDetails?._id)).then((resp: any) => {
          setShowLoader(false);
        });
      })
      .catch((error: any) => {
        setShowLoader(false);
        AntdToast({ type: "error", message: "Error", description: "Failed to update Job status" });
      });
  };

  if (!hasAccess) {
    return <NoAccess />;
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBarNotification
        openNotification={showSnackBar}
        closeSnackbar={() => {
          setShowSnackBar(false);
          setErrorMsg("");
        }}
        severity={"error"}
        message={errorMsg}
      />
      <Grid direction="column">
        <Grid item direction="row" alignItems="flex-end" justify="flex-end">
          <div
            style={{
              paddingRight: "0.75rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1rem",
              marginBottom: "1rem",
            }}
          >
            {displayJobReqForm && (
              <VendorRequestModal
                data={reqModalData}
                negativeAction={() => {
                  handleAction();
                }}
                positiveAction={() => {
                  handleAction();
                }}
              />
            )}
            <>
              <div style={{ justifySelf: "flex-start", flex: "auto" }}>
                <span>Job Status: </span>
                {hasVREditAccess ? (
                  <DropdownSelect selectedOption={jobRequestDetails?.jobStatus} onSelect={onJobStatusChange} options={JobStatusOptions}/>
                ) : (
                  jobRequestDetails?.jobStatus
                )}
              </div>
              {hasVREditAccess && (
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[200] }}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setDisplayJobReqForm(true);
                    setReqModaldata(jobRequestDetails);
                  }}
                >
                  Edit Job Request
                </Button>
              )}
              <Button
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(getSharableJobLink(jobRequestDetails?.requestId));
                }}
              >
                Sharable Link
              </Button>
              {/* {!!showPublish && jobRequestDetails?.status !== "Pending" && !!hasAddedSuggestedVendor && (
                <Button
                  variant="outlined"
                  endIcon={
                    <Switch
                      checked={jobRequestDetails?.isPublished}
                      onChange={() =>
                        publishGstnToRequest(jobRequestDetails?._id, jobRequestDetails?.isPublished || false, jobRequestDetails)
                      }
                      name="isActive"
                      color="primary"
                    />
                  }
                >
                  Publish
                </Button>
              )} */}
            </>
          </div>
        </Grid>
      </Grid>
      <Paper elevation={2}>
        <TableContainer>
          <Table stickyHeader className={classes.table} aria-label="sticky table">
            <TableHead>
              <TableCell align="left" className={classes.jobRequestProperty}>
                <Typography variant="h5" gutterBottom>
                  Job Request Property
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h5" gutterBottom>
                  Job Request Value
                </Typography>
              </TableCell>
            </TableHead>
            <TableBody>
              {(checkIsVendorRequest(jobRequestDetails?.requestType) ? jobReqDetailRows : rfqDetailRows).map(
                (row, index) => {
                  return (
                    <TableRow key={row.key}>
                      <TableCell scope="row" align="left" className={classes.jobRequestProperty}>
                        {row.label}
                      </TableCell>
                      <TableCell scope="row" align="left" height={50}>
                        <RenderJobReqCellValue
                          jobRequestProperty={row?.key}
                          jobRequestData={jobRequestDetails}
                          gstnObj={gstnObj}
                          setGstnObj={setGstnObj}
                          setErrorMsg={setErrorMsg}
                          setShowSnackBar={setShowSnackBar}
                          setReloadUpdate={setReloadUpdate}
                          setShowLoader={setShowLoader}
                          companyId={selectedCompany?.id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
