import { notification } from "antd";

export const openNotification = (message:string,desc:string,) => {
  notification.open({
    message: message,
    description:
    desc,
    style: {
      marginTop:"80px",
      width: "auto",
    },
  });
};