export const RapidApiStateID = [
  { state_id: 1, state: "Telangana" },
  { state_id: 2, state: "Andhra Pradesh" },
  { state_id: 3, state: "Pondicherry" },
  { state_id: 4, state: "Assam" },
  { state_id: 5, state: "Bihar" },
  { state_id: 6, state: "Chattisgarh" },
  { state_id: 7, state: "Delhi" },
  { state_id: 8, state: "Gujarat" },
  { state_id: 9, state: "Daman & Diu" },
  { state_id: 10, state: "Dadra & Nagar Haveli" },
  { state_id: 11, state: "Haryana" },
  { state_id: 12, state: "Himachal Pradesh" },
  { state_id: 13, state: "Jammu & Kashmir" },
  { state_id: 14, state: "Jharkhand" },
  { state_id: 15, state: "Karnataka" },
  { state_id: 16, state: "Kerala" },
  { state_id: 17, state: "Lakshadweep" },
  { state_id: 18, state: "Madhya Pradesh" },
  { state_id: 19, state: "Maharashtra" },
  { state_id: 20, state: "Goa" },
  { state_id: 21, state: "Manipur" },
  { state_id: 22, state: "Mizoram" },
  { state_id: 23, state: "Nagaland" },
  { state_id: 24, state: "Tripura" },
  { state_id: 25, state: "Arunachal Pradesh" },
  { state_id: 26, state: "Meghalaya" },
  { state_id: 27, state: "Odisha" },
  { state_id: 28, state: "Chandigarh" },
  { state_id: 29, state: "Punjab" },
  { state_id: 30, state: "Rajasthan" },
  { state_id: 31, state: "Tamil Nadu" },
  { state_id: 32, state: "Uttar Pradesh" },
  { state_id: 33, state: "Uttarakhand" },
  { state_id: 34, state: "West Bengal" },
  { state_id: 35, state: "Andaman & Nicobar Islands" },
  { state_id: 36, state: "Sikkim" },
];

export const RFQ_FILES_SUPPORTED_TYPES: Array<string> = [
  "dwg",
  "vnd",
  "dwg",
  "autocad_dwg",
  "jpeg",
  "jpg",
  "png",
  "tiff",
  "bmp",
  "doc",
  "docx",
  "xlsx",
  "xls",
  "txt",
  "pdf",
  "zip",
  "rar",
  "stp",
  "csv",
];

export const MAX_FILE_UPLOAD_SIZE = 20971520; // 20 mb

export enum JOB_INTENT {
  TEST = "test",
  LIVE = "live"
}
export const JobIntentOptions = [{ label: "Test Job", value: JOB_INTENT.TEST }, { label: "Live Job", value: JOB_INTENT.LIVE }];

export const VENWIZ_INTERNAL_USER_EMAILS = [
  "venwiz",
  "yopmail",
] as const;