import React, { useState } from "react";
import { Table, Typography } from "antd";
import {
  ACCEPTED_STATUS,
  JOB_MONTEZATION_TABLE_CONSTANTS,
  PRICING_APPLY_OPTS,
} from "container/JobRequests/Constants/JobMonetizationTable.constants";
import { useDispatch, useSelector } from "react-redux";
import { IJobRequestData } from "container/JobRequests/JobRequestDetails.interfaces";
import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";
import { DropdownSelect } from "container/JobRequests/components/JobStatusSelect";
import { formatTime } from "Utils/DisplayTimeFormat";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { IJobMonetizationRow } from "container/JobRequests/Interfaces/JobRequestInterfaces";
import { useStyles } from "container/JobRequests/jobRequestsListing.styles";
import ROLES from "constants/RolesConstants";
import { checkHasAnyOfTheRole } from "shared/helpers/checkHasAccess";
import { useCheckVendorRemovedByClient } from "container/JobRequests/hooks/useCheckVendorRemovedByClient";
import { VendorNameDisplay } from "container/JobRequests/components/VendorNameDisplay/VendorNameDisplay";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const { Column } = Table;
const { Text } = Typography;

export const JobMonetizationTable = () => {
  const { jobRequestDetails, loading }: { jobRequestDetails: IJobRequestData; loading: boolean } = useSelector(
    (state: any) => state.ClientRequestReducer
  );
  const dispatch = useDispatch();
  const classes = useStyles();

  const hasUpdateAccess = checkHasAnyOfTheRole([ROLES.CLIENT_PROJECTS, ROLES.CLIENT_VR_EDIT_PUBLISH]);

  const columns = Object.keys(JOB_MONTEZATION_TABLE_CONSTANTS);

  const { checkVendorRemovedByClient } = useCheckVendorRemovedByClient();

  const getRows = () => {
    let rows: IJobMonetizationRow[] = [];
    jobRequestDetails?.jobMonetizations.forEach((itr) => {
      const row = {} as IJobMonetizationRow;
      row.vendor = itr?.gstn || itr?.invitedEmail;
      row.leadFeeApplicable = itr?.leadFeeApplicable;
      row.leadFee = itr?.leadFee;
      row.commissionPercent = itr?.commissionPercent;
      row.valueOfPO = itr?.valueOfPO;
      row.accepted = itr?.accepted;
      row.lastContacted = itr?.lastContacted;
      const agreementLength = itr?.agreement?.length || 0;
      row.agreement = itr?.agreement?.[agreementLength - 1] || null;
      row.id = itr._id;
      rows.push(row);
    });
    return rows;
  };

  const getRenderCellValue = (
    keyName: keyof typeof JOB_MONTEZATION_TABLE_CONSTANTS,
    value: any,
    idx: number,
    record: any
  ) => {
    const isAccepted = record?.accepted;
    const isVendorRemoved = checkVendorRemovedByClient(record?.vendor);
    const acceptedBy = record?.agreement?.acceptedBy?.email || "";

    switch (keyName) {
      case "leadFeeApplicable":
        const selectedOption = value ? PRICING_APPLY_OPTS.YES : PRICING_APPLY_OPTS.NO;
        return (
          <DropdownSelect
            selectedOption={selectedOption}
            onSelect={(value) => {
              dispatch(
                ClientRequestActions.updateVendorMonetizationValue({
                  idx,
                  data: { [keyName]: value === PRICING_APPLY_OPTS.YES ? true : false },
                })
              );
            }}
            options={Object.values(PRICING_APPLY_OPTS)}
            disabled={isAccepted || !hasUpdateAccess || isVendorRemoved}
          />
        );

      case "commissionPercent":
      case "valueOfPO":
        return (
          <TextField
            id={keyName}
            label={keyName === "commissionPercent" ? "Percentage" : "INR in Lakhs"}
            variant="outlined"
            onChange={(event) => {
              dispatch(
                ClientRequestActions.updateVendorMonetizationValue({
                  idx,
                  data: { [keyName]: Number(event.target.value) },
                })
              );
            }}
            value={value}
            type="number"
            disabled={(keyName === "commissionPercent" && isAccepted) || !hasUpdateAccess || isVendorRemoved}
          />
        );

      case "accepted":
        return (
          <>{value ? `${ACCEPTED_STATUS.ACCEPTED}${acceptedBy ? ` by ${acceptedBy}` : ""}` : ACCEPTED_STATUS.PENDING}</>
        );

      case "agreement":
        const file = value?.file || null;
        const downloadAgreement = () => {
          /**Below code is to download the pdf directly */
          // fetch(file?.fileUrl).then((response) => {
          //   response.blob().then((blob) => {
          //     let url = window.URL.createObjectURL(blob);
          //     let a = document.createElement("a");
          //     a.href = url;
          //     a.download = file?.name;
          //     a.click();
          //   });
          // });
          window.open(file?.fileUrl, "_blank");
        };
        const generateAgreement = () => {
          dispatch(ClientRequestActions.generateAgreement(record?.id));
        };
        return (
          <Button
            color="primary"
            size="small"
            variant={file ? "text" : "contained"}
            onClick={() => {
              file ? downloadAgreement() : generateAgreement();
            }}
            disabled={!isAccepted}
          >
            {file ? (
              <>
                <img src="/images/document.svg" alt="file" height={"21px"} />
                <span> &nbsp;View</span>
              </>
            ) : (
              "Create"
            )}
          </Button>
        );

      case "sendAgreement":
        return <SendAgreementTo jobMonetization={record} />;

      case "lastContacted":
        return (
          <>
            <div>
              <strong>Call</strong> - {value?.phone ? formatTime(String(value?.phone), "DD/MM/YYYY h:mm:ss A") : ""}
            </div>
            <div>
              <strong>Email</strong> - {value?.email ? formatTime(String(value?.email), "DD/MM/YYYY h:mm:ss A") : ""}
            </div>
          </>
        );

      case "update":
        return (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => {
              const updatedMonetization = jobRequestDetails?.jobMonetizations?.[idx];
              dispatch(ClientRequestActions.updateVendorMonetization(updatedMonetization));
            }}
            disabled={!hasUpdateAccess || isVendorRemoved}
          >
            Update
          </Button>
        );

      case "vendor":
        return VendorNameDisplay(String(value));

      default:
        return <> {String(value) || "-"} </>;
    }
  };

  const SendAgreementTo = (props: { jobMonetization: IJobMonetizationRow }) => {
    const [sendTo, setSendTo] = useState("");
    const { jobMonetization } = props;

    const handleSendAgreementToEmail = () => {
      const emailPayload = {
        sendTo,
        jrCity: jobRequestDetails?.jobLocation?.city,
        jrState: jobRequestDetails?.jobLocation?.state,
        commission: jobMonetization.commissionPercent,
        leadFeeApplicable: jobMonetization.leadFeeApplicable,
        fileName: jobMonetization?.agreement?.file?.name,
        fileUrl: jobMonetization?.agreement?.file?.fileUrl,
        jrId: jobRequestDetails.requestId,
        jobMonetizationId: jobMonetization.id,
        jobId: jobRequestDetails?._id,
      };
      dispatch(ClientRequestActions.sendAgreementToVendor(emailPayload));
    };

    return (
      <>
        <OutlinedInput
          id="outlined-adornment-password"
          value={sendTo}
          onChange={(e) => {
            setSendTo(e?.target?.value);
          }}
          placeholder="Enter email/s, separated by commas."
          endAdornment={
            <span
              onClick={handleSendAgreementToEmail}
              style={{ color: sendTo ? "#215EC9" : "#8D8D8D", cursor: "pointer" }}
            >
              Send
            </span>
          }
          style={{ height: "40px" }}
          disabled={!jobMonetization.accepted}
        />
      </>
    );
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Table
        sticky
        bordered
        pagination={false}
        title={() => <Text strong>Job Monetizations</Text>}
        scroll={{ y: 450 }}
        dataSource={getRows()}
      >
        {columns.map((key: any, idx: number) => {
          const col = JOB_MONTEZATION_TABLE_CONSTANTS[key];
          return (
            <Column
              width={col.width}
              title={col.title}
              dataIndex={col.keyName}
              key={col.keyName}
              align={"center"}
              render={(text, record, index) => getRenderCellValue(col?.keyName, text, index, record)}
            />
          );
        })}
      </Table>
    </>
  );
};
