import React from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TITLE } from "./constant";
import "./VendorFilter.scss";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAutocomplete-tag": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      height: "22px",
      fontSize: "10px",
      maxWidth: "54% !important",
    },
  },
  inputRoot: {
    "& .MuiAutocomplete-input": {
      minWidth: "10px !important",
    },
  },
}));

const AdditionalTagsFilters = (props: any) => {
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  const handleChange = (e: any, selectedOption: any, reason: string, key: string) => {
    props?.handleAdvancedFilter(selectedOption, key);
  };
  return (
    <div className="additional-filter-wrapper">
      <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" wrap="wrap">
        {Object.entries(props.filterOption).map((filter: any, index: number) => {
          const keyName: string = filter[0];
          const options = filter[1];
          return options?.length > 0 ? (
            <Autocomplete
              classes={classes}
              key={`${keyName}` + `${index}`}
              filterOptions={filterOptions}
              multiple
              limitTags={1}
              multiple
              id="size-small-filled-multi"
              size="small"
              options={options}
              disableCloseOnSelect
              value={props?.selectedAdvancedFilter?.[keyName]}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={`${keyName}` + `${option}`}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              style={{
                width: props?.width || "13%",
                margin: "0px 1px 5px 0px",
                // display: options?.length ? "block" : "none",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    `${TITLE?.[props?.titleKey]?.[keyName]}` +
                    `${props?.showTotalTags ? " " + `(` + options?.length + " " + `values)` : ""}`
                  }
                  variant="filled"
                  placeholder=""
                />
              )}
              onChange={(e, value, reason) => handleChange(e, value, reason, keyName)}
            />
          ) : (
            <></>
          );
        })}
      </Grid>
    </div>
  );
};

export default AdditionalTagsFilters;
