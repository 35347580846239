import React, { useEffect, useState, useRef } from "react";
import {
  KeyContactInfoFields,
  KeyManagementInfoFields,
  PeopleDbKeys,
} from "../constants";
import { InputLabel, TextField } from "@material-ui/core";
import { useStyles } from "../VendorUpdatePeopleInfo.styles";
import { scrollToView } from "shared/helpers/scrollToView";

export const EditAddKeyPeople = ({
  chipData,
  data,
  setPeopleInfo,
  type,
  index,
  editable,
}) => {
  const [fields, setFields] = useState([]);
  const [editData, setEditData] = useState({});
  const classes = useStyles();
  const editChipRef = useRef(null);

  const handleFieldChange = (e) => {
    const { id, value } = e.target;
    const editData = { ...data };
    if (type === PeopleDbKeys.keyManagement) {
      editData[type] = editData?.[type]?.map((itr, idx) => {
        if ((itr?._id && itr?._id === chipData?._id) || idx === index) {
          itr[id] = id === "email" || id === "contact" ? value?.trim() : value;
        }
        return itr;
      });
      setEditData(data?.[type]?.[index] || {});
    } else {
      editData.strengths[type] = editData?.strengths?.[type]?.map(
        (itr, idx) => {
          if ((itr?._id && itr?._id === chipData?._id) || idx === index) {
            itr[id] = id === "email" ? value.trim() : value;
          }
          return itr;
        }
      );
      setEditData(editData?.strengths?.[type]?.[index] || {});
    }
    setPeopleInfo(editData);
  };

  useEffect(() => {
    type === PeopleDbKeys.keyManagement
      ? setFields(Object.keys(KeyManagementInfoFields))
      : setFields(Object.keys(KeyContactInfoFields));

    setEditData(chipData);

    return () => setEditData({});
  }, [type, chipData]);

  useEffect(() => {
    scrollToView(editChipRef?.current);
  }, [fields]);

  return (
    <>
      {!editData?.name && !editData?.firstName && (
        <div className="row mt-4">Name is mandatory to add a key person</div>
      )}
      <div className="row row-cols-2 mt-4" ref={editChipRef}>
        {fields.map((field) => (
          <div className="col mb-4" key={field}>
            <InputLabel className={classes.subHeader}>
              {type === PeopleDbKeys.keyManagement
                ? KeyManagementInfoFields[field]
                : KeyContactInfoFields[field]}
            </InputLabel>
            <TextField
              id={field}
              value={editData?.[field] || ""}
              variant="outlined"
              onChange={handleFieldChange}
              fullWidth
              disabled={!editable}
            />
          </div>
        ))}
      </div>
    </>
  );
};
