import { Typography, Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import { zipMultipleFilesAndDownload, zipSingleFileAndDownload } from "../file.handler";
import { IReportFile } from "../Interfaces/JobRequestInterfaces";
import { FileCard } from "./Reports.styles";

const { Text } = Typography;

interface IFileTag {
  docs: IReportFile[];
  type: "ADMIN_REPORTS" | "CLIENT_DOCUMENTS";
}

export const FileTag = (props: IFileTag) => {
  const { docs, type } = props;
  const [isZipping, setIsZipping] = useState(false);
  
  if (!docs || !docs?.length) return <>-</>;

  const handleZipAndDownloadSingleFile = (file: IReportFile) => {
    if (!isZipping) {
      zipSingleFileAndDownload(file, setIsZipping)
    }
  }

  const handleZipAndDownloadAllFiles = () => {
    const FILE_NAME_PREFIX = type === "ADMIN_REPORTS" ? "Reports" : "Documents";
    const zipFileName = `${FILE_NAME_PREFIX}_${moment().format(`DDMMYYYY_hhmmss`)}`;
    if (!isZipping) {
      zipMultipleFilesAndDownload(zipFileName, zipFileName, docs, setIsZipping)
    }
  }
  
  return (
    <>
      {docs.map((file, index) => (
        <>
          {index === 0 && docs.length > 1 && (
            <FileCard 
              color="orange" 
              style={{ margin: "0 0.5rem 0.5rem 0" }} 
              onClick={handleZipAndDownloadAllFiles}
              >
              <Text>Download All</Text>
            </FileCard>
          )}
          <Tooltip title={file?.name}>
            <FileCard 
              color="blue" 
              style={{ margin: "0 0.5rem 0.5rem 0" }} 
              onClick={() => handleZipAndDownloadSingleFile(file)}
              >
              <Text style={{ width: 100 }} ellipsis>
                {file?.name}
              </Text>
            </FileCard>
          </Tooltip>
        </>
      ))}
    </>
  );
};