import { blue, grey, orange, teal } from "@material-ui/core/colors"

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
};

const COLOR_SCHEME = 300;

export const getChipBgColor = (tagObj) => {
  let backgroundColor = "";
  if (tagObj?.newTagFlag === 1 || tagObj?.newTagFlag === true) {
    backgroundColor = tagObj?.manuallyAssigned === 1 ? grey[COLOR_SCHEME] : orange[COLOR_SCHEME];
  } else {
    backgroundColor = tagObj?.manuallyAssigned === 1 ? blue[COLOR_SCHEME] : teal[COLOR_SCHEME];
  }
  return backgroundColor
};

export const TAGS_COLOR_LEGEND = [
  { COLOR_LABEL: "Green", COLOR: teal[COLOR_SCHEME], DESCRIPTION: "Autotag in an existing library" },
  { COLOR_LABEL: "Orange", COLOR: orange[COLOR_SCHEME], DESCRIPTION: "Autotag in an new library" },
  { COLOR_LABEL: "Blue", COLOR: blue[COLOR_SCHEME], DESCRIPTION: "Manual add from the existing library" },
  { COLOR_LABEL: "Grey", COLOR: grey[COLOR_SCHEME], DESCRIPTION: "Manual add to the new library" },
];
