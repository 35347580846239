import { SyntheticEvent, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  DataGridProps,
} from "@material-ui/data-grid";

interface ICustomDataGridProps extends DataGridProps {
  col: GridColDef[];
  rows: any[];
  sortField: string;
  hideExport?: boolean;
  handleEditRowsModelChange?: any;
  pageSize?: number;
  hideCustomToolbar?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      height: "70vh",
      "& .row-height": {
        maxHeight: "252px !important",
      },
      // '& .MuiDataGrid-root .MuiDataGrid-viewport': {
      //   maxHeight: '100% !important'
      // },
      // '& .MuiDataGrid-root *, .MuiDataGrid-root *::before, .MuiDataGrid-root *::after': {
      //   maxHeight: '100% !important'
      // }
    },
  }),
);

const DataGridComp = (props: ICustomDataGridProps) => {
  const {col, rows, columns, handleEditRowsModelChange, ...remainingProps} = props;
  const classes = useStyles();
  const [pageSize, setPageSize] = useState<number>(props?.pageSize || 7);

  function CustomToolbar() {
    return !props?.hideCustomToolbar ? (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {props.hideExport ? null : <GridToolbarExport />}
      </GridToolbarContainer>
    ) : null;
  }

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            {...remainingProps}
            columns={col}
            rows={rows}
            pageSize={pageSize}
            onPageSizeChange={(params) => setPageSize(params.pageSize)}
            components={{
              Toolbar: CustomToolbar,
            }}
            hideFooterSelectedRowCount={true}
            getRowClassName={() => `row-height`}
            onEditRowModelChange={handleEditRowsModelChange}
          />
        </div>
      </div>
    </div>
  );
};
export default DataGridComp;
