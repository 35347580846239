import styled from "styled-components";

interface FlexContainerProps  {
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  width?: string;
  margin?: string;
  padding?: string;
  flexWrap?: string;
}
export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-direction: ${ props => props?.flexDirection };
  align-items: ${ props => props?.alignItems };
  justify-content: ${ props => props?.justifyContent };
  width: ${ props => props?.width };
  margin: ${ props => props?.margin };
  padding: ${ props => props?.padding };
  flex-wrap: ${ props => props?.flexWrap };
`;