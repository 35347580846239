import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientCommunicationActions } from "../actions";
import { MessageTwoTone, setTwoToneColor, StarFilled } from "@ant-design/icons";
import { Divider } from "antd";
import useInterval from "../../../hooks/useInterval";
import ChatBox from "../ChatBox/ChatBox";
import DeleteModal from "../Modal/DeleteModal";
import MessageModal from "../Modal/SentMessageModal";
import ApproveInitiation from "./ApproveInitiation";
import ResendModal from "../Modal/ResendModal";
import { ChatContainer, ChatHeader, RecipientWrapper, RecipientImage, RecipientDetails, RecipientName, RecipientCompany, ShortListedLabel, MessagesWrapper, TextAreaWrapper, TextAreaChat, SendMessageWrapper, CancelButton, TextAreaBttn, NoMessageWrapper, NoMessageText } from "../Chat.styles";
import { ChatSectionProps, SendMessage } from "../Chat.interface";
import OutgoingText from "../Components/OutgoingText";
import IncomingText from "../Components/IncomingText";
import SnackBarNotification from "../../../components/SnackBar/SnackBar";

const ChatSection = (props: ChatSectionProps) => {
  const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const USER_DETAILS = "user";
  const dispatch = useDispatch();
  const [isDeleteIncomingModal, setIsDeleteIncomingModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [onHoverId, setOnHoverId] = useState<number>();
  let scrollEnd: any = document.getElementById("scrollDiv");
  const [isWordSelected, setIsWordSelected] = useState("");
  const [scrollEndRendered, setScrollEndRendered] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(10);
  const [isMultiChatSelected, setIsMultiChatSelected] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessageSentModal, setShowMessageSentModal] = useState(false);
  const [adminId, setAdminId] = useState<any>();
  const [chatInitiate, setChatInitiate] = useState(false);
  const [showFailedMessageModal, setShowFailedMessageModal] = useState(false);
  const [failedData, setFailedData] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const multiChatMessage = (e: any) => {
    setMessage(e.target.value?.trimStart());
  };
  const chatInfo = useSelector(
    (state: any) => state.ClientCommunicationReducers
  );
  const {
    chatHeadData,
    messages,
    hasMoreMessages,
    multiSelectHeadData,
    adminInitiatedChat,
    chatListData,
  } = chatInfo;
  const files = useSelector((state: any) => state.FileOperationReducers);
  const { fileUploadData } = files;
  const vendorIntials = chatHeadData?.recipient_name?.charAt(0);
  const [delay, setDelay] = useState<number>(10000);
  const [messageData, setMessageData] = useState<any>();
  const [onHoverIncoming, setOnHoverIncoming] = useState<number>();
  // ON/OFF
  const [isPlaying, setPlaying] = useState<boolean>(false);
  useInterval(
    () => {
      const queryParam = { sender: "admin", chatId: chatHeadData?.chat_id };

      dispatch(ClientCommunicationActions.getChatMessages(queryParam));
    },
    // Delay in milliseconds or null to stop it
    isPlaying ? delay : null
  );

  useEffect(() => {
    if (chatInitiate) {
      setChatInitiate(false);
      props?.onInitiateChat(false);
    }
  }, [multiSelectHeadData?.length]);

  useEffect(() => {
    const userInfo: any = localStorage.getItem(USER_DETAILS);
    setAdminId(JSON?.parse(userInfo)?._id);
  }, [localStorage]);

  useEffect(() => {
    dispatch(ClientCommunicationActions.setIsScrollingActive(false));
  }, [props.recipient]);

  useEffect(() => {
    setIsMultiChatSelected(props.isMultiChatSelected);
  }, [props.isMultiChatSelected]);

  useEffect(() => {
    if (chatHeadData !== null && chatHeadData?.chat_id) {
      const queryParam = {
        sender: "admin",
        chatId: chatHeadData?.chat_id,
      };
      dispatch(ClientCommunicationActions.getChatMessages(queryParam));
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  }, [chatHeadData]);

  useEffect(() => {
    if (scrollHeight === 0 && hasMoreMessages && !props.isMultiChatSelected) {
      const queryParam = {
        chatId: chatHeadData?.chat_id,
        lastMsgId: messages[0]?.message_id,
        sender: "admin",
      };
      dispatch(ClientCommunicationActions.setIsScrollingActive(true));
      dispatch(ClientCommunicationActions.getOldChatMessages(queryParam)).then(
        () => {
          scrollEnd.scrollTop = scrollEnd.scrollHeight / 10;
        }
      );
    }
  }, [scrollHeight]);

  useEffect(() => {
    if (
      scrollEnd !== null &&
      scrollEnd !== undefined &&
      messages.length &&
      !scrollEndRendered
    ) {
      scrollEnd.scrollTop = scrollEnd?.scrollHeight;
      setScrollEndRendered(true);
    }
    if (
      scrollEndRendered &&
      chatListData?.length &&
      !props.isMultiChatSelected
    ) {
      if (scrollEnd.scrollTop <= scrollEnd.scrollHeight - 10) {
        scrollEnd.scrollTop = scrollEnd?.scrollHeight;
      }
    }
  }, [messages, chatListData]);

  const sendNewMessage = (message: any,file?: any) => {
    const queryParam = { sender: "admin", chatId: chatHeadData?.chat_id };
    const extractLink = message.match(urlRegex);
    const body: SendMessage = {
      message: message,
      from: adminId,
      sender_type: "admin",
      recipient_type: props.recipient,
      message_type:"text",
    };
    if (extractLink?.length) {
      body.message_type = "link";
      body.link = {
        label: extractLink[0],
        value: extractLink[0]?.match("http")
          ? extractLink[0]
          : `https://${extractLink[0]}`,
      };
    }

    if (file) {
      body.message_type = "file";
      body.sharedFiles = [
        {
          name: file?.name,
          type: file?.type,
          sharedFiles: fileUploadData,
        },
      ];
    }

    if (isMultiChatSelected) {
      dispatch(
        ClientCommunicationActions.adminSingleChat({
          chatIds: adminInitiatedChat,
          message: body,
        })
      )
        .then(() => {
          setShowMessageSentModal(true);
          setMessage("");
        })
        .catch((res: any) => {
          setShowFailedMessageModal(true);
          setFailedData(res?.failedChats?.recipients);
        });
    } else {
      dispatch(
        ClientCommunicationActions.adminSingleChat({
          chatIds: [chatHeadData?.chat_id],
          message: body,
        })
      ).then(() => {
        setMessage("");
        dispatch(ClientCommunicationActions.getChatMessages(queryParam)).then(
          () => {
            setScrollEndRendered(false);
          }
        );
        dispatch(ClientCommunicationActions.getSharedFiles(queryParam));
      });
    }
  };

  const multiSelectedNames = () => {
    if (!multiSelectHeadData?.length) {
      return "Select 1 or more person to send this message";
    }
    if (multiSelectHeadData.length > 1) {
      return `${multiSelectHeadData[0]?.firstName}+${
        multiSelectHeadData?.length - 1
      }`;
    }
    return `${multiSelectHeadData[0]?.firstName}`;
  };

  const deleteMessage = (isDeleteForBoth: any) => {
    setShowDeleteModal(false);
    setIsDeleteIncomingModal(false);
    const queryParam = { sender: "admin", chatId: chatHeadData?.chat_id };
    dispatch(
      ClientCommunicationActions.deleteAdminChat({
        communicationType:
          props?.recipient === "vendor" ? "admin_vendor" : "admin_client",
        chat_id: chatHeadData?.chat_id,
        message_id: messageData?.message_id,
        from: messageData?.from,
        lastMessageId: messages[0]?.message_id,
        deleteForBoth: isDeleteForBoth,
      })
    ).then(
      (res: any) => {
        dispatch(ClientCommunicationActions.getChatMessages(queryParam));
        if(messageData.sharedFiles){
         dispatch(ClientCommunicationActions.getSharedFiles(queryParam));
        }
      },
      (err: any) => {
        setShowNotification(true);
        setNotificationMessage(err.message);
      }
    );
  };

  const disableState = () => {
    if (!multiSelectHeadData?.length || !message?.length) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <ChatContainer>
      <SnackBarNotification
        openNotification={showNotification}
        closeSnackbar={() => {
          setShowNotification(false);
        }}
        severity={"error"}
        message={notificationMessage}
      ></SnackBarNotification>
      {showFailedMessageModal && (
        <ResendModal
          data={failedData}
          onClose={() => {
            setShowFailedMessageModal(false);
          }}
        ></ResendModal>
      )}
      {!!showMessageSentModal && (
        <MessageModal
          negativeAction={value => {
            props.onCancel();
            setShowMessageSentModal(false);
          }}
          positiveAction={value => {
            setShowMessageSentModal(false);
          }}
        />
      )}
      {!!showDeleteModal && (
        <DeleteModal
          negativeAction={value => {
            if (value !== "close") {
              //api call
              deleteMessage(true);
            } else {
              setShowDeleteModal(false);
            }
          }}
          positiveAction={value => {
            deleteMessage(false);
          }}
        />
      )}

      {!!isDeleteIncomingModal && (
        <DeleteModal
          singleAction
          negativeAction={value => {
            setIsDeleteIncomingModal(false);
          }}
          positiveAction={value => {
            deleteMessage(false);
          }}
        />
      )}
      {chatHeadData || multiSelectHeadData ? (
        <>
          <ChatHeader>
            <RecipientWrapper>
              <RecipientImage>
                {isMultiChatSelected
                  ? multiSelectHeadData?.[0]?.firstName?.charAt(0)
                  : chatHeadData?.recipient_name?.charAt(0)}
              </RecipientImage>
              <RecipientDetails>
                <RecipientName>
                  {isMultiChatSelected
                    ? multiSelectedNames()
                    : chatHeadData?.recipient_name}
                </RecipientName>
                <RecipientCompany
                >
                  {isMultiChatSelected ? "" : chatHeadData?.companyName}
                </RecipientCompany>
                {chatHeadData?.isShortListed && (
                  <ShortListedLabel>
                    <StarFilled style={{ color: "#FFFFFF", margin: "5px" }} />
                    Shortlisted
                  </ShortListedLabel>
                )}
              </RecipientDetails>
            </RecipientWrapper>
          </ChatHeader>
          <Divider></Divider>

          {!isMultiChatSelected && (
            <MessagesWrapper
              id="scrollDiv"
              onScroll={e => {
                setScrollHeight(e.currentTarget?.scrollTop);
              }}
            >
              {!!messages.length &&
                messages.map((message: any, index: number) =>
                  message.sender_type === "admin" ? (
                    <OutgoingText
                    key={index}
                    message={message}
                    onMouseLeave={() => setOnHoverId(0)}
                    onMouseEnter={() => setOnHoverId(index + 1)}
                    index={index}
                    onHoverId={onHoverId}
                    setMessageData={() => {
                      setMessageData(message);
                    }}
                    setShowDeleteModal={() => {
                      setShowDeleteModal(true);
                    }}
                    clientInitials={'A'}
                  />
                ) : (
                  <IncomingText
                    key={index}
                    message={message}
                    onMouseLeave={() => setOnHoverIncoming(0)}
                    onMouseEnter={() => setOnHoverIncoming(index + 1)}
                    index={index}
                    onHoverIncoming={onHoverIncoming}
                    setMessageData={() => {
                      setMessageData(message);
                    }}
                    setIsDeleteIncomingModal={() => {
                      setIsDeleteIncomingModal(true);
                    }}
                    vendorIntials={vendorIntials}
                  />
                  )
                )}
            </MessagesWrapper>
          )}

          {/* <Swiper
            onHandleSelect={value => {
              setIsWordSelected(value);
            }}
          ></Swiper> */}

          {isMultiChatSelected ? (
            chatInitiate ? (
              <TextAreaWrapper>
                <TextAreaChat
                  value={message}
                  onChange={e => {
                    multiChatMessage(e);
                  }}
                  placeholder="Write your message"
                  style={{ height: "88%" }}
                ></TextAreaChat>
                <SendMessageWrapper>
                  <CancelButton
                    // disabled={!multiSelectHeadData?.length}
                    shape="round"
                    onClick={() => {
                      props.onCancel();
                    }}
                  >
                    CANCEL
                  </CancelButton>
                  <TextAreaBttn
                    disabled={disableState()}
                    shape="round"
                    onClick={() => {
                      sendNewMessage(message);
                      // setIsMultiChatSelected(true);
                    }}
                  >
                    {multiSelectHeadData?.length > 1
                      ? `Send Message to ${multiSelectHeadData?.length} ${props.recipient}'s`
                      : `Send Message to ${props.recipient}`}
                  </TextAreaBttn>
                </SendMessageWrapper>
              </TextAreaWrapper>
            ) : (
              <ApproveInitiation
                initiateAction={(value: boolean) => {
                  setChatInitiate(value);
                  props?.onInitiateChat(value);
                }}
                total={multiSelectHeadData?.length}
              ></ApproveInitiation>
            )
          ) : (
            <ChatBox
              selectedWord={isWordSelected}
              onSend={sendNewMessage}
            ></ChatBox>
          )}
        </>
      ) : (
        <NoMessageWrapper>
          <MessageTwoTone style={{ fontSize: "50px" }} />
          {setTwoToneColor("#215ec9")}
          <NoMessageText>Your Messages will appear here.</NoMessageText>
        </NoMessageWrapper>
      )}
    </ChatContainer>
  );
};

export default ChatSection;

