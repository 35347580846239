import {
  Switch,
  TableBody,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import * as moment from "moment";
import React, { useEffect } from "react";
import VendorFilter from "../../components/VendorFilter/VendorFilter";
import { getUserRole } from "../../Utils/Storage";
import "./UserInvites.scss";
import { useHistory } from "react-router-dom";
import NoAccess from "../NoAccess/NoAccess";
import LocalStorage from "../../_services/LocalStorage";
import ROLES from "../../constants/RolesConstants";
import { formatTime } from "Utils/DisplayTimeFormat";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "92vw",
    maxHeight: "72vh",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {},
  container: {
    maxHeight: "70vh",
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 150,
    height: 40,
  },
  extraWide: {
    width: 250,
    height: 40,
  },
  extraWideXL: {
    width: 350,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const UserInvites = () =>{
  const classes = useStyles();
  const userRole = getUserRole() || null;
  const [startDate, setStartDate] = React.useState(
    moment().subtract(10, "days")
  );
  const [endDate, setEndDate] = React.useState(moment(new Date()));
  const [jobsData, setJobsData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [csvJobsdata, setCsvJobsdata] = React.useState([]);
  const [reloadUpdate, setReloadUpdate] = React.useState(false);
  const history = useHistory();
  const [hasAccess, setHasAccess] =React.useState(true);

  const showExport =
    userRole == ROLES.SUPER_ADMIN || userRole == ROLES.VENDOR_EXPORT || userRole == ROLES.CLIENT_EXPORT;

  const showPublish = userRole === ROLES.SUPER_ADMIN
  const getCsvHeaders = () => {
    return [
      { label: "Invite Date", key: "invitedAt" },
      { label: "Inviter User Type", key: "inviter" },
      { label: "Inviter Name", key: "inviterFullName" },
      { label: "Inviter Email", key: "inviterEmail" },
      { label: "Invite Type", key: "inviteType" },
      { label: "Invite Status", key: "inviteStatus" },
      { label: "Invite Email Status", key: "inviteEmailStatus" },
      { label: "Invitee Type", key: "inviteeType" },
      { label: "Invitee Name", key: "inviteeFullName" },
      { label: "Invitee Company Name", key: "inviteeCompanyName" },
      { label: "Invitee Email", key: "inviteeEmail" },
      { label: "Invitee Contact Number", key: "inviteePhoneNumber" },
    ];
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCsvFileName = (startDate, endDate) => {
    return (
      moment(startDate).format("YYYY-MM-DD") +
      "/" +
      moment(endDate).format("YYYY-MM-DD") +
      "-userinvites.csv"
    );
  };

  const changeInviteStatus = async (id, status) => {
    let payload = {
      invitedToggleStatus: !status,
    };
    const result = await axios.put(
      process.env.REACT_APP_BASE_URL + "/user-invite/" + id,
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    if (result.data.status === "SUCCESS") {
      setReloadUpdate(true);
    } else {
      setReloadUpdate(true);
    }
  };

  useEffect(async () => {
      setOpen(true);
      let params = {
        from: moment(startDate).format("YYYY-MM-DD"),
        to: moment(endDate).format("YYYY-MM-DD"),
      };
      await axios.get(
        process.env.REACT_APP_BASE_URL + "/user-invite/filter",
        {
          params: params,
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      ).then((result) => {
          setOpen(false);
          setJobsData(result?.data?.data || []);
          const csvData = result?.data?.data.map((itr) => {
            return {
              ...itr,
              inviteType: itr.inviteType?.toUpperCase(),
              inviter: itr.inviter?.toUpperCase(),
              inviteStatus: itr.inviteStatus?.toUpperCase(),
            };
        });
        setCsvJobsdata(csvData);
        setReloadUpdate(false);
      })
        .catch((error) => {
          if(error.response.status === 403){
            setHasAccess(false);
          } 
        });
  }, [startDate, endDate, reloadUpdate]);

  const handleFilterChange = (from, to) => {
    setStartDate(from);
    setEndDate(to);
  };

  return (
    hasAccess ? 
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <VendorFilter
        className="filter"
        startDate={startDate}
        endDate={endDate}
        handleFilterChange={handleFilterChange}
        showSearch={false}
        showCsv={showExport}
        csvHeaders={getCsvHeaders(jobsData)}
        csvData={csvJobsdata}
        csvFileName={getCsvFileName(startDate, endDate)}
      ></VendorFilter>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {/* <TableCell align="center">Client Company </TableCell> */}
                <TableCell align="center" style={{ width: "125%" }}>
                  Invite Date&Time
                </TableCell>
                <TableCell align="center">Inviter User Type</TableCell>
                <TableCell align="center">Inviter Name</TableCell>
                <TableCell align="center">Inviter Email</TableCell>
                <TableCell align="center">Invite Type</TableCell>
                <TableCell align="center">Invite Status</TableCell>
                <TableCell align="center">Toggle Invite Status</TableCell>
                <TableCell align="center">Invite Email Status</TableCell>
                <TableCell align="center">Invitee Type</TableCell>
                <TableCell align="center">Invitee Name</TableCell>
                <TableCell align="center">Invitee Company</TableCell>
                <TableCell align="center">Invitee Email</TableCell>
                <TableCell align="center">Invitee Contact</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobsData.map((row) => (
                <TableRow key={row.inviter}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                    align="center"
                    style={{ width: "500px" }}
                  >
                    {formatTime(row?.invitedAt, "YYYY-MM-DD HH:mm:ss", "DD-MM-YYYY hh:mm:ss A")}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                    align="center"
                    style={{ width: "15%" }}
                  >
                    {row?.inviter === "client" ? (
                      <Chip label="CLIENT" className="client_invite_chip" />
                    ) : row?.inviter === "vendor" ? (
                      <Chip label="VENDOR" className="vendor_invite_chip" />
                    ) : null}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.tableCell}
                    style={{ width: "15%" }}
                  >
                    {row?.inviterFullName}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableCell}
                    align="center"
                  >
                    {row?.inviterEmail}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                    align="center"
                    style={{ width: "15%" }}
                  >
                    {row?.inviteType == "resend" ? (
                      <span className="statuses_success">
                        {row?.inviteType?.toUpperCase()}
                      </span>
                    ) : (
                      <span className="status_pending">
                        {row?.inviteType?.toUpperCase()}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                    align="center"
                    style={{ width: "15%" }}
                  >
                    {row?.inviteStatus == "onboarded" ? (
                      <span className="statuses_success">
                        {row?.inviteStatus?.toUpperCase()}
                      </span>
                    ) : (
                      <span className="status_pending">
                        {row?.inviteStatus?.toUpperCase()}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                    align="center"
                  >
                    {showPublish ? (
                      <Switch
                        checked={row?.invitedToggleStatus}
                        onChange={() =>
                          changeInviteStatus(row?._id, row?.invitedToggleStatus)
                        }
                        name="isActive"
                        color="primary"
                      />
                    ) : null}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                  >
                    {row?.emailStatus}

                    {row?.emailStatus == "success" ? (
                      <span className="statuses_success">
                        {row?.emailStatus?.toUpperCase()}
                      </span>
                    ) : (
                      <span className="pending">
                        {row?.emailStatus?.toUpperCase()}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                  >
                    {row?.inviteeType === "client" ? (
                      <Chip label="CLIENT" className="client_invite_chip" />
                    ) : row?.inviteeType === "vendor" ? (
                      <Chip label="VENDOR" className="vendor_invite_chip" />
                    ) : null}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                    align="center"
                  >
                    {row?.inviteeFullName}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                    align="center"
                    style={{ width: "20%" }}
                  >
                    {row?.inviteeCompanyName}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                    align="center"
                    style={{ width: "25%" }}
                  >
                    {row?.inviteeEmail}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.extraWide}
                    align="center"
                  >
                    {row?.inviteePhoneNumber}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={jobsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
    :
    <NoAccess></NoAccess>
  );
}

export default UserInvites;
