import React, { useState } from "react";
import styled from "styled-components";
import { Modal, Button } from "antd";
// import { Button } from "../../Button";

interface ModalProp {
  header?: string;
  body?: React.ReactNode;
  positiveActionText?: string;
  negativeActionText?: string;
  closable?: boolean;
  width?: number;
  positiveAction: () => void;
  negativeAction: (value?: string) => void;
  disabled?: boolean;
  buttonDirectionColumn?: boolean;
  variant?:
    | "danger"
    | "primary"
    | "secondary"
    | "white"
    | "blue"
    | "red"
    | undefined;
  positiveButtonColor?: string;
  positiveButtonBorderColor?: string;
  negativeButtonColor?: string;
  negativeButtonBorderColor?: string;
  maskClosable?: boolean;
  usedFor?: string;
  isFooterAvailable?:boolean;
  showModal?: boolean;
}

const Base = styled.div`
  background-attachment: fixed;
`;

const ModalBox = styled(Modal)<ModalProp>`
  & .ant-modal-content {
    border-radius: 8px;
    width: ${props => (props?.usedFor === "deleteMessage" ? "445px" : "100%")};
    text-align: ${props =>
      props?.usedFor === "deleteMessage" ? "center" : ""};
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.1em;
    padding: 40px; //new css changes to be tested across the application, incase any UI bug, please review.
  }
  & .ant-modal-header {
    border-radius: 60px 60px 0 0;
    border: 0px;
   // padding: 60px 0px 16px 60px; new css changes to be tested across the application, incase any UI bug, please review.
      padding:0; // new css changes to be tested across the application, incase any UI bug, please review.
  }
  & .ant-modal-close {
    margin: 10px;
  }
  & .ant-modal-footer {
    text-align: center;
    border-top: 0px;
    display: flex;
    justify-content: center;
  }
  & .ant-modal-body {
   // padding: 0px 60px 40px 60px;  //new css changes to be tested across the application, incase any UI bug, please review.
    padding: 24px 0;
    color: ${props => props?.theme?.colors?.primary};
    overflow-y: auto;
    line-height: 170%;
    /* padding-bottom: 40px; */  //new css changes to be tested across the application, incase any UI bug, please review.
  }
`;
const Title = styled.div`
  color: #011759;
  font-weight: 600;
  font-size: 20px;
  text-align: start ;
`;

const ButtonWrap = styled.div<ModalProp>`
  display: flex;
  justify-content: space-around;
  width: ${props => (props?.buttonDirectionColumn ? "80%" : "100%")};
  /* height: ${props => (props?.buttonDirectionColumn ? "200px" : "none")}; */ //new css changes to be tested across the application, incase any UI bug, please review.
  flex-direction: ${props => (props?.buttonDirectionColumn ? "column" : "row")};
`;
const DeleteAll = styled(Button)``;
const Delete = styled(Button)``;
function AntdModal(props: ModalProp) {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleConfirm = () => {
    setIsModalVisible(false);
    //callback here
    props?.positiveAction();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = (e?: any) => {
    setIsModalVisible(false);

    props?.negativeAction(e.currentTarget.id);
  };

  return (
    <Base>
      <ModalBox
        {...props}
        centered={true}
        title={props?.header ? <Title>{props?.header}</Title> : null}
        footer={props?.isFooterAvailable?
          <ButtonWrap {...props}>
            {props?.positiveActionText && (
              <DeleteAll
                id="onOk"
                type="primary"
                onClick={handleConfirm}
                // variant={props?.variant ? props?.variant : "tertiary"}
                color={
                  props?.positiveButtonColor
                    ? props?.positiveButtonColor
                    : "white"
                }
                size="middle"
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  lineHeight: "18px",
                  height: "50px",
                  /* identical to box height */
                  background:`${props.positiveButtonBorderColor? props.positiveButtonBorderColor:" #BE000B"}` ,
                  letterSpacing: "0.1em",
                  textTransform: "uppercase",
                  borderColor:`${props.positiveButtonBorderColor? props.positiveButtonBorderColor :" #BE000B"}`,
                  borderRadius: "8px",
                  color: "#FFFFFF"
                }}
                disabled={props?.disabled}
              >
                {props?.positiveActionText}
              </DeleteAll>
            )}
            {props?.negativeActionText && (
              <Delete
                danger
                onClick={handleCancel}
                id="onCancel"
                shape="round"
                // variant={"white"}
                color={
                  props?.negativeButtonColor
                    ? props?.negativeButtonColor
                    : "secondary"
                }
                size="middle"
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: " #BE000B",
                  height: "50px",
                  /* identical to box height */
                  borderColor: "#BE000B",
                  letterSpacing: "0.1em",
                  textTransform: "uppercase",
                  backgroundColor: "#FFFFFF",
                }}
              >
                {props?.negativeActionText}
              </Delete>
            )}
          </ButtonWrap>:<></>
        }
        visible={props?.showModal || isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={props?.maskClosable ? true : false}
        closeIcon={<img src="/images/xCircle.svg" alt="close button"></img>}
        closable={props?.closable}
        width={props?.width}
      >
        {props?.body}
      </ModalBox>
    </Base>
  );
}

export default AntdModal;
