import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import * as _ from "lodash";
import { Backdrop, Switch, Button, CircularProgress, Tooltip, Typography } from "@material-ui/core";
import { DropDown } from "../../components/VendorCapabilitiesUpdate/components/DropDown";
import DataGridComp from "../../components/analytics/client/DataGrid/DataGrid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  prepareTagsData,
  formatEquipTypeComponentTags,
  getInitialStateFromType,
  getColumnsFromType,
  getKeyNameData,
  replaceNewTagType,
  getTagsDropdownOptions,
} from "./Tags_helper";
import LocalStorage from "../../_services/LocalStorage";
import { getUserRole } from "../../Utils/Storage";
import ROLES from "../../constants/RolesConstants";
import { openNotification } from "components/Notification/Notification";
import { Checkbox, Empty, Radio, notification } from "antd";
import { tabTypes, tagsItems } from "./constants";
import { GridEditCellPropsParams } from "@material-ui/data-grid"

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  dropdownCell: { marginTop: "0.4rem", marginLeft: "0.5rem", width: "100%" },
}));

const TagsTab = (props) => {
  const classes = useStyles();
  const [editState, setEditState] = React.useState(0);
  const [dataGridColumn, setDataGridColumn] = React.useState([{}]);
  // console.log({ dataGridColumn });
  const initialState = getInitialStateFromType(props.type);
  const [dataList, setDataList] = React.useState(initialState);
  const [showLoader, setShowLoader] = React.useState(false);
  const [currentChanges, setCurrentChanges] = React.useState({});
  const [reload, setReload] = React.useState(false);
  const userRole = getUserRole()?.split(",") || [];
  const hasAccessForTagsLibraryEdit = 
    userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.TAGS_LIBRARY_EDIT).length > 0;
  /** previous implementation of user-access, commented for future reference */
  // const showExport = userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_EXPORT).length > 0;
  // const showEdit =
  //   userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_EDIT || role === ROLES.VENDOR_PUBLISH)
  //     .length > 0;
  let columns = getColumnsFromType(props.type, hasAccessForTagsLibraryEdit);
  const [dropDownOptions, setDropDownOptions] = useState({ 
    equipmentGroupOptions: [], 
    serviceGroupOptions: [], 
    equipmentFamilyOptions: [],
  });
  /**
   * updates dropDownOptions state variable
   * @param {"equipmentGroupOptions" | "serviceGroupOptions" | "equipmentFamilyOptions"} keyName
   * @param {Array.<string>} value 
   */
  const updateDropDownOptionsState = (keyName, value) => setDropDownOptions(prev => ({ ...prev, [keyName]: value }));

  useEffect(() => {
    /** when ever user updates state(via currentChanges) - Update DataList */
    setDataList((prev) => {
      return prev.map((tagObj) => {
        if (currentChanges?.[tagObj?.id]) {
          return {...tagObj, ...currentChanges?.[tagObj?.id]};
        }
        return tagObj;
      });
    });
  }, [currentChanges])
  
  /** 
   * Updates DataList state to sync UI and payload for POST call
   * @param { GridEditCellPropsParams } params 
   * */
  const handleUpdateNameValuesInDataListState = (params) => {
    if (["equipmentName", "serviceName", "oemName"]?.includes(params?.field)) {
      /** only when 
       * - equipmentName | serviceName | oemName  - is commited with new values 
       * - Update Values in UI Table*/
      setDataList(prev => {
        return prev?.map(dataObj => {
          return dataObj?.id === params?.id
            ? { ...dataObj, [params?.field]: params?.props?.value } // updated Value
            : dataObj;
        });
      });
    }
  };

  /**
   * handler to update payload for save changes
   * @param {object} params -  table cell params
   * @param {object} valueObject - value to be updated
   */
  const handlePreparePayloadForSaveChanges = (params, valueObject) => {
    setCurrentChanges((prev) => ({
      ...prev,
      [params.row.id]: {
        ...prev[params.row.id],
        ...valueObject,
      },
    }));
  };

  const deleteCell = [
    {
      field: "deleteRow",
      width: 120,
      align: "center",
      renderHeader: (params) => {
        return <>{hasAccessForTagsLibraryEdit ? "Delete" : (
          <Tooltip title="Edit Access Unavailable">
            <Typography color="textSecondary">Delete</Typography>
          </Tooltip>
        )}</>
      },
      renderCell: (params) =>
        hasAccessForTagsLibraryEdit && (
          <IconButton aria-label="delete" onClick={() => handelDeleteCell(params.id)}>
            <DeleteIcon />
          </IconButton>
        ),
    },
  ];

  const handleOnChangeNewTagFlag = (params) => {
    setDataList((prev) => prev.map((tagObj) => (params.row.id !== tagObj.id ? tagObj : { ...tagObj, newTagFlag: 0 })));
    /**
     * Ex: Moving the selected single/multiple new-tag(s),
     * from New_OEM_Tag column to OEM_Tags Column
     */
    handlePreparePayloadForSaveChanges(params, { newTagFlag: 0 });
  };

  const newTagToggleCell = [
    {
      field: "newTagFlag",
      headerName: "New Tag Flag",
      width: 200,
      align: "left",
      renderCell: (params) => {
        if (!hasAccessForTagsLibraryEdit) {
          return "";
        }

        return params?.value === 1 ? (
          <Switch value={params.row.newTagFlag} onChange={() => handleOnChangeNewTagFlag(params)} color="primary" />
        ) : (
          <>0</>
        );
      },
    },
  ];

    /**
   * @param {string} value - value from dropdown selection
   * @param {object} params -  table cell params
   * @param {"group1" | "group2" | "group3" | "family1" | "family2"} keyName - keyName of each tag Object
   */
  const handleChangeDropDownValues = (value, params, keyName) => {
    /** updating payload for POST call at Save Changes */
    handlePreparePayloadForSaveChanges(params, { 
      [keyName]: value || "", 
      /** updating previous vendorsList value */
      vendorsList: params?.row?.vendorsList 
    });
  };

  const getGroupDropdownCells = (tagType) => {
    const currentTagType = tagType.toLowerCase();
    if (currentTagType.includes("oem")) {
      // OEM table(s) - does not have dropdown
      return [];
    }

    const GROUPS = { group1: "Group 1", group2: "Group 2" };
    /**
     * @type {"equipment" | "service" | ""}
     */
    let keyName = "";
    if (currentTagType.includes("equipment")) {
      keyName = "equipment";
      GROUPS.group3 = "Group 3";
    } else if (currentTagType.includes("service")) {
      keyName = "service";
    }

    return Object.keys(GROUPS).map((groupNumber) => ({
      field: groupNumber,
      headerName: GROUPS[groupNumber],
      width: 330,
      align: "left",
      renderCell: (params) => <>{params?.row?.[groupNumber]}</>,
      renderEditCell: (params) => {
        const GROUP_NUMBER = groupNumber;
        const getOptions = () => {
          switch (keyName) {
            case "equipment":
              return dropDownOptions.equipmentGroupOptions;
            case "service":
              return dropDownOptions.serviceGroupOptions;
            default:
              return 
          }
        };
        return (
          <div className={classes.dropdownCell}>
            <DropDown
              size={"small"}
              width={310}
              disabled={!hasAccessForTagsLibraryEdit}
              initialValue={params.row[GROUP_NUMBER]}
              options={getOptions()}
              handleOnChange={(e, value) => {
                if (hasAccessForTagsLibraryEdit) handleChangeDropDownValues(value, params, GROUP_NUMBER);
              }}
            />
          </div>
        );
      },
    }));
  };

  const getFamilyDropdownCells = (tagType) => {
    const currentTagType = tagType.toLowerCase();
    if (currentTagType.includes("oem") || currentTagType.includes("service")) {
      // OEM & Service table(s) - does not have family dropdown
      return [];
    }

    const FAMILIES = { family1: "Family 1", family2: "Family 2" };
    return Object.keys(FAMILIES).map((familyKeyName) => ({
      field: familyKeyName,
      headerName: FAMILIES[familyKeyName],
      width: 320,
      align: "left",
      renderCell: (params) => <>{params?.row?.[familyKeyName]}</>,
      renderEditCell: (params) => {
        return (
          <div className={classes.dropdownCell}>
            <DropDown
              width={300}
              size={"small"}
              options={dropDownOptions.equipmentFamilyOptions}
              disabled={!hasAccessForTagsLibraryEdit}
              initialValue={params.row?.[familyKeyName]}
              handleOnChange={(e, value) => {
                if (hasAccessForTagsLibraryEdit) handleChangeDropDownValues(value, params, familyKeyName);
              }}
            />
          </div>
        );
      },
    }));
  };

  columns = [
    ...columns, 
    ...getFamilyDropdownCells(props.type), 
    ...getGroupDropdownCells(props.type), 
    ...newTagToggleCell, 
    ...deleteCell
  ];

  const handelDeleteCell = async (tagId) => {
    setShowLoader(true);
    const payload = { tagId: tagId };
    const result = await axios.post(process.env.REACT_APP_API_URL + `/v1/tags/delete/${tagId}`, payload, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    });
    setReload(true);
    if (result?.data?.status === "SUCCESS") {
      openNotification("", result?.data?.message);
      setReload(false);
    } else {
      openNotification("", result?.data?.message);
      setReload(false);
      setShowLoader(false);
    }
  };

  const handleEditRowsModelChange = React.useCallback(
    (newModel) => {
      let currentChangesCopy = _.cloneDeep(currentChanges);
      if (newModel?.model) {
        const modelKeys = Object.keys(newModel?.model);
        if (modelKeys?.length > 0) {
          modelKeys.forEach((model) => {
            const eachValue = Object.entries(newModel.model[model]);
            if (eachValue.length > 0) {
              const renameKeys = {
                equipmentName: "name",
                serviceName: "name",
                oemName: "name",
              };
              let keyName = eachValue[0][0];
              if (Object.keys(renameKeys).includes(keyName)) keyName = renameKeys[keyName];
              if (currentChangesCopy[model]) {
                if (keyName === "equipmentType" || keyName === "component") {
                  currentChangesCopy[model][keyName] = formatEquipTypeComponentTags(
                    dataList,
                    eachValue[0][1]["value"],
                    keyName,
                    model
                  );
                } else {
                  currentChangesCopy[model][keyName] = eachValue[0][1]["value"];
                }
              } else {
                if (keyName === "equipmentType" || keyName === "component") {
                  currentChangesCopy[model] = {
                    [keyName]: formatEquipTypeComponentTags(dataList, eachValue[0][1]["value"], keyName, model),
                  };
                } else {
                  currentChangesCopy[model] = {
                    [keyName]: eachValue[0][1]["value"],
                  };
                }
              }
            }
          });
        }
      }
      setCurrentChanges(currentChangesCopy);
    },
    [currentChanges, dataList]
  );

  const nameAndSynonymsApiCall = async (payload, id) => {
    const updatedIdDetails = dataList.filter((item) => {
      return item.id === id;
    });
    const currentVendorsList = updatedIdDetails[0]?.vendorsList?.length
      ? updatedIdDetails[0]?.vendorsList?.split(",")
      : [];
    const result = await axios.post(
      process.env.REACT_APP_API_URL + "/v1/tags/vendorsList",
      {
        ...updatedIdDetails[0],
        _id: updatedIdDetails[0]?.id,
        type:tabTypes.includes(props.type)?replaceNewTagType(props.type):props.type,
        name: payload?.name || getKeyNameData(updatedIdDetails[0], props?.type),
        ...payload,
        vendorsList: currentVendorsList,
      },
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    const newVendorList = result?.data?.vendorsList;
    return {
      ...result,
      data: { ...result?.data, vendorsList: [...new Set(newVendorList.concat(currentVendorsList))] },
    };
  };

  const updateTags = async (payload) => {
    setShowLoader(true);
    const result = await axios.post(process.env.REACT_APP_API_URL + "/v1/tags/update", payload, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    });
    setReload(true);
    if (result?.data?.status === "SUCCESS") {
      setReload(false);
      setCurrentChanges({});
      openNotification("", result?.data?.message);
    } else {
      setReload(false);
      setShowLoader(false);
      openNotification("", result?.data?.message);
    }
  };
  const updateNameAndSyn = async () => {
    setShowLoader(true);
    if (Object.keys(currentChanges).length) {
      let updatedList = [];
      for (let index of Object.keys(currentChanges)) {
        updatedList.push(await nameAndSynonymsApiCall(currentChanges[index], index));
      }
      const objData = updatedList.reduce((acc, itr) => {
        acc[itr.data.id] = {
          synonyms: itr?.data?.synonyms,
          group1: itr?.data?.group1,
          group2: itr.data?.group2,
          group3: itr.data?.group3,
          family1: itr?.data?.family1,
          family2: itr.data?.family2,
          newTagFlag: itr.data?.newTagFlag,
          vendorsList: itr.data?.vendorsList,
          type: tabTypes.includes(itr.data?.type)?replaceNewTagType(itr.data?.type):itr.data?.type,
          name: itr.data?.name,
        };
        return acc;
      }, {});
      updateTags(objData);
    }
    setReload(false);
    setShowLoader(false);
  };

  const saveChanges = async () => {
    if (editState === 0) {
      updateNameAndSyn();
    } else {
      const ids = Object.keys(currentChanges);
      const editedData = dataList.filter((item) => {
        return ids.includes(item?.id);
      });
      var object = Object.assign(
        {},
        ...Object.entries({ ...editedData }).map(([a, item]) => ({
          [item.id]: {
            ...currentChanges[item.id],
            name: getKeyNameData(item, props?.type),
            synonyms: item.synonyms,
            vendorsList: currentChanges[item.id].vendorsList?.length
              ? currentChanges[item.id].vendorsList?.split(",")
              : [],
          },
        }))
      );
      updateTags(object);
    }
  };

  useEffect(() => {
    setShowLoader(true);
    const getDataList = async () => {
      await axios
        .get(process.env.REACT_APP_BASE_URL + "/tags/AllField", {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        })
        .then((result) => {
          if (result?.data?.data) {
            const tagsData = prepareTagsData(result?.data?.data, props.type);
            setDataList(tagsData);
          } else {
            setDataList([]);
          }
          setShowLoader(false);
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            props.setAccessStatus(false);
          }
        });
    };
    getDataList();

    /** fetch and inject Drop-down Options in state */
    const propType = props?.type;
    if (hasAccessForTagsLibraryEdit) {
      if (propType.toLowerCase()?.includes("equipment")) {
        getTagsDropdownOptions({
          type: "equipmentGroupOptions",
          successCallback: (res) => { updateDropDownOptionsState("equipmentGroupOptions", res?.data) },
          failureCallback: (err) => { updateDropDownOptionsState("equipmentGroupOptions", []) },
        });
      }
      if (propType.toLowerCase()?.includes("service")) {
        getTagsDropdownOptions({
          type: "serviceGroupOptions",
          successCallback: (res) => { updateDropDownOptionsState("serviceGroupOptions", res?.data) },
          failureCallback: (err) => { updateDropDownOptionsState("serviceGroupOptions", []) },
        });
      }
      /** Dont load family options as OEM does not have drop-down */
      if (!propType.toLowerCase()?.includes("oem")) {
        getTagsDropdownOptions({
          type: "equipmentFamilyOptions",
          successCallback: (res) => { updateDropDownOptionsState("equipmentFamilyOptions", res?.data) },
          failureCallback: (err) => { updateDropDownOptionsState("equipmentFamilyOptions", []) },
        });
      }
    }
  }, [reload]);

  React.useEffect(() => {
    if (editState === 1) {
      setDataGridColumn(
        columns.map((item) => {
          if (tagsItems.includes(item?.field)) {
            return { ...item, editable: false };
          } else {
            if (["newTagFlag"].includes(item?.field)) {
              if (["NewEquipment", "NewService", "NewOEM"].includes(props?.type)) {
                return { ...item, editable: true };
              } else {
                return { ...item, editable: false };
              }
            } else {
              return { ...item, editable: true };
            }
          }
        })
      );
    } else {
      setDataGridColumn(
        columns.map((item) => {
          if (!tagsItems.includes(item?.field)) {
            return { ...item, editable: false };
          } else {
            return item;
          }
        })
      );
    }
  }, [editState]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {hasAccessForTagsLibraryEdit && (
        <>
          <Button
            className="filter-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => hasAccessForTagsLibraryEdit && saveChanges()}
          >
            Save Changes
          </Button>
          <Radio.Group
            onChange={(e) => {
              hasAccessForTagsLibraryEdit && setEditState(e.target.value);
            }}
            value={editState}
          >
            <Radio value={0}>Edit Name/Synonyms</Radio>
            <Radio value={1}>Edit Others</Radio>
          </Radio.Group>
        </>
      )}
      <DataGridComp
        hideExport={!hasAccessForTagsLibraryEdit}
        col={dataGridColumn}
        rows={dataList}
        pageSize={50}
        sortField="createdDate"
        handleEditRowsModelChange={handleEditRowsModelChange}
        onEditCellChangeCommitted={(params) => {
          handleUpdateNameValuesInDataListState(params);
        }}
      />
    </>
  );
};

export default TagsTab;
