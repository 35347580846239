import React, { useState } from "react";
import { IProfileProps } from "./Interfaces/AvatarInterfaces";
import { AvatarBase, ProfileBase } from "./Avatar.styles";

const Profile = ({
  imgSrc,
  data,
  width,
  height,
  bgColor,
  ...rest
}: IProfileProps) => {
  const [isImgSrcBroken, setIsImagSrcBroken] = useState(false);
  return (
    <ProfileBase>
      <AvatarBase
        // src={imgSrc}
        size="large"
        width={width}
        height={height}
        bgcolor={imgSrc?.length && !isImgSrcBroken ? "#FFFFFF" : bgColor}
        fontSize={rest.fontSize}
        fontWeight={rest.fontWeight}
        fontcolor={rest.fontColor}
      >
        {imgSrc?.length && !isImgSrcBroken ? (
          <img
            src={imgSrc}
            alt={"company logo"}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              // currentTarget.src="image_path_here";
              setIsImagSrcBroken(true);
            }}
          />
        ) : (
          data && data.charAt(0)
        )}
      </AvatarBase>
    </ProfileBase>
  );
};

export default Profile;
