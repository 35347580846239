import axios from "axios";
import LocalStorage from "_services/LocalStorage";

export const leadSquaredTrigger = async (gstn: string) => {
  try {
    await axios({
      method: "GET",
      url: process.env.REACT_APP_API_URL + `/v1/trigger/vendorprofile/${gstn}`,
      headers: { authorization: `Bearer ${LocalStorage.get("token")}`},
    })
  } catch (e) {
    console.error("Lead Squared Trigger failed!", e);
  }
}
