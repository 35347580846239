import { Autocomplete, TextField } from "@mui/material";
import AntdModal from "components/Modal/AntdModal/AntdModal";
import { FlexContainer } from "components/utils";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddMore, AddUsersList, AddUserSlNo, ErrorText, ModalBody } from "./AddUserModal.styles";
import { debounce } from "lodash";
import { IAddUserModalProps } from "container/ClientRequests/interfaces/ClientProjectDetails.interface";
import AntdToast from "components/Toast/AntdToast";

export const AddUserModal = (props: IAddUserModalProps) => {
  const NEW_USER_INITIAL_STATE = { searchInput: "", email: "", hasError: false };
  const [addUsers, setAddUsers] = useState<{ searchInput: string; email: string; hasError: boolean }[]>(
    new Array(3).fill(NEW_USER_INITIAL_STATE)
  );
  const [userOptions, setUserOptions] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { selectedProject, selectedProjectDetails } = useSelector((state: any) => state.ClientRequestReducer);

  const [isAddEnabled, setisAddEnabled] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [hasAddUserError, setHasAddUserError] = useState(false);

  const handleOnAdd = () => {
    let hasNoErrorsToAddUsers = true;
    setAddUsers(
      addUsers.map((user) => {
        if (user.searchInput) {
          user.hasError = true;
          setHasAddUserError(true);
          hasNoErrorsToAddUsers = false;
        }
        return user;
      })
    );
    if (hasNoErrorsToAddUsers) {
      const addUsersPayload = {
        id: selectedProject?._id,
        addUser: addUsers.reduce(
          (acc: any, user) => (user.email ? [...acc, { email: user.email, type: props?.addUser?.type }] : acc),
          []
        ),
        projectId: selectedProject?.value,
      };
      dispatch(ClientRequestActions.addOrRemoveUsersFromRoles(addUsersPayload)).then((resp: any) => props?.onClose());
    }
  };

  const getUserListOnUserInput = (input: string) => {
    if (input) {
      setOptionsLoading(true);
      dispatch(ClientRequestActions.getUsersList(input))
        .then((resp: any) => {
          const searchResult =
            resp?.data?.reduce(
              (acc: string[], curr: any) =>
                addUsers.some((user) => user?.email === curr?.email) ||
                selectedProjectDetails?.userRoles?.[props?.addUser?.type]?.some(
                  (user: any) => user?.email === curr?.email
                )
                  ? acc
                  : [...acc, curr?.email],
              []
            ) || [];
          setUserOptions(searchResult);
          setOptionsLoading(false);
        })
        .catch(() => {
          setOptionsLoading(false);
          setUserOptions([]);
        });
    }
  };

  const debouncedUsersOptionHandler = debounce((input) => getUserListOnUserInput(input), 1000);

  useEffect(() => {
    setisAddEnabled(addUsers.some((user) => user.searchInput || user.email));
    if (hasAddUserError) {
      setHasAddUserError(addUsers.some((user) => user.hasError));
    }
  }, [addUsers]);

  const modalBody = () => {
    return (
      <ModalBody>
        {hasAddUserError && (
          <ErrorText>
            <img src="/images/warning.svg" alt="warning"></img> Enter correct format / official domain name
          </ErrorText>
        )}
        <AddUsersList>
          {addUsers.map((newUser, index) => (
            <FlexContainer alignItems="center" margin={"0 6px 12px 0"} key={index}>
              <AddUserSlNo>{index + 1}.</AddUserSlNo>
              <Autocomplete
                disablePortal
                options={userOptions}
                loading={optionsLoading}
                sx={{
                  width: "100%",
                  border: `1px solid ${newUser.hasError ? "#BE000B" : "#D4E5FE"}`,
                  borderRadius: "10px",
                  color: "#011759",
                  background: newUser.hasError ? "rgba(190, 0, 11, 0.05)" : "",
                }}
                renderInput={(params) => <TextField {...params} label="Enter Email Address" />}
                onInputChange={(e, value) => {
                  debouncedUsersOptionHandler(value?.toLowerCase());
                  if (userOptions?.length > 1) {
                    setAddUsers((addUsers) =>
                      addUsers.map((user, idx) => (idx === index ? { ...user, searchInput: value || "" } : user))
                    );
                  }
                }}
                onChange={(e, value) => {
                  if (value) {
                    debouncedUsersOptionHandler(""); //to avoid an API call as there will be onInputChange
                    setAddUsers((addUsers) =>
                      addUsers.map((user, idx) =>
                        idx === index ? { searchInput: "", email: value, hasError: false } : user
                      )
                    );
                  }
                }}
                onBlur={() => setUserOptions([])}
              />
            </FlexContainer>
          ))}
        </AddUsersList>
        <AddMore onClick={() => setAddUsers([...addUsers, NEW_USER_INITIAL_STATE])}>+ Add More</AddMore>
      </ModalBody>
    );
  };

  return (
    <AntdModal
      usedFor="deleteMessage"
      variant={"white"}
      positiveButtonColor={"#53DB79"}
      positiveButtonBorderColor={isAddEnabled ? "#215EC9" : "#B7C8E6"}
      disabled={!isAddEnabled}
      buttonDirectionColumn
      positiveActionText={"ADD"}
      isFooterAvailable={true}
      header={`Add ${props?.addUser?.name}`}
      positiveAction={handleOnAdd}
      closable={true}
      body={modalBody()}
      negativeAction={() => props.onClose()}
      maskClosable
      showModal={props?.showModal}
    />
  );
};
