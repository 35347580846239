import { IGridState } from "./vendorVerification.interface";

export const vendorVerifInitialState: IGridState = {
  experience: [{
    verificationStatus: "",
    value: "",
    note: "",
  }],
  epf: [{
    verificationStatus: "",
    value: "",
    note: "",
  }],
  lin: [{
    verificationStatus: "",
    value: "",
    note: "",
  }],
  tan: [{
    verificationStatus: "",
    value: "",
    note: "",
  }],
  esic: [{
    verificationStatus: "",
    value: "",
    note: "",
  }],
  urn: [{
    verificationStatus: "",
    value: "",
    note: "",
  }],
  oem: [{
    verificationStatus: "",
    value: "",
    note: "",
  }],
  technicalCert: [{
    verificationStatus: "",
    value: "",
    note: "",
  }],
  gst: {
    verificationStatus: "",
    value: "",
    note: "",
  },
  turnOver: {
    verificationStatus: "",
    value: "",
    note: "",
  },
  yoc: {
    verificationStatus: "",
    value: "",
    note: "",
  },
  pan: {
    verificationStatus: "",
    value: "",
    note: "",
  },
  cin: {
    verificationStatus: "",
    value: "",
    note: "",
  },
  address: {
    verificationStatus: "",
    value: "",
    note: "",
  },
  bankDetails: {
    verificationStatus: "",
    value: "",
    note: "",
  },
};

export const verificationConstants: {
  [key: string]: { fieldName: string; verifCategory: string };
} = {
  experience: {
    fieldName: "Experience",
    verifCategory: "Business Profile",
  },
  epf: {
    fieldName: "EPF",
    verifCategory: "Statutory/Regulatory",
  },
  lin: {
    fieldName: "LIN",
    verifCategory: "Statutory/Regulatory",
  },
  tan: {
    fieldName: "TAN",
    verifCategory: "Statutory/Regulatory",
  },
  gst: {
    fieldName: "GST Verified",
    verifCategory: "Statutory/Regulatory",
  },
  turnOver: {
    fieldName: "Revenue Slab",
    verifCategory: "Business Profile",
  },
  yoc: {
    fieldName: "Year of Incorporation",
    verifCategory: "Business Profile",
  },
  esic: {
    fieldName: "ESIC",
    verifCategory: "Statutory/Regulatory",
  },
  urn: {
    fieldName: "URN/ MSME Registration",
    verifCategory: "Statutory/Regulatory",
  },
  pan: {
    fieldName: "PAN",
    verifCategory: "Statutory/Regulatory",
  },
  cin: {
    fieldName: "CIN",
    verifCategory: "Statutory/Regulatory",
  },
  address: {
    fieldName: "Address",
    verifCategory: "Statutory/Regulatory",
  },
  bankDetails: {
    fieldName: "Bank Details",
    verifCategory: "Statutory/Regulatory",
  },
  oem: {
    fieldName: "OEM",
    verifCategory: "Statutory/Regulatory",
  },
  technicalCert: {
    fieldName: "Technical Certification",
    verifCategory: "Statutory/Regulatory",
  },
};
