import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Label from "../Label/Label";
import styles from "./VendorRequestModal.module.scss";
import { Input, DatePicker, Button } from "antd";
import moment from "moment";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { StateSelect } from "../StateSelect/StateSelect";
import { JOB_INTENT, JobIntentOptions, RapidApiStateID, VENWIZ_INTERNAL_USER_EMAILS } from "./constants";
import { ButtonWrap, ModalBox, Title, InputWrap, HeaderContentConatiner } from "./VendorRequestModal.styles";
import ProfileInputHeading from "../Heading/Heading";
import Multiselect from "../Multiselect/Multiselect";
import LogoImage from "../LogoImage/LogoImage";
import { TextBox } from "../TextBox";
import TextArea from "../TextArea/TextArea";
import CardTag from "../CardTag/CardTag";
import { JOB_REQUEST } from "../../container/JobRequests/Constants/constants";
import { handleUploadRFQFiles } from "shared/handlers/jobRequest.handler";
import AntdToast from "components/Toast/AntdToast";
import { IDMSFile } from "container/JobRequests/JobRequestDetails.interfaces";
import { useHistory } from "react-router-dom";
import { bytesToMB } from "container/JobRequests/file.handler";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { getJobTitleFromDescp } from "container/JobRequests/jobRequest.helper";
import { Checkbox } from "@material-ui/core";

interface ModalProps {
  negativeAction: () => void;
  positiveAction: () => void;
  data?: any;
}

const InputStyles = { borderRadius: "8px", color: "#011759", height: "60px" };
const VendorRequestModal = (props: ModalProps) => {
  const [buttonDisabledState, setButtonDisabledState] = useState(true);
  const [fileName, setFileName] = useState("");
  const [clientsEmail, setClientsEmail] = useState("");
  const [tempList, setTempList] = useState<any>([]);
  const [jobDesc, setJobDesc] = useState<string>("");
  const [jobLocationState, setJobLocationState] = useState<string>();
  const [jobLocationCity, setJobLocationCity] = useState<string>("");
  const [requiredCertifications, setRequiredCertifications] = useState<string>("");
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [date, setDate] = useState<any>();
  const [budget, setBudget] = useState<any>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileUploadErrorMsg, setFileUploadErrorMsg] = useState("Max. file size 500MB.");
  const [rfQDocuments, setRfQDocuments] = useState<IDMSFile[]>([]);
  const [version, setVersion] = useState(JOB_REQUEST.VERSION_1);
  const [fileList, setFileList] = useState<any>({});
  const [removeUploadedRfqFiles, setRemoveUploadedRfqFiles] = useState<{ jobId: string; fileNames: string[] }>({
    jobId: "",
    fileNames: [],
  });
  const history = useHistory();
  const [jobTitle, setJobTitle] = useState("");
  const [jobIntent, setJobIntent] = useState<null | JOB_INTENT>();
  const isInternalEmail = VENWIZ_INTERNAL_USER_EMAILS.some((itr) => new RegExp(`${itr}`, "i").test(clientsEmail));

  useEffect(() => {
    if (props?.data?._id) {
      setBudget(props?.data?.budget);
      setJobDesc(props?.data?.description);
      props?.data?.estimatedPOIssueDate && setDate(moment(props?.data?.estimatedPOIssueDate));
      setRequiredCertifications(props?.data?.requiredCertifications);
      setJobLocationCity(props?.data?.jobLocation?.city);
      setJobLocationState(props?.data?.jobLocation?.state);
      setClientsEmail(props?.data?.clientEmail);
      setSearchKeyWord(props?.data?.searchKeyWord);
      setRfQDocuments(props?.data?.jobRfq || []);
      setVersion(props?.data?.version || "");
      setJobTitle(props?.data?.title || getJobTitleFromDescp(props?.data?.description));
      setJobIntent(props?.data?.jobIntent)
    }
  }, [props?.data]);

  useEffect(() => {
    if(!props?.data?._id) isInternalEmail ? setJobIntent(null) : setJobIntent(JOB_INTENT.LIVE);
  }, [clientsEmail]);

  const getFile = (e: any) => {
    const files = e.target.files;
    const newTempList: any = [];
    let newFileList = {};
    const currFileListLength = Object.keys(fileList).length;
    let fileListLength = currFileListLength;
    for (const key of Object.keys(files)) {
      const file = files[key];
      const dupFileFlag = [...tempList, ...rfQDocuments]?.some((item: any) => item.fileName === file.name);
      if (!dupFileFlag) {
        handleBttnDisableState();
        newTempList.push({ fileName: file.name, file });
        newFileList = { ...newFileList, [fileListLength]: file };
        fileListLength += 1;
      } else {
        AntdToast({ type: "error", message: "Error", description: `${file.name} is already uploaded` });
      }
    }

    setTempList((prev: any) => [...prev, ...newTempList]);
    setFileList((prev: any) => ({ ...prev, ...newFileList }));
  };

  const closeTag = (removeFile: string) => {
    handleBttnDisableState();
    let updatedList = {};
    let counter = 0;
    setTempList(tempList.filter((item: any) => item.fileName !== removeFile));
    for (const key of Object.keys(fileList)) {
      if (fileList[key].name !== removeFile) {
        updatedList = { ...updatedList, [counter]: fileList[key] };
        counter++;
      }
    }
    setFileList(updatedList);
  };

  const removeUploadedFile = (removeFile: string) => {
    handleBttnDisableState();
    setRfQDocuments(rfQDocuments.filter((file) => file?.fileName !== removeFile));
    setRemoveUploadedRfqFiles({
      jobId: removeUploadedRfqFiles?.jobId || props?.data?.requestId,
      fileNames: [...removeUploadedRfqFiles.fileNames, removeFile],
    });
  };

  const checkAndUplaodRfqFiles = (requestId: string, id?: string) => {
    if (Object.keys(fileList).length) {
      handleUploadRFQFiles(fileList, requestId, rfQDocuments, dispatch, () => {
        setIsSubmitted(false);
        posJobCreationAction(id);
      });
    } else {
      setIsSubmitted(false);
      posJobCreationAction(id);
    }
  };

  const updatejobReq = async (payload: any) => {
    dispatch(ClientRequestActions.updateJobRequest({ ...props?.data, ...payload }))
      .then(() => {
        if (removeUploadedRfqFiles?.fileNames?.length) {
          dispatch(ClientRequestActions.removeRfqDocs(removeUploadedRfqFiles)).then(() => {
            checkAndUplaodRfqFiles(props?.data?.requestId);
          });
        } else {
          checkAndUplaodRfqFiles(props?.data?.requestId);
        } 
      })
      .catch(() => {
        setIsSubmitted(false);
      });
  };

  const createjobReq = async (payload: any) => {
    dispatch(ClientRequestActions.createVendorRequest(payload))
      .then((res: any) => {
        checkAndUplaodRfqFiles(res?.data?.requestId, res?.data?._id);
      })
      .catch(() => {
        setIsSubmitted(false);
      });
  };

  const handleDispatch = async (payload: any) => {
    props?.data?._id ? updatejobReq(payload) : createjobReq(payload);
  };

  const HandleOk = () => {
    setErrorState(true);
    if (jobDesc.length > 50 && jobLocationState && clientsEmail?.length && jobIntent) {
      setIsSubmitted(true);
      const payload = {
        jobLocation: { city: jobLocationCity, state: jobLocationState },
        description: jobDesc.replaceAll("`", "'"),
        estimatedPOIssueDate: date,
        requiredCertifications: requiredCertifications,
        budget: budget ? (+budget)?.toFixed(2)?.toString() : "",
        clientEmail: clientsEmail,
        title: jobTitle,
        jobIntent,
      };
      handleDispatch(payload);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.negativeAction();
  };

  const hiddenFileInput = useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  function disabledDate(current: any) {
    var today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return current && current < moment(yesterday, "YYYY-MM-DD");
  }

  function myFunction(e: any) {
    const key = e.keyCode || e.which;
    if (key === 109 || key === 107 || key === 189 || key === 187) {
      if (e.preventDefault) e.preventDefault(); //normal browsers
      e.returnValue = false;
    }
  }

  const onInputClick = (event: any) => {
    event.target.value = "";
  };

  const handleBttnDisableState = () => {
    setButtonDisabledState(false);
  };

  const posJobCreationAction = (id?: string) => {
    props.positiveAction();
    id && history.push(`job-requests/${id}`);
  };

  const headerContent = () => {
    return (
      <HeaderContentConatiner style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
        <Title>Request Vendor</Title>
        <div className={styles.headerInputDiv}>
          <input
            placeholder="Enter clients email"
            value={clientsEmail}
            onChange={(e) => {
              setClientsEmail(e.target.value);
            }}
            disabled={props?.data?.clientEmail}
          />
          {clientsEmail.length === 0 && errorState && (
            <div className={styles.flex}>
              <div className={styles.warningIconWrap}>
                <LogoImage altMesg="icon" imageSrc="/images/warning.svg" />
              </div>
              <div className={styles.warningMsg}>Required Field</div>
            </div>
          )}
        </div>
      </HeaderContentConatiner>
    );
  };
  return (
    <ModalBox
      zIndex={10000}
      centered={true}
      maskClosable={false}
      closeIcon={<img src="/images/x_blue_bg.svg" alt="close button"></img>}
      closable
      width={1000}
      visible={isModalVisible}
      onOk={HandleOk}
      onCancel={handleCancel}
      title={headerContent()}
      footer={
        <ButtonWrap>
          <Button
            onClick={HandleOk}
            disabled={isSubmitted || buttonDisabledState}
            loading={isSubmitted}
            style={{
              padding: "14px 96px",
              textTransform: "uppercase",
              fontStyle: "normal",
              fontWeight: 800,
              fontSize: "14px",
              lineHeight: "21px",
              borderRadius: "28px",
              height: "60px",
              backgroundColor: "#47C882",
              color: "#ffffff",
            }}
          >
            Submit
          </Button>
        </ButtonWrap>
      }
    >
      <div className={styles.body}>
        <div className={styles.flexWrap}>
          <div className={styles.wrapper}>
            <div className={styles.elementHeader}>Job Title</div>
            <TextBox
              style={InputStyles}
              value={jobTitle}
              placeholder="Enter Job Title"
              onChange={(e: any) => {
                handleBttnDisableState();
                setJobTitle(e?.target?.value || "");
              }}
            />
          </div>
          <div className={styles.wrapper}>
            <ProfileInputHeading isStar name="Location of job" info="" />
            <div className={styles.flex} style={{ columnGap: "1.25rem" }}>
              <div className={styles.wrapper}>
                <StateSelect
                  value={jobLocationState}
                  options={RapidApiStateID}
                  onChange={(value: any) => {
                    handleBttnDisableState();
                    setJobLocationState(value);
                  }}
                  allowClear={true}
                  placeholder="Select State"
                />
                {!jobLocationState && errorState && (
                  <div className={styles.flex}>
                    <div className={styles.warningIconWrap}>
                      <LogoImage altMesg="icon" imageSrc="/images/warning.svg" />
                    </div>
                    <div className={styles.warningMsg}>Required Field</div>
                  </div>
                )}
              </div>
              <div className={styles.wrapper}>
                <TextBox
                  style={InputStyles}
                  value={jobLocationCity}
                  placeholder="Enter City"
                  onChange={(e) => {
                    handleBttnDisableState();
                    setJobLocationCity(e?.target?.value?.trimStart());
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.textareaWrapper}>
            <ProfileInputHeading isStar name="Detailed job description" info="" />
            <div>
              <TextArea
                onChange={(e) => {
                  handleBttnDisableState();
                  setJobDesc(e?.target?.value?.trimStart());
                }}
                value={jobDesc}
                placeholder="What are you looking for?"
              />
            </div>

            {errorState && jobDesc.length < 50 && (
              <div className={styles.flex}>
                <div className={styles.warningIconWrap}>
                  <LogoImage altMesg="icon" imageSrc="/images/warning.svg" />
                </div>
                <div className={styles.warningMsg}>Please enter minimum 50 characters</div>
              </div>
            )}
          </div>
          <Input.Group style={{ width: "49%", zIndex: 11001 }} compact>
            <div style={{ width: "100%" }}>
              <div className={styles.elementHeader}>Estimated date for PO Issue</div>
              <DatePicker
                popupStyle={{ zIndex: 12000 }}
                value={date}
                suffixIcon={<img src="/images/calendar.svg" alt="calender icon" />}
                disabledDate={disabledDate}
                className={styles.inputStyle}
                style={{ width: "100%", zIndex: 11000 }}
                placeholder="DD/MM/YYYY"
                format={"DD/MM/YYYY"}
                onChange={(date, dateString) => {
                  handleBttnDisableState();
                  const dateValue = moment(dateString, "DD-MM-YYYY");
                  if (dateString?.length) {
                    setDate(moment(dateValue.format()));
                  } else {
                    setDate("");
                  }
                }}
              />
            </div>
          </Input.Group>
          <div className={styles.wrapper}>
            <div className={styles.elementHeader}>Estimated Budget (INR in Lakhs)</div>
            <Input
              value={budget}
              className={styles.inputStyle}
              placeholder="Enter budget"
              type="number"
              onChange={(e: any) => {
                handleBttnDisableState();
                setBudget(e?.target?.value);
              }}
              onKeyDown={myFunction}
            />
          </div>
          <div className={styles.wrapper}>
            <ProfileInputHeading
              isStar={false}
              name="Documents"
              position="bottom"
              info={
                <div>
                  <p>Upload job scope, drawings, photos, RFQ etc</p>
                  <p>
                    Supported file formats: <br />
                    <span className={styles.bold}>CAD</span> (.dwg, .vnd.dwg, .autocad_dwg), <br />
                    <span className={styles.bold}>STEP</span> (.stp),
                    <span className={styles.bold}>Image</span> (.jpeg, .png, .tiff, .bmp) <br />
                    <span className={styles.bold}>Others</span> (.doc, .docx, .xlsx, .xls, .txt, .pdf, .zip, .rar, .csv)
                  </p>
                </div>
              }
            />
            <div className={styles.documentWrapper}>
              <TextBox
                placeholder="Upload Relevant Documents"
                onChange={() => {}}
                disabled
                value={fileName}
                style={InputStyles}
              />
              <Button
                onClick={handleClick}
                style={{
                  borderRadius: "0px 8px 8px 0px",
                  width: "60px",
                  marginTop: "0px",
                  position: "absolute",
                  right: "0",
                  top: "0px",
                  height: "60px",
                  fontSize: "12px",
                  border: "1px solid #D5D9EC",
                  backgroundColor: "rgb(33, 94, 201)",
                }}
              >
                <img src="/images/download.svg" />
              </Button>
            </div>
            <div className={styles.warningWrapper}>
              <div className={styles.iconWrapppr}>
                {fileUploadError ? <LogoImage altMesg="icon" imageSrc="/images/warning.svg" /> : <InfoCircleTwoTone />}
              </div>
              <Label type="warningMessege" text={fileUploadErrorMsg} isError={fileUploadError} />
            </div>
            <div>
              {rfQDocuments?.map((item: any) => (
                <div className={styles.cardWrap}>
                  <CardTag
                    onClose={() => removeUploadedFile(item?.fileName)}
                    cardText={item?.fileName}
                    imgType="document"
                    fileSize={Number(bytesToMB(item?.files?.[0]?.file?.fileSize))}
                    closable
                  />
                </div>
              ))}
              {tempList.map((item: any) => (
                <div className={styles.cardWrap}>
                  <CardTag
                    cardText={item?.fileName}
                    imgType="document"
                    fileSize={Number(bytesToMB(item?.file?.size))}
                    onClose={() => closeTag(item?.fileName)}
                    closable
                  />
                </div>
              ))}
            </div>
            <InputWrap type="file" onChange={getFile} ref={hiddenFileInput} onClick={onInputClick} multiple />
          </div>
          <div className={styles.wrapper}>
            <ProfileInputHeading
              isStar={false}
              name="Required Qualifications/ Certifications"
              info="Mention specific qualifying criteria for the vendors, if any"
            />
            <TextBox
              placeholder="Enter certifications required by Vendor"
              onChange={(e) => {
                handleBttnDisableState();
                setRequiredCertifications(e.target.value);
              }}
              value={requiredCertifications}
              style={InputStyles}
            />
          </div>
          {isInternalEmail && (
            <div>
              <div className={styles.flex} style={{ alignItems: "center" }}>
                <div style={{ color: "#011759", fontWeight: 600, opacity: 0.7 }}>
                  Create as
                  <span>*</span>:
                </div>
                {JobIntentOptions.map((opt) => {
                  return (
                    <div className={styles.flex} style={{ alignItems: "center" }}>
                      <Checkbox
                        checked={jobIntent ? jobIntent === opt.value : false}
                        onChange={(e) => {
                          setJobIntent(opt.value);
                          handleBttnDisableState();
                        }}
                        value={opt.value}
                        style={{
                          color: "#011759",
                        }}
                      />
                      <div>{opt.label}</div>
                    </div>
                  );
                })}
              </div>
              {!jobIntent && errorState && (
                <div className={styles.flex}>
                  <div className={styles.warningIconWrap}>
                    <LogoImage altMesg="icon" imageSrc="/images/warning.svg" />
                  </div>
                  <div className={styles.warningMsg}>Required Field</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </ModalBox>
  );
};

export default VendorRequestModal;
