import axios from "axios";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardContent, CardHeader, makeStyles, Theme, Typography} from '@material-ui/core';
import styled from "styled-components";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from "moment";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../../components/NoAccess/NoAccess";

const MessageWrapper = styled.div`
    background: #f1f1f1;
    text-align: start;
    padding: 10px;
    margin: 12px;`;

const Date= styled.span`
    margin-left:5px;
    font-size:10px;`;

const Sender = styled.span`
    font-size:12px;
`;

const Message = styled.div`
    font-size: 14px;
    margin-bottom:5px;`

const SharedFile = styled.span`
    font-size: 14px;
    border: 1px solid #6697d8;
    border-radius: 8px;
    padding: 5px;
    background: #dcebff;
`;

const useStyles = makeStyles((theme: Theme) => ({
    transparentButton: {
      border: 'none',
      background: 'transparent',
      color: 'blue'
  }
  }));
  

const ClientVendorMessagingActivity = (() => {
  const [messages, setMessages] = React.useState([]);
  const history = useHistory();
  const classes = useStyles();
  const { clientId } = useParams<{ clientId: string }>();
  const { vendorId } = useParams<{ vendorId: string }>();
  const [hasAccess, setHasAccess] =React.useState(true);
  
  useEffect(() => {
      const getClientAndVendorList =  async () =>{
        await axios.get(`${process.env.REACT_APP_BASE_URL}/client-vendor-communication/messages/${clientId}/${vendorId}`,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }).then((result)=>{
          if (result) {
            setMessages(result.data.messages);
          }
          else{
            setMessages([]);
          }
        })
        .catch((error) => {
          if(error.response.status === 403){
            setHasAccess(false);
          } 
        });
      }
      getClientAndVendorList();
  },[]);

  const navigateBackToList = () => {
    const url = sessionStorage.getItem('perviousUrl');
    history.push(url||'');
}

  const getSharedFileUrl = (message: any) => {
    return (
      message["sharedFiles"]?.[0]?.["file"]?.["fileUrl"] ||
      message["sharedFiles"]?.[0]?.["sharedFiles"]?.[0]?.["fileUrl"]
    );
  };

  return (
    hasAccess ?
    <Card>
         <CardHeader
        //  title={`${data['firstName'].toLocaleUpperCase()} ${data.lastName.toLocaleUpperCase()}`} 
        avatar={
          <button className={classes.transparentButton} onClick={() => {
              navigateBackToList()
          }}><ArrowBackIosIcon /></button>
      }>
      </CardHeader>
        <CardContent>
            {!!messages && !!messages.length ?
                messages.map((message, index) => {
                   return ( 
                       <Typography key={index}>
                            <MessageWrapper>
                                <div style={{marginBottom:'5px'}}>
                                   <Sender>{message['sender_type']}</Sender> 
                                   <Date>{moment(message['date']).format("YYYY-MM-DD")}</Date>
                                </div>
                                { message['sharedFiles']?.[0] && 
                                <Message>
                                  <SharedFile>
                                    <a href={getSharedFileUrl(message)} target="_blank" rel="noopener noreferrer">
                                      {message['sharedFiles']?.[0]?.['name']}
                                    </a>
                                  </SharedFile> 
                                </Message>
                                }
                                {message['message'] && 
                                <Message>
                                    {message['message']}
                                </Message>
                                }
                            </MessageWrapper>
                       </Typography>
                  )
                }):
                <div>There are no messages</div>
            }
        </CardContent>
    </Card>
  :
  <NoAccess></NoAccess>
  )
})

export default ClientVendorMessagingActivity;