import Rating from "components/Rating/Rating";
import React from "react";
import { ICustomerReview } from "../interfaces/interfaces";
import {
  CustomerRatingsContainer,
  CustomerRatingsHeadingWrapper,
  CustomerRatingsCard,
  CustomerRatingsCardWrapper,
  CompanyDetailsWrapper,
  RatingWrapper,
  ButtonWrapper,
} from "../VendorReviews.styles";
import CustomerCardEllipsis from "./CustomerCardEllipsis";
import { useDispatch } from "react-redux";
import { VendorProfileActions } from "container/UpdateVendorProfiles/actions";
import Avatar from "components/Avatar/Avatar";

const CustomerReview = (props: ICustomerReview) => {
  const { data } = props;
  const dispatch = useDispatch();

  const renderCardDetails = (item: any) => {
    return (
      <>
        <CompanyDetailsWrapper>
          <Avatar
            imgSrc={item?.clientDetails?.logo}
            data={item?.clientDetails?.company}
            width="26px"
            height="26px"
          />
          <div>{item?.clientDetails?.company}</div>
          <ButtonWrapper
            style={{ marginLeft: "auto" }}
            onClick={(e) => {
              e?.stopPropagation();
              props.onCardClick("edit", item);
            }}
          >
            Edit review
          </ButtonWrapper>
        </CompanyDetailsWrapper>
        <RatingWrapper>
          <Rating
            value={item?.overallRating}
            color={"#FFCB45"}
            allowHalf
            disabledStatus
          ></Rating>
        </RatingWrapper>
        <CustomerCardEllipsis data={item?.overallReview} />
      </>
    );
  };
  return (
    <CustomerRatingsContainer>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ButtonWrapper
          onClick={() => {
            dispatch(
              VendorProfileActions.setOpenReviewFormModal({ status: true })
            );
          }}
        >
          Add Review
        </ButtonWrapper>{" "}
      </div>
      <CustomerRatingsHeadingWrapper>
        <p>
          <div>
            <hr />
          </div>
          Here what clients say about the vendor
        </p>
        <div>Reaching Business with all requirement</div>
      </CustomerRatingsHeadingWrapper>
      <CustomerRatingsCardWrapper>
        {data?.reviews
          ?.filter(
            (review: any) =>
              review?.reviewStatus === "reviewAccepted" || !review?.reviewStatus
          )
          .map((item: any) => {
            return (
              <CustomerRatingsCard
                key={item?._id}
                onClick={() => {
                  props.onCardClick("view", item);
                }}
              >
                {renderCardDetails(item)}
              </CustomerRatingsCard>
            );
          })}
      </CustomerRatingsCardWrapper>
    </CustomerRatingsContainer>
  );
};

export default CustomerReview;
