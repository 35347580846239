
import React from 'react'
import styled from 'styled-components'

interface ImageProps {
  imageSrc?: any,
  altMesg: string,
  logoSize? : string,
  onClick?: (e: any) => void,
  imageType?:any
}

const ImageBase = styled.img<Partial<ImageProps>>`
    width: 100%;
    height: 100%;
`

const LogoImage = ({ imageSrc, altMesg, onClick, logoSize }: ImageProps) => {
  return (
    <ImageBase src={imageSrc} alt={altMesg} onClick={onClick} style={{transform : logoSize}}/>
  )
}

export default LogoImage