import axios, { AxiosResponse } from "axios";
import LocalStorage from "_services/LocalStorage";
import { renderCellExpand } from "../../components/CellExpandPopup/CellExpandPopup";
import { getDateFromUTC } from "../../Utils/DisplayTimeFormat";
import { END_POINTS, NEO4J_END_POINTS } from "./constants";

export const equipmentInitialState = [
  {
    id: 0,
    createdDate: "",
    updatedDate: "",
    equipmentName: "",
    synonyms: "",
    group1: "",
    group2: "",
    group3: "",
    family1: "",
    family2: "",
    vendorsList: "",
    newTagFlag: "",
    manuallyAssigned: "",
  },
];

export const oemInitialState = [
  {
    id: 1,
    createdDate: "",
    updatedDate: "",
    oemName: "",
    synonyms: "",
    group1: "",
    vendorsList: "",
    newTagFlag: "",
    manuallyAssigned: "",
  },
];

export const serviceInitialState = [
  {
    id: 2,
    createdDate: "",
    updatedDate: "",
    serviceName: "",
    synonyms: "",
    group1: "",
    group2: "",
    vendorsList: "",
    newTagFlag: "",
    manuallyAssigned: "",
  },
];

export const oemColumns = (hasAccessForTagsLibraryEdit) => [
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 200,
    align: "left",
    editable: false,
    renderCell: (params) => renderCellExpand({ ...params, value: getDateFromUTC(params?.value) }),
  },
  {
    field: "updatedDate",
    headerName: "Updated Date",
    width: 200,
    align: "left",
    editable: false,
    renderCell: (params) => renderCellExpand({ ...params, value: getDateFromUTC(params?.value) }),
  },
  {
    field: "oemName",
    headerName: "OEM Name",
    width: 200,
    align: "left",
    editable: hasAccessForTagsLibraryEdit,
    renderCell: renderCellExpand,
  },
  {
    field: "vendorsListCount",
    headerName: "Vendor List Count",
    width: 200,
    align: "left",
    editable: false,
    renderCell: renderCellExpand,
  },
  {
    field: "synonyms",
    headerName: "Synonyms",
    width: 200,
    align: "left",
    editable: hasAccessForTagsLibraryEdit,
    renderCell: renderCellExpand,
  },
  {
    field: "group1",
    headerName: "Group",
    width: 200,
    align: "left",
    editable: true,
    renderCell: renderCellExpand,
  },
  {
    field: "vendorsList",
    headerName: "Vendor List",
    width: 200,
    align: "left",
    editable: true,
    renderCell: renderCellExpand,
  },
];

export const EquipmentColumns = (hasAccessForTagsLibraryEdit) => [
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 200,
    align: "left",
    editable: false,
    renderCell: (params) => renderCellExpand({ ...params, value: getDateFromUTC(params?.value) }),
  },
  {
    field: "updatedDate",
    headerName: "Updated Date",
    width: 200,
    align: "left",
    editable: false,
    renderCell: (params) => renderCellExpand({ ...params, value: getDateFromUTC(params?.value) }),
  },
  {
    field: "equipmentName",
    headerName: "Equipment Name",
    width: 200,
    align: "left",
    editable: hasAccessForTagsLibraryEdit,
    renderCell: renderCellExpand,
  },
  {
    field: "vendorsListCount",
    headerName: "Vendor List Count",
    width: 200,
    align: "left",
    editable: false,

    renderCell: renderCellExpand,
  },
  {
    field: "synonyms",
    headerName: "Synonyms",
    width: 200,
    align: "left",
    editable: hasAccessForTagsLibraryEdit,
    renderCell: renderCellExpand,
  },
  {
    field: "vendorsList",
    headerName: "Vendor List",
    width: 200,
    align: "left",
    editable: true,
    renderCell: renderCellExpand,
  },
];

export const serviceColumns = (hasAccessForTagsLibraryEdit) => [
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 200,
    align: "left",
    editable: false,
    renderCell: (params) => renderCellExpand({ ...params, value: getDateFromUTC(params?.value) }),
  },
  {
    field: "updatedDate",
    headerName: "Updated Date",
    width: 200,
    align: "left",
    editable: false,
    renderCell: (params) => renderCellExpand({ ...params, value: getDateFromUTC(params?.value) }),
  },
  {
    field: "serviceName",
    headerName: "Service Name",
    width: 200,
    align: "left",
    editable: hasAccessForTagsLibraryEdit,
    renderCell: renderCellExpand,
  },
  {
    field: "vendorsListCount",
    headerName: "Vendor List Count",
    width: 200,
    align: "left",
    editable: false,

    renderCell: renderCellExpand,
  },
  {
    field: "synonyms",
    headerName: "Synonyms",
    width: 200,
    align: "left",
    editable: hasAccessForTagsLibraryEdit,
    renderCell: renderCellExpand,
  },
  {
    field: "vendorsList",
    headerName: "Vendor List",
    width: 200,
    align: "left",
    editable: true,
    renderCell: renderCellExpand,
  },
];

export const getInitialStateFromType = (propType) => {
  switch (propType) {
    case "Service":
      return serviceInitialState;
    case "NewService":
      return serviceInitialState;

    case "OEM":
      return oemInitialState;
    case "NewOEM":
      return oemInitialState;

    default:
      return equipmentInitialState;
  }
};

export const getColumnsFromType = (propType, hasAccessForTagsLibraryEdit) => {
  switch (propType) {
    case "Service":
      return serviceColumns(hasAccessForTagsLibraryEdit);

    case "NewService":
      return serviceColumns(hasAccessForTagsLibraryEdit);

    case "OEM":
      return oemColumns(hasAccessForTagsLibraryEdit);

    case "NewOEM":
      return oemColumns(hasAccessForTagsLibraryEdit);

    default:
      return EquipmentColumns(hasAccessForTagsLibraryEdit);
  }
};

export const prepareTagsData = (data, type) => {
  let dataList = [];
  const isNewFlag = (field) => field.newTagFlag === 1;
  const oemGroupValue = (field) => field?.group1 || field?.group || "-";
  const isNotNewFlag = (field) => field.newTagFlag === "" || field.newTagFlag === 0;
  const synonymsValue = (field) => {
    if (field?.synonyms === "" || field?.synonyms === "NaN") {
      return "-";
    } else {
      return field?.synonyms;
    }
  };
  /** can only be 0 or 1 */
  const getManuallyAssignedTagValue = (field) => field?.manuallyAssigned ? 1 : 0;

  if (type === "Equipment") {
    data?.forEach((field) => {
      if (field.type === "Equipment" && isNotNewFlag(field)) {
        dataList.push({
          id: field._id,
          createdDate: field.createdAt,
          updatedDate: field?.updatedAt,
          equipmentName: field.name,
          synonyms: synonymsValue(field),
          group1: field.group1,
          group2: field.group2,
          group3: field.group3,
          family1: field?.family1 || "",
          family2: field?.family2 || "",
          newTagFlag: field.newTagFlag,
          manuallyAssigned: getManuallyAssignedTagValue(field),
          vendorsList: field.vendorsList.toString(),
          vendorsListCount: field.vendorsList.length,
          deleteRow: "",
        });
      }
    });
  } else if (type === "OEM") {
    data?.forEach((field) => {
      if (field.type === "OEM" && isNotNewFlag(field)) {
        dataList.push({
          id: field._id,
          createdDate: field.createdAt,
          updatedDate: field?.updatedAt,
          oemName: field.name,
          synonyms: synonymsValue(field),
          group1: oemGroupValue(field),
          newTagFlag: field.newTagFlag,
          manuallyAssigned: getManuallyAssignedTagValue(field),
          vendorsList: field.vendorsList.toString(),
          vendorsListCount: field.vendorsList.length,
          deleteRow: "",
        });
      }
    });
  } else if (type === "Service") {
    data?.forEach((field) => {
      if (field.type === "Service" && isNotNewFlag(field)) {
        dataList.push({
          id: field._id,
          createdDate: field.createdAt,
          updatedDate: field?.updatedAt,
          serviceName: field.name,
          synonyms: synonymsValue(field),
          group1: field.group1,
          group2: field.group2,
          newTagFlag: field.newTagFlag,
          manuallyAssigned: getManuallyAssignedTagValue(field),
          vendorsList: field.vendorsList.toString(),
          vendorsListCount: field.vendorsList.length,
          deleteRow: "",
        });
      }
    });
  } else if (type === "NewEquipment") {
    data?.forEach((field) => {
      if (field.type === "Equipment" && isNewFlag(field)) {
        dataList.push({
          id: field._id,
          createdDate: field.createdAt,
          updatedDate: field?.updatedAt,
          equipmentName: field.name,
          synonyms: synonymsValue(field),
          group1: field.group1,
          group2: field.group2,
          group3: field.group3,
          family1: field?.family1 || "",
          family2: field?.family2 || "",
          newTagFlag: field.newTagFlag,
          manuallyAssigned: getManuallyAssignedTagValue(field),
          vendorsList: field.vendorsList.toString(),
          vendorsListCount: field.vendorsList.length,
          deleteRow: "",
        });
      }
    });
  } else if (type === "NewOEM") {
    data?.forEach((field) => {
      if (field.type === "OEM" && isNewFlag(field)) {
        dataList.push({
          id: field._id,
          createdDate: field.createdAt,
          updatedDate: field?.updatedAt,
          oemName: field.name,
          synonyms: synonymsValue(field),
          group1: oemGroupValue(field),
          newTagFlag: field.newTagFlag,
          manuallyAssigned: getManuallyAssignedTagValue(field),
          vendorsList: field.vendorsList.toString(),
          vendorsListCount: field.vendorsList.length,
          deleteRow: "",
        });
      }
    });
  } else if (type === "NewService") {
    data?.forEach((field) => {
      if (field.type === "Service" && isNewFlag(field)) {
        dataList.push({
          id: field._id,
          createdDate: field.createdAt,
          updatedDate: field?.updatedAt,
          serviceName: field.name,
          synonyms: synonymsValue(field),
          group1: field.group1,
          group2: field.group2,
          newTagFlag: field.newTagFlag,
          manuallyAssigned: getManuallyAssignedTagValue(field),
          vendorsList: field.vendorsList.toString(),
          vendorsListCount: field.vendorsList.length,
          deleteRow: "",
        });
      }
    });
  }

  return dataList;
};

export const formatEquipTypeComponentTags = (dataList, value, type, tagId) => {
  const equipmentTag = dataList.find(({ id }) => id === tagId);
  const currTags = equipmentTag?.[type];
  const editedTags = value?.split(",");
  let updatedTags = [];
  if (editedTags.length >= currTags.length) {
    editedTags.forEach((tag, index) => {
      const name = tag.trim();
      if (name.length > 0) {
        const id = currTags[index]?.id || "";
        updatedTags.push({ id, name, type: "equipmentType" });
      }
    });
  } else if (editedTags.length < currTags.length) {
    updatedTags = editedTags.map((tag) => {
      const name = tag.trim();
      const id = currTags.find((curr) => curr.name === name)?.id;
      return { id, name, type: "equipmentType" };
    });
  }
  return updatedTags;
};

export const getKeyNameData = (data, type) => {
  console.log("tags", data, type)
  if (["Equipment", "NewEquipment"].includes(type)) {
    return data.equipmentName;
  } else if (["OEM", "NewOEM"].includes(type)) {
    return data.oemName;
  } else {
    return data.serviceName;
  }
};

export const replaceNewTagType = (type) => {
  if (["NewEquipment"].includes(type)) {
    return "Equipment";
  } else if (["NewOEM"].includes(type)) {
    return "OEM";
  } else {
    return "Service";
  }
};

/**
 * GET options for specific drop-downs
 * @param {{
 *   type: "equipmentGroupOptions" 
 *    | "serviceGroupOptions" 
 *    | "equipmentFamilyOptions"
 *    | "NEO4J_EQUIPMENT_GROUP_OPTIONS"
 *    | "NEO4J_EQUIPMENT_FAMILY_OPTIONS"
 *    | "NEO4J_EQUIPMENT_TAGS_OPTIONS"
 *    | "NEO4J_SERVICE_GROUP_OPTIONS"
 *    | "NEO4J_SERVICE_TAGS_OPTIONS"
 *    | "NEO4J_OEM_TAGS_OPTIONS"", 
 *   successCallback: (resp: AxiosResponse) => void, 
 *   failureCallback: (err: any) => void,
 * }} 
 */
export const getTagsDropdownOptions = async ({
  type,
  successCallback,
  failureCallback,
}) => {
  let endpoint = "";
  switch (type) {
    case "equipmentGroupOptions":
      endpoint = END_POINTS.TAGS.EQUIPMENT_GROUPS_LIST;
      break;
    case "serviceGroupOptions":
      endpoint = END_POINTS.TAGS.SERVICE_GROUPS_LIST;
      break;
    case "equipmentFamilyOptions":
      endpoint = END_POINTS.TAGS.EQUIPMENT_FAMILY_LIST;
      break;

    /** Neo4j Equipment APIs */
    case "NEO4J_EQUIPMENT_GROUP_OPTIONS":
      endpoint = NEO4J_END_POINTS.EQUIPMENT.GROUPS;
      break;
    case "NEO4J_EQUIPMENT_FAMILY_OPTIONS":
      endpoint = NEO4J_END_POINTS.EQUIPMENT.FAMILIES;
      break;
    case "NEO4J_EQUIPMENT_TAGS_OPTIONS":
      endpoint = NEO4J_END_POINTS.EQUIPMENT.TAGS;
      break;

    /** Neo4j Service APIs */
    case "NEO4J_SERVICE_GROUP_OPTIONS":
      endpoint = NEO4J_END_POINTS.SERVICE.GROUPS;
      break;
    case "NEO4J_SERVICE_TAGS_OPTIONS":
      endpoint = NEO4J_END_POINTS.SERVICE.TAGS;
      break;

    /** Neo4j OEM APIs */
    case "NEO4J_OEM_TAGS_OPTIONS":
      endpoint = NEO4J_END_POINTS.OEM.TAGS;
      break;

    default:
      break;
  }

  if (endpoint) {
    try {
      const resp = await axios({
        method: "GET",
        url: process.env.REACT_APP_API_URL + endpoint,
        headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
      });
      successCallback(resp);
    } catch (err) {
      console.error("Unable to get options", err);
      failureCallback(err);
    }
  }
}