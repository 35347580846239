import React, { ReactNode } from "react";
import Popper from "@material-ui/core/Popper";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: "1px solid",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    toggleButton: {
      cursor: "pointer",
    },
  }),
);

interface ISimplePopper {
  children: JSX.Element | ReactNode;
  toggleButton?: JSX.Element | ReactNode;
  showContentOnHover?: boolean;
}

export const SimplePopper = ({ toggleButton, children, showContentOnHover }: ISimplePopper) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!showContentOnHover) {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    } else {
      // re-setting opened pop-over content box
      setAnchorEl(event.currentTarget);
    }
  };

  const handleOnHover = (e: React.MouseEvent<HTMLElement>) => {
    if (showContentOnHover) {
      if (anchorEl) {
        setAnchorEl(null); // on Mouse Leave
      } else {
        setAnchorEl(e.currentTarget); // on Mouse Enter
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <div
        aria-describedby={id}
        onClick={handleClick}
        className={classes.toggleButton}
        onMouseEnter={handleOnHover}
        onMouseLeave={handleOnHover}
      >
        <>{toggleButton || "Show Content"}</>
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <div className={classes.paper}>{children || "The content of the Popper."}</div>
      </Popper>
    </div>
  );
};
