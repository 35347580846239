export const removeBreakLines = (passage: string) => {
  passage = passage?.replace(/(\r\n|\n|\r)/gm, "") || "";
  return passage?.replace(/['"]+/g, '') ||  "";
}

export const convertFilesArrayToStringsForCSVExport = (files: any[]) => {
  if (files?.length === 1 && Object.keys(files?.[0])?.length === 0) return "";
  return files ? files?.map((fileObj, index) => {
    if (fileObj?.file || fileObj?.documentFile) {
      const FILE_NAME = fileObj?.name || fileObj?.file?.fileName || fileObj?.documentFile?.fileName;
      const FILE_URL = fileObj?.file?.fileUrl || fileObj?.documentFile?.fileUrl || fileObj?.videoUrl;
      return `name${index+1}-> ${FILE_NAME}, url${index+1}-> ${FILE_URL}`;
    } else {
      return `name${index+1}-> ${fileObj?.fileName}, url${index+1}-> ${fileObj?.fileUrl}`;
    }
  }).join(" | ") : "";
};

export const joinArrayOfStrings = (strings: string[]) => Array.isArray(strings) ? strings?.join(" | ") : "";

export const convertObjToString = (obj: {[key in string]: string[]}) => {
  if (!obj) return "";
  let customerTierString = "";
  Object.keys(obj).forEach((keyName) => {
    if (obj?.[keyName] && Array.isArray(obj?.[keyName]) && obj?.[keyName]?.length) { 
      // when value is non-empty-array
      const currString = joinArrayOfStrings(obj?.[keyName]);
      customerTierString += ` ${keyName}: ${currString} || `;
    }
  });
  return removeBreakLines(customerTierString);
}

export const getIndividualRegisteredLocationAsString = (fullAddress: { state: string, address: string }) => {
  if (fullAddress?.address) {
    return `${removeBreakLines(fullAddress?.state)} - ${removeBreakLines(fullAddress?.address)}`;
  }
  return "";
}

export const getStringOfRegisteredLocations = (registeredLocations: { gstn: string, fulladdress: { state: string, address: string }}[]) => {
  if (registeredLocations) {
    return joinArrayOfStrings(registeredLocations.map(locationObj => getIndividualRegisteredLocationAsString(locationObj?.fulladdress)));
  }
  return "";
}
