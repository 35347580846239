import { Tabs } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientCommunicationActions } from "../actions";
import { FileContainer, FileDetails, FileName, FileSharedDate, FileType, FileWrapper, Header, SharedFilesSection } from "../Chat.styles";


const SharedFiles = () => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const chatInfo = useSelector(
    (state: any) => state.ClientCommunicationReducers
  );
  const { chatHeadData, sharedFiles } = chatInfo;

  useEffect(() => {
    if (chatHeadData !== null && chatHeadData?.chat_id) {
      const queryParam = {
        sender: "client",
        chatId: chatHeadData?.chat_id,
      };
      dispatch(ClientCommunicationActions.getSharedFiles(queryParam));
    }
  }, [chatHeadData]);

  const sharedFile = (file: any, date: any) => {
    const getFileType = (fileType: any) => {
      switch (file?.type) {
        case "image/jpg":
        case "image/jpeg":
          return <FileType style={{ background: "#FFB82A" }}>.jpg</FileType>;
        case "image/png":
          return <FileType style={{ background: "#FFB82A" }}>.png</FileType>;
        case "application/pdf":
          return <FileType style={{ background: "#C80A0A" }}>.pdf</FileType>;
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "doc":
        case "docx":
          return <FileType style={{ background: "#2B7CD3" }}>.doc</FileType>;
        // case 'csv':
        //   return (<FileType style={{background:"#54B9A0"}}>.csv</FileType>)
        // case 'application/vnd.ms-excel':
        // case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        //   return (<FileType style={{background:"#54B9A0"}}>.xls</FileType>)
      }
    };

    return (
      <FileContainer
        onClick={() => {
          window.open(file?.file?.fileUrl, "_blank");
        }}
      >
        {getFileType(file?.type)}
        <FileDetails>
          <FileName>{file?.name}</FileName>
          <FileSharedDate>{moment(date).format(" DD-MM-YYYY")}</FileSharedDate>
        </FileDetails>
      </FileContainer>
    );
  };
  const hasSharedFiles =
    sharedFiles?.received?.length || sharedFiles?.sent?.length;
  return (
    <SharedFilesSection
      style={{
        margin: hasSharedFiles ? "0 30px" : "0",
        width: hasSharedFiles ? "40%" : "0",
      }}
    >
      {!!(sharedFiles?.received?.length || sharedFiles?.sent?.length) && (
        <>
          <Header>Shared Files</Header>
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={`Received ( ${sharedFiles?.received?.length} )`}
              key="1"
            >
              <FileWrapper>
                {sharedFiles?.received &&
                  sharedFiles?.received.map((message: any, index: number) => (
                    <React.Fragment key={index}>
                      {sharedFile(message.sharedFiles[0], message.date)}
                    </React.Fragment>
                  ))}
              </FileWrapper>
            </TabPane>
            <TabPane tab={`Sent ( ${sharedFiles?.sent?.length} )`} key="2">
              <FileWrapper>
                {sharedFiles?.sent &&
                  sharedFiles?.sent.map((message: any, index: number) => (
                    <React.Fragment key={index}>
                      {sharedFile(message.sharedFiles[0], message.date)}
                    </React.Fragment>
                  ))}
              </FileWrapper>
            </TabPane>
          </Tabs>
        </>
      )}
    </SharedFilesSection>
  );
};

export default SharedFiles;
