/**
 * FYI: same values are present in vendor-ui dropdown,
 * and category selection is mandatory,
 * if not provided, it will be set to "Other"
 * 
 * and the below order is important
 */
export const GALLERY_MEDIA_CATEGORIES_OPTIONS = [
  { label: "Product", value: "Product" },
  { label: "Factory", value: "Factory" },
  { label: "Other", value: "Other" },
  { label: "Projects", value: "Projects"},
] as const;