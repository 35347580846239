const ROLES = {
    SUPER_ADMIN: 'Super_Admin',
    VENDOR_VIEW: 'Vendor_View',
    VENDOR_EDIT: 'Vendor_Edit',
    VENDOR_EXPORT: 'Vendor_Export',
    VENDOR_PUBLISH: 'Vendor_Publish',
    CLIENT_VIEW: 'Client_View',
    CLIENT_EDIT: 'Client_Edit',
    CLIENT_EXPORT: 'Client_Export',
    CLIENT_PUBLISH: 'Client_Publish',
    VENDOR_CLIENT_COMMUNICATION: 'Communications_View',
    ADMIN_MESSAGING: 'Admin_Messaging',
    AGENT_VENDOR: 'Agent_Vendor',
    TAGS_LIBRARY_EDIT: 'TagsLibrary_Edit',
    CLIENT_PROJECTS:'Client_Projects',
    CLIENT_VR_VIEW_EXPORT: "Client_VendorRequest_View_Export",
    CLIENT_VR_EDIT_PUBLISH: "Client_VendorRequest_Edit_Publish",
};

export default ROLES