import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GridColDef } from '@material-ui/data-grid';
import moment from 'moment';
import React from 'react';
import ROLES from '../../../../../constants/RolesConstants';
import { getFormattedTimeStamp } from '../../../../../Utils/DisplayTimeFormat';
import { getUserRole } from '../../../../../Utils/Storage';
import { renderCellExpand } from '../../../../CellExpandPopup/CellExpandPopup';
import DataGridComp from '../../DataGrid/DataGrid';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    }
  }),
);

interface ViewProfileProps {
    data:any[]
}

const getVendorName = (params: any) => {
    return params.row.results[0];
}

const getValue= (params: any, fieldName: string) => {
  return params.row[fieldName] ? "YES" : "NO";
}
const columns: GridColDef[] = [
    { field: 'date', headerName: 'Time', width: 250, valueGetter: params => getFormattedTimeStamp(params.row.date),
    renderCell: renderCellExpand },
    { field: 'profileViewed', headerName: 'Vendor', width:300, valueGetter: (params) => {return getVendorName(params)},
    renderCell: renderCellExpand },
    { field: 'isShortlisted', headerName: 'Shorlisted', type: 'boolean', width: 150, valueGetter: (params) => {return getValue(params, 'isShortlisted')},
    renderCell: renderCellExpand },
    { field: 'isPhoneNumberViewed', headerName: 'Phone Number Viewed', type: 'boolean', width: 200, valueGetter: (params) => {return getValue(params, 'isPhoneNumberViewed')},
    renderCell: renderCellExpand },
    { field: 'isEmailViewed', headerName: 'Email Viewed', type: 'boolean', width: 150, valueGetter: (params) => {return getValue(params, 'isEmailViewed')},
    renderCell: renderCellExpand},
    { field: 'isBrochureViewed', headerName: 'Brochure Viewed', type: 'boolean', width: 180, valueGetter: (params) => {return getValue(params, 'isBrochureViewed')},
    renderCell: renderCellExpand },
  ];



const ViewProfilePanel = (props: ViewProfileProps) => {
  const classes = useStyles();
  const userRole = getUserRole()?.split(',') || [];
  const [showExport, setShowExport] = React.useState(
    userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_EXPORT).length > 0
  );  

  return (
    <div className={classes.root}>
        {
          <DataGridComp col={columns} rows={props.data} sortField= 'date' hideExport={!showExport}></DataGridComp>
        }
     
    </div>
  );
}

export default ViewProfilePanel;
