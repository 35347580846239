import moment from "moment";
import { Types as AnalyticsTypes } from "./AnalyticsTypes"

const initialState = {
    startDate:moment().subtract(10, "days"),
    endDate: new Date()

};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case AnalyticsTypes.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload      
      };
    case AnalyticsTypes.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload      
      };
      case AnalyticsTypes.RESET_DATE_FILTERS:
        return {
          ...state,
          ...initialState      
        };
    
    default:
      return state;
  }
};