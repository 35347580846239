import { parametersConstants } from "container/UpdateVendorProfiles/components/VendorReviews/constants";

export const isAnyParameterRated = (data: any) => {
  const ratedParameters = parametersConstants.filter((item) => {
    return data?.averageDetailedRating?.[item?.keyName];
  });
  return ratedParameters?.length > 0;
};

export const isAnyParameterReviewed = (data: any) => {
  const ratedParameters = parametersConstants.filter((item) => {
    return data?.[item?.keyName]?.rating || data?.[item?.keyName]?.comment;
  });
  return ratedParameters?.length > 0;
};