import _ from "lodash"

export const mapSource = (source: string) => {
  switch(source){
    case 'client_email': 
    return "Email";
    default: 
    return source ? _.capitalize(source.split('_')[0]) : '-'
  }
}
