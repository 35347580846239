import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const BASE_URL = "https://countriesnow.space/api/v0.1/countries";
const STATES_URL = BASE_URL + "/states";
const CITIES_URL = BASE_URL + "/state/cities";

/**
 * if param is empty => returns states
 *
 * else state param is passed => returns cities
 *
 */
export const getIndianStatesOrCities = async (state?: string) => {
  const country = "India";
  const url = state ? CITIES_URL : STATES_URL;
  const bodyData = state ? { country, state } : { country };

  try {
    const option: AxiosRequestConfig = { method: "POST", url, data: bodyData };
    const resp: AxiosResponse = await axios(option);

    if (resp.status === 200) {
      const respData = resp?.data?.data;
      if (state) {
        // get cities within a state
        const cities = respData as string[];
        return cities;
      } else {
        // get states from India
        const states = respData?.states as { name: string; state_code: string }[];
        return states;
      }
    }
  } catch (err) {
    console.error("unable to get states/cities", err);
    return [];
  }
};
