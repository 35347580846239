import styled from "styled-components";
import { Avatar } from "antd";
import { AvatarBaseProps, IProfileProps } from "./Interfaces/AvatarInterfaces";

export const ProfileBase = styled.div`
  margin-right: 10px;
  border: 1px solid #8d9095;
  border-radius: 50%;
`;

export const AvatarBase = styled(Avatar)<AvatarBaseProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  color: "#215EC9";
  font-weight: "regular";
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;
  line-height: ${(props) => props?.height || "auto"};
  & .ant-avatar-string {
    position: relative;
    left: 0;
    text-align: center;
    line-height: ${(props) => props.height};
  }

  &.ant-avatar-lg {
    display: inline-block;
  }
  img {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    object-fit: contain;
    box-shadow: 3px 3px 1px #ccc;
    -webkit-box-shadow: 3px 3px 1px #ccc;
    -moz-box-shadow: 3px 3px 1px #ccc;
  }
`;
export const Text = styled.div<IProfileProps>`
  font-size: ${(props) => props.fontSize};
  color: "#215EC9";
  font-weight: "regular";
`;
