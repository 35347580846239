import { IgetValidUploadedFiles, IErrors } from "./fileUpload.handler.interface";
import { notification } from "antd";
import { FIVE_HUNDRED_MB_IN_BYTES } from "constants/fileConstants";
import { ArgsProps } from "antd/lib/notification";

export const handleShowNotification = (
  notifType: "success" | "warning",
  autoClose: "AUTO_CLOSE" | "CLICK_CLOSE",
  args: ArgsProps,
) => {
  notification[notifType]({ ...args, placement: "bottomRight", duration: autoClose === "AUTO_CLOSE" ? 4.5 : 0 });
};

const showNotifications = (errors: IErrors, duplicateFiles: string[], supportedFileTypes: string[]) => {
  if (errors.isUnSupportedFileTypeError) {
    handleShowNotification("warning", "AUTO_CLOSE", {
      message: "Unsupported File Type!",
      description: `Supported file types - ${supportedFileTypes.join(", .")}`,
    });
  }
  if (errors.isFileMoreThan50MBError) {
    handleShowNotification("warning", "AUTO_CLOSE", {
      message: "Max File Size!",
      description: "File should be less than 500MB",
    });
  }
  if (errors.isDuplicateFileError) {
    handleShowNotification("warning", "AUTO_CLOSE", {
      message: "File is Already Present",
      description: `${duplicateFiles.join(", ")}`,
    });
  }
};

export const validateUploadedFiles = ({
  newFiles,
  existingFiles,
  supportedFileTypes,
  errCallback,
}: IgetValidUploadedFiles) => {
  /**
   * function checks three types of errors
   * 1. un-supported file types
   * 2. file more than 50mb
   * 3. duplicate files
   */
  const validFiles = [];
  const uniqueFiles: { [key: string]: boolean } = {};
  const duplicateFiles: string[] = [];

  // considering already uploaded(existing) files with unique names
  existingFiles.forEach((fileObj) => {
    uniqueFiles[fileObj?.fileName] = true;
  });

  const errors: IErrors = {
    isDuplicateFileError: false,
    isFileMoreThan50MBError: false,
    isUnSupportedFileTypeError: false,
  };

  for (const file of newFiles) {
    const fileName = file?.name;
    const fileSize = file?.size;
    const isFileNotSupported = !supportedFileTypes.includes(fileName.split(".").pop().toLowerCase());

    // 1. check un-supported file types
    if (isFileNotSupported) {
      errors.isUnSupportedFileTypeError = true;
      continue;
    }

    // 2. fileSize more than 500mb
    if (fileSize >= FIVE_HUNDRED_MB_IN_BYTES) {
      errors.isFileMoreThan50MBError = true;
      continue;
    }

    // 3. check duplicates
    if (uniqueFiles[fileName]) {
      errors.isDuplicateFileError = true;
      duplicateFiles.push(fileName);
      continue; // duplicate found
    } else {
      uniqueFiles[fileName] = true;
    }

    validFiles.push(file); // valid file
  }

  // show notifications
  showNotifications(errors, duplicateFiles, supportedFileTypes);

  // callback runs if any error is present
  if (Object.values(errors).includes(true)) {
    errCallback && errCallback();
  }

  return validFiles;
};
