import {
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  SetloginUser,
  setLogout,
  SetRole,
  SetToken,
} from "../../Utils/Storage";
import SnackBarNotification from "../SnackBar/SnackBar";
import "./Login.scss";
import LocalStorage from "../../_services/LocalStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  subHeader: {
    color: "#66759c",
    fontSize: "14px",
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const [otp, setOtp] = React.useState("");
  const [resetEmail, setResetEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [pwdError, setPwdError] = React.useState(false);
  const [otpError, setOtpError] = React.useState(false);
  const [resetEmailError, setResetEmailError] = React.useState(false);

  const [snackBar, openSnackBar] = React.useState(false);
  const [verifyOtp, openVerifyOtp] = React.useState(false);
  const [otpSentSuccess, setOtpSentSuccess] = React.useState(true);
  const [authenticateOtp, showAuthenticateOtp] = React.useState(false);
  const [showOtp, setShowOtp] = React.useState(false);

  const [newPasswordTab, openNewPasswordTab] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordError, setNewPasswordError] = React.useState(false);
  const [visibleNewPassword, setVisibleNewPassword] = React.useState(false);

  const [notificationMsg, setNotifificationMsg] = React.useState({
    msg: "",
    severity: "error",
  });

  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])((?=.*?[0-9])|(?=.*?[#?!@$%^&*-])).{10,}$/;

  const [resetPwdToken, setResetPwdToken] = React.useState("");

  const authenticate = () => {
    if (email.length === 0 && password.length === 0) {
      setEmailError(true);
      setPwdError(true);
    } else if (email.length === 0) {
      setEmailError(true);
    } else if (password.length === 0) {
      setPwdError(true);
    } else {
      authenticateUser(email, password);
    }
  };

  const resetNewPassword = (email, newPwd) => {
    if (newPwd.length === 0 || !passwordRegex.test(newPwd)) {
      setNewPasswordError(true);
    } else {
      setNewPasswordError(false);
      updatePassword(email, newPwd);
    }
  };

  const updatePassword = async (email, pwd) => {
    let payload = {
      email: email,
      password: pwd,
      resetPwdToken,
    };
    const result = await axios.post(
      process.env.REACT_APP_API_URL + "/v1/authentication/reset-password",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    if (result.status === 201) {
      openSnackBar(true);
      let msg = {
        msg: result.data.message,
        severity: "success",
      };
      setNotifificationMsg(msg);
      openVerifyOtp(false);
      openNewPasswordTab(false);
      setVisibleNewPassword(false);
      setShowOtp(false);
      setShowPassword(false);
    } else if (result.data.status == "ERROR") {
      openSnackBar(true);
      let msg = {
        msg: result.data.message,
        severity: "error",
      };
      setNotifificationMsg(msg);
      openVerifyOtp(false);
      openNewPasswordTab(true);
      setShowOtp(false);
      setShowPassword(false);
    } else {
      openSnackBar(true);
      let msg = {
        msg: "Something Went Wrong",
        severity: "error",
      };
      setNotifificationMsg(msg);
      openVerifyOtp(false);
      openNewPasswordTab(true);
      setShowOtp(false);
      setShowPassword(false);
    }
  };

  const validateOtpProvided = (resetEmail, otp) => {
    if (otp.length === 0) {
      setOtpError(true);
    } else {
      setOtpError(false);
      authenticateResetUser(resetEmail, otp);
    }
  };

  const authenticateResetUser = async (email, otp) => {
    let payload = {
      email: email,
      otp: otp,
    };
    const result = await axios.post(
      process.env.REACT_APP_API_URL + "/v1/authentication/verify-email-otp",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    if (result.status === 201) {
      openSnackBar(true);
      let msg = {
        msg: result.data.message,
        severity: "success",
      };
      setResetPwdToken(result.data?.resetPwdToken);
      setNotifificationMsg(msg);
      openNewPasswordTab(true);
      setVisibleNewPassword(false);
      setShowOtp(false);
      setShowPassword(false);
    } else if (result.data.status == "ERROR") {
      openSnackBar(true);
      let msg = {
        msg: result.data.message,
        severity: "error",
      };
      setNotifificationMsg(msg);
      openNewPasswordTab(false);
      setVisibleNewPassword(false);
      setShowPassword(false);
    } else {
      openSnackBar(true);
      let msg = {
        msg: "Something Went Wrong",
        severity: "error",
      };
      setNotifificationMsg(msg);
      openNewPasswordTab(false);
      setVisibleNewPassword(false);
      setShowPassword(false);
    }
  };

  const getOtpFromEmail = () => {
    if (resetEmail.length === 0) {
      setResetEmailError(true);
    } else {
      getResetOtp(resetEmail);
    }
  };

  const getResetOtp = async (email) => {
    let payload = {
      entity: "email",
      value: email,
    };
    const result = await axios.post(
      process.env.REACT_APP_API_URL + "/v1/authentication/forgot-password",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    console.log("result",result);
    if (result.status === 201) {
      openSnackBar(true);
      let msg = {
        msg: result.data.message,
        severity: "success",
      };
      setNotifificationMsg(msg);
      setOtpSentSuccess(false);
      showAuthenticateOtp(true);
      openNewPasswordTab(false);
      setVisibleNewPassword(false);
      setShowOtp(false);
      setShowPassword(false);
    } else {
      openSnackBar(true);
      let msg = {
        msg: result.data.message,
        severity: "error",
      };
      setNotifificationMsg(msg);
      setOtpSentSuccess(true);
      showAuthenticateOtp(false);
      openNewPasswordTab(false);
      setVisibleNewPassword(false);
      setShowOtp(false);
      setShowPassword(false);
    }
  };

  const openForgotTab = () => {
    openVerifyOtp(true);
  };

  const authenticateUser = async (email, password) => {
    let payload = {
      email: email,
      password: password,
    };
    const result = await axios.post(
      process.env.REACT_APP_API_URL + "/v1/authentication/login",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    );
    if (result.data.status === "SUCCESS") {
      SetloginUser(result.data?.data?.userData);
      SetToken(result.data?.data?.access_token);
      SetRole(result.data?.data?.userData?.roles);
      openSnackBar(true);
      let msg = {
        msg: "Login Successful",
        severity: "success",
      };
      setNotifificationMsg(msg);
      openNewPasswordTab(false);
      setVisibleNewPassword(false);
      setShowOtp(false);
      setShowPassword(false);
      history.replace("/vendors");
    } else {
      openSnackBar(true);
      let msg = {
        msg: "Invalid Credentials",
        severity: "error",
      };
      setNotifificationMsg(msg);
      openNewPasswordTab(false);
      setVisibleNewPassword(false);
      setShowOtp(false);
      setLogout();
    }
  };
  return (
    <div className="login_container container-fluid">
      <SnackBarNotification
        openNotification={snackBar}
        closeSnackbar={() => {
          openSnackBar(false);
        }}
        severity={notificationMsg.severity}
        message={notificationMsg.msg}
      ></SnackBarNotification>
      {newPasswordTab ? (
        <div className="login-card">
          <div className="login-logo mt-4">
            <img
              alt="login_logo"
              className="logo"
              src="images/venwiz.svg"
            ></img>
          </div>
          <div className="content mt-5">
            <form
              className={classes.root}
              noValidate
              autoComplete="new-password"
            >
              <div className="row-12">
                <div className="col">
                  <InputLabel className={classes.subHeader}>
                    Please Enter your new Password
                  </InputLabel>
                  <TextField
                    error={newPasswordError}
                    id="new-password"
                    label="New Password"
                    type={visibleNewPassword ? "text" : "password"}
                    helperText={newPasswordError ? "atleast 10+ characters, a upper & a lower case, a special char or a number required" : ""}
                    variant="outlined"
                    fullWidth
                    value={newPassword || ""}
                    style={{ width: 350 }}
                    onChange={(e) => [
                      setNewPassword(e.target.value),
                      setNewPasswordError(e.target.value.length ? false : true),
                    ]}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setVisibleNewPassword((itr) => !itr);
                            }}
                          >
                            {visibleNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="footer mt-4">
            <Button
              variant="contained"
              className="login-button"
              color="secondary"
              onClick={() => resetNewPassword(resetEmail, newPassword)}
            >
              Reset
            </Button>
          </div>
        </div>
      ) : (
        <div className="login-card">
          <div className="login-logo mt-4">
            <img
              alt="login_logo"
              className="logo"
              src="images/venwiz.svg"
            ></img>
          </div>
          <div className="content mt-5">
            <form
              className={classes.root}
              noValidate
              autoComplete="current-password"
            >
              {verifyOtp ? (
                <Fragment>
                  <div className="mt-4">
                    <InputLabel className={classes.subHeader}>
                      Please Enter your Registered Email
                    </InputLabel>
                    <TextField
                      error={resetEmailError}
                      id="outlined-error-helper-text-otpEmail"
                      label="Registered Email"
                      helperText={
                        resetEmailError ? "Registered Email Required." : ""
                      }
                      variant="outlined"
                      fullWidth
                      value={resetEmail || ""}
                      style={{ width: 350 }}
                      onChange={(e) => [
                        setResetEmail(e.target.value),
                        setResetEmailError(
                          e.target.value.length ? false : true
                        ),
                      ]}
                    />
                  </div>
                  <div className="mt-4">
                    <TextField
                      error={otpError}
                      id="current-password"
                      label="otp"
                      type={showOtp ? "number" : "password"}
                      maxLength="5"
                      helperText={otpError ? "Otp Required." : ""}
                      variant="outlined"
                      disabled={otpSentSuccess}
                      fullWidth
                      value={otp || ""}
                      style={{ width: 350 }}
                      onChange={(e) => [
                        setOtp(e.target.value),
                        setOtpError(e.target.value.length ? false : true),
                      ]}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowOtp((itr) => !itr);
                              }}
                            >
                              {showOtp ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="mt-4">
                    <TextField
                      error={emailError}
                      id="outlined-error-helper-text-email"
                      label="Email"
                      helperText={emailError ? "Email Required." : ""}
                      variant="outlined"
                      fullWidth
                      value={email || ""}
                      style={{ width: 350 }}
                      onChange={(e) => [
                        setEmail(e.target.value),
                        setEmailError(e.target.value.length ? false : true),
                      ]}
                    />
                  </div>
                  <div className="mt-4">
                    <TextField
                      error={pwdError}
                      id="current-password"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      helperText={pwdError ? "Password Required." : ""}
                      variant="outlined"
                      style={{ width: 350 }}
                      value={password || ""}
                      onChange={(e) => [
                        setPassword(e.target.value),
                        setPwdError(e.target.value.length ? false : true),
                      ]}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword((itr) => !itr);
                              }}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Fragment>
              )}
            </form>
          </div>
          {verifyOtp ? (
            <div className="footer mt-4">
              {authenticateOtp ? (
                <Button
                  variant="contained"
                  className="login-button"
                  color="secondary"
                  onClick={() => validateOtpProvided(resetEmail, otp)}
                >
                  Verify
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="login-button"
                  color="secondary"
                  onClick={getOtpFromEmail}
                >
                  Get Otp
                </Button>
              )}
            </div>
          ) : (
            <Fragment>
              <div className="footer mt-4">
                <Button
                  variant="contained"
                  className="login-button"
                  color="secondary"
                  onClick={authenticate}
                >
                  Login
                </Button>
              </div>
              <div className="footer mt-4">
                <span
                  onClick={openForgotTab}
                  style={{
                    cursor: "pointer",
                    color: "#66759c",
                    textDecoration: "underline",
                  }}
                >
                  Forgot Password?
                </span>
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
}
