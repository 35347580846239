import styled from "styled-components";
import { FiltersDropDownProps } from "./FiltersDropDown";

export const SearchAndFilterContainer = styled.div`
  width: 354px;
  margin: 20px 0;
  display: flex;
  @media screen and (min-width: 1920px) {
    width: 420px;
  }
`;

export const FilterIcon = styled.div<Partial<FiltersDropDownProps>>`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || "45px"};
  height: ${(props) => props.height || "40px"};
  background: ${(props) => props.filterBackgroundColor || "#215ec9"};
  border-radius: 8px;
  cursor: pointer;
`;

export const FilterLines = styled.div<Partial<FiltersDropDownProps>>`
  border: 1.5px solid ${(props) => props.filterColor || "#fff"};
  width: 19.5px;
  margin-bottom: ${(props) => props.marginBottom || "3.5px"};
  background: ${(props) => props.filterColor || "#fff"};
  border-radius: 8px;
`;

export const FilterHeader = styled.div`
  margin: 10px 0;
  color: #215ec9;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const FilterName = styled.div`
  margin: 10px 0;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #011759;
`;

export const ResetFilter = styled.div`
  margin-top: 30px;
  color: #215ec9;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  cursor: pointer;
`;

export const Indicator = styled.div<Partial<FiltersDropDownProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.counterWidthNHeight || "20px"};
  height: ${(props) => props.counterWidthNHeight || "20px"};
  position: absolute;
  border-radius: 50%;
  background: #be000b;
  color: #ffffff;
  right: -8px;
  top: -8px;
`;
