import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core";

interface ISelectDropdown extends SelectProps {
  label?: string;
  options: {label: string, value: string}[];
  value: any;
  handleOnChange: (e: any) => void;
  hideLabel?: boolean;
}

export const SelectDropdown = (props: ISelectDropdown) => {
  const { label, options, handleOnChange, value, hideLabel, ...remainingProps } = props;

  return (
    <FormControl variant="outlined" size="small">
      {!hideLabel && <InputLabel>{label}</InputLabel>}
      <Select value={value} onChange={handleOnChange} label={label} {...remainingProps}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
