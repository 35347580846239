import { Modal } from "antd";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 70%;`;

export const Header = styled.div<{width:string}>`
  width: ${props => props.width};
  padding: 15px 0;`;

export const ClientProjectsContainer = styled.div``;

export const ClientProjectsWrapper = styled(HeaderWrapper)`
  cursor: pointer;
`;

export const ClientProjectData = styled(Header)`
  overflow-wrap: break-word;
  `;

export const ModalBody = styled.div`
`;

export const InputBox = styled.div`
&:not(:last-child){
  margin-bottom: 30px;
}
`;
