import { AntdModal } from "../../../components/Modal";
import styled from "styled-components";
import {
  ProfileImg,
  UserChatHeadActive,
  UserCompany,
  UserInfo,
  UserName,
} from "../ChatList/ChatList.styles"

interface ResendProps {
  data: any;
  onClose:()=>void
}
const Wrapper = styled.div`
  max-height: 200px;
  overflow: scroll;
`;
const ResendModal = (props:ResendProps) => {
  const renderResendModal = () => {
    return (
      <Wrapper>
        {props?.data?.failedChats?.recipients?.map((item:any, index:number) => {
          return (
            <UserChatHeadActive key={index}>
              <ProfileImg></ProfileImg>
              <UserInfo>
                <UserName>
                  {/* {isCheckboxVisible ? data?.firstName : data?.recipient_name} */}
                  {item?.firstName+" "+item?.lastName}
                </UserName>
                <UserCompany>{item?.companyName}</UserCompany>
              </UserInfo>
            </UserChatHeadActive>
          );
        })}
      </Wrapper>
    );
  };
  return (
    <AntdModal
      usedFor="ResendModal"
      disabled={false}
      variant={"red"}
      header={"FAILED MESSAGES"}
      positiveAction={() => {
        //   props.positiveAction("all");
      }}
      closable={true}
      body={renderResendModal()}
      negativeAction={value => {}}
    />
  );
};

export default ResendModal;
