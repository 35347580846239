import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import DataGridComp from "../../DataGrid/DataGrid";
import LocalStorage from "../../../../../_services/LocalStorage";
import { renderCellExpand } from "../../../../CellExpandPopup/CellExpandPopup";
import { getUserRole } from "../../../../../Utils/Storage";
import ROLES from "../../../../../constants/RolesConstants";
import { getFormattedTimeStamp } from "../../../../../Utils/DisplayTimeFormat";

interface CompareVendorProps {
  startDate: any;
  endDate: any;
  setAccessStatus: (status: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const intialState = [
  {
    id:'',
    clientEmail: "",
    date: "",
    comparedVendors: '',
    sharedComparedVendors: '',
    downloadedComparedVendors: ''
  },
];

const CompareVendors = (props: CompareVendorProps) => {
  const { startDate, endDate } = props;
  const classes = useStyles();
  const userRole = getUserRole()?.split(',') || [];
  const [showExport, setShowExport] = React.useState(
    userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_EXPORT).length > 0
  );  
  
  const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "User Id",
        width: 200,
        align: "left",
        resizable: true,
        hide:true,
        renderCell: renderCellExpand
      },
    {
      field: "clientEmail",
      headerName: "User Email",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "date",
      headerName: "Timestamp",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "comparedVendors",
      headerName: "Vendors Compared",
      width: 450,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "source",
      headerName: "Source",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "sharedComparedVendors",
      headerName: "Share Table",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "downloadedComparedVendors",
      headerName: "Download Table",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    }
  ];

  const [comparedVendors, setComparedVendors] = React.useState(intialState);
  const [showLoader, setShowLoader] = React.useState(false);

  const prepareCompareVendorsData = (data: any) => {
    const comparedVendorsData = data?.map((comparedVendor: any) => {
        let result= '';
        const comparedVendorsName = comparedVendor.comparedVendors?.map((vendor:any) => {
            result = `${vendor.companyName},`;
            return result;});
      return {
        id: comparedVendor.id,
        clientEmail: comparedVendor?.clientEmail?.[0],
        date: moment(comparedVendor?.date).format("DD/MM/YYYY hh:mm A"),
        comparedVendors: comparedVendorsName,
        source: comparedVendor.source,
        sharedComparedVendors: comparedVendor?.sharedComparedVendors?.length ? 'Shared' : '',
        downloadedComparedVendors: comparedVendor?.downloadedComparedVendors?.length ? 'Downloaded' : ''
      };
    });
    return comparedVendorsData;
  };

  useEffect(() => {
    setShowLoader(true);
    const getComparedVendors = async () => {
      await axios.get(
        process.env.REACT_APP_BASE_URL + "/analytics/client/compareVendor",
        {
          params: {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
          },
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      ).then((result) => {
        if (result?.data?.data) {
          const comparedVendorsData = prepareCompareVendorsData(result.data.data);
          setComparedVendors(comparedVendorsData);
        } else {
          setComparedVendors([]);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        if(error?.response?.status === 403){
          props.setAccessStatus(false);
        } 
      });
    };

    getComparedVendors();
  }, [startDate, endDate]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {<DataGridComp col={columns} rows={comparedVendors} sortField="date" hideExport={!showExport}/>}
    </>
  );
};

export default CompareVendors;
