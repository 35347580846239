import Rating from "components/Rating/Rating";
import React, { useEffect, useRef, useState } from "react";
import { parametersConstants } from "../constants";
import {
  IClientDetailsKeys,
  IParametersConstants,
  IReviewForm,
} from "../interfaces/interfaces";
import {
  RatingSection,
  ReviewFormContainer,
  RatingSectionHeader,
  RatingSectionStars,
  CustomerReviewAndParameterWrapper,
  CustomerReviewTextarea,
  CustomerParameterWrapper,
  ParameterItemWrapper,
  ParameterItemInput,
  ParameterItemHeading,
  ParameterItemsContainer,
  ParameterSectionHeading,
  ErrMessage,
  ViewMoreTextWrapper,
  ClientDetailsContainer,
  ClientDetailsInput,
  ParameterRatingWrapper,
} from "../VendorReviews.styles";

const Form = (props: IReviewForm) => {
  const [viewMoreState, setViewMoreState] = useState(false);
  const errorRef = useRef<null | HTMLDivElement>(null);

  const onHandleOverallRatingData = (value: any, keyName: string) => {
    props?.onHandleOverallRatingData(value, keyName);
  };

  const onHandleParameterValue = (
    value: any,
    keyName: string,
    parameterType: string
  ) => {
    props?.onHandleParameterValue(value, keyName, parameterType);
  };

  const clientDetailsKeyNames = Object.keys(IClientDetailsKeys);

  useEffect(() => {
    if (props?.errMess?.length && errorRef) {
      errorRef?.current?.scrollIntoView();
    }
  }, [props?.errMess]);

  return (
    <ReviewFormContainer>
      {props?.errMess?.length > 0 && (
        <ErrMessage ref={errorRef}>! {props?.errMess}</ErrMessage>
      )}
      <RatingSectionHeader>Client Details</RatingSectionHeader>
      <ClientDetailsContainer>
        {clientDetailsKeyNames.map((keyName) => (
          <div key={keyName}>
            <label>
              {`${keyName.charAt(0).toUpperCase()}${keyName.slice(1)}`} :{" "}
            </label>
            <ClientDetailsInput
              onChange={(e) => {
                props?.onHandleClientDetailsValue(e.target.value, keyName);
              }}
              value={props?.clientDetails[keyName]}
              disabled={props?.data?.clientId ? true : false}
            />
          </div>
        ))}
      </ClientDetailsContainer>
      <RatingSection>
        <RatingSectionHeader>
          Give Your Rating <span>*field is required</span>
        </RatingSectionHeader>
        <span>Out of 5 star, how much would you rate?</span>
      </RatingSection>
      <RatingSectionStars>
        <Rating
          value={props?.overallData?.overallRating}
          color={"#FFCB45"}
          allowHalf
          onChange={(value: number) => {
            onHandleOverallRatingData(value, "overallRating");
          }}
        ></Rating>
      </RatingSectionStars>
      <CustomerReviewAndParameterWrapper>
        <RatingSectionHeader>Give your review</RatingSectionHeader>
        <CustomerReviewTextarea
          onChange={(e) => {
            onHandleOverallRatingData(e.target.value, "overallReview");
          }}
          value={props?.overallData?.overallReview || ""}
        />
        <RatingSectionHeader>
          Would you like to know what are the things that went well?
        </RatingSectionHeader>
        <CustomerParameterWrapper>
          <ParameterItemWrapper>
            <ParameterRatingWrapper>
              <ParameterSectionHeading style={{ width: "377px" }}>
                Parameters
              </ParameterSectionHeading>
            </ParameterRatingWrapper>
            <ParameterSectionHeading>Comments</ParameterSectionHeading>
          </ParameterItemWrapper>
          <ParameterItemsContainer $viewMore={viewMoreState}>
            {parametersConstants?.map((item: IParametersConstants) => {
              const keyName: string = item?.keyName;
              return (
                <ParameterItemWrapper key={keyName}>
                  <ParameterRatingWrapper>
                    <ParameterItemHeading>
                      {item?.bttnText}
                    </ParameterItemHeading>
                    <Rating
                      value={props?.parametersData?.[keyName]?.rating}
                      color={"#FFCB45"}
                      allowHalf
                      onChange={(value: number) => {
                        onHandleParameterValue(value, "rating", keyName);
                      }}
                    ></Rating>
                  </ParameterRatingWrapper>
                  <ParameterItemInput
                    onChange={(e) => {
                      onHandleParameterValue(
                        e.target.value,
                        "comment",
                        keyName
                      );
                    }}
                    value={props?.parametersData?.[keyName]?.comment}
                  />
                </ParameterItemWrapper>
              );
            })}
          </ParameterItemsContainer>
          <ViewMoreTextWrapper
            onClick={() => {
              setViewMoreState(!viewMoreState);
            }}
          >
            {viewMoreState ? "View Less" : "View More"}
          </ViewMoreTextWrapper>
        </CustomerParameterWrapper>
      </CustomerReviewAndParameterWrapper>
    </ReviewFormContainer>
  );
};

export default Form;
