import React from "react";
import styled from "styled-components";
import Text from "./Text";
import {
  FileImageOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileOutlined,
} from "@ant-design/icons";

interface ImageProps {
  file: any;
  message: string;
  type: "outgoing" | "incoming";
}
export const FileMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  letter-spacing: 0.01em;
  padding: 10px;
  line-height: 1;
  margin: 0 10px 10px;
  max-width: 300px;
`;

export const FileIconWrapper = styled.div`
  margin-right: 15px;
`;

export const FileName = styled.span`
  overflow-wrap: anywhere;
`;
const File = (props: ImageProps) => {
  return (
    <>
      <FileMessageWrapper>
        <FileIconWrapper>
          {(() => {
            switch (props?.file?.type) {
              case "image/jpg":
              case "image/png":
              case "image/jpeg":
                return <FileImageOutlined style={{ color: "#215EC9" }} />;
              case "application/pdf":
                return <FilePdfOutlined style={{ color: "#215EC9" }} />;
              case "application/msword":
              case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              case "doc":
              case "docx":
                return <FileWordOutlined style={{ color: "#215EC9" }} />;
              // case 'csv':
              // case 'application/vnd.ms-excel':
              // case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              //   return (<FileExcelOutlined style={{color:"#215EC9"}}/>)
              default:
                return <FileOutlined style={{ color: "#215EC9" }} />;
            }
          })()}
        </FileIconWrapper>
        <a
          href={
            props?.file?.file?.fileUrl || props?.file?.sharedFiles?.[0]?.fileUrl
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <FileName>{props?.file?.name}</FileName>
        </a>
      </FileMessageWrapper>
      {!!props?.message?.length && (
        <Text
          message={props?.message}
          isLinkAvailable={false}
          message_type={props?.type}
        />
      )}
    </>
  );
};

export default File;
