import { IJobRequestData } from "container/JobRequests/JobRequestDetails.interfaces";

export const ADMIN_ACTIONS_TABLE_CONSTANTS = {
  desc: { keyName: "desc", title: "Title" },
  actionType: { keyName: "actionType", title: "Action" },
  result: { keyName: "result", title: "Result" },
};

export const getRowsForAdminActiontable = (jobRequestData: IJobRequestData) => [
  {
    key: "1",
    desc: "Send To", // value for column 1
    actionType: jobRequestData?.sentToNew ? "sentToNew" : "", // value for column 2
    result: jobRequestData?.sentToNew ? "sentToNew" : "", // value for column 3
  },
  {
    key: "2",
    desc: "Suggested Vendors",
    actionType: jobRequestData?.suggestedGstns ? "suggestedGstns" : "",
    result: jobRequestData?.vendor ? "vendor" : "",
  },
];
