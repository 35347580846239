import { Modal } from "antd";
import styled from "styled-components";

export const ModalBox = styled(Modal)`
  .ant-modal-mask {
    // z-index:10000;
  }
  & .ant-modal-content {
    border-radius: 60px;
  }
  & .ant-modal-header {
    border-radius: 60px 60px 0 0;
    border: 0px;
    padding: 60px 0px 16px 60px;
  }
  & .ant-modal-close {
    margin: 30px;
  }
  & .ant-modal-footer {
    text-align: center;
    border-top: 0px;
    display: flex;
    justify-content: center;
  }
  & .ant-modal-body {
    height: 60vh;
    padding: 0px 60px 40px 60px;
    color: ${(props) => props?.theme?.colors?.secondary};
    overflow-y: auto;
    line-height: 170%;
    padding-bottom: 40px;
  }
`;
export const Title = styled.div`
  color: ${(props) => props?.theme?.colors?.secondary};
  font-weight: 600;
  font-size: 20px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 55px;
`;

export const InputWrap = styled.input`
  display: none;
`;

export const HeaderContentConatiner = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
`;
