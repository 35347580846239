import { VendorProfileActions } from "container/UpdateVendorProfiles/actions";
import React from "react";
import { useDispatch } from "react-redux";
import {
  NoReviewsContainer,
  NoReviewsDetailsWrapper,
  NoReviewsDetailsText,
  ButtonWrapper,
} from "../VendorReviews.styles";

const NoReviews = () => {
  const dispatch = useDispatch();
  return (
    <NoReviewsContainer>
      <img src="/images/empty-review-tab.svg" alt="empty review image" />
      <NoReviewsDetailsWrapper>
        <NoReviewsDetailsText $color={"#303030"} $marginBottom={"0px"} $fontSize={"18px"} $fontWeight={700}>
          No reviews found yet
        </NoReviewsDetailsText>
        <NoReviewsDetailsText $color={"#9e9e9e"} $marginBottom={"32px"} $fontSize={"16px"} $fontWeight={500}>
          No reviews have been given yet, Are you here to give review?
        </NoReviewsDetailsText>
        <ButtonWrapper
          onClick={() => {
            dispatch(VendorProfileActions.setOpenReviewFormModal({ status: true }));
          }}
        >
          Add review
        </ButtonWrapper>
      </NoReviewsDetailsWrapper>
    </NoReviewsContainer>
  );
};

export default NoReviews;
