export const JOB_REQUEST = {
  VERSION_1: "v1",
};

export enum FILE_LOG {
  FDelete = "FDelete",
  FUpload = "FUpload",
}

export enum JOB_STATUS {
  "In scope discussion" = "In scope discussion",
  "Accepting quotes" = "Accepting quotes",
  "Quotes under review" = "Quotes under review",
  "Job awarded" = "Job awarded",
  "Job on hold" = "Job on hold",
  "Inactive job" = "Inactive job",
  "Not applicable" = "Not applicable", // option is only for admin & mostly used for Venwiz job requests
}

export const JOB_INITIAL_STATUS: JOB_STATUS = JOB_STATUS["In scope discussion"];

export const JobStatusOptions = Object.keys(JOB_STATUS);

export const REPORTS_SUPPORTED_UPLOAD_FILE_TYPES = ["pdf", "xls", "xlsx", "msword", "doc", "docx", "csv"];

export const REPORTS_TABLE_CONSTANTS = {
  description: { keyName: "description", title: "Description" },
  createdAt: { keyName: "createdAt", title: "Created On" },
  documents: { keyName: "documents", title: "Client's Documents" },
  reports: { keyName: "reports", title: "Admin's Reports" },
  uploadReports: { keyName: "uploadReports", title: "Upload Reports" },
  organization: { keyName: "clientCompanyEmail", title: "Organization" },
};

export const JOB_REQUEST_DETAILS_ROW_CONFIG= {
  ADD_TO_PROJECT:{ label: "Add to Project", key:"requestAddToProject"},
  ASSIGN_TO_PROJECT: { label: "Assign to Project", key:"projectName"},
}
export const JOB_REQUEST_TYPE = {
  vendorrequest: "vendorrequest"
}

export const QUOTATION_TYPE = {
  TECHNICAL: "technical",
  COMMERCIAL: "commercial"
}