import React, { useEffect, useState } from "react";
import { checkOverflow } from "shared/handlers/elementOverflow.handler";
import { CardEllipsisDiv, CardTextWrapper, ViewMoreWrapper } from "../VendorReviews.styles";

const CustomerCardEllipsis = (props: any) => {
  const [readMoreState, setReadMoreState] = useState(false);
  const [viewMoreState, setViewMoreState] = useState(false);
  const ref: any = React.createRef();

  useEffect(() => {
    if (ref?.current) setReadMoreState(checkOverflow(ref));
  }, [ref]);

  return (
    <CardTextWrapper>
      <CardEllipsisDiv ref={ref} $maxHeight={"84px"}>
        <div>{props?.data}</div>
      </CardEllipsisDiv>
      {readMoreState && (
        <ViewMoreWrapper>
          <a>View more</a>
        </ViewMoreWrapper>
      )}
    </CardTextWrapper>
  );
};

export default CustomerCardEllipsis;
