import React from "react";
import {
  Box,
  FormControl,
  Modal,
  TextField,
  Typography,
  InputLabel,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import { useManufacturingModalStyles } from "./manufModal.styles";
import { IPlantMachineDetail, ManufCapabilitiesTableProps } from "../ManufacturingTable/ManufacturingTable.interface";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { isDecimalNumber, isWordWithSpace } from "shared/helpers/regex.helpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  FORMIK_PLANT,
  INDIAN_STATES,
  PLANT_DROPDOWN_OPTIONS,
} from "../ManufacturingTable/ManufacturingTable.constants";

interface IAddOrEditPlantModalProps {
  plantMachineDetails: IPlantMachineDetail[];
  setPlantMachineDetails: React.Dispatch<
    React.SetStateAction<IPlantMachineDetail[]>
  >;

  /** modal related props */
  handleCloseModal: () => void;
  showAddOrEditPlantModal: boolean;

  /** form related props */
  currPlantObj: IPlantMachineDetail;
  indexOfCurrPlant: ManufCapabilitiesTableProps["indexOfCurrPlant"];
  setCurrPlantObj: React.Dispatch<React.SetStateAction<IPlantMachineDetail>>;
  isFormAddNewOrEditExisting: ManufCapabilitiesTableProps["isFormAddNewOrEditExisting"];
  setIsDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddOrEditPlantModal = (props: IAddOrEditPlantModalProps) => {
  const {
    plantMachineDetails,
    setPlantMachineDetails,
    showAddOrEditPlantModal,
    currPlantObj,
    setCurrPlantObj,
    handleCloseModal,
    indexOfCurrPlant,
    isFormAddNewOrEditExisting,
    setIsDataChanged
  } = props;
  const ID_OF_EXISTING_PLANT = currPlantObj?._id || "";
  const classes = useManufacturingModalStyles();
  const [plantFormErrorMsg, setPlantFormErrorMsg] = React.useState(
    FORMIK_PLANT.INITIAL_ERRORS
  );

  const PLANT_ERROR_MSG = {
    location: {
      city: plantFormErrorMsg?.location?.city || "",
      state: plantFormErrorMsg?.location?.state || "",
    },
    totalArea:
      plantFormErrorMsg?.totalArea?.value ||
      plantFormErrorMsg?.totalArea?.unit ||
      "",
    workshopArea:
      plantFormErrorMsg?.workshopArea?.value ||
      plantFormErrorMsg?.workshopArea?.unit ||
      "",
    capacity:
      plantFormErrorMsg?.capacity?.value ||
      plantFormErrorMsg?.capacity?.unit ||
      "",
    otherInfo: plantFormErrorMsg?.otherInfo || "",
  };

  const SHOW_PLANT_ERRORS = {
    location: {
      city: Boolean(plantFormErrorMsg?.location?.city),
      state: Boolean(plantFormErrorMsg?.location?.state),
    },
    totalArea: Boolean(
      plantFormErrorMsg?.totalArea?.value?.length ||
        plantFormErrorMsg?.totalArea?.unit?.length
    ),
    workshopArea: Boolean(
      plantFormErrorMsg?.workshopArea?.value?.length ||
        plantFormErrorMsg?.workshopArea?.unit?.length
    ),
    capacity: Boolean(
      plantFormErrorMsg?.capacity?.value?.length ||
        plantFormErrorMsg?.capacity?.unit?.length
    ),
    otherInfo: Boolean(plantFormErrorMsg?.otherInfo?.length),
  };

  const handleResetPlantModalState = () => {
    handleCloseModal();
    setPlantFormErrorMsg(FORMIK_PLANT.INITIAL_ERRORS);
  };

  // form related handlers
  const handleOnChangePlantForm = {
    plantCity: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setIsDataChanged(true)
      const VAL = e?.target?.value;
      if (isWordWithSpace(VAL)) {
        setCurrPlantObj((prev) => ({
          ...prev,
          location: { city: VAL || "", state: prev?.location?.state || "" },
        }));
      } else if (!VAL?.length) {
        setCurrPlantObj((prev) => ({
          ...prev,
          location: { city: "", state: prev?.location?.state || "" },
        }));
      }
    },
    plantState: (item: { label: string; value: string }) => {
      setIsDataChanged(true)
      setCurrPlantObj((prev) => ({
        ...prev,
        location: {
          city: prev?.location?.city || "",
          state: item?.value || "",
        },
      }));
    },
    plantTotalareaValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setIsDataChanged(true)
      const VAL = e?.target?.value;
      setCurrPlantObj((prev) => ({
        ...prev,
        totalArea: {
          unit: prev?.totalArea?.unit || "",
          value: VAL?.length
            ? isDecimalNumber(VAL)
              ? Number(VAL)
              : prev?.totalArea?.value || null
            : null,
        },
      }));
    },
    plantTotalareaUnit: (item: { label: string; value: string }) => {
      setIsDataChanged(true)
      setCurrPlantObj((prev) => ({
        ...prev,
        totalArea: {
          value: prev?.totalArea?.value || null,
          unit: item?.value || "",
        },
      }));
    },
    plantWorkshopAreaValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setIsDataChanged(true)
      const VAL = e?.target?.value;
      setCurrPlantObj((prev) => ({
        ...prev,
        workshopArea: {
          unit: prev?.workshopArea?.unit || "",
          value: VAL?.length
            ? isDecimalNumber(VAL)
              ? Number(VAL)
              : prev?.workshopArea?.value || null
            : null,
        },
      }));
    },
    plantWorkshopAreaUnit: (item: { label: string; value: string }) => {
      setIsDataChanged(true)
      setCurrPlantObj((prev) => ({
        ...prev,
        workshopArea: {
          value: prev?.workshopArea?.value || null,
          unit: item?.value || "",
        },
      }));
    },
    plantCapacityValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setIsDataChanged(true)
      const VAL = e?.target?.value;
      setCurrPlantObj((prev) => ({
        ...prev,
        capacity: {
          unit: prev?.capacity?.unit || "",
          value: VAL?.length
            ? isDecimalNumber(VAL)
              ? Number(VAL)
              : prev?.capacity?.value || null
            : null,
        },
      }));
    },
    plantCapacityUnit: (item: { label: string; value: string }) => {
      setIsDataChanged(true)
      setCurrPlantObj((prev) => ({
        ...prev,
        capacity: {
          value: prev?.capacity?.value || null,
          unit: item?.value || "",
        },
      }));
    },
    plantOtherInformation: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setIsDataChanged(true)
      const VAL = e?.target?.value;
      setCurrPlantObj((prev) => ({ ...prev, otherInfo: VAL || "" }));
    },
  };

  const handleOnSubmitPlantForm = (
    updatedPlantMachineDetail: IPlantMachineDetail
  ) => {
    const {
      IS_FORM_VALID,
      IS_VALID_CITY,
      IS_VALID_TOTAL_AREA,
      IS_VALID_WORKSHOP_AREA,
      IS_VALID_CAPACITY,
    } = FORMIK_PLANT.IS_VALID_SCHEMA(updatedPlantMachineDetail);

    /** Show/Hide Errors */
    const MEASUREMENT_ERR_MSG =
      "Either Value & Unit both should be present OR none of them";
    setPlantFormErrorMsg((prev) => ({
      ...prev,
      location: {
        ...prev?.location,
        city: !IS_VALID_CITY ? "City is Mandatory" : "",
      },
      totalArea: {
        ...prev?.totalArea,
        value: !IS_VALID_TOTAL_AREA ? MEASUREMENT_ERR_MSG : "",
        unit: !IS_VALID_TOTAL_AREA ? MEASUREMENT_ERR_MSG : "",
      },
      workshopArea: {
        ...prev?.workshopArea,
        value: !IS_VALID_WORKSHOP_AREA ? MEASUREMENT_ERR_MSG : "",
        unit: !IS_VALID_WORKSHOP_AREA ? MEASUREMENT_ERR_MSG : "",
      },
      capacity: {
        ...prev?.capacity,
        value: !IS_VALID_CAPACITY ? MEASUREMENT_ERR_MSG : "",
        unit: !IS_VALID_CAPACITY ? MEASUREMENT_ERR_MSG : "",
      },
    }));

    if (IS_FORM_VALID) {
      /** Proceed with Saving/Creating Updated Plant */
      const PLANT_PAYLOAD_OBJECT: IPlantMachineDetail = {
        ...props?.currPlantObj,
        location:
          updatedPlantMachineDetail?.location ||
          FORMIK_PLANT.INITIAL_STATE.location,
        totalArea:
          updatedPlantMachineDetail?.totalArea ||
          FORMIK_PLANT.INITIAL_STATE.totalArea,
        workshopArea:
          updatedPlantMachineDetail?.workshopArea ||
          FORMIK_PLANT.INITIAL_STATE.workshopArea,
        capacity:
          updatedPlantMachineDetail?.capacity ||
          FORMIK_PLANT.INITIAL_STATE.capacity,
        otherInfo:
          (updatedPlantMachineDetail?.otherInfo ||
          FORMIK_PLANT.INITIAL_STATE.otherInfo) as string,
        machines: updatedPlantMachineDetail?.machines || [],
      };

      if (isFormAddNewOrEditExisting === "EDIT_EXISTING") {
        /** Update Existing Plant */
        setPlantMachineDetails((prev) =>
          prev?.map((plant, plantIdx) => {
            const isSamePlant = (
              plant?._id 
              && ID_OF_EXISTING_PLANT 
              && plant?._id === ID_OF_EXISTING_PLANT
            ) || indexOfCurrPlant === plantIdx;
  
            return isSamePlant ? PLANT_PAYLOAD_OBJECT : plant;
          })
        );
      } else if (isFormAddNewOrEditExisting === "ADD_NEW") {
        /** Add New Plant */
        setPlantMachineDetails((prev) => prev?.concat(PLANT_PAYLOAD_OBJECT));
      }

      /** Reset Modal State */
      handleResetPlantModalState();
      // toggleIsPageEdited(dispatch, true); // TODO: toggle success toast
    }
  };

  return (
    <Modal open={showAddOrEditPlantModal}>
      <Box className={classes.modalBody}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            {ID_OF_EXISTING_PLANT
              ? "Existing Plant Details"
              : "New Plant Details"}
          </Typography>
          <CancelRoundedIcon onClick={handleResetPlantModalState} />
        </Box>

        {/* Plant Form */}
        <Box className={classes.formContainer}>
          {/* Location - City */}
          <TextField
            // is mandatory
            label="City"
            variant="outlined"
            value={currPlantObj?.location?.city || ""}
            onChange={handleOnChangePlantForm.plantCity}
            helperText={PLANT_ERROR_MSG.location.city}
            error={SHOW_PLANT_ERRORS.location.city}
          />

          {/* Location - State */}
          <Autocomplete
            selectOnFocus
            className={classes.autocomplete}
            getOptionLabel={(option) => option.label}
            renderOption={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                variant="outlined"
                error={SHOW_PLANT_ERRORS.location.state}
                helperText={PLANT_ERROR_MSG.location.state}
              />
            )}
            options={INDIAN_STATES.map((option) => ({
              label: option,
              value: option,
            }))}
            value={(() => {
              const VAL = currPlantObj?.location?.state || "";
              return VAL ? { label: VAL, value: VAL } : null;
            })()}
            onChange={(event, newValue) => {
              setIsDataChanged(true)
              const VAL = newValue?.value || "";
              handleOnChangePlantForm.plantState({
                label: VAL,
                value: VAL,
              });
            }}
          />

          {/* Total Area */}
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.inputWithAutocompleteContainer}
          >
            <InputLabel shrink>Total Area</InputLabel>
            <OutlinedInput
              fullWidth
              type="number"
              label="Total Area"
              className={classes.inputWithAutocomplete}
              value={currPlantObj?.totalArea?.value || ""}
              onChange={handleOnChangePlantForm.plantTotalareaValue}
              error={SHOW_PLANT_ERRORS.totalArea}
              endAdornment={
                <Box className={classes.autocompleteConatinerWithinInput}>
                  <Autocomplete
                    className={classes.autocompleteWithinInput}
                    selectOnFocus
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => option.label}
                    options={PLANT_DROPDOWN_OPTIONS.AREA}
                    value={(() => {
                      const VAL = currPlantObj?.totalArea?.unit || "";
                      return VAL ? { label: VAL, value: VAL } : null;
                    })()}
                    onChange={(event, newValue) => {
                      const VAL = newValue?.value || "";
                      handleOnChangePlantForm.plantTotalareaUnit({
                        label: VAL,
                        value: VAL,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Unit" variant="filled" />
                    )}
                  />
                </Box>
              }
            />
            {SHOW_PLANT_ERRORS.totalArea && (
              <Box className={classes.errorText}>
                {PLANT_ERROR_MSG.totalArea}
              </Box>
            )}
          </FormControl>

          {/* Workshop Area */}
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.inputWithAutocompleteContainer}
          >
            <InputLabel shrink>Workshop Area</InputLabel>
            <OutlinedInput
              fullWidth
              type="number"
              label="Workshop Area"
              className={classes.inputWithAutocomplete}
              value={currPlantObj?.workshopArea?.value || ""}
              onChange={handleOnChangePlantForm.plantWorkshopAreaValue}
              error={SHOW_PLANT_ERRORS.workshopArea}
              endAdornment={
                <Box className={classes.autocompleteConatinerWithinInput}>
                  <Autocomplete
                    className={classes.autocompleteWithinInput}
                    selectOnFocus
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => option.label}
                    options={PLANT_DROPDOWN_OPTIONS.AREA}
                    value={(() => {
                      const VAL = currPlantObj?.workshopArea?.unit || "";
                      return VAL ? { label: VAL, value: VAL } : null;
                    })()}
                    onChange={(event, newValue) => {
                      const VAL = newValue?.value || "";
                      handleOnChangePlantForm.plantWorkshopAreaUnit({
                        label: VAL,
                        value: VAL,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Unit" variant="filled" />
                    )}
                  />
                </Box>
              }
            />
            {SHOW_PLANT_ERRORS.workshopArea && (
              <Box className={classes.errorText}>
                {PLANT_ERROR_MSG.workshopArea}
              </Box>
            )}
          </FormControl>

          {/* Capacity */}
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.inputWithAutocompleteContainer}
          >
            <InputLabel shrink>Capacity</InputLabel>
            <OutlinedInput
              fullWidth
              type="number"
              label="Capacity"
              className={classes.inputWithAutocomplete}
              value={currPlantObj?.capacity?.value || ""}
              onChange={handleOnChangePlantForm.plantCapacityValue}
              error={SHOW_PLANT_ERRORS.capacity}
              endAdornment={
                <Box className={classes.autocompleteConatinerWithinInput}>
                  <Autocomplete
                    className={classes.autocompleteWithinInput}
                    selectOnFocus
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => option.label}
                    options={PLANT_DROPDOWN_OPTIONS.CAPACITY}
                    value={(() => {
                      const VAL = currPlantObj?.capacity?.unit || "";
                      return VAL ? { label: VAL, value: VAL } : null;
                    })()}
                    onChange={(event, newValue) => {
                      const VAL = newValue?.value || "";
                      handleOnChangePlantForm.plantCapacityUnit({
                        label: VAL,
                        value: VAL,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Unit" variant="filled" />
                    )}
                  />
                </Box>
              }
            />
            {SHOW_PLANT_ERRORS.capacity && (
              <Box className={classes.errorText}>
                {PLANT_ERROR_MSG.capacity}
              </Box>
            )}
          </FormControl>

          <TextField
            className={classes.textField}
            label="Other Information"
            variant="outlined"
            value={currPlantObj?.otherInfo || ""}
            onChange={handleOnChangePlantForm.plantOtherInformation}
            error={SHOW_PLANT_ERRORS.otherInfo}
            helperText={PLANT_ERROR_MSG.otherInfo}
          />
        </Box>

        <Button
          size={"small"}
          color={"primary"}
          variant={"contained"}
          onClick={() => {
            handleOnSubmitPlantForm(currPlantObj);
          }}
        >
          {isFormAddNewOrEditExisting === "EDIT_EXISTING" ? "Update" : "Add"}
        </Button>
      </Box>
    </Modal>
  );
};
