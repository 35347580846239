import React from 'react'
import { VERIFICATION_OPTION } from '../../constants/updateVendorConstants';
import UpdateVendorProfile from "../../container/UpdateVendorProfiles/UpdateVendorProfiles";
import { PromptProvider } from 'components/Modal/PromptProvider';
const UpdateVerification = () => {
  return (
    <PromptProvider>
      <UpdateVendorProfile bodyContent={VERIFICATION_OPTION} />
    </PromptProvider>
  );
}

export default UpdateVerification;