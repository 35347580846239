import styled from "styled-components";

export const ModalBody = styled.div`
`

export const ErrorText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #be000b;
  text-align: start;
  img {
    margin-right: 14px;
  }
`;

export const AddUsersList = styled.div`
  max-height: 45vh;
  overflow-y: scroll;
  padding-top: 10px;
`;

export const AddUserSlNo = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  opacity: 0.7;
  margin-right: 28px;
`;

export const AddMore = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #215ec9;
  text-align: start;
  cursor: pointer;
  width: max-content;
`;