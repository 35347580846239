import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import clsx from "clsx";
import * as moment from "moment";
import { IconButton, TableBody, TableContainer, TablePagination, TableSortLabel } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@material-ui/icons/Done";
import { getSharableJobLink, jobReqListingHeaderNames } from "./jobRequest.helper";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import VendorRequestModal from "../../components/RequestModal/VendorRequestModal";
import SnackBarNotification from "../../components/SnackBar/SnackBar";
import VendorFilter from "../../components/VendorFilter/VendorFilter";
import { getUserRole } from "../../Utils/Storage";
import { getDateFromUTC } from "../../Utils/DisplayTimeFormat.ts";
import "./JobRequests.scss";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../../components/NoAccess/NoAccess";
import ROLES from "../../constants/RolesConstants";
import { useStyles } from "./jobRequestsListing.styles";
import { getCsvHeaders, checkIsVendorRequest } from "./jobRequest.helper";
import { useHistory } from "react-router-dom";
import SessionStorage from "../../_services/SessionStorage";
import { renderCellExpand } from "../../components/CellExpandPopup/CellExpandPopup";
import { FiltersDropDown } from "../../components/Filters/FiltersDropDown";
import { JobStatusOptions } from "./Constants/constants";
import { DropdownSelect } from "container/JobRequests/components/JobStatusSelect";
import { Empty } from "antd";
import AntdToast from "../../components/Toast/AntdToast";
import { JobRequestActions } from "container/JobRequests/actions";
import { Tabs, Typography } from "antd";
import { Reports } from "./Reports/Reports";
import { formatTime } from "Utils/DisplayTimeFormat";
const { TabPane } = Tabs;
const { Title } = Typography;

const JobRequests = () => {
  const history = useHistory();
  const _lodash = require("lodash");
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRole = getUserRole().split(",") || [];
  const sessionFilters = SessionStorage.get("job-request-date-filters");
  const [startDate, setStartDate] = useState(sessionFilters ? sessionFilters.startDate : moment().subtract(10, "days"));
  const [endDate, setEndDate] = useState(sessionFilters ? sessionFilters.endDate : moment(new Date()));
  const [displayJobReqForm, setDisplayJobReqForm] = useState(false);
  const [jobsListingData, setJobsListingData] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [csvJobsdata, setCsvJobsdata] = useState([]);
  const [reloadUpdate, setReloadUpdate] = useState(false);
  const [snackbarState, setSnackBarState] = useState({ show: false, messege: "", severity: "error" });
  const [hasAccess, setHasAccess] = useState(true);
  const [reqModalData, setReqModaldata] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [isExportButtonVisible, setExportButtonVisible] = useState(false);
  const [hasUserCopiedShareableLink, setHasUserCopiedShareableLink] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");

  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filterDropdownVisibility, setFilterDropdownVisibility] = useState(false);

  const showExport = userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_EXPORT).length > 0;

  useEffect(async () => {
    /** API - Export CSV Feature */
    setOpen(true);
    setExportButtonVisible(false);
    let params = {
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
    };
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/batch-requests/filter", {
        params: params,
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      })
      .then((result) => {
        setOpen(false);
        handleShowSnackbar(false);
        const CSV_DATA = result?.data?.jobRequests?.map(dataObj => ({
          ...dataObj,
          // Job Request Date&Time - date to 24 Hour IST
          requestedOn: formatTime(dataObj.requestedOn, "YYYY-MM-DD HH:mm:ss") 
        }));
        setCsvJobsdata(CSV_DATA || []);
        setExportButtonVisible(true);
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          setHasAccess(false);
        }
      });
  }, [startDate, endDate, reloadUpdate]);

  useEffect(async () => {
    /** API - Job Requests Listing */
    setOpen(true);
    let params = {
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
    };
    try {
      const resp = await axios.get(process.env.REACT_APP_BASE_URL + "/batch-requests/getJobs", {
        params: params,
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      });
      if (resp.status === 200) {
        setJobsListingData(resp?.data?.jobLists || []);
      }
    } catch (error) {
      console.error("Unable to get Job Listings data", error);
    }
    setOpen(false);
  }, [startDate, endDate, reloadUpdate]);

  const getCsvFileName = (startDate, endDate) => {
    return moment(startDate).format("YYYY-MM-DD") + "/" + moment(endDate).format("YYYY-MM-DD") + "-jobrequests.csv";
  };

  const renderTabTitle = (title) => (
    <Title level={4} style={{ margin: 0 }}>
      {title}
    </Title>
  );

  const handleFilterChange = (from, to) => {
    setStartDate(from);
    setEndDate(to);
  };

  const reload = () => {
    setReloadUpdate((itr) => !itr);
  };

  const handleAction = () => {
    setDisplayJobReqForm(false);
    setReqModaldata([]);
  };
  const sortBy = (keyName) => {
    setJobsListingData(_lodash.orderBy(jobsListingData, keyName, order === "desc" ? "desc" : "asc"));
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handleViewDetails = (rowId) => {
    const jobRequestFiltersObj = { startDate, endDate };
    SessionStorage.set("job-request-date-filters", jobRequestFiltersObj);
    history.push(`job-requests/${rowId}`);
  };

  const handleShowSnackbar = (flag) => {
    setSnackBarState((prev) => ({ ...prev, show: flag }));
  };

  const handleCopySharableLink = (rowId) => {
    navigator.clipboard.writeText(getSharableJobLink(rowId));
    setHasUserCopiedShareableLink(true);
    setSelectedRowId(rowId);
  };

  const handleResetCopyIcon = () => {
    setHasUserCopiedShareableLink(false);
    setSelectedRowId("");
  };

  const filterData = (jobsListingData, appliedFilters) => {
    if (appliedFilters.length) {
      const filteredData = jobsListingData.filter(({ jobStatus }) => appliedFilters.includes(jobStatus?.toLowerCase()));
      return filteredData;
    } else {
      return jobsListingData;
    }
  };

  const filteredValue = useMemo(() => filterData(jobsListingData, appliedFilters), [jobsListingData, appliedFilters]);

  const onJobStatusChange = (jobStatus, requestId) => {
    /** API - Job Requests status update */
    setOpen(true);
    dispatch(JobRequestActions.updateJobStatus({ requestId, jobStatus }))
      .then(() => {
        AntdToast({ type: "success", message: "Success", description: "Job status updated" });
        setReloadUpdate(true);
      })
      .catch(() => {
        setOpen(false);
        AntdToast({ type: "error", message: "Error", description: "Failed to update Job status" });
      });
  };

  if (!hasAccess) {
    return <NoAccess />;
  }

  return (
    hasAccess && (
      <Tabs defaultActiveKey="1">
        <TabPane tab={renderTabTitle("Reports")} key="1">
          <Reports />
        </TabPane>
      </Tabs>
    )
  );
};

export default JobRequests;
