import { Types as AnalyticsTypes } from "./AnalyticsTypes";

export const ActionCreators: any = {
  setStartDate: (data: any) => ({
    type: AnalyticsTypes.SET_START_DATE,
    payload: data
  }),
  setEndDate: (data: any) => ({
    type: AnalyticsTypes.SET_END_DATE,
    payload: data
  }),
  resetFilter: () => ({
    type:AnalyticsTypes.RESET_DATE_FILTERS
  })
}

export default ActionCreators;