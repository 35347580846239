import React from "react";
import styled from "styled-components";
import { AntdModal } from "../../../components/Modal";

interface DeleteMessageModalProps {
  positiveAction: (value: string) => void;
  negativeAction: (value: string) => void;
  singleAction?: boolean;
}
const MessageModal = (props: DeleteMessageModalProps) => {
  const renderDeleteModal = () => {
    return <div>Message sent successfully</div>;
  };
  return (
    <AntdModal
      isFooterAvailable
      usedFor="message"
      buttonDirectionColumn
      disabled={false}
      variant={"red"}
      negativeButtonColor={"red"}
      positiveActionText={"send New message"}
      negativeActionText={props.singleAction ? "" : "Close"}
      header={" "}
      positiveAction={() => {
        props.positiveAction("all");
      }}
      closable={false}
      body={renderDeleteModal()}
      negativeAction={value => {
        if (value === "onCancel") {
          props.negativeAction("only");
        } else {
          props.negativeAction("close");
        }
      }}
    />
  );
};

export default MessageModal;
