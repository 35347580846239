import React from 'react';
import styled from 'styled-components';
import AntInput, { InputProps } from '../TextBox';

const TextBoxBase = styled(AntInput)<InputProps>`
  border: 0;
  border-bottom: ${(props) => `1px solid ${props?.theme?.input?.states[props?.state || 'default']}`};
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;

  &:disabled {
    background-color: transparent;
  }
`;

const SecondaryTextBox = ({ ...rest }: InputProps) => (
  <>
    <TextBoxBase styleType="underline" {...rest} />
  </>
);

export default SecondaryTextBox;
