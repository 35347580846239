import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import React, { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  chipCard: {
    position: "relative",
    display: "block",
    border: "0.5px solid #c4c4c4",
    borderRadius: "4px",
    height: "100px",
    overflow: "auto",
    width: "70vw",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  chips: {
    float: "left",
    marginLeft: "10px",
    marginTop: "10px",
  },
}));

const ChipsArray = (props) => {
  const classes = useStyles();

  const dragChip = useRef(0);
  const dragOverChip = useRef(0);

  const handleSort = () =>{
    const chipArrayClone = [...props.ChipsArray]; 
    chipArrayClone.splice(dragOverChip.current, 0, chipArrayClone.splice(dragChip.current, 1)[0]);
    props.setChipsArray(chipArrayClone);
  }

  return (
    <div
      className={
        classes.chipCard + (props.className ? " " + props.className : "")
      }
    >
      {props.ChipsArray?.length
        ? props.ChipsArray.map((chip, index) => {
            {
              return props.deletable ? (
                <Chip
                  key={index}
                  icon={props.icon ? <InsertDriveFileIcon /> : null}
                  className={classes.chips}
                  size="small"
                  label={chip.label.length > 50 ? `${chip.label.substring(0, 50)}...` : chip.label}
                  onClick={() => props.onClick && props.onClick(chip, index)}
                  onDelete={() => props.onDelete && props.onDelete(chip, index)}
                  color="primary"
                  draggable={props.drag}
                  onDragStart = {() =>(dragChip.current = index)}
                  onDragEnter = {() =>(dragOverChip.current = index)}
                  onDragEnd = {props.drag? handleSort: () =>{}}
                  onDragOver = {(e) => e.preventDefault()}
                />
              ) : (
                <Chip
                  key={index}
                  className={classes.chips}
                  size="small"
                  label={chip.label.length > 50 ? `${chip.label.substring(0, 50)}...` : chip.label}
                  draggable={props.drag}
                  onDragStart = {() =>(dragChip.current = index)}
                  onDragEnter = {() =>(dragOverChip.current = index)}
                  onDragEnd = {props.drag? handleSort: () =>{}}
                  onDragOver = {(e) => e.preventDefault()}
                />
              );
            }
          })
        : null}
    </div>
  );
};

export default ChipsArray;
