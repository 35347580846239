import React from "react";
import { Table, Typography } from "antd";
import { useSelector } from "react-redux";
import { IJobRequestData } from "../../JobRequestDetails.interfaces";
import { getColumnsForVendorsAddedByTable } from "./tableVendorsAddedBy.constants";

export type IVendorAddedByUserType = "ADMIN" | "CLIENT";

export interface IVendorsWithInterestColumn {
  key: number;
  interestedEmailOrGstn: string;
  interestedVendorName: string;
  notInterestedEmailOrGstn: string;
  reasonToDecline: string;
}

const { Text } = Typography;

export const TableVendorsAddedBy = (props: { type: IVendorAddedByUserType }) => {
  const { type } = props;
  const VENDORS_WITH_INTEREST_SHOWN_COLUMNS = getColumnsForVendorsAddedByTable(type);
  const { jobRequestDetails }: { jobRequestDetails: IJobRequestData } = useSelector((state: any) => state.ClientRequestReducer);
  const getRowsForVendorWithInterests = (type: IVendorAddedByUserType) => {
    const INTEREST_ARR_TYPE = type === "ADMIN" ? "interestResponseAdmin" : "interestResponseClient";
    const NOT_INTEREST_ARR_TYPE = type === "ADMIN" ? "notInterestResponseAdmin" : "notInterestResponseClient";

    const interestedVendors = jobRequestDetails[INTEREST_ARR_TYPE] || [];
    const notInterestedVendors = jobRequestDetails[NOT_INTEREST_ARR_TYPE] || [];

    /**
     * array with maximum number of objects from:
     * either interestedVendors or notInterestedVendors array
     */
    const numberOfRows =
      interestedVendors.length > notInterestedVendors.length ? interestedVendors : notInterestedVendors;
    let rows: IVendorsWithInterestColumn[] = [];

    /**
     * iterating over numberOfRows - to populate table row object:
     * at each iteration - grabing values simultaneously from both arrays: interestedVendors & notInterestedVendors
     */
    numberOfRows?.length > 0 &&
      numberOfRows?.forEach((arrObj, index: number) => {
        rows.push({
          key: index,
          interestedEmailOrGstn: interestedVendors[index]?.requested_to || interestedVendors[index]?.email || "",
          interestedVendorName: interestedVendors[index]?.vendorName || "",
          notInterestedEmailOrGstn:
            notInterestedVendors[index]?.requested_to || notInterestedVendors[index]?.email || "",
          reasonToDecline: notInterestedVendors[index]?.notInterestedReason || "",
        });
      });
    return rows;
  };

  return (
    <>
      {jobRequestDetails && (
        <Table
          title={() => <Text strong>Vendors GST/Email</Text>}
          bordered
          pagination={false}
          columns={VENDORS_WITH_INTEREST_SHOWN_COLUMNS}
          dataSource={getRowsForVendorWithInterests(type)}
        />
      )}
    </>
  );
};
