import moment from "moment";
import { JOB_REQUEST_DETAILS_ROW_CONFIG } from "./Constants/constants";
import { JOB_REQUEST_TYPE } from "./Constants/constants";

export const getCsvHeaders = () => {
  return [
    { label: "Client Name", key: "clientName" },
    { label: "Job Request Id", key: "requestId" },
    { label: "Job Request Date&Time ", key: "requestedOn" },
    { label: "Status", key: "status" },
    { label: "Client Email", key: "clientEmail" },
    { label: "Keyword Searched", key: "searchKeyWord" },
    { label: "Suggested Vendor GSTN", key: "csvData.suggestedGstns" },
    { label: "Suggested Vendor & Rank", key: "csvData.vendor" },
    { label: "Admin Updated On", key: "lastSaved" },
    { label: "Updated By", key: "updatedBy" },
    { label: "Requested Date and Time", key: "requestedOn" },
    { label: "Requested Nature of Work", key: "natureOfWork" },
    { label: "Location of Job", key: "csvData.jobLocation" },
    { label: "Detailed Description", key: "description" },
    { label: "Uploaded Documents", key: "documentFile" },
    { label: "Required Certifications", key: "requiredCertifications" },
    { label: "Budget", key: "budget" },
    { label: "Estimated PO Issue Date", key: "estimatedPOIssueDate" },
    { label: "Job modified", key: "updatedOn" },
    { label: "Admin Edited On", key: "adminEditedRequestOn" },
    { label: "Send to", key: "csvData.sentToNew" },
    { label: "Send Status", key: "csvData.sendStatus" },
    { label: "Interested Vendors GST", key: "csvData.interestResponse" },
    {
      label: "Interested Vendors Names",
      key: "csvData.interestedVendorsNames",
    },
    { label: "Not Interested Vendors GST", key: "csvData.notInterestResponse" },
    { label: "Reason to decline", key: "csvData.reasonToDecline" },
  ];
};

export const jobReqListingHeaderNames = [
  { label: "Id", key: "requestId", isSortingEnabled: true },
  { label: "Job Status", key: "jobStatus", isSortingEnabled: true },
  { label: "VR Creator", key: "clientName", isSortingEnabled: true },
  {
    label: "Date Created",
    key: "requestedOn",
    isSortingEnabled: true,
  },
  { label: "Job Title", key: "title", isSortingEnabled: false },
  { label: "Admin Updated On", key: "lastSaved", isSortingEnabled: true },
  { label: "Source", key: "source", isSortingEnabled: true },
  { label: "Status", key: "status", isSortingEnabled: true },
  { label: "Copy Shareable Link", key: "", isSortingEnabled: false },
  { label: "View Details", key: "", isSortingEnabled: false },
];

export const rfqListingHeaderNames = [
  { label: "RFQ ID", key: "requestId", isSortingEnabled: true },
  { label: "Job Status", key: "jobStatus", isSortingEnabled: true },
  { label: "RFQ Creator", key: "clientName", isSortingEnabled: true },
  {
    label: "Date Created",
    key: "requestedOn",
    isSortingEnabled: true,
  },
  { label: "Job Title", key: "title", isSortingEnabled: false },
  { label: "Admin Updated On", key: "lastSaved", isSortingEnabled: true },
  { label: "Source", key: "source", isSortingEnabled: true },
  { label: "Request Status", key: "status", isSortingEnabled: true },
  { label: "Copy Shareable Link", key: "", isSortingEnabled: false },
  { label: "View Details", key: "", isSortingEnabled: false },
];

export const jobReqDetailRows = [
  { label: "Client Name", key: "clientName" },
  {
    label: "Job Request Date&Time ",
    key: "requestedOn",
  },
  { label: "Status", key: "status" },
  { label: "Source", key: "source" },
  { label: "Title", key: "title" },
  {
    label: "Detailed Description",
    key: "description",
  },
  { label: "Admin Updated On", key: "lastSaved" },
  { label: "Admin Edited On", key: "adminEditedRequestOn" },
  { label: JOB_REQUEST_DETAILS_ROW_CONFIG.ADD_TO_PROJECT.label, key: JOB_REQUEST_DETAILS_ROW_CONFIG.ADD_TO_PROJECT.key },
  { label: JOB_REQUEST_DETAILS_ROW_CONFIG.ASSIGN_TO_PROJECT.label, key: JOB_REQUEST_DETAILS_ROW_CONFIG.ASSIGN_TO_PROJECT.key },
  { label: "Job Request Id", key: "requestId" },
  { label: "Updated By", key: "updatedBy" },
  { label: "Keyword Searched", key: "searchKeyWord" },
  { label: "Client Email", key: "clientEmail" },

  {
    label: "Requested Nature of Work",
    key: "natureOfWork",
  },
  { label: "Location of Job", key: "jobLocation" },
  // { label: "Uploaded Documents", key: "documentFile" },
  {
    label: "Required Certifications",
    key: "requiredCertifications",
  },
  { label: "Budget", key: "budget" },
  { label: "Estimated PO Issue Date", key: "estimatedPOIssueDate" },
  { label: "Job modified", key: "updatedOn" },
];

export const rfqDetailRows = jobReqDetailRows.filter(
  (row) =>
    row?.key !== JOB_REQUEST_DETAILS_ROW_CONFIG.ADD_TO_PROJECT.key &&
    row?.key !== JOB_REQUEST_DETAILS_ROW_CONFIG.ASSIGN_TO_PROJECT.key
);

export const getSharableJobLink = (requestId: string) => {
  const link = window?.location?.href;
  if (link.includes("-dev") || link.includes("localhost")) {
    return `https://vendor-qa-amplify.venwiz.com/job/${requestId}/job-scope`;
  } else {
    return `https://vendor.venwiz.com/job/${requestId}/job-scope`;
  }
};

export const GENERATE_ZIP_FILE_NAME = (jobRequestId: string) => {
  return moment().format(`[RFQ_${jobRequestId}]_DDMMYYYY_hhmmss`);
};

export const checkIsVendorRequest = (type: string) => {
  return type === JOB_REQUEST_TYPE.vendorrequest;
};

export const checkIfSelectedCompanyIsVenwiz = (companyDetails: any)=>{
  return companyDetails.value === 'https://venwiz.com';
}

export const isRFQRoute = (pathname: string) => pathname.split("/").includes("client-project");

export const getJobTitleFromDescp = (desc: string, charLimit?: number) => {
  if (desc?.length) {
    let first5Words = desc?.split(" ")?.slice(0, 5)?.join(" ");
    if (charLimit && first5Words?.length > charLimit) {
      return first5Words?.slice(0, charLimit - 2) + "...";
    }
    return first5Words;
  }
  return "";
};
