import { Types as LoaderTypes } from "../../../_types/LoaderTypes";

export const ActionCreators: any = {
  setIsLoading: (data: any) => ({
    type: LoaderTypes.SET_IS_LOADING,
    payload: data,
  }),
};

export default ActionCreators;
