import { IJobRequestData } from "container/JobRequests/JobRequestDetails.interfaces";
import React from "react";
import { useSelector } from "react-redux";
import { DataSharingConsent, FinalVendorCard, ProfileLink, VendorCompanyName } from "./VendorsAwardedByClient.styles";

export const VendorsAwardedByClient = () => {
  const { jobRequestDetails }: { jobRequestDetails: IJobRequestData } = useSelector(
    (state: any) => state.ClientRequestReducer
  );

  return (
    <>
      {jobRequestDetails?.finalVendors?.map((vendor, index) => {
        return (
          <FinalVendorCard>
            <VendorCompanyName>
              {index + 1} - {vendor?.companyName}{" "}
            </VendorCompanyName>
            <DataSharingConsent>
              {" "}
              ( Data Sharing Consent - {vendor?.dataSharingConsent?.agreed ? "Acknowledged" : "Pending"} )
            </DataSharingConsent>
            <ProfileLink onClick={() => window.open(`/updateVerification/${vendor.gstn}`, "_blank")}>
              Profile link
            </ProfileLink>
          </FinalVendorCard>
        );
      })}
    </>
  );
};
