import React, { useRef } from "react";
import {
  Box,
  FormControl,
  Modal,
  TextField,
  Typography,
  InputLabel,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import { useManufacturingModalStyles } from "./manufModal.styles";
import {
  IMachine,
  IPlantMachineDetail,
  ManufCapabilitiesTableProps,
} from "../ManufacturingTable/ManufacturingTable.interface";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { isDecimalNumber, isWordWithSpace } from "shared/helpers/regex.helpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FORMIK_MACHINE } from "../ManufacturingTable/ManufacturingTable.constants";
import {
  ALL_AVAILABLE_MACHINE_NAMES,
  SORTED_MACHINE_PROCESS_NAMES,
} from "../ManufacturingTable/ManufacturingTable.constants";
import { blue } from "@mui/material/colors";
import clsx from "clsx";

interface IAddOrEditMachineModalProps {
  indexOfCurrMachine: number | null;
  indexOfCurrPlant: number | null;

  plantMachineDetails: IPlantMachineDetail[];
  setPlantMachineDetails: React.Dispatch<
    React.SetStateAction<IPlantMachineDetail[]>
  >;

  /** modal related props */
  handleCloseModal: () => void;
  showAddOrEditMachineModal: boolean;

  /** form related props */
  currPlantObj: IPlantMachineDetail;
  currMachineObj: IMachine;
  setCurrMachineObj: React.Dispatch<React.SetStateAction<IMachine>>;
  machineMakeOptions: { value: string; label: string }[];
  isFormAddNewOrEditExisting: ManufCapabilitiesTableProps["isFormAddNewOrEditExisting"];
  setIsDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddOrEditMachineModal = (props: IAddOrEditMachineModalProps) => {
  const {
    plantMachineDetails,
    setPlantMachineDetails,
    showAddOrEditMachineModal,
    currPlantObj,
    currMachineObj,
    setCurrMachineObj,
    handleCloseModal,
    machineMakeOptions,
    indexOfCurrMachine,
    indexOfCurrPlant,
    isFormAddNewOrEditExisting,
    setIsDataChanged
  } = props;

  const processAutocompleteRef = useRef<HTMLElement | null>(null);
  const machineNameAutocompleteRef = useRef<HTMLElement | null>(null);
  const machineMakeAutocompleteRef = useRef<HTMLElement | null>(null);

  const ID_OF_EXISTING_PLANT = currPlantObj?._id || "";
  const ID_OF_EXISTING_MACHINE = currMachineObj?._id || "";
  const classes = useManufacturingModalStyles();
  const [machineFormErrorsMsg, setMachineFormErrorMsg] = React.useState(
    FORMIK_MACHINE.INITIAL_ERRORS
  );
  const IS_CURRENT_MACHINE_NAME_NOT_PRESENT_IN_ALL_MACHINE_NAMES = !(
    ALL_AVAILABLE_MACHINE_NAMES as string[]
  ).includes(currMachineObj?.name);

  const handleResetMachineModalState = () => {
    handleCloseModal();
    setMachineFormErrorMsg(FORMIK_MACHINE.INITIAL_ERRORS);
  };

  const MACHINE_ERROR_MSG = {
    process: machineFormErrorsMsg?.process || "",
    name: machineFormErrorsMsg?.name || "",
  };

  const SHOW_MACHINE_ERRORS = {
    process: Boolean(MACHINE_ERROR_MSG.process),
    name: Boolean(MACHINE_ERROR_MSG.name),
  };

  const handleOnMachineChange = ({
    value,
    TYPE,
  }: {
    value: string | number | null;
    TYPE: keyof IMachine;
  }) => {
    const VAL = value;
    switch (TYPE) {
      case "process":
      case "name":
      case "specification":
      case "modelName":
      case "otherInfo":
      case "make":
        setIsDataChanged(true)
        setCurrMachineObj((prev) => ({ ...prev, [TYPE]: VAL || "" }));
        break;

      case "number":
        setIsDataChanged(true)
        setCurrMachineObj((prev) => ({
          ...prev,
          number: String(VAL)?.length
            ? isDecimalNumber(String(VAL))
              ? Number(VAL)
              : prev?.number || null
            : null,
        }));
        break;

      default:
        break;
    }
  };

  const handleOnSubmitMachineForm = (updatedMachineObj: IMachine) => {
    const { IS_FORM_VALID, IS_VALID_MACHINE_NAME } =
      FORMIK_MACHINE.IS_VALID_SCHEMA(updatedMachineObj);

    /** Show/Hide Errors */
    setMachineFormErrorMsg((prev) => ({
      ...prev,
      name: !IS_VALID_MACHINE_NAME ? "Machine name is Mandatory" : "",
    }));

    if (IS_FORM_VALID) {
      /** Proceed with Saving/Creating Updated Machine */
      const MACHINE_PAYLOAD_OBJECT = {
        ...props?.currMachineObj,
        process: updatedMachineObj?.process || "",
        name: updatedMachineObj?.name || "",
        number: updatedMachineObj?.number || 1,
        specification: updatedMachineObj?.specification || "",
        modelName: updatedMachineObj?.modelName || "",
        otherInfo: updatedMachineObj?.otherInfo || "",
        make: updatedMachineObj?.make || "",
      } as IMachine;

      if (isFormAddNewOrEditExisting === "EDIT_EXISTING") {
        /** Update Existing Machine */
        setPlantMachineDetails((prev) => {
          return prev?.map((plantObj, plantIdx) => {
            const isSamePlant = (
              plantObj?._id 
              && ID_OF_EXISTING_PLANT 
              && plantObj?._id === ID_OF_EXISTING_PLANT
            ) || indexOfCurrPlant === plantIdx;
  
            if (isSamePlant) {
              const existingMachines = plantObj?.machines || [];
              return {
                ...plantObj,
                machines: existingMachines?.map((existingMachine, machineIdx) => {
                  const isSameMachine = (
                    existingMachine?._id 
                    && ID_OF_EXISTING_MACHINE 
                    && existingMachine?._id === ID_OF_EXISTING_MACHINE
                  ) || indexOfCurrMachine === machineIdx;
  
                  return isSameMachine
                    ? { ...existingMachine, ...MACHINE_PAYLOAD_OBJECT }
                    : existingMachine;
                }),
              };
            }
            return plantObj;
          });
        });
      } else if (isFormAddNewOrEditExisting === "ADD_NEW") {
        /** Add New Machine */
        setPlantMachineDetails((prev) => {
          return prev?.map((plantObj, plantIdx) => {
            const isSamePlant = (
              plantObj?._id 
              && ID_OF_EXISTING_PLANT 
              && plantObj?._id === ID_OF_EXISTING_PLANT
            ) || indexOfCurrPlant === plantIdx;
  
            if (isSamePlant) {
              return {
                ...plantObj,
                machines: [ ...plantObj?.machines, { ...MACHINE_PAYLOAD_OBJECT, gallery: [] }, ],
              };
            }
            return plantObj;
          });
        });
      }

      /** Reset Modal State */
      handleResetMachineModalState();
      // toggleIsPageEdited(dispatch, true); // TODO: toggle success toast
    }
  };

  return (
    <Modal open={showAddOrEditMachineModal}>
      <Box className={classes.modalBody}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Typography>
              {ID_OF_EXISTING_MACHINE
                ? "Existing Machine Details"
                : "New Machine Details"}
            </Typography>
          </Box>

          <CancelRoundedIcon onClick={handleResetMachineModalState} />
        </Box>

        {/* Machine Form */}
        <Box className={classes.formContainer}>
          {/* Machine Process Dropdown */}
          <FormControl
            fullWidth
            variant="outlined"
            error={SHOW_MACHINE_ERRORS.process}
            className={classes.autocomplete}
          >
            <Autocomplete
              ref={processAutocompleteRef}
              freeSolo
              selectOnFocus
              className={classes.autocomplete}
              getOptionLabel={(option) => option.label}
              renderOption={(option) => option.label}
              options={
                SORTED_MACHINE_PROCESS_NAMES?.map((str) => ({
                  value: str,
                  label: str,
                })) || []
              }
              value={(() => {
                const VAL = currMachineObj?.process || "";
                return VAL ? { label: VAL, value: VAL } : null;
              })()}
              onChange={(event, newValue) => {
                const VAL = typeof newValue === "string" || newValue instanceof String
                  ? (newValue as string)
                  : newValue?.value || "";
                handleOnMachineChange({ value: VAL as string, TYPE: "process" });
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                const VAL = e?.target?.value || "";
                handleOnMachineChange({ value: VAL, TYPE: "process" });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Machine Process"
                  variant="outlined"
                />
              )}
            />
            {SHOW_MACHINE_ERRORS.process && (
              <Box className={classes.errorText}>
                {MACHINE_ERROR_MSG.process}
              </Box>
            )}
          </FormControl>

          {/* Machine Name Dropdown */}
          <FormControl
            fullWidth
            variant="outlined"
            error={SHOW_MACHINE_ERRORS.name}
            className={clsx(classes.autocomplete)}
            style={{
              backgroundColor: (() => {
                return currMachineObj?.name &&
                  IS_CURRENT_MACHINE_NAME_NOT_PRESENT_IN_ALL_MACHINE_NAMES
                  ? blue[100]
                  : "";
              })(),
            }}
          >
            {/* // is mandatory */}
            <Autocomplete
              // Use any arbitrary string as the value typed by user
              ref={machineNameAutocompleteRef}
              freeSolo
              selectOnFocus
              className={classes.autocomplete}
              getOptionLabel={(option) => option.label}
              renderOption={(option) => option.label}
              options={
                ALL_AVAILABLE_MACHINE_NAMES.map((name) => ({
                  label: name,
                  value: name,
                })) || []
              }
              value={(() => {
                const VAL = currMachineObj?.name || "";
                return VAL ? { label: VAL, value: VAL } : null;
              })()}
              onChange={(event, newValue) => {
                const VAL = typeof newValue === "string" || newValue instanceof String
                  ? (newValue as string) || ""
                  : newValue?.value || "";
                handleOnMachineChange({ value: VAL, TYPE: "name" });
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                const VAL = e?.target?.value || "";
                handleOnMachineChange({ value: VAL, TYPE: "name" });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Machine Name"
                  placeholder="Select or Type and press Enter"
                />
              )}
            />
            {SHOW_MACHINE_ERRORS.name && (
              <Box className={classes.errorText}>{MACHINE_ERROR_MSG.name}</Box>
            )}
          </FormControl>

          {/* Number of Machines */}
          <TextField
            className={classes.textField}
            label="Number of Machines"
            variant="outlined"
            type={"number"}
            value={currMachineObj?.number || ""}
            onChange={(e) =>
              handleOnMachineChange({
                value: e?.target?.value || "",
                TYPE: "number",
              })
            }
          />

          {/* Model Name */}
          <TextField
            className={classes.textField}
            label="Model Name"
            variant="outlined"
            value={currMachineObj?.modelName || ""}
            onChange={(e) =>
              handleOnMachineChange({
                value: e?.target?.value || "",
                TYPE: "modelName",
              })
            }
          />

          {/* Machine Make Dropdown */}
          {Boolean(machineMakeOptions?.length > 0) && (
            <Autocomplete
              ref={machineMakeAutocompleteRef}
              freeSolo
              selectOnFocus
              className={classes.autocomplete}
              getOptionLabel={(option) => option.label}
              renderOption={(option) => option.label}
              options={machineMakeOptions || []}
              value={(() => {
                const VAL = currMachineObj?.make || "";
                return VAL ? { label: VAL, value: VAL } : null;
              })()}
              onChange={(event, newValue) => {
                const VAL = typeof newValue === "string" || newValue instanceof String
                  ? (newValue as string) || ""
                  : newValue?.value || "";
                handleOnMachineChange({ value: VAL, TYPE: "make" });
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                const VAL = e?.target?.value || "";
                handleOnMachineChange({ value: VAL, TYPE: "make" });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Machine Make"
                  variant="outlined"
                />
              )}
            />
          )}

          {/* Specifications */}
          <TextField
            variant="outlined"
            label="Specifications"
            className={classes.textField}
            value={currMachineObj?.specification || ""}
            onChange={(e) =>
              handleOnMachineChange({
                value: e?.target?.value || "",
                TYPE: "specification",
              })
            }
          />

          {/* Other Information */}
          {/* <TextField
            className={classes.textField}
            label="Other Information"
            variant="outlined"
            value={currMachineObj?.otherInfo || ""}
            onChange={(e) =>
              handleOnMachineChange({
                value: e?.target?.value || "",
                TYPE: "otherInfo",
              })
            }
          /> */}
        </Box>

        <Button
          size={"small"}
          color={"primary"}
          variant={"contained"}
          onClick={() => {
            if (processAutocompleteRef?.current) processAutocompleteRef?.current?.blur();
            if (machineNameAutocompleteRef?.current) machineNameAutocompleteRef?.current?.blur();
            if (machineMakeAutocompleteRef?.current) machineMakeAutocompleteRef?.current?.blur();
            /** run the above onblur effects always before save button is clicked */
            handleOnSubmitMachineForm(currMachineObj);
          }}
        >
          {isFormAddNewOrEditExisting === "EDIT_EXISTING" ? "Update" : "Add"}
        </Button>
      </Box>
    </Modal>
  );
};
