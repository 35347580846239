import React from "react";
import { COMPANY_DOCUMENTS } from "../../constants/updateVendorConstants";
import UpdateVendorProfile from "../../container/UpdateVendorProfiles/UpdateVendorProfiles";
import { PromptProvider } from "components/Modal/PromptProvider";

const UpdateDocuments = () => (
  <PromptProvider>
    <UpdateVendorProfile bodyContent={COMPANY_DOCUMENTS} />
  </PromptProvider>
);

export default UpdateDocuments;