import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";

interface Props {
  total: number;
  initiateAction: (value: boolean) => void;
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #215ec9;
`;
const MessageWrapper=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`
const Bttn = styled(Button)`
width:25%;
  background: #47c882;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-to: 10px;
  color: #ffffff;
  margin-right: 20px;
`;

const ApproveInitiation = (props: Props) => {
  const [reInitiateChat, setReInitiateChat] = useState(false);
  const [firstInitiation, setFirstInitiation] = useState(false);

  const onHandleClick = () => {
    setFirstInitiation(true);
    props?.initiateAction(true);
  };
  return (
    <Container>
      <Content>
        {props?.total ? (
          <MessageWrapper>
            <Message>
              {` Please confirm whether you want to ${
                reInitiateChat ? "Reinitiate" : "initiate"
              } chat for the selected
              users`}
            </Message>
            <Bttn   shape="round" onClick={onHandleClick}>Yes</Bttn>
          </MessageWrapper>
        ) : (
          <Message>{`Select 1 or more person to ${
            reInitiateChat ? "Reinitiate" : "initiate"
          } chat`}</Message>
        )}
      </Content>
    </Container>
  );
};

export default ApproveInitiation;
