export interface IDetailedReview {
  vendorCompetency: IParameterReview;
  qualityOfExecution: IParameterReview;
  onTimeExecution: IParameterReview;
  responsiveness: IParameterReview;
  withinBudget: IParameterReview;
  ethics: IParameterReview;
  safety: IParameterReview;
}
export interface IParameterReview {
  rating: number;
  comment: string;
}

export interface IParametersConstants {
  bttnText: string;
  keyName: string;
}

export type ModalTypes = "view" | "edit";

export interface ICustomerReview {
  onCardClick: (type: ModalTypes, data: any) => void;
  data: any;
}

export interface IOverallRating {
  data: any;
}

export interface IReviewModals {
  onCancel: (value: boolean) => void;
  data: any;
}
export interface IViewReviews {
  data: any;
}

export interface IReviewForm {
  data: any;
  onHandleOverallRatingData: (value: any, keyName: string) => void;
  onHandleParameterValue: (
    value: any,
    keyName: string,
    parameterType: string
  ) => void;
  onHandleClientDetailsValue: (value: any, keyName: string) => void;
  overallData: any;
  parametersData: any;
  errMess: string;
  clientDetails: any;
}

export interface IParameterContent {
  expandContent: boolean;
  onViewMoreCheck: (val: boolean) => void;
  data: any;
}

export type IClientDetails = {
  [key in IClientDetailsKeys]: string;
};

export enum IClientDetailsKeys {
  "name" = "name",
  company = "company",
  designation = "designation",
  email = "email",
  logo = "logo",
}
