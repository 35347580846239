import { Select } from "antd";
import { JobStatusOptions } from "../Constants/constants";

export interface IDropdownSelect {
  selectedOption: any;
  id?: string; //mongo id
  onSelect: (value: any, id?: string) => void;
  options: any[];
  disabled?: boolean;
}

export function DropdownSelect(props: IDropdownSelect) {
  const { selectedOption, id, onSelect, options, disabled } = props;
  const { Option } = Select;

  return (
    <Select
      value={selectedOption}
      onChange={(value) => {
        onSelect(value, id);
      }}
      size="middle"
      disabled={disabled}
    >
      {options?.map((item) => (
        <>
          <Option value={item} key={item}>
            {item}
          </Option>
        </>
      ))}
    </Select>
  );
}
