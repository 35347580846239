import ROLES from "constants/RolesConstants";
import { getUserRole } from "Utils/Storage";

const getUserRolesArray = () => {
  return getUserRole()?.split(",") || [];
};

export const checkIsSuperAdmin = (userRoles: string[]): boolean => {
  if (!userRoles) return false;
  return userRoles.includes(ROLES.SUPER_ADMIN);
};

/**
 * 
 * @param requiredRoles - checks for Super Admin role first & if not any of the required roles
 * @returns boolean
 */
export const checkHasAnyOfTheRole = (requiredRoles: string[]): boolean => {
  const userRoles = getUserRolesArray();

  if (userRoles.length === 0) return false;

  if (checkIsSuperAdmin(userRoles)) return true;

  return (requiredRoles?.filter(role => userRoles.includes(role))).length > 0;
};

/**
 * 
 * @param requiredRoles - checks for Super Admin role first & if not all of the required roles
 * @returns boolean
 */
export const checkHasAllTheRoles = (requiredRoles: string[]): boolean => {
  const userRoles = getUserRolesArray();

  if (userRoles.length === 0) return false;

  if (checkIsSuperAdmin(userRoles)) return true;

  for (let role of requiredRoles) {
    if (!userRoles.includes(role)) return false;
  }

  return true;
};

export const checkUserHasOnlyVendorViewRole = () =>{
  const userRole = getUserRole()?.split(",") || [];
  const roles = [
    ROLES.SUPER_ADMIN, 
    ROLES.VENDOR_EDIT,  
    ROLES.VENDOR_PUBLISH,  
  ];
  
  let access = roles.some(role => userRole.includes(role));
  
  if (!access && userRole.includes(ROLES.VENDOR_VIEW)) {
    access = false;
  }  

  return access;
}