import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { DataGrid } from "@material-ui/data-grid";

const StyledDataGrid = withStyles({
    root: {
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-cell": {
        lineHeight: "unset !important",
        maxHeight: "none !important",
        whiteSpace: "normal",
      },
      "& .MuiDataGrid-row": {
      },
      marginTop: "1rem"
    },
  })(DataGrid);

export function VendorEquipmentTagsTable (props) {

    const [page, setPage] = React.useState(0);

    const getRows = () => {
      return props?.allSelectedTags.reduce((acc, curr)=>{
        const row = {
          id: curr?._id || "", 
          tagName: curr?.name || "",
          tagType: curr?.type || "",
          synonyms: curr?.synonyms || "",
          group1: curr?.group1 || "", 
          group2: curr?.group2 || "", 
          group3: curr?.group3 || "",
          family1: curr?.family1 || "", 
          family2: curr?.family2 || "", 
        };
        acc.push(row);
        return acc;
      }, []);
    }
  
    
    const columns = [
      {
        field: "tagType",
        type: "string",
        headerName: "Tag Type",
        width: 150,
        headerAlign: "center",
      },
      {
        field: "tagName",
        type: "string",
        headerName: "Tag Name",
        width: 150,
        headerAlign: "center",
      },
      {
        field: "family1",
        headerName: "Family 1",
        width: 150,
        headerAlign: "center",
      },
      {
        field: "family2",
        headerName: "Family 2",
        width: 150,
        headerAlign: "center",
      },
      {
        field: "group1",
        headerName: "Group 1",
        width: 150,
        headerAlign: "center",
      },
      {
        field: "group2",
        headerName: "Group 2",
        width: 150,
        headerAlign: "center",
      },
      {
        field: "group3",
        headerName: "Group 3",
        width: 150,
        headerAlign: "center",
      },
      {
        field: "synonyms",
        headerName: "Synonyms",
        width: 150,
        headerAlign: "center",
      },
    ];

    return(
        <>
        <Paper >
            <StyledDataGrid
            pagination
            autoHeight={true}
            page={page}
            onPageChange={(params) => {
                setPage(params.page);
            }}
            //rowsPerPageOptions={[5, 10]}
            pageSize={5}
            checkboxSelection={false}
            rows={getRows()}
            columns={columns}
            rowHeight={58}
            GridAlignment={"center"}
            />
        </Paper>
        </>
    )
}