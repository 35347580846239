import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Checkbox } from "antd";
import {
  FilterHeader,
  FilterIcon,
  FilterLines,
  FilterName,
  Indicator,
  ResetFilter,
} from "./Filters.styles";

interface FilterItem {
  filterType: string;
  filterValue: string;
}

export interface FiltersDropDownProps {
  setShowDropDown: React.Dispatch<React.SetStateAction<boolean>>;
  filterOptions: FilterItem[];
  showDropDown: boolean;
  onFiltering?: (data: any) => void;
  filterValues?: any;
  filterBackgroundColor?: string;
  filterColor?: string;
  width?: string;
  height?: string;
  marginBottom?: string;
  filterName?: string;
  counterWidthNHeight?: string;
}

export const FiltersDropDown = (props: FiltersDropDownProps) => {
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const [initialLoadStatus, setInitialLoadStatus] = useState(false);

  const onFilterApplied = (e: any, filterValue: string) => {
    if (e.target.checked) {
      setAppliedFilters([...appliedFilters, filterValue.toLowerCase()]);
    } else {
      const data = appliedFilters.filter(
        appliedFilter => appliedFilter !== filterValue.toLowerCase()
      );
      setAppliedFilters([...data]);
    }
  };
  useEffect(() => {
    if (props?.onFiltering && initialLoadStatus)
      props.onFiltering(appliedFilters);
    setInitialLoadStatus(true);
  }, [appliedFilters]);

  useEffect(() => {
    setAppliedFilters(props?.filterValues);
  }, [props?.filterValues]);

  const filters = (
    <Menu style={{ padding: "20px", borderRadius: "8px", width: "194px" }}>
      <FilterHeader>Filter by</FilterHeader>
      <FilterName>{props.filterName  || 'Status'}</FilterName>

      {props.filterOptions.map(({ filterValue: filterName, filterType }, index) => (
        <Menu.Item key={index} style={{ padding: "0px" }}>
          <Checkbox
            onChange={e => onFilterApplied(e, filterType)}
            style={{
              fontSize: "14px",
              color: "#011759",
              letterSpacing: "0.01em",
            }}
            checked={
              appliedFilters?.findIndex(filter => filter === filterType.toLowerCase()) !== -1
            }
          >
            {filterName}
          </Checkbox>
        </Menu.Item>
      ))}
      <ResetFilter onClick={() => setAppliedFilters([])}>
        Reset Filters
      </ResetFilter>
    </Menu>
  );

  return (
    <Dropdown
      overlay={filters}
      trigger={["click"]}
      placement="bottomRight"
      onVisibleChange={() => props.setShowDropDown(!props.showDropDown)}
      visible={props.showDropDown}
    >
      <a className="ant-dropdown-link" style={{position:"relative"}} onClick={e => e.preventDefault()}>
     {!!appliedFilters?.length && 
        <Indicator counterWidthNHeight = {props.counterWidthNHeight}>{appliedFilters?.length}</Indicator>}
        <FilterIcon filterBackgroundColor={props.filterBackgroundColor} width={props.width} height={props.height}>
          <FilterLines filterColor={props.filterColor} marginBottom = {props.marginBottom}></FilterLines>
          <FilterLines style={{ width: "12px" }} filterColor={props.filterColor} marginBottom = {props.marginBottom}></FilterLines>
          <FilterLines filterColor={props.filterColor}
            style={{ width: "4.5px", marginBottom: 0 }}
          ></FilterLines>
        </FilterIcon>
      </a>
    </Dropdown>
  );
};
