import { Switch } from "antd";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/EditOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";
import * as _ from "lodash";
import * as moment from "moment";
import React, { useEffect } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import VendorFilter from "../components/VendorFilter/VendorFilter";
import { getUserRole } from "../Utils/Storage";
import "./VendorProfiles.scss";
import DataGridComp from "../components/analytics/client/DataGrid/DataGrid";
import {
  columns,
  prepareData,
  formatGstArray,
  APPROVAL_STATUS_DROPDOWN_OPTIONS,
  RETOOL_LINK,
  getColumnNamesWithEmptyFields,
} from "./VendorProfiles_helper";
import { CSVLink } from "react-csv";
import LocalStorage from "../_services/LocalStorage";
import NoAccess from "../components/NoAccess/NoAccess";
import ROLES from "../constants/RolesConstants";
import AdvancedVendorFilter from "../components/VendorFilter/AdvancedVendorFilter";
import Link from "@mui/material/Link";
import { Button } from "@material-ui/core";
import {
  getCategoryArrays,
  getDifference,
  getActualValues,
} from "components/VendorCapabilitiesUpdate/VendorTags_helper";
import { TagsDispatchType, tagsReducer } from "../components/VendorCapabilitiesUpdate/tagsReducer";
import { getUniqueListBy } from "../components/VendorTagsUpdate/helper";
import { dropDownData, VendorDateType } from "constants/updateVendorConstants";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";
import {
  convertFilesArrayToStringsForCSVExport,
  convertObjToString,
  getStringOfRegisteredLocations,
  joinArrayOfStrings,
} from "shared/helpers/csv.helper";
import { getTagsDropdownOptions } from "./tags/Tags_helper";
import { SelectDropdown } from "components/VendorDocumentsUpdate/SelectDropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "92vw",
    maxHeight: "72vh",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {},
  container: {
    maxHeight: "70vh",
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  selectWidth: { width: 160 },
}));

const VendorProfiles = () => {
  const turnOverSlabData = dropDownData?.turnOverSlab.map((item) => {
    return item?.value;
  });
  const statesServedData = dropDownData?.allRegisteredStates.map((item) => {
    return item?.value;
  });
  const industriesData = dropDownData?.industries.map((item) => {
    return item?.value;
  });
  const [callFilterApi, setCallFilterApi] = React.useState(false);
  const [dateTypeObj, setDateTypeObj] = React.useState({
    dateType: ["Published", "Vendor Updated Date", "Admin Updated Date", "Registration Date"],
  });
  const [dateTypeSelected, setDateTypeSelected] = React.useState({
    dateType: "Registration Date",
  });

  /** State variables used to store fetched options for advance filter  */
  const [fetchedEquipments, setFetchedEquipments] = React.useState({ GROUPS: [], FAMILIES: [], TAGS: [] });
  const [fetchedServices, setFetchedServices] = React.useState({ GROUPS: [], TAGS: [] });

  const [initialFilterObj, setInitialFilterObj] = React.useState({
    industriesServed: industriesData,
    turnOverSlab: turnOverSlabData,
    statesServed: statesServedData,
    regionServed: ["north", "south", "east", "west"],
    customerTier: ["A", "B", "C", "D", "E", "Others"],
    service: [], // service tags dropdown
    equipment: [], // equipment tags dropdown
    serviceGroup: [],
    equipmentGroup: [],
    equipFamily: [],
  });
  const [selectedInitialFilterObj, setSelectedInitialFilterObj] = React.useState({
    statesServed: [],
    industriesServed: [],
    turnOverSlab: [],
    service: [], // service tags dropdown
    equipment: [], // equipment tags dropdown
    serviceGroup: [],
    equipmentGroup: [],
    regionServed: [],
    customerTier: [],
    equipFamily: [],
  });

  const stored = sessionStorage.getItem("searchParams");
  const searhParams = stored ? JSON.parse(stored) : null;
  const userRole = getUserRole().split(",") || [];
  const [startDate, setStartDate] = React.useState(searhParams ? searhParams.startDate : moment().subtract(7, "days"));
  const [endDate, setEndDate] = React.useState(searhParams ? searhParams.endDate : moment(new Date()));
  const [gstNumber, setGstNumber] = React.useState(searhParams ? searhParams.gstn : "");
  const [vendorName, setVendorName] = React.useState(searhParams ? searhParams.vendorName : "");
  const [vendorList, setVendorListData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [csvVendorsdata, setCsvVendorsData] = React.useState([]);
  const [activeVendorReload, toggleVendorActivation] = React.useState();
  const history = useHistory();
  const [vendorResultData, setVendorResultData] = React.useState([]);
  const showPublish = userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_PUBLISH).length > 0;
  const showExport = userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_EXPORT).length > 0;
  const showEdit =
    userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_EDIT || role === ROLES.VENDOR_PUBLISH || role === ROLES.VENDOR_VIEW)
      .length > 0;
  const showBulkUserCreation =
    userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_PUBLISH).length > 0;
  const showBulkUserPublish = userRole.filter((role) => role === ROLES.SUPER_ADMIN).length > 0;
  const showRefreshTurnOver = userRole.filter((role) => role === ROLES.SUPER_ADMIN).length > 0;
  const showBulkUserDelete = userRole.filter((role) => role === ROLES.SUPER_ADMIN).length > 0;
  const showRetoolLink = userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_EDIT).length > 0;
  const userData = JSON.parse(localStorage.getItem("user"));
  const agentCode = userData?.agentCode;
  const [hasAccess, setHasAccess] = React.useState(true);
  const [viewAdvancedFilter, setViewAdvancedFilter] = React.useState(false);
  const [actualResultData, setActualResultData] = React.useState();

  const initialFilterState = {
    equipment: [],
    service: [],
    oem: [],
    equipmentGroups: [],
    serviceGroups: [],
    industriesServed: [],
    statesServed: [],
    customerTier: [],
    equipFamily: [],
  };

  const [filterOption, setFilterOption] = React.useState(initialFilterState);
  const [filterCount, setFilterCount] = React.useState({});
  const [selectedAdvancedFilter, setSelectedAdvancedFilter] = React.useState(initialFilterState);

  const handleUpdateApprovalStatusValue = (value, params) => {
    if (params?.row?.adminCreated?.toLowerCase() === "yes") {
      setOpen(true);
      axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + "/v1/user-info/update-approval-status",
        headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
        data: { gstn: params?.row?.gstn, approvalStatus: value },
      })
        .then((resp) => {
          setVendorResultData((prev) =>
            prev?.map((rowData) => {
              return rowData?.id === params?.row?.id
                ? {
                    ...rowData,
                    approvalStatus: resp?.data?.approvalStatus || value,
                  }
                : rowData;
            }),
          );
          setOpen(false);
        })
        .catch((err) => {
          setOpen(false);
          console.log({ err });
        });
    }
  };

  const dynamicColumns = [
    {
      field: "vendorGst",
      headerName: "Update",
      width: 130,
      align: "center",
      renderCell: (params) =>
        showEdit ? (
          <RouterLink
            target="_blank"
            to={`/updateCompanyInfo/${params.value}`}
            onClick={() => onToggleEditMode(params.value, startDate, endDate,vendorName)}
          >
            <IconButton aria-label="delete">
              <EditIcon />
            </IconButton>
          </RouterLink>
        ) : (
          <></>
        ),
    },
    {
      field: "isActive",
      headerName: "Publish",
      width: 130,
      align: "center",
      renderCell: (params) =>
          <Switch checked={params.value} onChange={() => publishVendor(params)} name="isActive" color="primary" disabled={!showPublish} type="primary"/>
    },
    {
      field: "projectVendor",
      headerName: "Project",
      width: 130,
      align: "center",
      renderCell: (params) =>
        showPublish ? (
          <Switch
            checked={params.value}
            onChange={() => projectVendor(params.id, params.value)}
            name="projectVendor"
            color="primary"
          />
        ) : (
          <></>
        ),
    },
    {
      field: "approvalStatus",
      headerName: "Approval Status",
      width: 180,
      align: "center",
      renderCell: (params) => {
        return (
          <SelectDropdown
            hideLabel
            displayEmpty // shows None, when value is empty
            className={classes.selectWidth}
            value={params?.row?.approvalStatus}
            options={APPROVAL_STATUS_DROPDOWN_OPTIONS}
            disabled={params?.row?.adminCreated?.toLowerCase() === "no"}
            handleOnChange={(e) => handleUpdateApprovalStatusValue(e?.target?.value, params)}
          />
        );
      },
    },
  ];

  const getHeaderData = (name, num, payload, documentColumn) => {
    let value;
    if (documentColumn) {
      value = payload.experiences?.experience[num]?.document?.documentFile?.fileUrl;
    } else {
      value = payload.experiences?.experience[num]?.[name];
    }
    return value === undefined ? "" : value;
  };

  const getLabel = (name, keyName) => {
    const value = [1, 2, 3];
    const arr = value.map((num) => {
      return { label: name + num + "\t(Experiences)", key: keyName + num };
    });
    return arr;
  };

  /** Data to be shown for exported CSV file */
  const customCsvDatahandler = (payload) => {
    const customCsvPayload = payload.map((itr) => {
      return {
        ...itr,
        clientCompanyName1: getHeaderData("clientCompanyName", 0, itr),
        clientCompanyName2: getHeaderData("clientCompanyName", 1, itr),
        clientCompanyName3: getHeaderData("clientCompanyName", 2, itr),

        companyProfile1: getHeaderData("companyProfile", 0, itr),
        companyProfile2: getHeaderData("companyProfile", 1, itr),
        companyProfile3: getHeaderData("companyProfile", 2, itr),

        description1: getHeaderData("description", 0, itr),
        description2: getHeaderData("description", 1, itr),
        description3: getHeaderData("description", 2, itr),

        projectName1: getHeaderData("projectName", 0, itr),
        projectName2: getHeaderData("projectName", 1, itr),
        projectName3: getHeaderData("projectName", 2, itr),

        clientIndustry1: getHeaderData("clientIndustry", 0, itr),
        clientIndustry2: getHeaderData("clientIndustry", 1, itr),
        clientIndustry3: getHeaderData("clientIndustry", 2, itr),

        city1: getHeaderData("city", 0, itr),
        city2: getHeaderData("city", 1, itr),
        city3: getHeaderData("city", 2, itr),

        projStartDate1: getHeaderData("projStartDate", 0, itr),
        projStartDate2: getHeaderData("projStartDate", 1, itr),
        projStartDate3: getHeaderData("projStartDate", 2, itr),

        projEndDate1: getHeaderData("projEndDate", 0, itr),
        projEndDate2: getHeaderData("projEndDate", 1, itr),
        projEndDate3: getHeaderData("projEndDate", 2, itr),

        tagFlag: getHeaderData("tagFlag", 0, itr),
        projFileUpload1: getHeaderData("document?.documentFile?.fileUrl", 0, itr, true),
        projFileUpload2: getHeaderData("document?.documentFile?.fileUrl", 1, itr, true),
        projFileUpload3: getHeaderData("document?.documentFile?.fileUrl", 2, itr, true),

        // new fields
        financialITRX: itr?.financialData?.incomeTaxDetails || "",

        // convert arrays of file objects to strings
        productCatalogue: convertFilesArrayToStringsForCSVExport(itr?.manufacturingCapabilties?.productCatalog),
        experienceProjectDocs: convertFilesArrayToStringsForCSVExport(itr?.experienceProjectDocs),
        imageUploads: convertFilesArrayToStringsForCSVExport(itr?.sows?.gallery),
        videoUploads: convertFilesArrayToStringsForCSVExport(itr?.companyInfo?.companyVideo),
        cancelledCheque: convertFilesArrayToStringsForCSVExport(
          itr?.financialData?.cancelledCheque ? [itr?.financialData?.cancelledCheque] : [],
        ),
        companyBrochure: convertFilesArrayToStringsForCSVExport(itr?.sows?.companyBrochure),
        addressProof: convertFilesArrayToStringsForCSVExport(itr?.companyInfo?.addressProof || []),
        registeredLocations: getStringOfRegisteredLocations(itr?.companyInfo?.registeredLocations || []),
        panCertificate: convertFilesArrayToStringsForCSVExport(
          itr?.complianceData?.panCertificate ? [itr?.complianceData?.panCertificate] : [],
        ),
        gstCertificate: convertFilesArrayToStringsForCSVExport(
          itr?.complianceData?.gstCertificate ? [itr?.complianceData?.gstCertificate] : [],
        ),
        customerFileUpload: convertFilesArrayToStringsForCSVExport(
          itr?.sows?.customersListFile ? [itr?.sows?.customersListFile] : [],
        ),

        // convert object to string for CSV Export
        customerTier: convertObjToString(itr?.sows?.customerTier),

        // approvalStatus
        approvalStatus: itr?.registrations?.approvalStatus || "",
        isGoToVendor: itr?.registrations?.isGoToVendor || false,
        actualUserUpdatedTime: itr?.registrations?.actualUserUpdatedTime || "",

        // number of secondary users pending Approval
        pendingUserApprovals: itr?.secondaryUsers?.pendingUserApprovals || "",

        // self assigned tags by vendor(while onboarding)
        equipFamily: joinArrayOfStrings(itr?.tags?.equipFamily),
        selfAssignedServiceGroups: joinArrayOfStrings(itr?.tags?.selfAssignedServiceGroups),

        // job Monetization part
        acceptedJobs: joinArrayOfStrings(itr?.jobMonetization?.accepted),
        pendingJobs: joinArrayOfStrings(itr?.jobMonetization?.pending),

        // missing Fields
        missingFields: getColumnNamesWithEmptyFields(itr),
      };
    });

    setCsvVendorsData(customCsvPayload);
  };

  const onToggleEditMode = (gstn, startDate, endDate, vendorName) => {
    let sessionObj = {
      gstn: gstn,
      startDate: startDate,
      endDate: endDate,
      vendorName: vendorName
    };
    sessionStorage.setItem("searchParams", JSON.stringify(sessionObj));
  };

  const getCsvHeaders = () => {
    return [
      { label: "Registration Date", key: "registrations.registerDate" },
      { label: "Vendor Published", key: "vendorPublished" },
      { label: "Published Date", key: "registrations.vendorPublishedTime" },
      { label: "Admin Updated Date", key: "registrations.adminUpdatedTime" },
      { label: "Vendor Updated Date", key: "registrations.vendorUpdatedTime" },
      { label: "Project Vendor", key: "registrations.projectVendor" },
      { label: "Is Go To Vendor", key: "isGoToVendor" },
      { label: "Actual User Updated Time", key: "actualUserUpdatedTime" },

      //companyinfo
      { label: "company Name", key: "companyInfo.companyName" },
      { label: "Missing Fields", key: "missingFields" },
      { label: "Primary Company Address", key: "companyInfo.address" },
      { label: "Registered Locations", key: "registeredLocations" },
      { label: "City", key: "companyInfo.city" },
      { label: "State", key: "companyInfo.state" },
      { label: "Pincode", key: "companyInfo.pincode" },
      { label: "Annual Turn Over", key: "companyInfo.annualTurnOver" },
      { label: "Turn Over Slab", key: "companyInfo.turnOverSlab" },
      { label: "CIN", key: "companyInfo.cin" },
      { label: "YOC", key: "companyInfo.yoc" },
      { label: "Website", key: "companyInfo.website" },
      { label: "Address Proof", key: "addressProof" },

      { label: "GSTN", key: "registrations.gstn" },
      {
        label: "Secondary Users Pending Approval",
        key: "pendingUserApprovals",
      },

      // compliance
      { label: "GST Certificate", key: "gstCertificate" },
      { label: "GST status (Active)", key: "complianceData.gstStatus" },
      { label: "PAN", key: "complianceData.pan" },
      { label: "PAN certificate", key: "panCertificate" },
      { label: "LIN", key: "complianceData.lin" },
      { label: "URN", key: "complianceData.urn" },
      { label: "ESIC", key: "complianceData.esic" },
      { label: "EPF", key: "complianceData.epf" },
      { label: "TAN", key: "complianceData.tan" },

      // registration
      {
        label: "Company Role(registrations)",
        key: "registrations.companyRole",
      },
      { label: "Email (registrations)", key: "registrations.email" },
      { label: "First Name (registrations)", key: "registrations.firstName" },
      { label: "Last Name (registrations)", key: "registrations.lastName" },
      { label: "Mobile Verified", key: "registrations.phoneVerified" },
      { label: "Email Verified", key: "registrations.emailVerified" },
      {
        label: "Phone Number (registrations)",
        key: "registrations.phoneNumber",
      },
      { label: "Approval Status", key: "approvalStatus" },

      ...getLabel("Company Profile ", "companyProfile"),
      ...getLabel("Project Name", "projectName"),
      ...getLabel("Client Company Name", "clientCompanyName"),
      ...getLabel("Client Industry", "clientIndustry"),
      ...getLabel("City", "city"),
      ...getLabel("Project Start Date", "projStartDate"),
      ...getLabel("Project End Date", "projEndDate"),
      ...getLabel("Project Description ", "description"),
      ...getLabel("Project File Upload", "projFileUpload"),
      { label: "KM First Name", key: "keyPeopleInfo.firstName" },
      { label: "KM Last Name", key: "keyPeopleInfo.lastName" },
      { label: "KM Designation", key: "keyPeopleInfo.designation" },
      { label: "KM Qualification", key: "keyPeopleInfo.qualification" },
      { label: "KM Experience(in years)", key: "keyPeopleInfo.experience" },
      { label: "Total Manpower", key: "peopleStrength.totalManpower" },
      { label: "Manpower Full Time", key: "peopleStrength.fullTime" },
      { label: "Manpower Part Time", key: "peopleStrength.partTime" },
      // { label: "Brochure Link (SOW)", key: "sows.fileUrl" }, // previous company brochure key - array of strings
      { label: "Brochure Link (SOW)", key: "companyBrochure" },
      { label: "about us (SOW)", key: "sows.aboutUs" },
      { label: "Key Customers(SOW)", key: "sows.keyCustomers" },
      { label: "Countres Served (SOW)", key: "sows.countriesServed" },
      { label: "States Served (SOW)", key: "sows.statesServed" },
      { label: "Industries Served (SOW)", key: "sows.industriesServed" },
      // {
      //   label: "Other Industries Served (SOW)",
      //   key: "sows.industriesServedOther",
      // },
      { label: "Technical (CERTIFICATIONS)", key: "certifications.technical" },
      { label: "Comliance (CERTIFICATIONS)", key: "certifications.compliance" },
      {
        label: "regulatory (CERTIFICATIONS)",
        key: "certifications.regulatory",
      },

      // job Monetization
      { label: "Pending pricing consent", key: "pendingJobs" },
      { label: "Accepted pricing", key: "acceptedJobs" },

      // finance
      {
        label: "Financial- Registered contact",
        key: "financialData.registeredContact",
      },
      {
        label: "Financial- Registered email",
        key: "financialData.registeredEmail",
      },
      { label: "Financial- Cheque upload", key: "cancelledCheque" },
      {
        label: "Financial- Bank Account number",
        key: "financialData.accountNumber",
      },
      { label: "Financial- Bank Name", key: "financialData.bankName" },
      { label: "IFSC Code", key: "financialData.ifscCode" },
      { label: "Account Holder's Name", key: "financialData.accountName" },
      { label: "Financial ITRx", key: "financialITRX" },

      // tags
      { label: "Oem Tags", key: "tags.oem" },
      { label: "Equipment Tags", key: "tags.equipment" },
      { label: "Service Tags", key: "tags.service" },
      { label: "Service Group Tags", key: "tags.serviceGroups" },
      { label: "Equipment Group Tags", key: "tags.equipmentGroups" },
      { label: "Tag Flag", key: "tags.flag" },
      { label: "Self Assigned Equipment Family", key: "equipFamily" },
      {
        label: "Self Assigned Service Groups",
        key: "selfAssignedServiceGroups",
      },

      // new fields
      { label: "Admin Created", key: "registrations.adminCreated" },
      { label: "Case Studies", key: "companyInfo.caseStudies" },
      { label: "FT Manufacturing", key: "peopleStrength.inManufacturing" },
      { label: "FT Service", key: "peopleStrength.inServicing" },
      { label: "FT Management", key: "peopleStrength.ftManagement" },
      { label: "FT Admin", key: "peopleStrength.ftAdmin" },
      { label: "FT Others", key: "peopleStrength.ftOthers" },
      { label: "PT Manufacturing", key: "peopleStrength.ptManufacturing" },
      { label: "PT Service", key: "peopleStrength.ptService" },
      { label: "PT Others", key: "peopleStrength.ptOther" },
      { label: "Tools Carried to Site", key: "serviceCapabilities.toolsAndPlants" },
      { label: "Product Catalogue", key: "productCatalogue" },
      { label: "Experience", key: "experienceProjectDocs" },
      { label: "Image Uploads", key: "imageUploads" },
      { label: "Video Uploads", key: "videoUploads" },
      { label: "HSN", key: "companyInfo.hsn" },
      { label: "SAC", key: "companyInfo.sac" },
      {
        label: "GST Verification Status",
        key: "complianceData.gstVerification",
      },
      { label: "Reviews", key: "reviews" },
      { label: "Customer Tier", key: "customerTier" },

      //ref 1
      // {
      //   label: "Client Company Name (Reference 1)",
      //   key: "ref0.clientCompanyName",
      // },
      // {
      //   label: "Contact Number (Reference 1)",
      //   key: "ref0.contactName",
      // },
      // {
      //   label: "Mobile Number (Reference 1)",
      //   key: "ref0.mobileNo",
      // },
      // {
      //   label: "Client Email (Reference 1)",
      //   key: "ref0.clientEmail",
      // },
      // {
      //   label: "Role in Org (Reference 1)",
      //   key: "ref0.roleInOrg",
      // },
      // {
      //   label: "Plant Name (Reference 1)",
      //   key: "ref0.plantName",
      // },
      // {
      //   label: "Completion Certificate Name (Reference 1)",
      //   key: "ref0.completionCertificate.name",
      // },
      // {
      //   label: "Completion Certificate Url (Reference 1)",
      //   key: "ref0.completionCertificate.file.fileUrl",
      // },

      //ref 2
      // {
      //   label: "Client Company Name (Reference 2)",
      //   key: "ref1.clientCompanyName",
      // },
      // {
      //   label: "Contact Number (Reference 2)",
      //   key: "ref1.contactName",
      // },
      // {
      //   label: "Mobile Number (Reference 2)",
      //   key: "ref1.mobileNo",
      // },
      // {
      //   label: "Client Email (Reference 2)",
      //   key: "ref1.clientEmail",
      // },
      // {
      //   label: "Role in Org (Reference 2)",
      //   key: "ref1.roleInOrg",
      // },
      // {
      //   label: "Plant Name (Reference 2)",
      //   key: "ref1.plantName",
      // },
      // {
      //   label: "Completion Certificate Name (Reference 2)",
      //   key: "ref1.completionCertificate.name",
      // },
      // {
      //   label: "Completion Certificate Url (Reference 2)",
      //   key: "ref1.completionCertificate.file.fileUrl",
      // },

      //ref 3
      // {
      //   label: "Client Company Name (Reference 3)",
      //   key: "ref2.clientCompanyName",
      // },
      // {
      //   label: "Contact Number (Reference 3)",
      //   key: "ref2.contactName",
      // },
      // {
      //   label: "Mobile Number (Reference 3)",
      //   key: "ref2.mobileNo",
      // },
      // {
      //   label: "Client Email (Reference 3)",
      //   key: "ref2.clientEmail",
      // },
      // {
      //   label: "Role in Org (Reference 3)",
      //   key: "ref2.roleInOrg",
      // },
      // {
      //   label: "Plant Name (Reference 3)",
      //   key: "ref2.plantName",
      // },
      // {
      //   label: "Completion Certificate Name (Reference 3)",
      //   key: "ref2.completionCertificate.name",
      // },
      // {
      //   label: "Completion Certificate Url (Reference 3)",
      //   key: "ref2.completionCertificate.file.fileUrl",
      // },

      // docs
      // {
      //   label: "Other Documents Name",
      //   key: "otherDocs.name",
      // },
      // {
      //   label: "Other Documents",
      //   key: "otherDocs.file",
      // },
      { label: "Customer File Upload", key: "customerFileUpload" },
      // {label : 'Client Company Name (Experiences)', key : 'experiences.clientCompanyName'},
      /**
       * for reference:
       * Below fields(string values) could contain a backslash(already handled in backend),
       * but if it comes in the value(from some source),
       * then the excel csv data is pushed to extra cells to the right,
       * hence kept the below columns at last.
       */
      {
        label: "Products (manufacturingCapabilties)",
        key: "manufacturingCapabilties.products",
      },
      {
        label: "Plant Locations and Capacity(manufacturingCapabilties)",
        key: "manufacturingCapabilties.plantLocations",
      },
      {
        label: "Key Equipments[Equipment, Make and Capacity] (manufacturingCapabilties)",
        key: "manufacturingCapabilties.keyEquipments",
      },
      {
        label: "Nature of Work (serviceCapabilities)",
        key: "serviceCapabilities.natureOfWork",
      },
      {
        label: "Disciplines (serviceCapabilities)",
        key: "serviceCapabilities.disciplines",
      },
      {
        label: "Description (serviceCapabilities)",
        key: "serviceCapabilities.description",
      },
    ];
  };

  const handleFilterChange = (from, to, searchValue, vendorName) => {
    setStartDate(from);
    setEndDate(to);
    setGstNumber(searchValue);
    setVendorName(vendorName);
    setViewAdvancedFilter(false);
    setCallFilterApi(true);
  };

  const handleAdvancedFilter = (selectedOption, key) => {
    let selectedAdvancedFilterCopy = _.cloneDeep(selectedAdvancedFilter);
    selectedAdvancedFilterCopy[key] = selectedOption;
    setSelectedAdvancedFilter(selectedAdvancedFilterCopy);
  };

  const handleInitialFilters = (selectedOption, key) => {
    let selectedInitialFilterCopy = _.cloneDeep(selectedInitialFilterObj);
    selectedInitialFilterCopy[key] = selectedOption;
    setSelectedInitialFilterObj(selectedInitialFilterCopy);
  };

  const handleDateTypeFilters = (selectedOption, key) => {
    let selectedDateTypeCopy = _.cloneDeep(dateTypeSelected);
    selectedDateTypeCopy[key] = selectedOption;
    setDateTypeSelected(selectedDateTypeCopy);
  };

  const isValidPath = () => {
    switch (true) {
      case userRole.filter(
        (role) =>
          role === ROLES.SUPER_ADMIN ||
          role === ROLES.VENDOR_VIEW ||
          role === ROLES.VENDOR_EDIT ||
          role === ROLES.VENDOR_EXPORT ||
          role === ROLES.VENDOR_PUBLISH ||
          role === ROLES.AGENT_VENDOR,
      ).length > 0:
        return true;
      case userRole.filter(
        (role) =>
          role === ROLES.SUPER_ADMIN ||
          role === ROLES.CLIENT_VIEW ||
          role === ROLES.CLIENT_EDIT ||
          role === ROLES.CLIENT_EXPORT ||
          role === ROLES.CLIENT_PUBLISH,
      ).length > 0:
        history.push("/clients");
        break;
      case userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_CLIENT_COMMUNICATION).length >
        0:
        history.push("/client-vendor-messages");
        break;
      case userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN_MESSAGING).length > 0:
        history.push("/messaging");
        break;
    }
  };
  const returnVendordateTypeKeyName = (value) => {
    return value.split(" ")?.[0]?.toLowerCase();
  };

  const filterApiCall = async () => {
    if (isValidPath()) {
      setOpen(true);
      const getKeyName = dateTypeSelected?.["dateType"]?.length
        ? returnVendordateTypeKeyName(dateTypeSelected?.["dateType"])
        : "";
      let params = {
        dateType: getKeyName?.length ? VendorDateType[getKeyName] : "",
        gstn: formatGstArray(gstNumber),
        vendorName: vendorName,
        from: moment(startDate).format("YYYY-MM-DD"),
        to: moment(endDate).format("YYYY-MM-DD"),
        agentCode: agentCode,
        ...selectedInitialFilterObj,
      };

      await axios
        .post(process.env.REACT_APP_API_URL + "/v1/vendor-profile/vendor-filter", params, {
          // params: params,
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        })
        .then((result) => {
          if (result) {
            let res = result?.data?.vendors?.map((itr) => {
              return {
                ...itr,
                editMode: false,
                vendorPublished: itr.registrations.isActive ? "Published" : "Not Published",
              };
            });
            const vendorData = prepareData(res);
            setVendorListData(_.orderBy(res, ["registrations.registerDate"], ["desc"]));
            setVendorResultData(vendorData);
            setActualResultData(res);
            setFilterOption(result?.data?.filterValues || initialFilterState);
            setFilterCount(result?.data?.filterValuesCount || {});
            setSelectedAdvancedFilter(initialFilterState);
            setViewAdvancedFilter(false);
            customCsvDatahandler(res);
          }
          setOpen(false);
          setCallFilterApi(false);
        })
        .catch((error) => {
          console.log(error);
          setCallFilterApi(false);
          if (error?.response?.status === 403) {
            setHasAccess(false);
          }
          setOpen(false);
        });
    }
  };

  useEffect(async () => {
    if (callFilterApi) filterApiCall();
  }, [callFilterApi]);

  useEffect(async () => {
    filterApiCall();
  }, []);

  const handleEquipServiceTagCollection = (data, keyName) => {
    const keyNames = Object.keys(data?.groups?.[keyName]);
    const tagsData = keyNames.map((groupName) => {
      return data?.groups?.[keyName]?.[groupName];
    });
    return [...new Set(tagsData.flat())];
  };

  useEffect(async () => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/tags/All", {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      })
      .then(async (tagResults) => {
        await axios
          .get(process.env.REACT_APP_BASE_URL + "/library/tagsGroup/All", {
            headers: {
              authorization: `Bearer ${LocalStorage.get("token")}`,
            },
          })
          .then((result) => {
            /** previous source of DATA */
            // const serviceData = handleEquipServiceTagCollection(result.data, "service");
            // const equipmentData = handleEquipServiceTagCollection(result.data, "equipment");
            // const tagsData = tagResults?.data?.data;
            // const [equipmentsArray, oemArray, serviceArray] = getCategoryArrays(tagsData);
            // const updatedServiceOptions = serviceArray?.map((item) => { return item?.name }) || [];
            // const updatedEquipmentOptions = equipmentsArray.map((item) => { return item?.name }) || [];

            /** Equipment Group Options */
            getTagsDropdownOptions({
              type: "NEO4J_EQUIPMENT_GROUP_OPTIONS",
              successCallback: (res) => {
                setFetchedEquipments(prev => ({ ...prev, GROUPS: res?.data?.map((item) => item?.name) || [] }))
              },
              errCallback: (err) => { setFetchedEquipments(prev => ({ ...prev, GROUPS: [] })) }
            });
            
            /** Equipment Family Options */
            getTagsDropdownOptions({
              type: "NEO4J_EQUIPMENT_FAMILY_OPTIONS",
              successCallback: (res) => {
                setFetchedEquipments(prev => ({ ...prev, FAMILIES: res?.data?.map((item) => item?.name) || [] }))
              },
              errCallback: (err) => { setFetchedEquipments(prev => ({ ...prev, FAMILIES: [] })) }
            });

            /** Equipment Tags Options */
            getTagsDropdownOptions({
              type: "NEO4J_EQUIPMENT_TAGS_OPTIONS",
              successCallback: (res) => {
                setFetchedEquipments(prev => ({ ...prev, TAGS: res?.data?.map((item) => item?.name) || [] }))
              },
              errCallback: (err) => { setFetchedEquipments(prev => ({ ...prev, TAGS: [] })) }
            });

            /** Service Group Options */
            getTagsDropdownOptions({
              type: "NEO4J_SERVICE_GROUP_OPTIONS",
              successCallback: (res) => {
                setFetchedServices(prev => ({ ...prev, GROUPS: res?.data?.map((item) => item?.name) || [] }))
              },
              errCallback: (err) => { setFetchedServices(prev => ({ ...prev, GROUPS: [] })) }
            });

            /** Service Tags Options */
            getTagsDropdownOptions({
              type: "NEO4J_SERVICE_TAGS_OPTIONS",
              successCallback: (res) => {
                setFetchedServices(prev => ({ ...prev, TAGS: res?.data?.map((item) => item?.name) || [] }))
              },
              errCallback: (err) => { setFetchedServices(prev => ({ ...prev, TAGS: [] })) }
            });

            setInitialFilterObj({ ...initialFilterObj });
          });
      });
  }, []);

  /**
   * update the dropdown-opions for advanced filter
   */
  useEffect(() => {
    if (fetchedEquipments?.GROUPS?.length) {
      setInitialFilterObj((prev) => ({ ...prev, equipmentGroup: fetchedEquipments?.GROUPS || [] }));
    }
    if (fetchedEquipments?.FAMILIES?.length) {
      setInitialFilterObj((prev) => ({ ...prev, equipFamily: fetchedEquipments?.FAMILIES || [] }));
    }
    if (fetchedEquipments?.TAGS?.length) {
      setInitialFilterObj((prev) => ({ ...prev, equipment: fetchedEquipments?.TAGS || [] }));
    }
    if (fetchedServices?.GROUPS?.length) {
      setInitialFilterObj((prev) => ({ ...prev, serviceGroup: fetchedServices?.GROUPS || [] }));
    }
    if (fetchedServices?.TAGS?.length) {
      setInitialFilterObj((prev) => ({ ...prev, service: fetchedServices?.TAGS || [] }));
    }
  }, [fetchedEquipments, fetchedServices]);

  const handleAdvancedFilterClick = () => {
    let filteredResult = actualResultData;
    if (Object.keys(selectedAdvancedFilter).some((element) => selectedAdvancedFilter[element].length > 0)) {
      filteredResult = actualResultData.filter((vendor) => {
        return (
          (selectedAdvancedFilter?.equipment?.length
            ? vendor?.tags?.equipment?.some((item) => selectedAdvancedFilter?.equipment?.includes(item))
            : true) &&
          (selectedAdvancedFilter?.oem?.length
            ? vendor?.tags?.oem?.some((item) => selectedAdvancedFilter?.oem?.includes(item))
            : true) &&
          (selectedAdvancedFilter?.service?.length
            ? vendor?.tags?.service?.some((item) => selectedAdvancedFilter?.service?.includes(item))
            : true) &&
          (selectedAdvancedFilter?.equipmentGroups?.length
            ? vendor?.tags?.equipmentGroups?.some((item) => selectedAdvancedFilter?.equipmentGroups?.includes(item))
            : true) &&
          (selectedAdvancedFilter?.serviceGroups?.length
            ? vendor?.tags?.serviceGroups?.some((item) => selectedAdvancedFilter?.serviceGroups?.includes(item))
            : true) &&
          (selectedAdvancedFilter?.industriesServed?.length
            ? vendor?.sows?.industriesServed?.some((item) => selectedAdvancedFilter?.industriesServed?.includes(item))
            : true) &&
          (selectedAdvancedFilter?.statesServed?.length
            ? vendor?.sows?.statesServed?.length &&
              vendor?.sows?.statesServed?.some((item) => selectedAdvancedFilter?.statesServed?.includes(item))
            : true) &&
          (selectedAdvancedFilter?.turnOverSlab?.length
            ? vendor?.companyInfo?.turnOverSlab?.length &&
              selectedAdvancedFilter?.turnOverSlab?.includes(vendor?.companyInfo?.turnOverSlab)
            : true)
        );
      });
    }
    const prepareFilteredData = prepareData(filteredResult);
    setVendorResultData(prepareFilteredData);
  };

  const getCsvFileName = (startDate, endDate) => {
    return moment(startDate).format("YYYY-MM-DD") + "/" + moment(endDate).format("YYYY-MM-DD") + "-vendors.csv";
  };

  const publishVendor = async (params) => {
    setCallFilterApi(true);
    let payload = {
      activateVendor: !params?.value,
    };
    const result = await axios
      .put(process.env.REACT_APP_BASE_URL + "/vendor-registration/activate/" + params?.id, payload, {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      })
      .then(() => {
        console.log(params?.row);
        if (!params?.value && params?.row?.adminCreated?.toLowerCase() === "no") {
          updateVendorWithKarzaEntity(params?.row?.gstn);
        }
        setCallFilterApi(false);
        leadSquaredTrigger(params?.row?.gstn);
      })
      .catch(() => {
        setCallFilterApi(false);
      });
    if (result?.data?.status === "SUCCESS") {
      toggleVendorActivation(!activeVendorReload);
    }
  };

  const updateVendorWithKarzaEntity = async (gstn) => {
    setOpen(true);
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + "/v1/user-info/karza-api-update",
      headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
      data: { gstn },
    })
      .then((resp) => {
        setOpen(false);
      })
      .catch((err) => {
        setOpen(false);
        console.log({ err });
      });
  };

  const projectVendor = async (id, projectVendor) => {
    setCallFilterApi(true);
    let payload = {
      projectVendor: !projectVendor,
    };
    const result = await axios
      .put(process.env.REACT_APP_BASE_URL + "/vendor-registration/projectVendor/" + id, payload, {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      })
      .then(() => {
        setCallFilterApi(false);
      })
      .catch(() => {
        setCallFilterApi(false);
      });
    if (result?.data?.status === "SUCCESS") {
      toggleVendorActivation(!activeVendorReload);
    }
  };

  // This API call will be used in the future
  // const adminPublishVendor = async (id, activeVendor) => {
  //   setCallFilterApi(true);
  //   let payload = {
  //     activateVendor: !activeVendor,
  //   };
  //   const result = await axios.put(process.env.REACT_APP_BASE_URL + "/vendor-registration/activate/admin-publish/" + id, payload, {
  //     headers: {
  //       authorization: `Bearer ${LocalStorage.get("token")}`,
  //     },
  //   }).then(()=>{
  //     setCallFilterApi(false);
  //   }).catch(()=>{
  //     setCallFilterApi(false);
  //   })
  //   if (result?.data?.status === "SUCCESS") {
  //     toggleVendorActivation(!activeVendorReload);
  //   }
  // };

  const updatedColumns = [..._.cloneDeep(dynamicColumns), ..._.cloneDeep(columns)];

  return hasAccess ? (
    <>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <VendorFilter
        className="filter"
        startDate={startDate}
        endDate={endDate}
        handleFilterChange={handleFilterChange}
        showSearch={true}
        searchField={gstNumber}
        vendorName={vendorName}
        filterOption={initialFilterObj}
        selectedFilter={selectedInitialFilterObj}
        onHandleFiltersApplied={handleInitialFilters}
        onHandleDateTypeFiltersApplied={handleDateTypeFilters}
        dateTypeOptions={dateTypeObj}
        dateTypeSelected={dateTypeSelected}
      ></VendorFilter>
      <Link
        component="button"
        onClick={() => {
          if (viewAdvancedFilter) {
            setSelectedAdvancedFilter(initialFilterState);
            const prepareFilteredData = prepareData(actualResultData);
            setVendorResultData(prepareFilteredData);
          }
          setViewAdvancedFilter(!viewAdvancedFilter);
        }}
      >
        {viewAdvancedFilter ? "Close Advanced Filter" : "Advanced Filter"}
      </Link>
      {viewAdvancedFilter ? (
        <div className="advanced-filter">
          <AdvancedVendorFilter
            filterOption={filterOption}
            filterCount={filterCount}
            handleAdvancedFilter={handleAdvancedFilter}
            selectedAdvancedFilter={selectedAdvancedFilter}
            titleKey={"advanceFilters"}
          />
          <Button
            className="advanced-filter-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleAdvancedFilterClick()}
          >
            Filter
          </Button>
          <Button
            className="advanced-filter-reset-button"
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "15px" }}
            onClick={() => {
              setSelectedAdvancedFilter(initialFilterState);
              const prepareFilteredData = prepareData(actualResultData);
              setVendorResultData(prepareFilteredData);
            }}
          >
            Reset
          </Button>
        </div>
      ) : (
        <></>
      )}
      <div className="export-bulk-create">
        {showRetoolLink && (
          <div className="csv_button mr-3">
            <Link component="button" variant="body2" underline="hover">
              <a target="_blank" rel="noopener noreferrer" href={RETOOL_LINK}>
                Retool(VDM)
              </a>
            </Link>
          </div>
        )}
        {showRefreshTurnOver ? (
          <div className="csv_button mr-3">
            <Link
              component="button"
              variant="body2"
              underline="hover"
              onClick={() => {
                history.push("/refresh-tos");
              }}
            >
              Refresh Turnover Slab
            </Link>
          </div>
        ) : null}
        {showBulkUserPublish ? (
          <div className="csv_button mr-3">
            <Link
              component="button"
              variant="body2"
              underline="hover"
              onClick={() => {
                history.push("/bulk-vendor-publish");
              }}
            >
              Bulk Vendor Publish
            </Link>
          </div>
        ) : null}
        {showBulkUserCreation ? (
          <div className="csv_button mr-3">
            <Link
              component="button"
              variant="body2"
              underline="hover"
              onClick={() => {
                history.push("/bulk-vendor-creation");
              }}
            >
              Bulk User Creation
            </Link>
          </div>
        ) : null}
        {/* {showBulkUserDelete ? (
          <div className="csv_button">
            <Link
              component="button"
              variant="body2"
              underline="hover"
              onClick={() => {
                history.push("/bulk-vendor-deletion");
              }}
            >
              Bulk User Deletion
            </Link>
          </div>
        ) : null} */}
        {showExport ? (
          <div className="csv_button ml-2">
            <CSVLink
              data={csvVendorsdata}
              headers={getCsvHeaders(vendorList)}
              filename={getCsvFileName(startDate, endDate)}
              className="filter-link-csv"
            >
              <GetAppIcon></GetAppIcon>Export Full Report
            </CSVLink>
          </div>
        ) : null}
      </div>
      {
        <DataGridComp
          pageSize={50}
          col={updatedColumns}
          rows={vendorResultData}
          sortField="registerDate"
          hideExport={false}
        ></DataGridComp>
      }
    </>
  ) : (
    <NoAccess></NoAccess>
  );
};

export default VendorProfiles;
