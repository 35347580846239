import ClientCompanyInfoUpdate from "../components/ClientCompanyInfoUpdate/ClientCompanyInfoUpdate";
import ClientInfoUpdate from "../components/ClientInfoUpdate/ClientInfoUpdate";
import ClientPlantInfoUpdate from "../components/ClientPlantInfoUpdate/ClientPlantInfoUpdate";

export const CLIENT_PLANT_INFO = {
  id: 3,
  key: "plantInfo",
  cardName: "Plant Info",
  linkTo: "/client-plantInfo",
  render: () => <ClientPlantInfoUpdate />,
};

export const CLIENT_COMPANY_INFO = {
  id: 2,
  key: "clientCompany",
  cardName: "Company Info",
  linkTo: "/client-company",
  render: () => <ClientCompanyInfoUpdate />,
};

export const CLIENT_USER_INFO = {
  id: 1,
  key: "userInfo",
  cardName: "User Info",
  linkTo: "/client-info",
  render: () => <ClientInfoUpdate />,
};


export const ClientUpdateData = [
  CLIENT_USER_INFO,
  CLIENT_COMPANY_INFO,
  CLIENT_PLANT_INFO
];