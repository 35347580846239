import { SettingsTypes } from "_types/SettingsTypes";
import { EmailSettings } from "../Settings.interface";

export const ActionCreators: any = {
  getEmailSettings: () => ({
    types: [
      SettingsTypes.EMAIL_SETTINGS_REQUESTED,
      SettingsTypes.GET_EMAIL_SETTINGS_SUCCESS,
      SettingsTypes.EMAIL_SETTINGS_FAILURE,
    ],
    promise: (client: any) => client.get('email_settings', {}),
  }),

  updateEmailSettings: (id: string, data:EmailSettings) => ({
    types: [
      SettingsTypes.EMAIL_SETTINGS_REQUESTED,
      SettingsTypes.UPDATE_EMAIL_SETTINGS_SUCCESS,
      SettingsTypes.EMAIL_SETTINGS_FAILURE,
    ],
    promise: (client: any) => client.put(`email_settings/${id}`, {data}),
  }),
};

export default ActionCreators;
