import Routes from "./Routes";
import "./style.scss";
import { createGlobalStyle } from "styled-components";
import "antd/dist/antd.css";
import { globalStyles } from "./Theme";

export const GlobalStyle = createGlobalStyle`.

body{
  font-family: Poppins;

}
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  }
  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 104px;
    background: #d5d9ec;
    border-radius: 10px; /* color of the scroll thumb */
    /* roundness of the scroll thumb */
    /* creates padding around scroll thumb */
    padding-bottom:60px;
  }
`;
function App() {
  return (
    <div>
      <GlobalStyle theme={globalStyles} />
      {Routes}
    </div>
  );
}

export default App;
