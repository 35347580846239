import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { JobRequestActions } from "container/JobRequests/actions";
import {
  ICompareQuotationRequest,
  ICompareQuotationTabsProps,
  IUploadCompareQuotation,
} from "container/JobRequests/JobRequestDetails.interfaces";
import { TableContainer, TableHead, TableCell, TableRow, Table, Button, makeStyles } from "@material-ui/core";
import { getDateFromUTC } from "Utils/DisplayTimeFormat";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileOperationActions } from "container/common/FileOperations/actions";
import { FORM_DATA_KEY, QUOTATION_FILES_SUPPORTED_TYPES } from "../../jobRequest.constants";
import { Input } from "antd";
import { FileName, UploadFile } from "../Quotation.style";
import FileCopy from "@mui/icons-material/FileCopy";
import AntdToast from "components/Toast/AntdToast";
import { checkHasAnyOfTheRole } from "shared/helpers/checkHasAccess";
import ROLES from "constants/RolesConstants";
import { LoaderActions } from "components/Spinner/actions";
// import e from "express";

export const useStyles = makeStyles((theme) => ({
  tableContainer: { marginTop: 10 },
  button: { width: 200, marginLeft: "auto" },
  table: { minWidth: 650 },
  selectWidth: { width: 200 },
  tableHeader: { "& th": { borderRight: "1px solid #eeeeee" } },
  tableRow: { "& td": { height: 20, padding: "6px 8px 6px 8px", borderRight: "1px solid #eeeeee" } },
  docName: { width: 200 },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const CompareQuotation = (props: ICompareQuotationTabsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const jobRequestData = useSelector((state: any) => state.JobRequestReducers);
  let { compareQuotationRequest } = jobRequestData;
  const hasAccesToCompareQuotes = checkHasAnyOfTheRole([ROLES.CLIENT_PROJECTS, ROLES.CLIENT_VR_EDIT_PUBLISH]);

  useEffect(() => {
    dispatch(JobRequestActions.getCompareQuotation(props.jobRequestId));
  }, []);

  const submitComparedQuotation = (e: any, type: any) => {
    const uploadedFiles = e.target.files;
    uploadFile(uploadedFiles, type);
    // uploadComparedQuotation(uploadedFiles,type)
    
  };

  const uploadFile = (uploadedFiles: any, type: any) => {
    setisLoading(true)
    const uploadedFileData = { name: "", type: "" }
    try {
      const formData = new FormData();
      for (const key of Object.keys(uploadedFiles)) {
        const file = uploadedFiles[key];
        const isFileNotSupported = !QUOTATION_FILES_SUPPORTED_TYPES.includes(file?.name.split(".").pop().toLowerCase());
        if (isFileNotSupported) {
          setisLoading(false);
          return AntdToast({ type: "error", message: "Error", description: "Filetype not supported" });
          // filesWithError = [...filesWithError, { name: file.name, error: "Filetype not supported" }];
        } else if (file?.size > 500 * 1024 * 1024) {
          setisLoading(false);
          return AntdToast({ type: "error", message: "Error", description: "Max file size exceeded" });
          // filesWithError = [...filesWithError, { name: file.name, error: "Max file size exceeded" }];
        } else {
          // DO NOT Change the key name - "files[]"
          uploadedFileData.name = file.name;
          uploadedFileData.type = file.type;
          formData.append(FORM_DATA_KEY, file);
        }
      }
      dispatch(FileOperationActions.uploadFileToPermanentFolder(formData)).then((res: any) => {
        uploadComparedQuotation(formData, type, res, uploadedFileData);
      });
    } catch (err) {
      setisLoading(false);
    }
  };

  const uploadComparedQuotation = (formData: any, templateType: string, uploadedFilesResponse: any, uploadedFiles: any) => {

    let currentVersion, newComparedQuotation, userGroup;
    if(templateType === 'technical'){
      newComparedQuotation = compareQuotationRequest?.comparedTechnicalQuotations || [];
      userGroup = "technical";
    } else {
      newComparedQuotation = compareQuotationRequest?.comparedCommercialQuotations || [];
      userGroup = "commercial";
    }
    newComparedQuotation.push({
      name: uploadedFiles.name,
      type: uploadedFiles.type,
      version: currentVersion,
      uploadedOn: new Date(),
      file: uploadedFilesResponse[0],
      userGroup,
    });
    formData.append("templateType", templateType);
    formData.append("clientRequestId", compareQuotationRequest?._id ? compareQuotationRequest?._id :"" );
    formData.append("jobId", props.jobRequestId );
    if(templateType === 'technical'){
      formData.append("comparedTechnicalQuotations", JSON.stringify(newComparedQuotation));
    } else {
      formData.append("comparedCommercialQuotations", JSON.stringify(newComparedQuotation));
    }
    
    dispatch(JobRequestActions.uploadQuoteComparison(formData)).then(() => {
      AntdToast({ type: "success", message: "Success", description: "File uploaded successfully" }); 
      setisLoading(false)
      dispatch(JobRequestActions.getCompareQuotation(props.jobRequestId));
    });
  };

  const navigate = (quoteType: string) => {
    window.open(`${props.jobRequestId}/quote-comparison/${quoteType}`)
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
        <>
          <h6>Compare Quotations</h6>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell align="left" style={{ fontWeight: 600, width: "25%" }}></TableCell>
                <TableCell align="left" style={{ fontWeight: 600, width: "20%" }}>
                  Request On
                </TableCell>
                <TableCell align="left" style={{ fontWeight: 600, width: "10%" }}>
                  Resubmitted
                </TableCell>
                <TableCell align="left" style={{ fontWeight: 600, width: "30%" }}>
                  Compared Quotation
                </TableCell>
              </TableHead>
              <TableRow key={1}>
                <TableCell
                  style={{
                    marginBottom: "0.25rem",
                    width: "300px",
                  }}
                >
                  {(compareQuotationRequest?.comparedTechnicalQuotations?.length > 0 ||
                    compareQuotationRequest?.comparedCommercialQuotations?.length > 0) &&
                  !compareQuotationRequest?.resubmitted
                    ? "You have uploaded the compared Quotation "
                    : "Client has requested for compare quotations"}
                </TableCell>
                <TableCell width={"300px"}>{getDateFromUTC(compareQuotationRequest?.createdAt)}</TableCell>
                <TableCell width={"300px"}>{compareQuotationRequest?.resubmitted ? "Yes" : "No"}</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </>
        <TableCell style={{ display: "flex", alignItems: "center" }}>
          <Button
              disabled={isLoading}
            variant="contained"
            component="label"
            className="upload-button ml-3"
            startIcon={<FileUploadIcon />}
            style={{ width: '220px', background: '#47c882', color: '#ffffff' }}
          >
            Upload Technical
            <Input
              type="file"
              id="compared-quotation"
              hidden={true}
              onInput={(e) => submitComparedQuotation(e, "technical")}
            />
          </Button>
          {/* To Do: code clean up standards and styled components */}
            {compareQuotationRequest?.comparedTechnicalQuotations?.length > 0 && (
            <UploadFile
              href={
                compareQuotationRequest?.comparedTechnicalQuotations[
                  compareQuotationRequest?.comparedTechnicalQuotations?.length - 1
                ]?.file[0]?.fileUrl
              }
            >
              <FileCopy style={{ fontSize: "14", marginRight: "10px" }} />
              <FileName 
              onClick={() => navigate("technical")}>
                {
                  compareQuotationRequest?.comparedTechnicalQuotations[
                    compareQuotationRequest?.comparedTechnicalQuotations?.length - 1
                  ]?.name
                }
              </FileName>
            </UploadFile>
          )}
        </TableCell>
          {hasAccesToCompareQuotes && 
          <TableCell style={{ display: "flex", alignItems: "center" }}>

              <Button     
              variant="contained"
              component="label"
              className="upload-button ml-3"
              startIcon={<FileUploadIcon />}
              style={{ width: '220px', background: '#47c882', color: '#ffffff' }}
            >
              Upload Commercial
              <Input
                type="file"
                id="compared-quotation"
                hidden={true}
                onInput={(e) => submitComparedQuotation(e, "commercial")}
              />
            </Button>

            {/* To Do: code clean up standards and styled components  */}

              {compareQuotationRequest?.comparedCommercialQuotations?.length > 0 && (
              <UploadFile
                href={
                  compareQuotationRequest?.comparedCommercialQuotations[
                    compareQuotationRequest?.comparedCommercialQuotations?.length - 1
                  ]?.file[0]?.fileUrl
                }
              >
                <FileCopy style={{ fontSize: "14", marginRight: "10px" }} />
                <FileName onClick={() => {navigate("commercial")}}>
                  {
                    compareQuotationRequest?.comparedCommercialQuotations[
                      compareQuotationRequest?.comparedCommercialQuotations?.length - 1
                    ]?.name
                  }
                </FileName>

              </UploadFile>
              )}
          </TableCell>
        }</>
  );
};

export default CompareQuotation;
