import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import DataGridComp from "../../DataGrid/DataGrid";
import LocalStorage from "../../../../../_services/LocalStorage";
import { renderCellExpand } from "../../../../CellExpandPopup/CellExpandPopup";
import { getUserRole } from "../../../../../Utils/Storage";
import ROLES from "../../../../../constants/RolesConstants";
import { getFormattedTimeStamp } from "../../../../../Utils/DisplayTimeFormat";

interface LoginProps {
  startDate: any;
  endDate: any;
  setAccessStatus: (status: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const intialState = [
  {
    id: "",
    userName: { firstName: "", lastName: "" },
    lastLogInTime: "",
    totalLogins: 0,
    lastSearch: "",
    input: "",
    totalSearches: 0,
  },
];

const LoginLogs = (props: LoginProps) => {
  const { startDate, endDate } = props;
  const classes = useStyles();
  const userRole = getUserRole()?.split(',') || [];
  const [showExport, setShowExport] = React.useState(
    userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_EXPORT).length > 0
  );  
  
  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "User Name",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "id",
      headerName: "User Id",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "lastLogInTime",
      headerName: "Last Login",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "totalLogins",
      headerName: "Total Logins",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "lastSearchDate",
      headerName: "Last Search",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "searchEventInput",
      headerName: "Last SearchKeyword",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
    {
      field: "totalSearches",
      headerName: "Total Searches",
      width: 200,
      align: "left",
      resizable: true,
      renderCell: renderCellExpand
    },
  ];

  const [loginLogs, setLoginLogs] = React.useState(intialState);
  const [showLoader, setShowLoader] = React.useState(false);

  const prepareLoginLogs = (data: any) => {
    const loginLogs = data?.map((loginLog: any) => {
      return {
        id: loginLog.id,
        username:
          loginLog?.user?.firstName?.[0] + " " + loginLog?.user?.lastName?.[0],
        email: loginLog?.user?.email?.[0],
        lastLogInTime: getFormattedTimeStamp(loginLog?.lastLogInTime),
        totalLogins: loginLog?.totalLogins,
        lastSearchDate: loginLog?.searchEvents?.[0]?.lastSearchDate 
        ? moment(loginLog?.searchEvents?.[0]?.lastSearchDate)?.format("MMMM Do YYYY, h:mm:ss a")
        : "-",
        searchEventInput: loginLog?.searchEvents?.[0]?.input,
        totalSearches: loginLog?.searchEvents?.[0]?.totalSearches,
      };
    });
    return loginLogs;
  };

  useEffect(() => {
    setShowLoader(true);
    const getClientList = async () => {
      await axios.get(
        process.env.REACT_APP_BASE_URL + "/analytics/client",
        {
          params: {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
            action: "login",
          },
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`
          }
        }
      ).then((result) => {
        if (result?.data?.displayData) {
          const loginLogs = prepareLoginLogs(result.data.displayData);
          setLoginLogs(loginLogs);
        } else {
          setLoginLogs([]);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        if(error.response.status === 403){
          props.setAccessStatus(false);
        } 
      });
    };

    getClientList();
  }, [startDate, endDate]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {<DataGridComp col={columns} rows={loginLogs} sortField="date" hideExport={!showExport}/>}
    </>
  );
};

export default LoginLogs;
