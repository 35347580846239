import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login/Login";
import SideNavBar from "./components/SideNavBar/SideNavBar";

const Routes = (
  <Router  getUserConfirmation={() => {
    /* Empty callback to block the default browser prompt */
  }}>
    <Switch>
      <Route exact path="/login" component={Login} />
      <SideNavBar></SideNavBar>
    </Switch>
  </Router>
);

export default Routes;
