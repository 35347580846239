import React, { useRef } from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IProps {
  buttonName: string;
  buttonWidth?: string;
  loadingState: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  handleOnInput: React.FormEventHandler<HTMLInputElement>;
  handleOnButtonClick: React.MouseEventHandler<HTMLSpanElement>;
}

const useStyles = makeStyles((theme) => ({
  input: { display: "none", visibility: "hidden" },
  fileUploadButtonWidth: { width: 200 },
}));

export const CSVUploadButton = (props: IProps) => {
  const classes = useStyles();
  const { 
    inputRef, 
    buttonName, 
    buttonWidth, 
    loadingState, 
    handleOnInput, 
    handleOnButtonClick,
  } = props;

  return (
    <Button
      className={buttonWidth || classes.fileUploadButtonWidth}
      disabled={loadingState}
      variant="contained" 
      color="primary" 
      component="span" 
      size="small"
      onClick={handleOnButtonClick}
      startIcon={loadingState ? <CircularProgress /> : <BackupRoundedIcon />}
    >
      {loadingState ? "Processing..." : buttonName}
      <input
        hidden
        type="file"
        ref={inputRef || null}
        accept=".csv"
        disabled={loadingState}
        className={classes.input}
        onClick={(e: any) => {
          // for admin-user to be able to reupload same file
          if (e?.target?.value) e.target.value = "";
        }}
        onInput={e => {
          e.stopPropagation();
          handleOnInput(e);
        }}
      />
    </Button>
  );
};
