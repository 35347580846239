import React from "react";
import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoAccess from "../../components/NoAccess/NoAccess";
import ROLES from "../../constants/RolesConstants";
import { getUserRole } from "../../Utils/Storage";
import CSVReader from "react-csv-reader";
import * as _ from "lodash";
import "./VendorDeletion.scss";
import axios from "axios";
import LocalStorage from "../../_services/LocalStorage";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  peopleInfoContainer: {
    padding: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface IRowData {
  gstn: string;
  status: "SUCCESS" | "ERROR";
  message?: { message?: string };
  err?: string;
}

const VendorDeletion = () => {
  const classes = useStyles();
  const userRole = getUserRole()?.split(",") || [];
  const hasAccess = userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_PUBLISH).length > 0;

  const [loader, enableLoader] = React.useState(false);
  const [inputData, setInputData] = React.useState([]);
  const [resultData, setResultData] = React.useState<IRowData[]>([]);
  const [invalidGSTData, setInvalidGSTData] = React.useState<IRowData[]>([]);

  const handleFileUpload = (data: any) => {
    let invalidGSTs: IRowData[] = [];
    const inputData = data
      ?.filter((value: { gst?: string }) => {
        if (value?.gst) {
          if (validateGST(value?.gst)) return true;
          /** For displaying in the UI */
          invalidGSTs.push({
            gstn: value?.gst,
            status: "ERROR",
            message: { message: "Invalid GST" },
            err: "Invalid GST",
          })
        }
        return false;
      })
      ?.map((element: { gst: string }) => element.gst.trim());
    setInvalidGSTData(invalidGSTs); // update invalid GSTNs
    setInputData(inputData);
  };

  function validateGST(gstn: string) {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gstn);
    if (regTest) {
      // let a=65,b=55,c=36;
      // return Array['from'](g).reduce((i,j,k,g)=>{
      // p=(p=(j.charCodeAt(0)<a?parseInt(j):j.charCodeAt(0)-b)*(k%2+1))>c?1+(p-c):p;
      // return k<14?i+p:j==((c=(c-(i%c)))<10?c:String.fromCharCode(c+b));
      // },0);
    }
    return regTest;
  }

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const createUserAccounts = async () => {
    if (inputData.length) {
      enableLoader(true);
      const payload = {
        gstns: inputData,
      };
      await axios
        .post(process.env.REACT_APP_API_URL + "/v1/user-info/bulk-delete-users", payload, {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        })
        .then((result) => {
          if (result?.data?.status === "SUCCESS") {
            if (invalidGSTData?.length) {
              setResultData(result.data.result?.concat(invalidGSTData));
            } else {
              setResultData(result.data.result);
            }
          }
          enableLoader(false);
        })
        .catch((error) => {
          console.log("Error", error);
          enableLoader(false);
        });
    }
  };

  const getMessageDetails = (rowData: IRowData) => {
    if (rowData?.status === "SUCCESS") {
      return rowData?.message?.message || JSON.stringify(rowData?.message) || "Vendor deleted Successully";
    } else {
      return rowData?.err ? JSON.stringify(rowData?.err) : JSON.stringify(rowData?.message) || "Unable to delete vendor";
    }
  }

  return hasAccess ? (
    <>
      <h4>Bulk Vendor Deletion</h4>
      <div className={classes.peopleInfoContainer}>
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="container">
        <CSVReader
          cssClass="react-csv-input"
          label="Upload the CSV file"
          onFileLoaded={handleFileUpload}
          parserOptions={parseOptions}
        />
        <Button
          className="create-button"
          variant="contained"
          color="secondary"
          onClick={() => createUserAccounts()}
        >
          Bulk Delete Vendors
        </Button>
      </div>

      {resultData?.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>GST Number</TableCell>
                  <TableCell align="left">STATUS</TableCell>
                  <TableCell align="left">Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultData.map((row) => (
                  <TableRow key={row.gstn} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.gstn}
                    </TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">{getMessageDetails(row)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  ) : (
    <NoAccess></NoAccess>
  );
};

export default VendorDeletion;
