import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Divider, Flex, Input } from "venwiz-ui";
import { JobRequestActions } from "../actions";
import { IJobDocs, IJobFile } from "../Interfaces/JobRequestInterfaces";
import { downloadFile, generateZipFile } from "../file.handler";
import JSZip from "jszip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export const JobDocs = () => {
  const [jobIds, setJobIds] = useState("");
  const [jobDocs, setJobDocs] = useState([]);
  const dispatch = useDispatch();

  const getJobDocs = () => {
    const jobRequestIds = jobIds.split(",").map(function (item) {
      return item.trim();
    });
    dispatch(JobRequestActions.getJobDocs({ jobIds: jobRequestIds })).then(
      (data: any) => {
        setJobDocs(data);
        if (jobRequestIds.length > 0 && jobRequestIds[0] !== "") {
          dowloadFiles(data); // Download only if Job IDs are entered
        }
      }
    );
  };
  const dowloadFiles = async (data: Array<IJobDocs>) => {
    const zip = new JSZip();
    const mainFolder = zip.folder('job-docs');
  
    // Create an array of promises for each jobDoc
    const promises = data.map(async (jobDoc) => {
      let level1Folder = mainFolder?.folder(jobDoc.jobRequestId);
      await getSecondLevelFolderData(level1Folder, jobDoc.rfqs, "Rfqs");
      await getSecondLevelFolderData(level1Folder, jobDoc.technicalQuote, "Techinacal-quotes");
      await getSecondLevelFolderData(level1Folder, jobDoc.commercialQuote, "Commercial-quotes");
    });
  
    // Wait for all promises to resolve
    await Promise.all(promises);
  
    if (mainFolder) {
      generateZipFile(mainFolder, "Job Docs");
    }
  };

  const getSecondLevelFolderData = async (level1Folder: any, data: IJobFile[], folderName: any) => {
    if(data) {
      const level2Folder = level1Folder?.folder(folderName);
  
      for (const file of data) {
        const fileResponse = await downloadFile(file.fileUrl);
        const fileName = file.fileName || folderName;
        level2Folder?.file(fileName, fileResponse);
      }
    }
      return level1Folder;
  };

  return (
    <Box>
      <Flex width={"50%"} justifyContent={"space-between"}>
        <Input
          value={jobIds}
          placeholder={"Enter JobIds"}
          onChange={(e) => {
            setJobIds(e.target.value);
          }}
          style={{ border: "1px solid", margin: "0 5px" }}
        ></Input>
        <Button variant={"primary"} colorSchema={"blue"} onClick={getJobDocs}>
          Get Docs
        </Button>
      </Flex>
        <Divider margin={"20px 0"}/>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >JobId</TableCell>
            <TableCell align="right">RFQ Count</TableCell>
            <TableCell align="right">Technical Quote Count</TableCell>
            <TableCell align="right">Commenrcial Quote Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobDocs?.map((jobDoc:{jobRequestId: string; rfqs:string[]; technicalQuote: string[]; commercialQuote: string[]}) => (
            <TableRow
              key={jobDoc.jobRequestId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {jobDoc.jobRequestId}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                {jobDoc.rfqs?.length}
              </TableCell>
              <TableCell align="right">{jobDoc.technicalQuote?.length}</TableCell>
              <TableCell align="right">{jobDoc.commercialQuote?.length}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
};

