import {
  Backdrop,
  Button,
  CircularProgress,
  InputLabel,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useStyles } from "./VendorUpdatePeopleInfo.styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./VendorUpdatePeopleInfo.scss";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../NoAccess/NoAccess";
import AddIcon from "@material-ui/icons/Add";
import ChipsArray from "../ChipsArray/ChipsArray";
import {
  PeopleDbKeys,
  TotalManPowerTypes,
  FullTimeBreakUpFields,
  PartTimeBreakUpFields,
  NewKeyManagement,
  NewKeyContact,
  FullTimeBreakUpManagementAndService,
} from "./constants";
import { EditAddKeyPeople } from "./components/EditAddKeyPeople.jsx";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";
import { usePrompt } from "components/Modal/usePrompt";
import { checkUserHasOnlyVendorViewRole } from "shared/helpers/checkHasAccess";

const VendorUpdatePeopleInfo = () => {
  const [loader, enableLoader] = useState(false);
  const [reloadUpdate, setReloadUpdate] = useState(false);
  const [peopleInfo, setPeopleInfo] = useState({});
  const { gstn } = useParams();

  const access = checkUserHasOnlyVendorViewRole()

  const [hasAccess, setHasAccess] = useState(access);
  const [openWarningModal, setWarningModal] = useState(false);
  const [editChip, setEditChip] = useState(false);
  const [editChipData, setEditChipData] = useState({});

  const prompt = usePrompt();

  const updateVendor = async (peopleInfo) => {
    setEditChipData({});
    peopleInfo.keyManagement =
      peopleInfo?.keyManagement?.filter((itr) => itr?.firstName) || [];
    peopleInfo.strengths.keyContacts =
      peopleInfo?.strengths?.keyContacts?.filter((itr) => itr?.name);
    const result = await axios.put(
      process.env.REACT_APP_BASE_URL +
        "/people-strengths/people-key-management/" +
        gstn,
      peopleInfo,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result.data.status === "SUCCESS") {
      setReloadUpdate(true);
      leadSquaredTrigger(gstn);
    } else {
      setReloadUpdate(true);
    }
  };

  useEffect(async () => {
    enableLoader(true);
    setEditChip(false);
    setEditChipData({});
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "/people-strengths/people-key-management/" +
          gstn,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      )
      .then((result) => {
        if (result.data.status == "SUCCESS") {
          setPeopleInfo(result.data.data);
          setReloadUpdate(false);
        } else {
          setPeopleInfo({});
          setReloadUpdate(false);
        }
        enableLoader(false);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          setHasAccess(false);
        }
      });
  }, [reloadUpdate]);

  const classes = useStyles();

  const handleClose = () => {
    setWarningModal(false);
  };

  const editKeyPeopleData = (data, key, index) => {
    setEditChip(true);
    setEditChipData(() => ({ type: key, data, index }));
  };

  const removePeopleInfo = (personInfo) => {
    const { type, index, data } = personInfo;
    const editData = { ...peopleInfo };
    if (type === PeopleDbKeys.keyManagement) {
      editData[type] = editData?.[type]?.filter((itr, idx) =>
        data?._id ? itr?._id !== data?._id : idx !== index
      );
    } else {
      editData.strengths[type] = editData?.strengths?.[type]?.filter(
        (itr, idx) => (data?._id ? itr?._id !== data?._id : idx !== index)
      );
    }
    setPeopleInfo(editData);
    setEditChip(false);
    setEditChipData({});
    handleClose();
    updateVendor(editData);
  };

  const addNewPeople = (peopleType) => {
    prompt.setIsDataChanged(true)
    const editData = { ...peopleInfo };
    let index;
    if (PeopleDbKeys[peopleType] === PeopleDbKeys.keyManagement) {
      index = editData?.[peopleType]?.push({ ...NewKeyManagement });
      editKeyPeopleData({ ...NewKeyManagement }, peopleType, index - 1);
    } else {
      if (!editData?.strengths?.[peopleType]) {
        editData.strengths[peopleType] = [];
      }
      index = editData?.strengths?.[peopleType]?.push({ ...NewKeyContact });
      editKeyPeopleData({ ...NewKeyContact }, peopleType, index - 1);
    }
    setPeopleInfo(editData);
  };

  return hasAccess ? (
    <>
      <div className="container-fluid scrollable-container">
        <Dialog
          open={openWarningModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Are you sure you want to remove " +
                (editChipData?.data?.name ||
                  editChipData?.data?.firstName ||
                  "")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => removePeopleInfo(editChipData)}
              color="primary"
              autoFocus
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.peopleInfoContainer}>
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="row">
            <InputLabel className={classes.subHeader}>Totals</InputLabel>
          </div>
          <div className="row mt-1 mb-4">
            {Object.keys(TotalManPowerTypes).map((key) => (
              <div className="col" key={key}>
                <InputLabel className={classes.subHeader}>
                  {TotalManPowerTypes[key]}
                </InputLabel>
                <TextField
                  id={key}
                  value={peopleInfo?.strengths?.[key] || ""}
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                  onChange={(e) => {
                    prompt.setIsDataChanged(true)
                    const updateData = { ...peopleInfo };
                    updateData.strengths[key] = Number(e.target.value);
                    setPeopleInfo(updateData);
                  }}
                  fullWidth
                />
              </div>
            ))}
          </div>
          <div className="row">
            <InputLabel className={classes.subHeader}>
              Full Time Break Up
            </InputLabel>
          </div>
          <div className="row mt-1 mb-4">
            {Object.keys(FullTimeBreakUpManagementAndService).map((field) => (
              <div className="col " key={field}>
                <InputLabel className={classes.subHeader}>
                  {FullTimeBreakUpManagementAndService[field]}
                </InputLabel>
                <TextField
                  id={field}
                  value={peopleInfo?.strengths?.[field] || ""}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                  onChange={(e) => {
                    prompt.setIsDataChanged(true)
                    const updateData = { ...peopleInfo };
                    updateData.strengths[field] = Number(e.target.value);
                    setPeopleInfo(updateData);
                  }}
                />
              </div>
            ))}
            {Object.keys(FullTimeBreakUpFields).map((field) => (
              <div className="col " key={field}>
                <InputLabel className={classes.subHeader}>
                  {FullTimeBreakUpFields[field]}
                </InputLabel>
                <TextField
                  id={field}
                  value={peopleInfo?.strengths?.fullTimeBreakUp?.[field] || ""}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                  onChange={(e) => {
                    prompt.setIsDataChanged(true)
                    const updateData = { ...peopleInfo };
                    updateData.strengths.fullTimeBreakUp =
                      updateData?.strengths?.fullTimeBreakUp || {};
                    updateData.strengths.fullTimeBreakUp[field] = Number(
                      e.target.value
                    );
                    setPeopleInfo(updateData);
                  }}
                />
              </div>
            ))}
          </div>
          <div className="row">
            <InputLabel className={classes.subHeader}>
              Part Time Break Up
            </InputLabel>
          </div>
          <div className="row mt-1 mb-4">
            {Object.keys(PartTimeBreakUpFields).map((field) => (
              <div className="col " key={field}>
                <InputLabel className={classes.subHeader}>
                  {PartTimeBreakUpFields[field]}
                </InputLabel>
                <TextField
                  id={field}
                  value={peopleInfo?.strengths?.partTimeBreakUp?.[field]}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                  onChange={(e) => {
                    prompt.setIsDataChanged(true)
                    const updateData = { ...peopleInfo };
                    updateData.strengths.partTimeBreakUp =
                      updateData?.strengths?.partTimeBreakUp || {};
                    updateData.strengths.partTimeBreakUp[field] = Number(
                      e.target.value
                    );
                    setPeopleInfo(updateData);
                  }}
                />
              </div>
            ))}
          </div>
          {Object.keys(PeopleDbKeys).map((key) => (
            <div className="row mt-4" key={key}>
              <InputLabel className={classes.subHeader}>
                Key
                {key === PeopleDbKeys.keyContacts ? "Contacts" : "Management"}
              </InputLabel>
              <div className="col text-right">
                <Button
                  variant="contained"
                  className="ml-2 new_add_button mb-2"
                  startIcon={<AddIcon />}
                  onClick={() => addNewPeople(key)}
                >
                  Add
                </Button>
              </div>
              <ChipsArray
                ChipsArray={
                  peopleInfo?.strengths
                    ? (peopleInfo?.[key] || peopleInfo?.strengths?.[key])
                        ?.map((itr) => ({
                          ...itr,
                          label: itr?.name || itr?.firstName,
                        }))
                        ?.filter((itr) => itr?.name || itr?.firstName)
                    : []
                }
                deletable={true}
                onDelete={(chip, index) => {
                  setEditChipData(() => ({ type: key, data: chip, index }));
                  setWarningModal(true);
                }}
                onClick={(chip, index) => {
                  editKeyPeopleData(chip, key, index);
                }}
              ></ChipsArray>
            </div>
          ))}
          {editChip && (
            <EditAddKeyPeople
              chipData={editChipData?.data}
              setPeopleInfo={setPeopleInfo}
              type={editChipData?.type}
              data={peopleInfo}
              index={editChipData?.index}
              editable={true}
            />
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col text-right">
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
            onClick={() => {
              prompt.setIsDataChanged(false)
              updateVendor(peopleInfo)
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  ) : (
    <NoAccess></NoAccess>
  );
};

export default VendorUpdatePeopleInfo;
