export const readOnlyModules = {
  toolbar: [
    /** commented for future reference 
     * as below commented values are being used in vendor portal & client portal */
    // [{ header: [3, false] }],
    // ["bold", "italic", "underline", "strike", "link"],
    // [{ list: "ordered" }, { list: "bullet" }],
    // ["clean"],
  ],
  clipboard: { matchVisual: false },
};

export const BLOCK_TAG_NAME = "DIV";
