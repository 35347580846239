import { VendorProfileActions } from "container/UpdateVendorProfiles/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomerReview from "./CustomerReview/CustomerReview";
import ReviewsModal from "./Form";
import { ModalTypes } from "./interfaces/interfaces";
import NoReviews from "./NoReviews/NoReviews";
import OverallReview from "./OverallReview/OverallReview";
import { ReviewContainer } from "./VendorReviews.styles";
import ViewReviewModal from "./ViewReviews";
import NoAccess from "components/NoAccess/NoAccess";
import { checkUserHasOnlyVendorViewRole } from "shared/helpers/checkHasAccess";

const VendorReviews = () => {
  const dispatch = useDispatch();
  const reviewDetails = useSelector(
    (state: any) => state.VendorProfileReducers
  );
  const { reviewData, formModal, reviewModal, myReview } = reviewDetails;
  const { gstn } = useParams<{ gstn: string }>();
  const [selectedCardData, setSelectedCardData] = useState<any>({});

  const access = checkUserHasOnlyVendorViewRole()
  const [hasAccess, setHasAccess] = useState(access);

  const setModalType = (type: ModalTypes, data: any) => {
    setSelectedCardData(data);
    type === "view"
      ? dispatch(VendorProfileActions.setOpenReviewModal({ status: true }))
      : dispatch(VendorProfileActions.setOpenReviewFormModal({ status: true }));
  };

  useEffect(() => {
    dispatch(VendorProfileActions.getVendorProfileReviewData(gstn));
    return () => {
      dispatch(VendorProfileActions.setOpenReviewModal({ status: false }));
      dispatch(VendorProfileActions.setOpenReviewFormModal({ status: false }));
    };
  }, []);


  if (!hasAccess) {
    return <NoAccess />;
  }

  return (
    <ReviewContainer>
      {formModal && (
        <ReviewsModal
          onCancel={() => {
            dispatch(
              VendorProfileActions.setOpenReviewFormModal({ status: false })
            );
            dispatch(VendorProfileActions.setReviewErrorMess({ message: "" }));
            setSelectedCardData({});
          }}
          data={selectedCardData}
        />
      )}
      {reviewModal && (
        <ViewReviewModal
          onCancel={() => {
            dispatch(
              VendorProfileActions.setOpenReviewModal({ status: false })
            );
            dispatch(VendorProfileActions.setReviewErrorMess({ message: "" }));
            setSelectedCardData({});
          }}
          data={selectedCardData}
        />
      )}
      {reviewData?.data?.count > 0 ? (
        <>
          <OverallReview data={reviewData?.data} />
          <CustomerReview onCardClick={setModalType} data={reviewData?.data} />
        </>
      ) : (
        <NoReviews />
      )}
    </ReviewContainer>
  );
};

export default VendorReviews;
