import { IUserInfo, VerificationStatus } from "./vendorUserInfoUpdate.interfaces";

export const END_POINTS = {
  GET_USERS: (gstn: string) => process.env.REACT_APP_API_URL + "/v1/user-info/get-user-info/" + gstn,
  POST_USERS: process.env.REACT_APP_API_URL + "/v1/user-info/update-user-info/",
} as const;

export const INITIAL_USER_INFO_STATE: IUserInfo = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
} as const;

export const APPROVE_DROPDOWN_OPTIONS = [
  { label: "Accept", value: VerificationStatus.verified },
  { label: "Reject", value: VerificationStatus.notVerified },
  { label: "Doc Pending", value: VerificationStatus.documentPending },
  { label: "In-Process", value: VerificationStatus.inProgress },
];
