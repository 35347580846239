import React from "react";
import styled from "styled-components";

interface AnchorProps {
  message: string;
  changeBackground: boolean;
  linkData: any;
}

const Anchor = styled.a<any>`
  color: ${props => props?.changeBackground && " #ffffff"};
  text-decoration: none;
  border-bottom: ${props => props?.changeBackground && "2px solid #ffffff"};
  border-right:${props => !props?.changeBackground && "2px solid transparent"};
  border-left:${props => !props?.changeBackground && "2px solid transparent"};
`;
const Link = (props: AnchorProps) => {
  var regex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const messageArr = props?.message?.split(" ");

  //functions
  const checkUrl = (url: string) => {
    if (url.includes("http")) {
      return url;
    } else {
      return `https://${url}`;
    }
  };
  const checkString = (text: string) => {
    if (regex.test(text)) {
      return (
        <Anchor
          changeBackground={props?.changeBackground}
          href={props?.linkData?.value}
          target="_blank"
        >
          {text}
        </Anchor>
      );
    } else {
      return `\n${text}\n`;
    }
  };
  //return statement
  return (
    <>
      {messageArr?.map((item: string) => {
        return checkString(item);
      })}
    </>
  );
};

export default Link;
