import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import clsx from "clsx";
import { cloneDeep } from "lodash";
import { Alert, Autocomplete, Backdrop, Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import { useStyles } from "./vendorTagsUpdate.styles";
import axios from "axios";
import { Chip, FormControlLabel, Switch, Grid, Divider, Typography, Box, Paper, Badge, Snackbar } from "@material-ui/core";
import {
  getDifference,
  getSelectedTagsFromDb,
  TagsCategory,
  getCategoryArrays,
} from "../VendorCapabilitiesUpdate/VendorTags_helper";
import { TagsDispatchType, tagsReducer } from "../VendorCapabilitiesUpdate/tagsReducer";
import { VendorEquipmentTagsTable } from "../VendorCapabilitiesUpdate/VendorEquipmentTagsTable";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../NoAccess/NoAccess";
import ChipsArray from "../ChipsArray/ChipsArray";
import "./VendorTagsUpdate.scss";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import _ from "lodash";
import { getUniqueListBy, oemTagsLists, serviceTagsLists, equipmentTagsLists, getChipBgColor, TAGS_COLOR_LEGEND } from "./helper";
import { debounce } from "lodash";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";
import { AccordianContainer } from "./AccordionContainer";
import CircleIcon from '@mui/icons-material/Circle';
import { getTagsDropdownOptions } from "container/tags/Tags_helper";
import { createFilterOptions } from "@material-ui/lab";
import { ADD_NEW_NEO4J_TAG_ENDPOINTS } from "container/tags/constants";
import { BLOCK_TAG_NAME, readOnlyModules } from "constants/reactQuill.constants";
import { checkUserHasOnlyVendorViewRole } from "shared/helpers/checkHasAccess";

// text editor related values
const Quill = ReactQuill.Quill;
var Block = Quill.import("blots/block");
Block.tagName = BLOCK_TAG_NAME;
Quill.register(Block);

const VendorTags = () => {
  const oemRef = React.useRef(null);
  const equipmentRef = React.useRef(null);
  const serviceRef = React.useRef(null);
  const [loader, enableLoader] = React.useState(false);
  const [tagsData, setTagsData] = React.useState([]);
  const [newEquipmentTag, setNewEquipmentTag] = React.useState("");
  const [newOEMTag, setNewOEMTag] = React.useState("");
  const [newServiceTag, setNewServiceTag] = React.useState("");
  const [reload, setReload] = React.useState(false);

  const access = checkUserHasOnlyVendorViewRole()

  const [hasAccess, setHasAccess] = React.useState(access);
  const [reloadUpdate, setReloadUpdate] = React.useState(false);
  const { gstn } = useParams();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [availableNEO4JTags, setAvailableNEO4JTags] = React.useState({ oem: [], equipment: [], service: [] }); 
  const [filteredNEO4JTags, setFilteredNEO4JTags] = React.useState({ oem: [], equipment: [], service: [] });
  const [oemTagsList, setOemTagsList] = React.useState([]);
  const [serviceTagsList, setServiceTagsList] = React.useState([]);
  const [equipmentsTagsList, setEquipmentsTagsList] = React.useState([]);
  const [openDropdown, setOpenDropdown] = React.useState({ equipment: false, oem: false, service: false });
  const [capabilitiesDetails, setCapabilitiesDetails] = React.useState({ aboutUs: "", serviceDescription: "" });
  const [families, setFamilies] = React.useState({ equipFamily: [], serviceGroups: [] });
  const [openTagsPageSuccessSnackbar, setOpenTagsPageSuccessSnackbar] = React.useState(false);

  const handleShowPageSuccessSnackbar = () => setOpenTagsPageSuccessSnackbar(true);

  const handleClosePageSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {return; }
    setOpenTagsPageSuccessSnackbar(false);
  };

  const initialStateTags = {
    oem: [],
    equipment: [],
    service: [],
    categories: [],
    flag: 0, // can always be 0 or 1
  };
  const [tags, tagsDispatch] = React.useReducer(tagsReducer, initialStateTags);

  const [tagsGroupsOptions, setTagsGroupsOptions] = React.useState({
    equipment: {
      group1: [],
      group2: [],
      group3: [],
    },
    service: {
      group1: [],
      group2: [],
    },
  });
  const [tagsGroupsValues, setTagsGroupsValues] = React.useState({
    equipment: {
      group1: "",
      group2: "",
      group3: "",
    },
    service: {
      group1: "",
      group2: "",
    },
  });

  /** Fetch available tags from neo4j */
  useEffect(() => {
    (async () => {
      try {
        await getTagsDropdownOptions({
          type: "NEO4J_EQUIPMENT_TAGS_OPTIONS",
          successCallback: (res) => {
            setAvailableNEO4JTags((prev) => ({ ...prev, equipment: res?.data || [] }));
            setFilteredNEO4JTags((prev) => ({ ...prev, equipment: res?.data || [] }));
          },
        });
        await getTagsDropdownOptions({
          type: "NEO4J_OEM_TAGS_OPTIONS",
          successCallback: (res) => {
            setAvailableNEO4JTags((prev) => ({ ...prev, oem: res?.data || [] }));
            setFilteredNEO4JTags((prev) => ({ ...prev, oem: res?.data || [] }));
        },
        });
        await getTagsDropdownOptions({
          type: "NEO4J_SERVICE_TAGS_OPTIONS",
          successCallback: (res) => {
            setAvailableNEO4JTags((prev) => ({ ...prev, service: res?.data || [] }));
            setFilteredNEO4JTags((prev) => ({ ...prev, service: res?.data || [] }));
          },
        });
      } catch {
        console.error("Unable to get available tags from neo4j");
      }
    })()
  }, []);

  useEffect(async () => {
    /**
     * Calling new API to get vendor tags
     * */
    (async () => {
      try {
        enableLoader(true);
        await axios
          .get(process.env.REACT_APP_API_URL + "/v1/tags/vendorTags/" + gstn, {
            headers: {
              authorization: `Bearer ${LocalStorage.get("token")}`,
            },
          })
          .then((result) => {
            if (result?.data?.status == "SUCCESS") {
              const { serviceTags, oemTags, equipmentTags, categories, flag } = result?.data?.data;
              tagsDispatch({
                type: TagsDispatchType.LOAD_TAGS,
                payload: {
                  oem: oemTags,
                  service: serviceTags,
                  equipment: equipmentTags,
                  categories: categories,
                  flag,
                },
              });
              /** injecting family values(arrays) for chips */
              setFamilies({
                equipFamily: result?.data?.data?.equipFamily || [],
                serviceGroups: result?.data?.data?.serviceGroups || [],
              });
            }
            enableLoader(false);
          });
      } catch (error) {
        enableLoader(false);
        console.error("Unable to get Vendor Tags", error);
      }
    })();
  }, []);

  /** GET About Us and Service Description */
  useEffect(async () => {
    await axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + "/capabilities/new-capabilities/" + gstn,
      headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
    })
    .then((result) => {
      if (result.data?.status == "SUCCESS") {
        setCapabilitiesDetails({ 
          aboutUs: result?.data?.data?.aboutUs || "", 
          serviceDescription: result?.data?.data?.serviceCapabilities?.description || "",
        });
        enableLoader(false);
      }
    })
    .catch((error) => enableLoader(false));
  }, []);

  useEffect(async () => {
    enableLoader(true);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/tags/All", {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      })
      .then((result) => {
        if (result?.data?.status === "SUCCESS") {
          setTagsData(result?.data?.data);
          tagsGroupAll();
          enableLoader(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          setHasAccess(false);
          tagsGroupAll();
          enableLoader(false);
        }
      });
  }, [reload]);

  /** Enables addNewTag Button only if:
   *  at-least one of the equipment group Dropdown value is non-empty string
   */
  const isAnyEquipmentGroupSelected =
    !tagsGroupsValues.equipment?.group1 && !tagsGroupsValues.equipment?.group2 && !tagsGroupsValues.equipment?.group3;

  const isAnyServiceGroupSelected = !tagsGroupsValues.service?.group1 && !tagsGroupsValues.service?.group2;

  const handleGroupDropDownValue = (e, value, tagCategory, tagGroup) => {
    setTagsGroupsValues((prev) => ({
      ...prev,
      [tagCategory]: {
        ...prev[tagCategory],
        [tagGroup]: value === null || value === "" ? "" : value,
      },
    }));
  };

  const updateVendorCategories = async (gstn) => {
    const result = await axios.post(
      process.env.REACT_APP_API_URL + "/v1/tags/categories/" + gstn,
      {},
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result?.data?.status === "SUCCESS") {
      setReloadUpdate(true);
    } else {
      setReloadUpdate(true);
    }
  };

  const updateVendorTags = async (gstn, vendorTagsPayload) => {
    let updateVendorTagsPayload = {
      gstn,
      serviceTags: vendorTagsPayload.service,
      oemTags: vendorTagsPayload.oem,
      equipmentTags: vendorTagsPayload.equipment,
      flag: vendorTagsPayload.flag,
    };
    const result = await axios.post(
      process.env.REACT_APP_API_URL + "/v1/tags/vendorTags/",
      updateVendorTagsPayload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result?.data?.status === "SUCCESS") {
      setReloadUpdate(true);
      leadSquaredTrigger(gstn);
      updateVendorCategories(gstn);
      handleShowPageSuccessSnackbar();
    } else {
      setReloadUpdate(true);
    }
  };

  const vendorCategoriesChipsArray = tags?.categories?.map((obj, idx) => ({
    ...obj,
    label: `${obj?.category} - ${obj?.subcategory}`,
  }));

  const addNewTag = async (value, type, tagsGroupsValues) => {
    enableLoader(true);
    setReload(true);
    try {
      let vendors = [];
      if (value.length > 0) {
        const payload = {
          // createdAt: new Date(),
          // type: type,
          // newTagFlag: 1,
          // manuallyAssigned: 1,
          // vendorsList: vendors,
          name: value,
          // new neo4j tag payload
          synonyms: "",
          stopWords: "",
        };
        // switch (type.toLowerCase()) {
        //   case "equipment":
        //     payload.group1 = tagsGroupsValues.equipment?.group1;
        //     payload.group2 = tagsGroupsValues.equipment?.group2;
        //     payload.group3 = tagsGroupsValues.equipment?.group3;
        //     break;
        //   case "service":
        //     payload.group1 = tagsGroupsValues.service?.group1;
        //     payload.group2 = tagsGroupsValues.service?.group2;
        //     break;
        //   case "oem":
        //     payload.group1 = "New Tag";
        //     break;
        //   default:
        //     break;
        // }
        const ENDPOINT = (() => {
          switch (type.toLowerCase()) {
            case "equipment":
              return ADD_NEW_NEO4J_TAG_ENDPOINTS.EQUIPMENT;
            case "service":
              return ADD_NEW_NEO4J_TAG_ENDPOINTS.SERVICE;
            case "oem":
              return ADD_NEW_NEO4J_TAG_ENDPOINTS.OEM;
            default:
              return "";
          }
        })();
        if (ENDPOINT) {
          const result = await axios.post(process.env.REACT_APP_API_URL + ENDPOINT, payload);
          const { data } = result;
          /** Every Tag added via addNewTag feature is manually Assigned  */
          const newTag = { ...data?.data, newTagFlag: data?.data?.newTagFlag ? 1 : 0,  manuallyAssigned: 1 }; 
  
          /** 
           * @param {"oem" | "service" | "equipment"} keyFromTagState 
           * @param {{ _id: string }} newTag
           */
          const isNewTagUnique = (keyFromTagState, newTag) => {
            return tags?.[keyFromTagState].filter((tagObj) => tagObj?._id === newTag?._id)?.length === 0;
          };
  
          if (type === "OEM") {
            setNewOEMTag("");
            if (isNewTagUnique("oem", newTag)) {
              /** update payload state only if tag is unique */
              tagsDispatch({ type: TagsDispatchType.ADD_TAGS, payload: { tagCategory: TagsCategory.OEM, tags: [newTag] } });
            }
          }
          if (type === "Equipment") {
            setNewEquipmentTag("");
            if (isNewTagUnique("equipment", newTag)) {
              tagsDispatch({
                type: TagsDispatchType.ADD_TAGS,
                payload: { tagCategory: TagsCategory.EQUIPMENT, tags: [newTag] },
              });
            }
          }
          if (type === "Service") {
            setNewServiceTag("");
            if (isNewTagUnique("service", newTag)) {
              tagsDispatch({
                type: TagsDispatchType.ADD_TAGS,
                payload: { tagCategory: TagsCategory.SERVICE, tags: [newTag] },
              });
            }
          }
          // resetting tags groups dropdown
          setTagsGroupsValues((prev) => ({
            ...prev,
            equipment: { group1: "", group2: "", group3: "" },
            service: { group1: "", group2: "" },
          }));
          setReload(false);
          enableLoader(false);
        }
      }
    } catch (err) {
      setReload(false);
      enableLoader(false);
      throw err;
    }
  };

  const handleNewTag = (value, type) => {
    if (type === "Equipment") {
      setNewEquipmentTag(value);
    } else if (type === "OEM") {
      setNewOEMTag(value);
    } else if (type === "Service") {
      setNewServiceTag(value);
    }
  };

  const handleAutoComplete = (_event, value, tagCategory) => {
    let addTags = getDifference(value, tags[tagCategory]);
    if (addTags?.length === 1) addTags[0].manuallyAssigned = 1; // manually assigned tag from dropdown
    tagsDispatch({ type: TagsDispatchType.ADD_TAGS, payload: { tagCategory, tags: addTags } });
  };

  const removeOption = (_id, type) => {
    tagsDispatch({ type: TagsDispatchType.REMOVE_TAGS, payload: { tagCategory: type, _id } });
  };

  const autoFillTags = async () => {
    enableLoader(true);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/sow/" + gstn, {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      })
      .then(async () => {
        const result = await axios.get(process.env.REACT_APP_API_URL + "/v1/tags/allTags/" + gstn, {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        });
        if (result?.data) {
          tagsDispatch({
            type: TagsDispatchType.ADD_TAGS,
            payload: { tagCategory: TagsCategory.OEM, tags: Array.isArray(result?.data?.oem) ? result.data.oem : [] },
          });
          tagsDispatch({
            type: TagsDispatchType.ADD_TAGS,
            payload: {
              tagCategory: TagsCategory.EQUIPMENT,
              tags: Array.isArray(result?.data?.equipment) ? result.data.equipment : [],
            },
          });
          tagsDispatch({
            type: TagsDispatchType.ADD_TAGS,
            payload: {
              tagCategory: TagsCategory.SERVICE,
              tags: Array.isArray(result?.data?.service) ? result.data.service : [],
            },
          });
          enableLoader(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          setHasAccess(false);
        }
      });
  };

  const tagsGroupAll = async () => {
    try {
      enableLoader(true);
      const resp = await axios.get(process.env.REACT_APP_BASE_URL + "/library/tagsGroup/All", {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      });
      if (resp.status === 200) {
        setTagsGroupsOptions(resp.data.groups);
        enableLoader(false);
      }
    } catch (err) {
      enableLoader(false);
    }
  };

  const classes = useStyles();

  const [equipmentsArray, oemArray, serviceArray] = getCategoryArrays(tagsData);

  const equipmentsOptions = getDifference(equipmentsArray, tags.equipment);
  const oemOptions = getDifference(oemArray, tags.oem);
  const serviceOptions = getDifference(serviceArray, tags.service);
  const selectedOemTags = tags.oem || [];
  const selectedEquipmentTags = tags.equipment || [];
  const selectedServiceTags = tags.service || [];

  let updatedOemOptions = [],
    updatedEquipmentOptions = [],
    updatedServiceOptions = [];
  if (oemOptions?.length > 0) {
    updatedOemOptions = getUniqueListBy(oemOptions, "name");
    updatedOemOptions = _.differenceBy(updatedOemOptions, selectedOemTags, "name");
  }
  if (equipmentsOptions?.length > 0) {
    updatedEquipmentOptions = getUniqueListBy(equipmentsOptions, "name");
    updatedEquipmentOptions = _.differenceBy(updatedEquipmentOptions, selectedEquipmentTags, "name");
  }
  if (serviceOptions?.length > 0) {
    updatedServiceOptions = getUniqueListBy(serviceOptions, "name");
    updatedServiceOptions = _.differenceBy(updatedServiceOptions, selectedServiceTags, "name");
  }

  const renderAddNewTagBttn = (refName, keyName, tagsGroupsValues) => {
    return (
      <button
        style={{
          position: "absolute",
          width: "118px",
          right: "100px",
          height: "30px",
          top: "14px",
          zIndex: "10",
        }}
        onClick={(e) => {
          addNewTag(refName?.current?.value, keyName, tagsGroupsValues);
        }}
      >
        Add New Tag
      </button>
    );
  };

  const autoCompleteInputField = (ref, keyName) => {
    return (
      <TextField id="outlined-basic" label={keyName} variant="outlined"
        style={{
          position: "absolute",
          width: "90%",
          height: "100%",
          top:" 0px",
          border:"transparent",
          maxHeight:"56px"
        }}
        onChange={(e) => {
          debouncedChangeHandler(e.target.value, keyName);
        }}
        inputRef={ref}
        onBlur={() => {
          setOpenDropdown({ ...openDropdown, [keyName.toLowerCase()]: false });
        }}
        onFocus={() => {
          setOpenDropdown({ ...openDropdown, [keyName.toLowerCase()]: true });
        }}
      />
    );
  };
  const setTagsList = (tagName, listData, searchKey) => {
    switch (tagName) {
      case "Equipment":
        setEquipmentsTagsList(listData);
        break;
      case "OEM":
        setOemTagsList(listData);

        break;
      case "Service":
        setServiceTagsList(listData);
        break;
      default:
        break;
    }
  };

  const tagsDropdownListApi = async (searchKeyword, tagName) => {
    if (searchKeyword) {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + `/v1/tags/suggestions/${tagName}/${searchKeyword.trim()}`,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      );
      if (result?.data) {
        setTagsList(tagName, result?.data, searchKeyword);
      }
    }
  };

  const debouncedChangeHandler = React.useCallback(debounce(tagsDropdownListApi, 1000), []);
  
  const filterAutocompleteTagOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.name || "",
  });

  return hasAccess ? (
    <>
      <div className="container-fluid scrollable-container">
        {/* <hr className={classes.splitLine}></hr> */}
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box position={"sticky"} top="0px" zIndex={20}>
          <Paper elevation={3}>
            {capabilitiesDetails?.aboutUs && (
              <AccordianContainer name="About Us" accordionContent={capabilitiesDetails?.aboutUs} />
            )}
            {capabilitiesDetails?.serviceDescription && (
              <AccordianContainer 
                name="Service Description" 
                // accordionContent={capabilitiesDetails?.serviceDescription}
                accordionContent={
                  <div className={"custom-react-quill-container"}>
                    <ReactQuill 
                      readOnly 
                      theme="snow" 
                      preserveWhitespace 
                      modules={readOnlyModules} 
                      value={capabilitiesDetails?.serviceDescription} 
                    />
                  </div>
                } 
              />
            )}
            {Boolean(families?.equipFamily?.length || families?.serviceGroups?.length) && (
              <AccordianContainer
                accordianHeader={
                  <Box display={"flex"} gridGap={"1.5rem"}>
                    {Boolean(families?.equipFamily?.length) && (
                      <Badge badgeContent={families?.equipFamily?.length || 0} color="primary">
                        <Typography>Equipment Families</Typography>
                      </Badge>
                    )}
                    {Boolean(families?.serviceGroups?.length) && (
                      <Badge badgeContent={families?.serviceGroups?.length || 0} color="primary">
                        <Typography>Service Groups</Typography>
                      </Badge>
                    )}
                  </Box>
                }
                accordionContent={
                  <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <InputLabel className={`${classes.subHeader} ${classes.familiesLabel}`}>Equipment Families</InputLabel>
                    <div className={clsx(classes.tagsContainer, classes.fullWidth)}>
                      {families?.equipFamily?.map((value) => (
                        <Chip key={value} label={value} className={classes.tagChip} style={{backgroundColor: getChipBgColor({})}} />
                      ))}
                    </div>
                    <InputLabel className={`${classes.subHeader} ${classes.familiesLabel}`}>Service Groups</InputLabel>
                    <div className={clsx(classes.tagsContainer, classes.fullWidth)}>
                      {families?.serviceGroups?.map((value) => (
                        <Chip key={value} label={value} className={classes.tagChip} style={{backgroundColor: getChipBgColor({})}} />
                      ))}
                    </div>
                  </Grid>
                }
              />
            )}
          </Paper>
        </Box>
        <Divider variant="fullWidth" style={{ marginTop: 16 }} />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.tagsActionButtons}
        >
          <Button
            className="add-new-button"
            variant="contained"
            onClick={autoFillTags}
          >
            Autofill Tag
          </Button>
          <FormControlLabel
            value="start"
            label="Doubtful Flag"
            labelPlacement="start"
            control={
              <Switch
                color="primary"
                checked={tags.flag ? true : false}
                onChange={() => {
                  tagsDispatch({
                    type: TagsDispatchType.UPDATE_FLAG,
                    // toggle the flag
                    payload: { flag: tags.flag === 0 ? 1 : 0 },
                  });
                }}
              />
            }
          />
        </Grid>
        {/* -----------------Oem tags----------------- */}
        <InputLabel className={classes.subHeader}>OEM</InputLabel>
        <div className={classes.tagsContainer}>
          {selectedOemTags.map((value) => (
            <Chip
              key={value._id}
              label={value.name}
              className={classes.tagChip}
              onDelete={() => {
                removeOption(value._id, TagsCategory.OEM);
              }}
              style={{backgroundColor: getChipBgColor(value)}}
            />
          ))}
        </div>
        {availableNEO4JTags?.oem && availableNEO4JTags?.oem?.length > 0 && (
          <div className={"autocomplete-wrapper"}>
            <Autocomplete
              className={classes.customAutocomplete}
              multiple
              limitTags={5}
              disableCloseOnSelect
              value={selectedOemTags}
              onChange={(e, value, reason) => handleAutoComplete(e, value, TagsCategory.OEM, reason)}
              // options related props
              filterSelectedOptions
              filterOptions={filterAutocompleteTagOptions}
              options={filteredNEO4JTags?.oem || []}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              // rendering related props
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option?._id || option?.createdAt || option?.name}>
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <div>
                  <TextField {...params} label="OEM" placeholder="Tags" inputRef={oemRef} />
                  {renderAddNewTagBttn(oemRef, "OEM", tagsGroupsValues)}
                </div>
              )}
            />
          </div>
        )}
        {/* -----------------Equipment tags----------------- */}
        <InputLabel className={classes.subHeader}>Equipment</InputLabel>
        <div className={classes.tagsContainer}>
          {selectedEquipmentTags.map((value) => (
            <Chip
              key={value._id}
              label={value.name}
              className={classes.tagChip}
              onDelete={() => {
                removeOption(value._id, TagsCategory.EQUIPMENT);
              }}
              style={{backgroundColor: getChipBgColor(value)}}
            />
          ))}
        </div>
        {availableNEO4JTags?.equipment && availableNEO4JTags?.equipment?.length > 0 && (
          <div className={"autocomplete-wrapper"}>
            <Autocomplete
              className={classes.customAutocomplete}
              multiple
              disableCloseOnSelect
              value={selectedEquipmentTags}
              onChange={(e, value) => handleAutoComplete(e, value, TagsCategory.EQUIPMENT)}
              // option related props
              filterSelectedOptions
              filterOptions={filterAutocompleteTagOptions}
              options={filteredNEO4JTags?.equipment || []}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              // rendering related props
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option?._id || option?.createdAt || option?.name}>
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <div>
                  <TextField {...params} label="EQUIPMENT" placeholder="Tags"  inputRef={equipmentRef} />
                  {renderAddNewTagBttn(equipmentRef, "Equipment", tagsGroupsValues)}
                </div>
              )}
            />
          </div>
        )}
        {/* ----------------- Service Tags ----------------- */}
        <InputLabel className={classes.subHeader}>Service</InputLabel>
        <div className={classes.tagsContainer}>
          {selectedServiceTags.map((value) => (
            <Chip
              key={value._id}
              label={value.name}
              className={classes.tagChip}
              onDelete={() => {
                removeOption(value._id, TagsCategory.SERVICE);
              }}
              style={{backgroundColor: getChipBgColor(value)}}
            />
          ))}
        </div>
        {availableNEO4JTags?.service && availableNEO4JTags?.service?.length > 0 && (
          <div className={"autocomplete-wrapper"}>
            <Autocomplete
              className={classes.customAutocomplete}
              multiple
              disableCloseOnSelect
              value={selectedServiceTags}
              onChange={(e, value) => handleAutoComplete(e, value, TagsCategory.SERVICE)}
              // options related props
              filterSelectedOptions
              filterOptions={filterAutocompleteTagOptions}
              options={filteredNEO4JTags?.service || []}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              // rendering related props
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                  <li {...props} key={option?._id || option?.createdAt || option?.name}>
                    {option.name}
                  </li>
                )}
              renderInput={(params) => {
                return (
                  <div>
                    <TextField {...params} label="SERVICE" placeholder="Tags" inputRef={serviceRef}  />
                    {renderAddNewTagBttn(serviceRef, "Service", tagsGroupsValues)}
                  </div>
                );
              }}
            />
          </div>
        )}
        <div className="row pl-3 mb-4">
          <InputLabel className="sub-header">Vendor Categories List [category - subcategory]</InputLabel>
          <ChipsArray ChipsArray={vendorCategoriesChipsArray} deletable={false} onDelete={false}></ChipsArray>
        </div>
        <VendorEquipmentTagsTable
          allSelectedTags={getSelectedTagsFromDb(
            [...selectedEquipmentTags, ...selectedOemTags, ...selectedServiceTags],
            tagsData
          )}
        />
        <Box display={"flex"} alignItems={"flex-start"} marginTop={"1rem"} padding={"0.3rem"} gridGap={"0.8rem"}>
          <Box gridGap={"1rem"} border={"1px dashed grey"} borderRadius={"8px"} padding={"0.2rem"}>
            <Box display={"flex"} flexWrap={"wrap"}>
              {TAGS_COLOR_LEGEND.map(item => (
                <Box display={"flex"} gridGap={"0.25rem"} marginRight={"0.8rem"} marginTop={"0.5rem"}>
                  <CircleIcon sx={{ color: item.COLOR }} />
                  <Typography>{item.DESCRIPTION}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <hr className={classes.splitLine}></hr>
      </div>
      <div className="save-button-container">
        <Button
          variant="contained"
          color="primary"
          className="save-button"
          startIcon={<CloudUploadIcon />}
          onClick={() => {updateVendorTags(gstn, tags)}}
        >
          Save
        </Button>
      </div>
      {/* Success Snackbar */}
      <Snackbar
        autoHideDuration={3000}
        open={openTagsPageSuccessSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClosePageSuccessSnackbar} severity="success">
          Saved Changes Successfully!
        </Alert>
      </Snackbar>
    </>
  ) : (
    <NoAccess></NoAccess>
  );
};

export default VendorTags;
