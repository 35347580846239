import moment from "moment";
import Text from "../Template/Text";
import File from "../Template/File";
import { OutgoingMessageContainer, MessageDate, MessageWrapper, DeleteIcon, ParticipantImage, Message } from "../Chat.styles";
import React from "react";

interface MessageProps {
  message_id: string;
  date: string;
  sharedFiles: any;
  message: string;
  link?: any;
  message_type?: string;
}

interface TextProps {
  message: MessageProps;
  onMouseLeave: () => void;
  onMouseEnter: () => void;
  index: number;
  onHoverId: any;
  setMessageData: () => void;
  setShowDeleteModal: () => void;
  clientInitials: string;
}
const OutgoingText = (props: TextProps) => {
  const renderTemplate = (data: any) => {
    switch (data?.message_type) {
      case "file":
        return <File file={data?.sharedFiles?.[0]} message={data?.message} type={"outgoing"}/>;
      case "text":
        return (
          <Text
            message={data?.message}
            isLinkAvailable={false}
            message_type={"outgoing"}
          />
        );
      case "link":
        return (
          <Text
            message={data?.message}
            isLinkAvailable={true}
            message_type={"outgoing"}
            linkData={props?.message?.link}

          />
        );
      default:
        return (
          <Text
            message={data?.message}
            isLinkAvailable={false}
            message_type={"outgoing"}
          />
        );
    }
  };
  return (
    <>
      <OutgoingMessageContainer key={props?.message?.message_id}>
      {props.message.message_type !== 'request' && 
        <MessageDate>
          {moment(props?.message?.date).format("YYYY-MM-DD HH:mm:ss")}
        </MessageDate>
      }
        <MessageWrapper
          className={"message-outgoing"}
          onMouseLeave={() => props?.onMouseLeave()}
          onMouseEnter={() => props?.onMouseEnter()}
        >
          {!!(props?.index + 1 === props?.onHoverId) && props.message.message_type !== 'request' && (
            <DeleteIcon
              onClick={() => {
                props?.setMessageData();
                props?.setShowDeleteModal();
              }}
              src="images/trash.svg"
            ></DeleteIcon>
          )}
          <Message>
            {renderTemplate(props?.message)}
          </Message>
          {props.message.message_type !== 'request' && 
            <ParticipantImage bgColor="#215ec9" color="#FFFFFF">
              {props?.clientInitials}
            </ParticipantImage>
          }
        </MessageWrapper>
      </OutgoingMessageContainer>
    </>
  );
};

export default OutgoingText;
