import { useState } from "react";
import { PromptContext } from "./PromptContext";
import { ReactPrompt } from "./ReactPrompt";
import { createPortal } from "react-dom";

export const PromptProvider = (props: any) => {
  const [isDataChanged, setIsDataChanged] = useState(false);
  return (
    <PromptContext.Provider value={{setIsDataChanged, isDataChanged}}>
      {props.children}
      {createPortal(
        <div
          style={{
            position: "absolute",
            bottom: "auto",
          }}
        >
          <ReactPrompt
            when={isDataChanged}
            okText="Discard Changes and Continue"
            confirmText="Stay on the Page"
            onOK={() => true}
            onConfirm={() => false}
          />
        </div>,
        document.body
      )}
    </PromptContext.Provider>
  );
};
