import React, { useState } from "react";
import * as _ from "lodash";
import axios from "axios";
import "./FileStorage.scss";
import { Button } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import LocalStorage from "../../_services/LocalStorage";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const FileStorage = (props) => {
  const defaultFileTypes = "image/jpeg,image/png,application/pdf";
  const uploadFile = async (e) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const result = await axios.post(process.env.REACT_APP_BASE_URL + "/file-storage/upload", data, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    });
    if (result.data.status === "SUCCESS") {
      createPermanentLink(result.data.data, e.target.files[0].name);
    } else {
    }
  };

  const openDocument = (chipvalue) => {
    const win = window.open(chipvalue, "_blank");
    win.focus();
  };

  const createPermanentLink = async (payload, fileName) => {
    const result = await axios.post(process.env.REACT_APP_BASE_URL + "/file-storage/permanent", payload, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    });
    if (result?.data?.status === "SUCCESS") {
      const file = result.data.data;
      props.setLocalState(file, fileName);
    } else {
    }
  };

  return (
    <>
      <div className="row">
        <Button variant="contained" component="label" style={{background: "#3385ff"}} startIcon={<FileUploadIcon />}>
          Upload
          <input
            type="file"
            hidden
            accept={props.acceptableFileTypes ? props.acceptableFileTypes : defaultFileTypes}
            onChange={(e) => uploadFile(e)}
          />
        </Button>
      </div>
      <div className="row mt-2">
        <Stack className="wrap-content" direction="row" spacing={1}>
          {props?.data?.length > 0 &&
            props?.data?.map((element, index) => {
              return (
                <Chip
                  key={index}
                  color="info"
                  size="small"
                  style={{ marginTop: "4px" }}
                  label={element?.name?.length > 10 ? element?.name?.substring(0, 7) + "..." : element?.name}
                  onDelete={(e) => props.removeDocuments(e, element)}
                  onClick={() => openDocument(element?.file?.fileUrl)}
                />
              );
            })}
        </Stack>
      </div>
    </>
  );
};

export default FileStorage;
