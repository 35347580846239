import { Tooltip } from "antd";
import Avatar from "components/Avatar/Avatar";
import Rating from "components/Rating/Rating";
import React from "react";
import { isAnyParameterReviewed } from "shared/handlers/vendorReview.handlers";
import { formatTime } from "Utils/DisplayTimeFormat";
import { parametersConstants } from "../constants";
import { IParametersConstants, IViewReviews } from "../interfaces/interfaces";
import {
  ViewReviewsCompDetails,
  ViewReviewsContainer,
  ViewReviewsCompanyName,
  ViewReviewsPostDetails,
  ViewReviewsOverallRating,
  ViewReviewsDesc,
  ViewReviewsParameterWrapper,
  ViewReviewsParameterItems,
  ViewReviewsParameterText,
  ViewReviewsParameterItemHeaderContent,
  ModalRatingContainer,
  ViewParameterContainer,
  ViewReviewsParameterContainer,
  DividerLine
} from "../VendorReviews.styles";

const ViewReviews = (props: IViewReviews) => {
  const { data } = props;
  const returnClientDetails = () => {
    const name = data?.clientDetails?.name;
    const designation = data?.clientDetails?.designation;
    return `${name} ${designation?.length ? "," : ""} ${designation}`;
  };

  const renderSpecificPanelParameters = (type: string, index: number) => {
    if (type === "leftPanel" && index % 2 === 0) {
      return true;
    }
    if (type === "rightPanel" && !(index % 2 === 0)) {
      return true;
    }
  };

  const renderParametersUi = (panelType: string) => {
    return (
      <ViewReviewsParameterWrapper>
        {parametersConstants?.map((item: IParametersConstants, index: number) => {
          return (data?.detailedReview?.[item?.keyName]?.rating ||
            data?.detailedReview?.[item?.keyName]?.comment?.length) &&
            renderSpecificPanelParameters(panelType, index) ? (
            <ViewReviewsParameterItems>
              <ViewReviewsParameterItemHeaderContent>
                <ViewReviewsParameterText>{item?.bttnText}</ViewReviewsParameterText>
                <Rating
                  value={data?.detailedReview?.[item?.keyName]?.rating}
                  color={"#FFCB45"}
                  allowHalf
                  disabledStatus
                ></Rating>
              </ViewReviewsParameterItemHeaderContent>
              <p>{data?.detailedReview?.[item?.keyName]?.comment}</p>
            </ViewReviewsParameterItems>
          ) : (
            <></>
          );
        })}
      </ViewReviewsParameterWrapper>
    );
  };

  return (
    <ViewReviewsContainer>
      <ViewReviewsCompDetails>
        <Avatar imgSrc={data?.clientDetails?.logo} data={data?.clientDetails?.company} width="56px" height="56px" />
        <Tooltip title={data?.clientDetails?.company}>
          <ViewReviewsCompanyName>{data?.clientDetails?.company}</ViewReviewsCompanyName>
        </Tooltip>
        <ViewReviewsPostDetails>
          <p>Posted by</p>
          <span>{returnClientDetails()}</span>
        </ViewReviewsPostDetails>
        <ViewReviewsPostDetails>
          <p>Posted on</p>
          <span>{formatTime(data?.createdAt, "DD/MM/YYYY")}</span>
        </ViewReviewsPostDetails>
      </ViewReviewsCompDetails>
      <ViewReviewsOverallRating>
        <ModalRatingContainer>
          <Rating value={data?.overallRating} color={"#FFCB45"} allowHalf disabledStatus></Rating>
        </ModalRatingContainer>
        <ViewReviewsDesc>{data?.overallReview}</ViewReviewsDesc>
      </ViewReviewsOverallRating>
      <ViewParameterContainer>
        {isAnyParameterReviewed(data?.detailedReview) && <div className="parameters-heading">Rating by Parameter</div>}
        <ViewReviewsParameterContainer>
          {renderParametersUi("leftPanel")}
          <DividerLine type={"vertical"} $height={"auto"} />
          {renderParametersUi("rightPanel")}
        </ViewReviewsParameterContainer>
      </ViewParameterContainer>
    </ViewReviewsContainer>
  );
};

export default ViewReviews;
