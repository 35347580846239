import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Typography } from "antd";
import { IJobRequestData } from "container/JobRequests/JobRequestDetails.interfaces";
import { ADMIN_ACTIONS_TABLE_CONSTANTS, getRowsForAdminActiontable } from "./AdminActionsTable.constants";
import { RenderJobReqCellValue } from "container/JobRequests/components/RenderJobReqCellValue";
import { useStyles } from "container/JobRequests/jobRequestsListing.styles";
import { Backdrop, CircularProgress } from "@material-ui/core";
import SnackBarNotification from "components/SnackBar/SnackBar";
import { useParams } from "react-router-dom";
import { ClientRequestActions } from "container/ClientRequests/actions";

const { Column } = Table;
const { Text } = Typography;

export const AdminActionsTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { _id } = useParams<{ _id: string }>();
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [reloadUpdate, setReloadUpdate] = useState(false);
  const [gstnObj, setGstnObj] = useState({ _id: "", gstnValue: "" });
  const { desc, actionType, result } = ADMIN_ACTIONS_TABLE_CONSTANTS;
  const { jobRequestDetails }: { jobRequestDetails: IJobRequestData } = useSelector((state: any) => state.ClientRequestReducer);

  useEffect(() => {
    (() => {
      setShowLoader(true);
      dispatch(ClientRequestActions.getJobRequestData(_id)).then((resp: any) => {
        setShowLoader(false);
      });
    })();
  }, [reloadUpdate]);

  const renderTitle = (title: string) => <Text strong>{title}</Text>;

  const renderJobRequestCell = (caseKey: string) => {
    return (
      <RenderJobReqCellValue
        jobRequestProperty={caseKey}
        jobRequestData={jobRequestDetails}
        gstnObj={gstnObj}
        setGstnObj={setGstnObj}
        setErrorMsg={setErrorMsg}
        setShowSnackBar={setShowSnackBar}
        setReloadUpdate={setReloadUpdate}
        setShowLoader={setShowLoader}
      />
    );
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBarNotification
        openNotification={showSnackBar}
        closeSnackbar={() => {
          setShowSnackBar(false);
          setErrorMsg("");
        }}
        severity={"error"}
        message={errorMsg}
      />
      <Table
        sticky
        bordered
        pagination={false}
        title={() => <Text strong>Admin Actions</Text>}
        scroll={{ y: 450 }}
        dataSource={getRowsForAdminActiontable(jobRequestDetails)}
      >
        <Column width={"10%"} title={renderTitle(desc.title)} dataIndex={desc.keyName} key={desc.keyName} />
        <Column
          width={"45%"}
          title={renderTitle(actionType.title)}
          dataIndex={actionType.keyName}
          key={actionType.keyName}
          render={(actionTypeKey: string) => renderJobRequestCell(actionTypeKey)}
        />
        <Column
          width={"45%"}
          title={renderTitle(result.title)}
          dataIndex={result.keyName}
          key={result.keyName}
          render={(resultTypeKey: string) => renderJobRequestCell(resultTypeKey === "vendor" ? "vendor" : "sendStatus")}
        />
      </Table>
    </>
  );
};
