import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "antd";
import { Backdrop, CardHeader, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsActions } from "../../../actions";
import VendorFilter from "../../../../../components/VendorFilter/VendorFilter";
import DataGridComp from "../../../../../components/analytics/client/DataGrid/DataGrid";
import { GridColDef } from "@material-ui/data-grid";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getUserRole } from "../../../../../Utils/Storage";
import LocalStorage from "../../../../../_services/LocalStorage";
import NoAccess from "../../../../../components/NoAccess/NoAccess";
import { renderCellExpand } from "../../../../../components/CellExpandPopup/CellExpandPopup";
import ROLES from "../../../../../constants/RolesConstants";

const columns: GridColDef[] = [
  { field: "vendorName", headerName: "Vendor Name", width: 170, renderCell: renderCellExpand },
  {
    field: "lastLogin",
    headerName: "Last Login",
    width: 150,
    align: "right",
    renderCell: renderCellExpand
  },
  {
    field: "totalLogin",
    headerName: "Total Login",
    width: 150,
    align: "right",
    renderCell: renderCellExpand
  },
  {
    field: "periodProfileViews",
    headerName: "Period Profile Views",
    width: 250,
    align: "right",
    renderCell: renderCellExpand
  },
  {
    field: "profileViews_total",
    headerName: "Total Profile Views",
    width: 200,
    align: "right",
    renderCell: renderCellExpand
  },
  {
    field: "periodPhoneNumberViews",
    headerName: "Period Phone Number Views",
    width: 250,
    align: "right",
    renderCell: renderCellExpand
  },
  {
    field: "phoneNumberViews_total",
    headerName: "Total Phone Number Views",
    width: 250,
    align: "right",
    renderCell: renderCellExpand
  },
  {
    field: "periodEmailViews",
    headerName: "Period Email Views",
    width: 250,
    align: "right",
    renderCell: renderCellExpand
  },
  {
    field: "emailViews_total",
    headerName: "Total Email Views",
    width: 250,
    align: "right",
    renderCell: renderCellExpand
  },
  {
    field: "periodSearchViews",
    headerName: "Period Search Views",
    width: 250,
    align: "right",
    renderCell: renderCellExpand
  },
  {
    field: "searchViews_total",
    headerName: "Total Search Views",
    width: 200,
    align: "right",
    renderCell: renderCellExpand
  },
];

interface Data {
  id?: string;
  vendorName?: string;
  lastLogin?: string;
  totalLogin?: number;
  periodProfileViews?: number;
  profileView_total?: number;
  periodPhoneNumberViews?: number;
  phoneNumberViews_total?: number;
  periodEmailViews?: number;
  emailViews_total?: number;
  periodSearchViews?: number;
  searchView_total?: number;
}

const rows: Data[] = [
  {
    id: "1",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const VendorAnalytics = () => {
  const classes = useStyles();
  const clientAnalyticsData = useSelector(
    (state: any) => state.AnalyticsReducers
  );
  const [analyticsData, setVendorAnalyticsData] = React.useState(rows);
  const [showLoader, setShowLoader] = React.useState(false);
  const { startDate, endDate } = clientAnalyticsData;
  const dispatch = useDispatch();
  const history = useHistory();
  const userRole = getUserRole()?.split(',') || [];
  const [hasAccess, setHasAccess] =React.useState(true);
  const [showExport, setShowExport] = React.useState(
    userRole?.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_EXPORT).length > 0
  );  

  useEffect(() => {
    getVendorData();
  }, [startDate, endDate]);

  const getVendorData = async () => {
    setShowLoader(true);
      await axios.get(
      process.env.REACT_APP_BASE_URL + `/analytics/vendor`,
      {
        params: {
          from: moment(startDate).format("YYYY-MM-DD"),
          to: moment(endDate).format("YYYY-MM-DD"),
        },
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      }
    ).then((result) =>{
      if (result?.data) {
        setVendorAnalyticsData(result.data);
      }
    })
    .catch((error) => {
      if(error.response.status === 403){
        setHasAccess(false);
      } 
    });
    
    setShowLoader(false);
  };

  const handleFilterChange = (from: any, to: any) => {
    dispatch(AnalyticsActions.setStartDate(from));
    dispatch(AnalyticsActions.setEndDate(to));
  };

  return (
    hasAccess ? 
    <Card className={classes.root}>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardHeader title="Vendor Analytics"></CardHeader>
      <VendorFilter
        className="filter"
        startDate={startDate}
        endDate={endDate}
        handleFilterChange={handleFilterChange}
      ></VendorFilter>
      {
        <DataGridComp
          hideExport={!showExport}
          col={columns}
          rows={analyticsData}
          sortField="date"
        ></DataGridComp>
      }
    </Card>
    :
    <NoAccess></NoAccess>
  );
};

export default VendorAnalytics;
