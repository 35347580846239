import { Button, Input } from "antd";
import styled from "styled-components";
import { ParticipantImageProps } from "./Chat.interface";

const { TextArea } = Input;
export const ChatContainer = styled.div`
  background: #f4f6ff;
  border-radius: 30px;
  width: 75%;
  color: #011759;
  margin-left: 30px;
`;

export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;
export const RecipientWrapper = styled.div`
  display: flex;
`;
export const RecipientImage = styled.div`
  background: #205fc9;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  color: #ffffff;
`;
export const RecipientDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
`;
export const RecipientName = styled.span`
  font-weight: 600;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #011759;
`;
export const RecipientCompany = styled.span`
  color: #215ec9;
  font-weight: 600;
  font-size: 11px;
`;

export const Divider = styled.div`
  border-bottom: 2px solid#D5D9EC;
  margin: 0 20px;
`;

export const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 210px);
  padding: 20px;
  overflow-y: scroll;
  overflow: auto;
`;
export const IncomingMessageContainer = styled.div`
  margin: 15px 0;
`;
export const OutgoingMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 15px 0;
`;
export const MessageWrapper = styled.div`
  display: flex;
  margin: 5px 0 0;
  position: relative;
`;

export const ParticipantImage = styled.div<Partial<ParticipantImageProps>>`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  align-self: flex-end;
`;
export const MessageDate = styled.span`
  font-size: 11px;
`;
export const IncomingMessage = styled.span`
  background: #215ec9;
  border-radius: 12px 12px 12px 0;
  color: #ffffff;
  letter-spacing: 0.01em;
  padding: 10px;
  margin-left: 10px;
  max-width: 600px;
  &:after {
    content: "";
    position: absolute;
    left: 35px;
    background-color: #4b060600;
    height: 31px;
    bottom: -1px;
    width: 15px;
    border-bottom-right-radius: 9px;
    box-shadow: 8px 0 0px -1px #205fc9;
  }
`;
export const OutgoingMessage = styled.span`
  background: #ffffff;
  border-radius: 12px 12px 0 12px;
  letter-spacing: 0.01em;
  padding: 10px;
  margin-right: 10px;
  max-width: 600px;
  &:after {
    content: "";
    position: absolute;
    right: 35px;
    background-color: #4b060600;
    height: 31px;
    width: 15px;
    bottom: 0;
    border-bottom-left-radius: 9px;
    box-shadow: -8px 0 0px 0px #ffffff;
  }
`;

export const ThreeDotCircle = styled.div`
  float: right;
  color: #ffff;
  width: 24px;
  border: 2px solid #112b7a;
  border-radius: 50%;
  border-radius: 15px;
  margin-top: 6px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallDots = styled.div`
  width: 3px;
  height: 3px;
  margin-left: 1px;
  background: #112b7a;
  border-radius: 50%;
`;
export const PopoverDiv = styled.div`
  font-size: 12px;
  color: #261c4b;
  font-weight: 400;
  width: 230px;
  padding: 5px;
  &:hover {
    background-color: #f4f6ff;
  }
`;

export const NoMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
export const NoMessageText = styled.div`
  color: #215ec9;
  margin: 20px 0;
  font-weight: 700;
  font-size: 24px;
`;

export const ShortListedLabel = styled.div`
  background-color: #47c882;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  margin: 10px 5px 0;
  font-size: 10px;
  width: 100px;
`;
export const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 15px 10px 15px;
  height: 85%;
`;
export const TextAreaBttn = styled(Button)`
  background: #47c882;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 10px;
  color: #ffffff;
  margin-right: 20px;
`;

export const CancelButton = styled(TextAreaBttn)`
  border: 1px solid #be000b;
  color: #be000b;
  background: #ffffff;
  margin-right: 20px;
`;

export const SendMessageWrapper = styled.div`
  width: 100%;
  height: 12%;
  display: flex;
  background-color: #ffffff;
  justify-content: flex-end;
  border-radius: 0px 0px 12px 12px;
  border-width: 1px;
  border-style: solid;
  border-left-color: #d5d9ec;
  border-right-color: #d5d9ec;
  border-bottom-color: #d5d9ec;
  border-top-color: #ffffff;
  z-index: 100;
  align-items: center;
`;
export const TextAreaChat = styled(TextArea)`
  position: relative;
  padding: 05px 05px 0px 05px;
  margin-top: 10px;
  height: 70%;
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 184%;
  /* or 26px */

  text-align: justify;
  letter-spacing: 0.05em;
  color: #011759;
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(159, 179, 248, 0.2);
  border-radius: 12px;

  height: 88%;
  border-bottom-color: #ffffff;
  box-sizing: none;
  z-index: 10;
  box-shadow: none;
  border-radius: 12px 12px 0px 0px;
`;
export const DeleteIcon = styled.img`
  margin-right: 5px;
  cursor: pointer;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SharedFilesSection = styled.div``;

export const Header = styled.span`
  color: #215ec9;
  font-weight: 600;
  font-size: 20px;
`;

export const FileWrapper = styled.div`
  height: calc(100vh - 250px);
  overflow-y: scroll;
`;

export const FileContainer = styled.div`
  border: 1px solid #d4e5fe;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
`;

export const FileType = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 11px;
  justify-content: center;
  margin-right: 15px;
  font-weight: 700;
`;

export const FileDetails = styled.div`
  max-width: 70%;
`;
export const FileName = styled.div`
  color: #011759;
  font-weight: 600;
  overflow-wrap: anywhere;
`;
export const FileSharedDate = styled.div`
  color: #011759;
  font-size: 11px;
`;