
export const JOB_MONTEZATION_TABLE_CONSTANTS = {
  vendor: { keyName: "vendor", title: "Vendor (Gstn/Invited email)", width: "16%" },
  leadFeeApplicable: { keyName: "leadFeeApplicable", title: "Lead Fee Applicable (Rs.2499+tax)", width: "10%" },
  commissionPercent: { keyName: "commissionPercent", title: "Commission %", width: "9%" },
  valueOfPO: { keyName: "valueOfPO", title: "PO value (INR lakhs)", width: "12%" },
  accepted: { keyName: "accepted", title: "Status", width: "18%" },
  agreement: { keyName: "agreement", title: "Document", width: "10%" },
  sendAgreement: { keyName: "sendAgreement", title: "Share Pricing Policy (Enter email/s, separated by commas.)", width: "20%" },
  lastContacted: { keyName: "lastContacted", title: "Contacted", width: "20%" },
  update: { keyName: "update", title: "Update", width: "8%" }
};

export const PRICING_APPLY_OPTS = {
  YES : "Yes",
  NO : "No",
}

export const ACCEPTED_STATUS = {
  ACCEPTED: "Accepted",
  PENDING: "Pending"
}