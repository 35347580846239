import CardTag from "components/CardTag/CardTag";
import AntdToast from "components/Toast/AntdToast";
import { FlexContainer } from "components/utils";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { IUserRoleContainerProps } from "container/ClientRequests/interfaces/ClientProjectDetails.interface";
import { useDispatch, useSelector } from "react-redux";
import { UserRolesConatinerDiv, RoleName, AddUserButton } from "./UserRoles.styles";

export const UserRoleConatiner = (props: IUserRoleContainerProps) => {
  const { userRole, users, onAddUser, showBottomBorder } = props;
  const dispatch = useDispatch();
  const { selectedProject } = useSelector((state: any) => state.ClientRequestReducer);

  const OnRemoveUser = (email: string) => {
    const addUsersPayload = {
      id: selectedProject?._id,
      removeUser: [{ type: userRole.type, email }],
      projectId: selectedProject?.value,
    };
    dispatch(ClientRequestActions.addOrRemoveUsersFromRoles(addUsersPayload))
  };

  return (
    <UserRolesConatinerDiv showBottomBorder={showBottomBorder}>
      <FlexContainer justifyContent="space-between" alignItems="center">
        <RoleName>{userRole.name}</RoleName>
        <AddUserButton onClick={onAddUser}>+ Add User</AddUserButton>
      </FlexContainer>
      <FlexContainer flexWrap="wrap">
        {users?.map((user) => (
          <CardTag onClose={() => OnRemoveUser(user?.email)} cardText={user?.email} imgType="" closable />
        ))}
      </FlexContainer>
    </UserRolesConatinerDiv>
  );
};
