import axios from "axios";

const TOKEN_KEY = "token";
const USER_DETAILS = "user";
const USER_ROLE = "role";

export const SetloginUser = (user) => {
  localStorage.setItem(USER_DETAILS, JSON.stringify(user));
};

export const SetToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const SetRole = (role) => {
  localStorage.setItem(USER_ROLE, role);
};

export const setLogout = () => {
  axios.post(
    process.env.REACT_APP_API_URL + "/v1/authentication/logout",
    {},
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    }
  );
  localStorage.removeItem(USER_DETAILS);
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_ROLE);
  localStorage.clear();
  sessionStorage.clear();
};

export const isLogin = () => {
  if (localStorage.getItem(USER_DETAILS)) {
    return true;
  }
  return false;
};

export const getLoggedInUsers = () => {
  let obj = localStorage.getItem(USER_DETAILS);
  return JSON.parse(obj);
};

export const getUserRole = () => {
  return localStorage.getItem(USER_ROLE);
};

export const getAccessToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const hasValidAccess = (userRole, page) => {
  switch (true) {
    case userRole === "ADMIN_VENDOR_VIEW" && page.toLowerCase() !== "vendor":
      return false;
    default:
      return true;
  }
};

export const navigateTo = (userRole) => {
  if (userRole === "ADMIN_VENDOR_VIEW") {
    return "/vendors";
  } else {
    return "";
  }
};
