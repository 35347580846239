export const getCategoryArrays = (data) => {
  let equipmentsArray = [],
    oemArray = [],
    serviceArray = []
  if (data?.length > 0) {
    data.forEach((tagObj) => {
      if (tagObj.group !== "New Tag") {
        if (tagObj.type === "Equipment") {
          equipmentsArray.push(tagObj);
        } else if (tagObj.type === "OEM") {
          oemArray.push(tagObj);
        } else if (tagObj.type === "Service") {
          serviceArray.push(tagObj);
        }
      } 
    });
  }
  return [equipmentsArray, oemArray, serviceArray];
};

export const getDifference = (array1, array2) => {
  return array1.filter(
    ({ _id: id1 }) => !array2.find(({ _id: id2 }) => id2 === id1)
  );
};

export const getActualValues = (selectedArray, dbArray) => {
  let actualSelectedArray = [];
  selectedArray.forEach((tagObj) => {
    let value = dbArray.find((itr) => itr?._id === tagObj?._id);
    value = value?.name || value?.title;
    if (value) {
      !actualSelectedArray.find(({_id}) => _id === tagObj?._id ) &&
      actualSelectedArray.push(tagObj)
    }
  });
  return actualSelectedArray;
};

export const TagsCategory = {
  OEM : "oem",
  EQUIPMENT : "equipment",
  SERVICE: "service",
}

export const getSelectedTagsFromDb = (selectedArray, dbArray) => {
  return selectedArray.map((tagObj) => {
    if (dbArray.find((tag) => tag._id === tagObj._id )) {
      return tagObj;
    }
  });
}