import { saveAs } from "file-saver";
import JSZip from "jszip";
import AntdToast from "../../components/Toast/AntdToast";
import { IFolder } from "./Interfaces/JobRequestInterfaces";
import { IDMSFile } from "./JobRequestDetails.interfaces";

export const bytesToMB = (sizeInBytes: number) => {
  const sizeInMBSring = (sizeInBytes / (1024 * 1024)).toFixed(2);
  return sizeInMBSring === "0.00" ? 0.01 : sizeInMBSring;
};

export function downloadFile(url: string) {
  return fetch(url).then((res) => {
    return res.blob();
  });
}

export const zipMultipleFilesAndDownload = (
  folderName: string,
  zipFilename: string,
  files: IDMSFile[],
  setIsDownloading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let zipFile = new JSZip();
  zipFile = downloadFiles(files, zipFile, folderName);
  generateZipFile(zipFile, zipFilename, setIsDownloading);
};

export const zipMultipleFoldersAndDownload = (
  zipFilename: string,
  folders: IFolder[],
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsDownloading(true);
  let zipFile = new JSZip();
  folders.forEach((folder) => {
    zipFile = downloadFiles(folder.files, zipFile, folder.folderName);
  });
  generateZipFile(zipFile, zipFilename, setIsDownloading);
};

const downloadFiles = (files: IDMSFile[], zipFile: JSZip, folderName: string) => {
  let zipFolder = zipFile.folder(folderName);
  try {
    files.forEach((file) => {
      let promise = downloadFile(file.files[0].file?.fileUrl);
      zipFolder?.file(`${file.fileName}`, promise);
    });
  } catch (ex) {
    AntdToast({ type: "error", message: "Error", description: "Error in fetching file for download" });
  }

  return zipFile;
};

export const generateZipFile = (
  zipFile: JSZip,
  zipFilename: string,
  setIsDownloading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  zipFile
    .generateAsync({ type: "blob" }) // Blob has limit of 345 MB
    .then(function (content) {
      saveAs(content, `${zipFilename}.zip`);
      if (setIsDownloading) setIsDownloading(false);
    })
    .catch((error) => {
      if (setIsDownloading) setIsDownloading(false);
      AntdToast({ type: "error", message: "Error", description: "Error in generating zip file" });
    });
};

export const zipSingleFileAndDownload = (
  fileObj: IDMSFile,
  setIsZipping?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const fileNameSuffix = fileObj.fileName.split(".").pop()?.toLowerCase();
  if (fileNameSuffix === "zip") {
    downloadFile(fileObj?.files?.[0]?.file?.fileUrl).then((respBlob)=> {
      /** 
       * FYI: passing first param as fileObj?.file?.fileUrl downloads zip file;
       * with name directly from the url, regardless of second param is the name we want
       * ref: https://github.com/eligrey/FileSaver.js/issues/670
       */ 
      saveAs(respBlob, fileObj?.fileName)
    });
  } else {
    const fileNamePrefix = fileObj?.fileName.split(".").slice(0, -1).join(".");
    setIsZipping && setIsZipping(true);
    let zipFile = new JSZip();
    let promise = downloadFile(fileObj?.files?.[0]?.file?.fileUrl);
    zipFile.file(fileObj.fileName, promise);
    generateZipFile(zipFile, fileNamePrefix, setIsZipping);
  }
};
