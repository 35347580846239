import { Select } from "antd";
import styled from "styled-components";
import { SelectProps } from "./StateSelect";


export const SelectBox = styled(Select)<SelectProps>`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: black;
  font-weight: 500;
  margin-top:0;

  & .ant-select-selector {
    height: 60px !important;
    border-radius: 8px !important;
    border: 1px solid #D5D9EC !important;
    background:  #FFFFFF;
    display: flex;
    align-items: center;
    padding-left: 20px !important;
  }
  & .ant-select-arrow, .ant-select-clear{
      margin-right:6px;
      top: 48%;
  }

  & .ant-select-arrow {
    color: black;
    opacity: 0.7;
  }

  & .ant-select-selection-search-input {
    height: 60px !important;
    padding-left: 9px !important;
  }
  & .ant-select-selection-placeholder {
    color: #011759;
    opacity: 1;
    font-weight: 500;
    & .ant-select-selector {
        height: 60px !important;
        border-radius: 8px !important;
        display: flex;
        align-items: center;  
        padding-left:20px !important;
       
    }
    & .ant-select-selection-search-input {
        height: 60px !important;
        padding-left:9px !important;
    }
    & .ant-select-selection-placeholder {
        font-weight: 500;
        font-size: 18px;
        font-style: normal;
        color: #011759 ;
        opacity: 0.7;
    }
    & :not(.ant-select-customize-input){
        color:gray;
    }
    & .ant-select-arrow{
      margin-right:6px;
      color: #011759 ;
  }
}
`; 