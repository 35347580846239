
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';


interface LayoutProps {
  scrollable?: boolean
  children: React.ReactNode,
  title?: string
}

const OuterWrapper = styled.div<any>`
 display:flex;
//  background: #f4f6ff;
//  height:100vh;
//  width:100vw;
//  height: ${props => props.scrollable ? 'none' : '100vh'};

`;
const SideBarWrap = styled.div`
display:flex;
justify-content:flex-start;
`
const MainContainer = styled.div<any>`
display:flex;
width: calc(100vw - 120px);
//  height: calc(100vh - 100px);
height: ${props => props.scrollable ? 'none' : 'calc(100vh - 130px)'};
background: #ffffff;
border-radius: 40px;
// margin-left: 90px;
// margin-top: 75px;
box-shadow: 0px 20px 30px rgba(159, 179, 248, 0.12);
// padding: 40px 20px 0 40px;
padding: 30px 20px 0 30px;
// overFlow:auto;
// margin-bottom:2rem;
// min-height: calc(100vh - 100px);
`
const ChatLayout = ({ title, scrollable, children }: LayoutProps) => {

  // const loader = useSelector((state: any) => state.LoaderReducers);
  // const {isLoading} = loader;

  return (
    <OuterWrapper scrollable={scrollable}>
     
      <MainContainer scrollable={scrollable}>
        {children}
        {/* {isLoading &&
        //  <Spinner></Spinner>
         } */}
      </MainContainer>
    </OuterWrapper>
  );
};

export default ChatLayout;
