export const TagsDispatchType = {
    LOAD_TAGS : "LOAD_TAGS",
    ADD_TAGS: "ADD_TAGS",
    REMOVE_TAGS: "REMOVE_TAGS",
    UPDATE_FLAG: "UPDATE_FLAG"

}

export const tagsReducer = (state, {type, payload}) => {
    switch(type){
        case TagsDispatchType.LOAD_TAGS : 
            return { ...state,
                /** Below arrays contain Tag Objects - populated from backend */
                oem: payload?.oem || [],
                equipment: payload?.equipment || [],
                service: payload?.service || [],
                categories: payload?.categories || [],
                flag: payload?.flag  // can only be 0 or 1
            }
        case TagsDispatchType.UPDATE_FLAG :
            return { ...state, flag: payload?.flag }
        case TagsDispatchType.ADD_TAGS : 
            return {
                ...state,
                [payload.tagCategory] : [...state[payload.tagCategory], ...payload.tags]
            }    
        case TagsDispatchType.REMOVE_TAGS :
            return {
                ...state,
                [payload.tagCategory] : state[payload.tagCategory].filter((tagObj) =>  tagObj._id !== payload._id )
            }
        default : return state;    
    }
}