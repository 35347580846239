import React, { useState } from "react";
import {
  OverallRatingsStatusContainer,
  OverallRatingContentDiv,
  OverallRatingHeadertext,
  OverallRatingContentWrapper,
  OverallRatingDiv,
  OverallRatingBasedOnStatus,
  OverallRatingNum,
  ParameterDiv,
  ViewMoreTextWrapper,
  Line,
} from "../VendorReviews.styles";
import Rating from "components/Rating/Rating";
import ParameterContent from "./ParameterContent";
import { IOverallRating } from "../interfaces/interfaces";
import { isAnyParameterRated } from "shared/handlers/vendorReview.handlers";

const OverallReview = (props: IOverallRating) => {
  const { data } = props;
  let count: number = 0;
  data?.reviews.map((item: any) => {
    //if item.reviewStatus === "approved" then count++ or there is no reviewStatus then count++
    if (item.reviewStatus === "reviewAccepted" || !item.reviewStatus) {
      count++;
    }
  });
  const [showViewMore, setShowViewMore] = useState(false);
  const [expandContent, setExpandContent] = useState(false);
  return (
    <OverallRatingsStatusContainer>
      <OverallRatingContentWrapper>
        <OverallRatingHeadertext>Overall Rating</OverallRatingHeadertext>
        <OverallRatingContentDiv>
          <OverallRatingDiv>
            <OverallRatingNum>{data?.averageRating}</OverallRatingNum>
            <Rating
              value={data?.averageRating}
              color={"#FFCB45"}
              allowHalf
              disabledStatus
            ></Rating>
          </OverallRatingDiv>
          <OverallRatingBasedOnStatus>
            {count === 1
              ? ` Based on ${count} review`
              : ` Based on ${count} reviews given by different clients`}
          </OverallRatingBasedOnStatus>
        </OverallRatingContentDiv>
      </OverallRatingContentWrapper>
      {isAnyParameterRated(data) && (
        <OverallRatingContentWrapper>
          <OverallRatingHeadertext $marginLeft={"3.472vw"}>
            Rating by parameter
          </OverallRatingHeadertext>
          <ParameterDiv>
            <Line>
              <hr />
            </Line>
            <ParameterContent
              data={data}
              expandContent={expandContent}
              onViewMoreCheck={setShowViewMore}
            />
            {(showViewMore || expandContent) && (
              <ViewMoreTextWrapper
                onClick={() => {
                  setExpandContent(!expandContent);
                }}
              >
                {expandContent ? "View less" : "View more"}
              </ViewMoreTextWrapper>
            )}
          </ParameterDiv>
        </OverallRatingContentWrapper>
      )}
    </OverallRatingsStatusContainer>
  );
};

export default OverallReview;
