import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createRowDataForObj, RowForObjValues } from "./components/RowForObjValues";

import {
  IRespData,
  IGridState,
  IKeysWithObjTypes,
  IKeysWithArrTypes,
  IVerificationObjectType,
} from "./vendorVerification.interface";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { verificationConstants, vendorVerifInitialState } from "./constants";
import { VendorVerificationStyles } from "./vendorVerification.styles";
import { createRowDataforArr, RowForArrayValues } from "./components/RowForArrayValues";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";
import NoAccess from "components/NoAccess/NoAccess";
import { checkUserHasOnlyVendorViewRole } from "shared/helpers/checkHasAccess";

const VendorVerificationUpdate = () => {
  const classes = VendorVerificationStyles();
  const { gstn } = useParams<{ gstn: string }>();
  const [loading, setLoading] = useState(false);
  const [gridState, setGridState] = useState<IGridState>(vendorVerifInitialState);
  const [reloadPage, setReloadPage] = useState(false)

  const access = checkUserHasOnlyVendorViewRole()
  const [hasAccess, setHasAccess] = useState(access);

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const result: IRespData = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/compliance/get-verification/${gstn}`
        );
        if (result?.data?.status === "SUCCESS") {
          const { data } = result?.data;
          const arrOfkeysWithObjValue: IKeysWithObjTypes[] = ["cin", "gst", "pan", "turnOver", "yoc", "address", "bankDetails"]
          arrOfkeysWithObjValue.forEach((key) => {
            delete data[key]?.removeDocument;
            delete data[key]?.addDocument;
          });
          setGridState(result?.data?.data);
          setLoading(false);
        }else if(result?.data?.status ==="ERROR" && result?.data?.message==="GSTN does not Exist"){
          setLoading(false);
        }
      })();
    } catch (error) {
      console.error("Error occured while fetching data", error);
      setLoading(false);
    }
  }, [reloadPage]);

  const getPayloadForPOSTCall = (gridState: IGridState) => {
    /**
     * returns new payload Object(for POST API) with deleted key - "fileUrl",
     * from all verification objects
     */
    const postPayload: any = {};
    const arrOfkeysWithObjValue: IKeysWithObjTypes[] = ["cin", "gst", "pan", "turnOver", "yoc", "address", "bankDetails"]
    const arrOfkeysWithArrValue: IKeysWithArrTypes[] = ["epf", "esic", "experience", "lin", "tan", "urn", "oem", "technicalCert"]

    Object.keys(gridState).forEach((key) => {
      // if value is object
      if (arrOfkeysWithObjValue.includes(key as IKeysWithObjTypes)) {
        const { document, ...remainingKeys } = gridState[key as IKeysWithObjTypes]
        postPayload[key] = { ...remainingKeys }
      }
      // if value is array of objects
      if (arrOfkeysWithArrValue.includes(key as IKeysWithArrTypes)) {
        const arrayField = gridState[key as IKeysWithArrTypes] || [];
        arrayField.forEach((verifObj: IVerificationObjectType) => {
          const { document, ...remainingKeys } = verifObj
          if (!postPayload.hasOwnProperty(key)) {
            postPayload[key] = []
          }
          postPayload[key].push({ ...remainingKeys })
        });
      }
    });
    return postPayload
  }

  const handleOnSubmit = async () => {
    setLoading(true);
    setGridState((prev) => {
      const newGridState = { ...prev }
      const arrOfkeysWithObjValue: IKeysWithObjTypes[] = ["cin", "gst", "pan", "turnOver", "yoc", "address", "bankDetails"]
      const arrOfkeysWithArrValue: IKeysWithArrTypes[] = ["epf", "esic", "experience", "lin", "tan", "urn", "oem", "technicalCert"]

      Object.keys(newGridState).forEach((key) => {
        if (arrOfkeysWithObjValue.includes(key as IKeysWithObjTypes)) {
          delete newGridState[key as IKeysWithObjTypes]?.fileUrl
        }
        if (arrOfkeysWithArrValue.includes(key as IKeysWithArrTypes)) {
          const arrayField = newGridState[key as IKeysWithArrTypes] || [];
          arrayField.forEach((verifObj: IVerificationObjectType) => {
            delete verifObj?.fileUrl
          });
        }
      });
      return newGridState
    })
    console.log("gridState", gridState)
    try {
      (async () => {
        const { data } = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_BASE_URL}/compliance/update-verification/${gstn}`,
          data: gridState,
        });
        if (data?.status === "SUCCESS") {
          setLoading(false);
          leadSquaredTrigger(gstn);
          setReloadPage((prev) => !prev)
        }
      })();
    } catch (error) {
      setLoading(false);
      console.error("Unable to updated Data", error);
    }
  };

  const rowsForObj = [
    /** Bottom Rows contain objects */
    createRowDataForObj(
      "gst",
      gridState.gst?.verificationStatus,
      gridState.gst?.note,
      gridState.gst?.value,
      gridState.gst?.document
    ),
    createRowDataForObj(
      "turnOver",
      gridState.turnOver?.verificationStatus,
      gridState.turnOver?.note,
      gridState.turnOver?.value,
      gridState.turnOver.document
    ),
    createRowDataForObj(
      "yoc",
      gridState.yoc?.verificationStatus,
      gridState.yoc?.note,
      gridState.yoc?.value,
      gridState.yoc?.document
    ),
    createRowDataForObj(
      "pan",
      gridState.pan?.verificationStatus,
      gridState.pan?.note,
      gridState.pan?.value,
      gridState.pan?.document
    ),
    createRowDataForObj(
      "cin",
      gridState.cin?.verificationStatus,
      gridState.cin?.note,
      gridState.cin?.value,
      gridState.cin?.document
    ),
    createRowDataForObj(
      "address",
      gridState.address?.verificationStatus,
      gridState.address?.note,
      gridState.address?.value,
      gridState.address?.document
    ),
    createRowDataForObj(
      "bankDetails",
      gridState.bankDetails?.verificationStatus,
      gridState.bankDetails?.note,
      gridState.bankDetails?.value,
      gridState.bankDetails?.document
    ),
  ]

  const rowsForArr = [
    /** Below items will contain arrays */
    createRowDataforArr(
      "esic",
      gridState.esic || []
    ),
    createRowDataforArr(
      "experience",
      gridState.experience || []
    ),
    createRowDataforArr(
      "tan",
      gridState.tan || []
    ),
    createRowDataforArr(
      "epf",
      gridState.epf || []
    ),
    createRowDataforArr(
      "lin",
      gridState.lin || []
    ),
    createRowDataforArr(
      "urn",
      gridState.urn || []
    ),
    createRowDataforArr(
      "oem",
      gridState.oem || []
    ),
    createRowDataforArr(
      "technicalCert",
      gridState.technicalCert || []
    ),
  ]

  if (!hasAccess) {
    return <NoAccess />;
  }

  return (
    <div style={{ height: 350, width: "100%" }}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        display="flex"
        mt={1}
        pb={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" component="div" gutterBottom>
          Verfication
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          startIcon={
            loading ? (
              <CircularProgress className={classes.submitButtonLoader} />
            ) : (
              <CloudUploadIcon />
            )
          }
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </Box>
      {/* TABLE STARTS HERE */}
      <TableContainer component={Paper} sx={{ overflowX: "initial" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {["Fields", "Value", "Document", "Verification Status", "Note"].map((item, idx) => (
                <TableCell key={idx} sx={{ fontSize: "1rem", textAlign: idx !== 0 ? "right" : "left" }}>
                  <Typography variant="h6" component="span">
                    {item}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsForObj.map((row, idx) => (
              <RowForObjValues key={idx} row={row} isEditMode setGridState={setGridState} gridState={gridState} />
            ))}
            {rowsForArr.map((row, idx) => (
              <RowForArrayValues key={idx} row={row} isEditMode setGridState={setGridState} gridState={gridState} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  );
};

export default VendorVerificationUpdate;
