import { CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ClientCommunicationActions } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInterval from "../../../hooks/useInterval";
import { Checkbox } from "antd";
import axios from "axios";
import { Spin } from "antd";
import LocalStorage from "../../../_services/LocalStorage";
import { Header } from "../Chat.styles";
import { Notification, PopOverContent, UnreadMessages, TextMessage, UserChatHeadActive, ProfileImg, UserInfo, UserName, UserCompany, TimeStamp, UserChatHead, TotalUnreadMessages, BttnWrapper, ClientBttn, VendorBttn, SearchBarWrapper, SearchBar, Suffix, ImgWrap, SendMessageText, AntdCheck, ListContainer, SpaceDiv, ChatHeadWrapper } from "./ChatList.styles";

const initiateChatResponse = {
  messages: [],
  recipients: ["clientId", "vendorId"],
  _id: "60b766df249469e1b7188466",
  chat_id: "clientId-vendorId",
  statusCode: 100,
};

interface ChatProps {
  usedAt?: "dashboard";
  initiateChatCheck?: typeof initiateChatResponse;
  onSelectMultiChat: (value: any) => void;
  isCancelled: boolean;
  getRecipientType: (value: string) => void;
  initiateChatStatus: boolean;
}


interface UserInfoProps {
  _id: "";
}


const ChatList = (props: ChatProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mockUserInfo = {
    _id: "",
  };

  const USER_DETAILS = "user";
  const [totalUnreadMsgs, settotalUnreadMsgs] = useState(0);
  const chatInfo = useSelector(
    (state: any) => state.ClientCommunicationReducers
  );
  const [selectedButton, setSelectedButton] = useState("vendor");
  const [selectedFilter, setSelectedFilter] = useState("recent");
  const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [userData, setUserData] = useState<string[]>([]);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const { chatListData, initiateChat } = chatInfo;
  const [adminId, setAdminId] = useState<any>(mockUserInfo);
  const addIndex = (id: any) => {
    setActiveIndex(id);
    setPlaying(true);
  };
  const [hasAccess, setHasAccess] =React.useState(true);

  const callInitiateChat = (senderType: any) => {
    dispatch(
      ClientCommunicationActions.adminInitiateSingleChat({
        senderId: adminId,
        communication_type: `admin_${senderType}`,
        recipientIds: checkedValues,
      })
    );
  };
  useEffect(() => {
    if (props?.initiateChatStatus) {
      if (selectedButton === "vendor") {
        callInitiateChat("vendor");
      } else {
        callInitiateChat("client");
      }
    }
  }, [props?.initiateChatStatus]);
  useEffect(() => {
    const userInfo: any = localStorage.getItem(USER_DETAILS);
    setAdminId(JSON?.parse(userInfo)?._id);
  }, [localStorage]);
  useEffect(() => {
    setIsCheckboxVisible(false);
    props.onSelectMultiChat(false);
    props.getRecipientType(selectedButton);
    dispatch(
      ClientCommunicationActions.getSelectedChat((chatInfo.chatHeadData = null))
    );
  }, [selectedButton]);

  useEffect(() => {
    if (!props.isCancelled) {
      setCheckedValues([]);
      setSearchString("");
    }
    setIsCheckboxVisible(props.isCancelled);
    props.onSelectMultiChat(props.isCancelled);
  }, [props.isCancelled]);
  const [delay, setDelay] = useState<number>(10000);
  // ON/OFF
  const [isPlaying, setPlaying] = useState<boolean>(false);
  useInterval(
    () => {
      // Your custom logic here
      const queryParam = {
        communication_type:
          selectedButton === "vendor" ? "admin_vendor" : "admin_client",
        sender_id: adminId,
      };
      if (!isCheckboxVisible) {
        dispatch(ClientCommunicationActions.getChatList(queryParam));
      }
    },
    // Delay in milliseconds or null to stop it
    isPlaying ? delay : null
  );

  const handleOnChange = (e: any) => {
    if (e.target.checked) {
      // dispatch(
      //   ClientCommunicationActions.getSelectedChat(
      //     (chatInfo.chatHeadData = { _id: e.target.value })
      //   )
      // );
      // getAllUserData(e.target.value);
      setCheckedValues([...checkedValues, e.target.value]);
    } else {
      setCheckedValues(
        checkedValues?.filter((item: any) => item !== e.target.value)
      );
    }
  };

  const getClientList = async () => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/communication/client",{
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      })
      .then(res => {
        setUserData(res?.data);
        setSpinnerStatus(false);
      })
      .catch(err => {
        if(err.response.status === 403){
          setHasAccess(false);
        } 
        setSpinnerStatus(false)});
  };

  const getVendorList = async () => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/communication/vendor",{
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`
        }
      })
      .then(res => {
        setUserData(res?.data);
        setSpinnerStatus(false);
      })
      .catch(err => {
        if(err.response.status === 403){
          setHasAccess(false);
        } 
        setSpinnerStatus(false)});
  };

  useEffect(() => {
    if (isCheckboxVisible) {
      if (selectedButton === "vendor") {
        getVendorList();
      } else {
        getClientList();
      }
    }
  }, [isCheckboxVisible]);

  useEffect(() => {
    if (adminId.length && !isCheckboxVisible) {
      setSpinnerStatus(true);
      setActiveIndex(0);
      const senderType = selectedButton;

      const queryParam = {
        communication_type:
          selectedButton === "vendor" ? "admin_vendor" : "admin_client",
        sender_id: adminId,
      };
      dispatch(ClientCommunicationActions.getChatList(queryParam)).then(
        (res: any) => {
          setSpinnerStatus(false);
          dispatch(
            ClientCommunicationActions.getSelectedChat(
              (chatInfo.chatHeadData = res?.chatList?.[0])
            )
          );
          addIndex(res?.chatList?.[0]?.chat_id);
        }
      );

      if (chatInfo.chatHeadData?.chat_id && props.usedAt !== "dashboard") {
        setPlaying(true);
        setActiveIndex(chatInfo.chatHeadData.chat_id);
      } else {
        setPlaying(false);
        setActiveIndex(0);
      }
    }
  }, [adminId, selectedButton, isCheckboxVisible]);

  // useEffect(() => {
  //   if (initiateChat?.data) {
  //     const selectedChat = {
  //       chat_id: initiateChat?.data.chat_id,
  //       chat_initiated_time: initiateChat?.updatedAt,
  //       companyName: initiateChat?.companyName,
  //       recipient_name: initiateChat.name,
  //       gstn: initiateChat.gstn,
  //     };
  //     dispatch(
  //       ClientCommunicationActions.getSelectedChat(
  //         (chatInfo.chatHeadData = selectedChat)
  //       )
  //     );
  //     addIndex(initiateChat?.data.chat_id);
  //   }
  // }, [initiateChat]);

  useEffect(() => {
    if (props.usedAt === "dashboard") {
      ClientCommunicationActions.clearInitiateChat(
        dispatch,
        (chatInfo.initiateChat = null)
      );
      setActiveIndex(0);
    }
  }, []);

  useEffect(() => {
    if (chatListData !== "null") {
      setUserData(chatListData?.chatList);

      settotalUnreadMsgs(
        chatListData?.totalUnreadMessages
          ? chatListData?.totalUnreadMessages
          : 0
      );
    }
  }, [chatListData, initiateChat]);

  useEffect(() => {
    const names = userData?.filter((item: any) => {
      if (checkedValues.includes(item?._id)) {
        return item;
      }
    });
    if (checkedValues !== null) {
      dispatch(
        ClientCommunicationActions.getMultiSelectedChats(
          (chatInfo.multiSelectHeadData = names)
        )
      );
    }
  }, [checkedValues]);

  const selectAll = (e: any) => {
    if (e?.target?.checked) {
      const checked = userData?.map((item: any) => {
        return item?._id;
      });
      setCheckedValues(checked);
    } else {
      setCheckedValues([]);
    }
  };

  const content = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PopOverContent
        style={{
          background: `${selectedFilter === "recent" ? " #f4f6ff" : ""}`,
        }}
        onClick={() => {
          setSelectedFilter("recent");
        }}
      >
        View Recent
      </PopOverContent>
      <PopOverContent
        style={{ background: `${selectedFilter === "all" ? " #f4f6ff" : ""}` }}
        onClick={() => {
          setSelectedFilter("all");
        }}
      >
        View All
      </PopOverContent>
    </div>
  );

  const renderMessage = (data: any) => {
    if (data?.unreadMessageCount) {
      return (
        <UnreadMessages>
          {data?.unreadMessageCount + "\tNew Messages"}
        </UnreadMessages>
      );
    }
    return <TextMessage>{data?.last_sent_message}</TextMessage>;
  };

  const loadChatHeads = (data: any) => {
    let start = "";
    if (data?.last_sent_message_time) {
      start = moment(data?.last_sent_message_time).format("HH:mm");
    } else {
      start = moment(data?.chat_initiated_time).format("HH:mm");
    }

    if (data?.chat_id === activeIndex && !isCheckboxVisible) {
      return (
        <UserChatHeadActive key={data.chat_id}>
          <ProfileImg>
            {isCheckboxVisible
              ? data?.firstName?.charAt(0)
              : data?.recipient_name?.charAt(0)}
          </ProfileImg>
          <UserInfo>
            <UserName>
              {isCheckboxVisible ? data?.firstName : data?.recipient_name}
            </UserName>
            <UserCompany>{data?.companyName}</UserCompany>
            {data?.unreadMessageCount ? (
              <UnreadMessages>
                {data?.unreadMessageCount + "\tNew Messages"}
              </UnreadMessages>
            ) : (
              <TextMessage>{data?.last_sent_message}</TextMessage>
            )}
          </UserInfo>
          <TimeStamp>{start}</TimeStamp>
        </UserChatHeadActive>
      );
    }
    return (
      <UserChatHead
        key={data?.chat_id}
        onClick={() => {
          if (props.usedAt === "dashboard" && !isCheckboxVisible) {
            dispatch(
              ClientCommunicationActions.getSelectedChat(
                (chatInfo.chatHeadData = data)
              )
            );
            history.push("/communication");
          }
          dispatch(
            ClientCommunicationActions.getSelectedChat(
              (chatInfo.chatHeadData = data)
            )
          );
          addIndex(data?.chat_id);
          dispatch(ClientCommunicationActions.resetHasMoreMessages());
          dispatch(ClientCommunicationActions.setIsScrollingActive(false));
        }}
      >
        <ProfileImg>
          {isCheckboxVisible
            ? data?.firstName?.charAt(0)
            : data?.recipient_name?.charAt(0)}
        </ProfileImg>
        <UserInfo>
          <UserName>
            {isCheckboxVisible ? data?.firstName : data?.recipient_name}
          </UserName>
          <UserCompany>{data?.companyName}</UserCompany>
          {renderMessage(data)}
        </UserInfo>
        <TimeStamp>{start}</TimeStamp>
      </UserChatHead>
    );
  };

  return (
    <div>
      <Header>Communications</Header>
      {props.usedAt === "dashboard" && totalUnreadMsgs > 0 ? (
        <TotalUnreadMessages>
          {totalUnreadMsgs + " unread"}{" "}
        </TotalUnreadMessages>
      ) : (
        ""
      )}
      <BttnWrapper>
        <ClientBttn
          selectedType={selectedButton}
          onClick={() => {
            setIsCheckboxVisible(false);
            setSelectedButton("client");
          }}
        >
          Client
        </ClientBttn>
        <VendorBttn
          selectedType={selectedButton}
          onClick={() => {
            setIsCheckboxVisible(false);
            setSelectedButton("vendor");
          }}
        >
          Vendor
        </VendorBttn>
      </BttnWrapper>
      <SearchBarWrapper>
        <div style={{ display: "flex" }}>
          <SearchBar
            size="large"
            placeholder="Search"
            value={searchString}
            onChange={e => {
              setSearchString(e.target.value);
            }}
            suffix={
              <Suffix onClick={() => {}}>
                <ImgWrap src="/images/search.svg" alt="search" />
              </Suffix>
            }
          ></SearchBar>
          {/* <Popover
            getPopupContainer={(trigger: any) => trigger.parentElement}
            placement="bottomRight"
            title={""}
            content={content}
            trigger="click"
          >
            <FilterButton type="primary" onClick={() => {}}>
              <ImgSrc alt="funnel image" src="/images/funnel.svg" />
            </FilterButton>
          </Popover> */}
        </div>
        {!isCheckboxVisible ? (
          <SendMessageText
            onClick={() => {
              setIsCheckboxVisible(true);
              props.onSelectMultiChat(true);
              setSpinnerStatus(true);
              setSearchString("");
              if (selectedButton === "vendor") {
                getVendorList();
              } else {
                getClientList();
              }
            }}
          >
            Start a new conversation
          </SendMessageText>
        ) : (
          <SendMessageText>
            <AntdCheck
              onChange={e => {
                selectAll(e);
              }}
            >
              Select All
            </AntdCheck>
            <CloseCircleOutlined
              onClick={() => {
                setCheckedValues([]);
                setIsCheckboxVisible(false);
                props.onSelectMultiChat(false);
              }}
            />
          </SendMessageText>
        )}
        {userData?.length ? (
          <ListContainer>
            {spinnerStatus && (
              <SpaceDiv size="middle" align="center">
                <Spin size="large" />
              </SpaceDiv>
            )}
            {searchString.length
              ? userData?.map((data: any) => {
                  if (
                    data?.recipient_name
                      ?.toLowerCase()
                      ?.includes(searchString.toLowerCase()) ||
                    data?.companyName
                      ?.toLowerCase()
                      ?.includes(searchString.toLowerCase()) ||
                    data?.firstName
                      ?.toLowerCase()
                      ?.includes(searchString.toLowerCase()) ||
                    data?.companyName
                      ?.toLowerCase()
                      ?.includes(searchString.toLowerCase())
                  )
                    return (
                      <ChatHeadWrapper>
                        {isCheckboxVisible && (
                          <Checkbox
                            value={data._id}
                            onChange={handleOnChange}
                            checked={checkedValues.includes(data._id)}
                            // disabled={
                            //   !checkedValues.includes(data._id) &&
                            //   checkedValues.length === 1
                            // }
                          ></Checkbox>
                        )}
                        {loadChatHeads(data)}
                      </ChatHeadWrapper>
                    );
                })
              : userData?.map((data: any) => {
                  return (
                    <ChatHeadWrapper>
                      {isCheckboxVisible && (
                        <Checkbox
                          value={data._id}
                          onChange={handleOnChange}
                          checked={checkedValues.includes(data._id)}
                          // disabled={
                          //   !checkedValues.includes(data._id) &&
                          //   checkedValues.length === 1
                          // }
                        ></Checkbox>
                      )}
                      {loadChatHeads(data)}
                    </ChatHeadWrapper>
                  );
                })}
          </ListContainer>
        ) : (
          <ListContainer>
            <Notification>
              <img alt="no chat" src="images/searching.svg" />
              <div>
                {" "}
                {selectedButton === "vendor"
                  ? "No Vendor Messages"
                  : "No Client Messages"}
              </div>
            </Notification>
          </ListContainer>
        )}
      </SearchBarWrapper>
    </div>
  );
};

export default ChatList;
