export const Types = {
  GET_VENDOR_PROFILE_REVIEW_REQUESTED:
    "clientVendor/GET_VENDOR_PROFILE_REVIEW_REQUESTED",
  GET_VENDOR_PROFILE_REVIEW_SUCCESS:
    "clientVendor/GET_VENDOR_PROFILE_REVIEW_SUCCESS",
  GET_VENDOR_PROFILE_REVIEW_FAILURE:
    "clientVendor/GET_VENDOR_PROFILE_REVIEW_FAILURE",

  SET_OPEN_REVIEW_MODAL_SUCCESS: "clientVendor/SET_OPEN_REVIEW_MODAL_SUCCESS",

  SET_OPEN_REVIEW_FORM_MODAL_SUCCESS:
    "clientVendor/SET_OPEN_REVIEW_FORM_MODAL_SUCCESS",

  SET_ERROR_MESSAGE: "clientVendor/SET_ERROR_MESSAGE",

  SET_VENDOR_PROFILE_REVIEW_SUCCESS:
    "clientVendor/SET_VENDOR_PROFILE_REVIEW_SUCCESS",
};
