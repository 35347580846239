import AntdToast from "components/Toast/AntdToast";
import { ClientRequestActions } from "container/ClientRequests/actions";
import { IUserRolesProps } from "container/ClientRequests/interfaces/ClientProjectDetails.interface";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AddUserModal } from "../../components/Modals/AddUserModal/AddUserModal";
import { PROJECT_USER_ROLES } from "../../constants/constants";
import { UserRoleConatiner } from "./UserRoleConatiner";
import { UserRolesLayout, UserRolesLayoutDivider, UserRolesSection } from "./UserRoles.styles";

export const UserRoles = (props: IUserRolesProps) => {
  const [addUserModal, setAddUserModal] = useState({ show: false, user: { type: "", name: "" } });
  const dispatch = useDispatch();
  const { selectedProjectDetails, errorMessage, successMessage } = useSelector((state: any) => state.ClientRequestReducer);
  const history = useHistory();

  const UserRoles = [
    {
      type: PROJECT_USER_ROLES.RFQ_OWNER,
      name: "RFQ Owner",
    },
    {
      type: PROJECT_USER_ROLES.COMMERCIAL_USERS,
      name: "Commercial Users",
    },
    {
      type: PROJECT_USER_ROLES.TECHNICAL_USERS,
      name: "Technical User",
    },
    {
      type: PROJECT_USER_ROLES.APPROVERS,
      name: "Approvers",
    },
    {
      type: PROJECT_USER_ROLES.OPERATIONS,
      name: "Operations",
    },
  ];

  useEffect(() => {
    if(props.projectId){
      dispatch(ClientRequestActions.getProjectDetails(props.projectId));
    }else{
      history.push("/client-requests")
    }
  }, [props.projectId]);

  useEffect(() => {
    if(errorMessage || successMessage) {
      AntdToast({ type: successMessage ? "success" : "error", message: successMessage? "Success" : "Error", description: successMessage || errorMessage });
      dispatch(ClientRequestActions.resetSuccessErrorMessage());
    }
  }, [errorMessage,successMessage]);

  return (
    <UserRolesLayout>
      {addUserModal.show && (
        <AddUserModal
          addUser={addUserModal.user}
          onClose={() => setAddUserModal({ show: false, user: { type: "", name: "" } })}
          showModal={addUserModal.show}
        />
      )}
      <UserRolesLayoutDivider />
      <UserRolesSection>
        {UserRoles?.map((userRole, index) => (
          <UserRoleConatiner
            userRole={userRole}
            users={selectedProjectDetails?.userRoles?.[userRole.type]}
            showBottomBorder={true}
            onAddUser={() => setAddUserModal({ show: true, user: userRole })}
          />
        ))}
      </UserRolesSection>
    </UserRolesLayout>
  );
};
