import React, { ReactNode, useState } from 'react'
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Typography } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IProps extends AccordionProps {
  name: string;
  accordianHeader?: string | ReactNode;
  accordionContent: string | ReactNode;
}

export const AccordianContainer = (props: IProps) => {
  const { name, accordionContent, onChange, expanded, ...remainingProps } = props;
  const [expand, setExpand] = useState(props?.defaultExpanded || false);

  return (
    <Accordion expanded={expand} onChange={() => setExpand(prev => !prev)} {...remainingProps}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} >
        {props?.accordianHeader || <Typography>{name}</Typography>}
      </AccordionSummary>
      <AccordionDetails>
        {typeof accordionContent === "string" ? (
          <Typography>
            {accordionContent}
          </Typography>
        ): accordionContent}
      </AccordionDetails>
    </Accordion>
  )
};
