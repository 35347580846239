import { Card, CardContent, CardHeader, List, ListItem, ListItemText, makeStyles, Theme } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import axios from "axios";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../../components/NoAccess/NoAccess";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  coloredListItem: {
    backgroundColor: '#f1f1f1',
    cursor:'pointer',
    color: 'blue'
  },

  listItem: {
    cursor:'pointer',
    color: 'blue'
  },
  transparentButton: {
    border: 'none',
    background: 'transparent',
    color: 'blue'
}
}));

const CommunicatedClientVendor = (() => {
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState({firstName:'', lastName:''});
  const selectedUserId = useParams<{ id: string }>();
  const { userType } = useParams<{ userType: string }>();
  const classes = useStyles();
  const history = useHistory();
  const [hasAccess, setHasAccess] =React.useState(true);

  useEffect(() => {
    getClientOrVendorList();
  },[]);

  const getClientOrVendorList =  async () =>{
    await axios.get(`${process.env.REACT_APP_BASE_URL}/client-vendor-communication/${userType}/${selectedUserId.id}`,
    {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`
      }
    }).then((result)=>{
      if (result) {
        setData(result.data.data);
        setUser(result.data.user)
      }
      else{
        setData([]);
      }
    })
    .catch((error) => {
      if(error.response.status === 403){
        setHasAccess(false);
      } 
    });
    
  }

  const navigateBackToList = () => {
    history.push('/client-vendor-messages');
  }

  const navigateToMessages = (id: any) => {
    let clientId, vendorId;
    if(userType === 'client') {
      clientId = selectedUserId.id;
      vendorId = id;
    } else {
      clientId = id;
      vendorId = selectedUserId.id;
    }
    history.push(`/client-vendor-messages/${clientId}/${vendorId}/messages`)
  }

  return (
    hasAccess?
    // <ClientVendorList vendors= {[]} clients = {[]} data = {data} userType={userType} user={user}></ClientVendorList>
   <Card>
      <CardHeader
          title={`${user?.firstName?.toLocaleUpperCase()} ${user?.lastName?.toLocaleUpperCase()}`} 
        avatar={
          <button className={classes.transparentButton} onClick={() => {
              navigateBackToList()
          }}><ArrowBackIosIcon /></button>
      }>
      </CardHeader>
     <CardContent>
        <List>
          {
            data.map((user: any, index: any) => {
              return (
                <ListItem key={user.id} className={(index%2 !== 0)? classes.coloredListItem: classes.listItem} onClick={() => navigateToMessages(user.id)} >
                  <ListItemText
                    primary= {`${user?.firstName} ${user?.lastName}`}
                    secondary= {user?.companyName}
                  />
                </ListItem>
              )
            })
          }
        </List>
     </CardContent>
   </Card>
   :
   <NoAccess></NoAccess>
  )
})

export default CommunicatedClientVendor;