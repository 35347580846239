import React from "react";
import { TAGS_OPTION } from "../../constants/updateVendorConstants";
import UpdateVendorProfile from "../../container/UpdateVendorProfiles/UpdateVendorProfiles";
import { PromptProvider } from "components/Modal/PromptProvider";
const UpdateTags = () => (
  <PromptProvider>
    <UpdateVendorProfile bodyContent={TAGS_OPTION} />
  </PromptProvider>
);

export default UpdateTags;