import React, { useEffect, useState, useRef } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import LocalStorage from "_services/LocalStorage";
import { useParams } from "react-router-dom";
import { COLUMNS, COMMON_DOCUMENTS_UPLOAD_OPTIONS, DOCUMENT_NAMES, REPORTS_UPLOAD_OPTIONS, SENSITIVE_DOC_UPLOAD_CLIENT_OPTIONS, SENSITIVE_DOC_UPLOAD_TYPE_OPTIONS } from "./vendorDocumentsUpdate.constant";
import { 
  IFileDoc,
  IOtherDocumentsData,
  IOtherDocumentsKeyNamesType,
  IOtherDocumentsKeyNamesTypeWithoutGstn,
  IOtherSensitiveDocumentsKeyNamesType,
  IReportDocumentsData,
  IReportDocumentsKeyNamesType,
  IReportPayload,
  IVcbDocumentData,
  IVendorDocsResp,
  IVendorDocument,
  OTHER_DOCUMENTS_KEY_NAMES_ENUM, 
  OTHER_SENSITIVE_DOCUMENTS_KEY_NAMES_ENUM,
  REPORTS_KEY_NAMES_ENUM
} from "./vendorDocumentsUpdate.interface";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { 
  Button,
  makeStyles,
  Typography,
  Tooltip,
  Zoom,
  Backdrop,
  CircularProgress,
  Box,
  Chip 
} from "@material-ui/core";
import { SelectDropdown } from "./SelectDropdown";
import { grey } from "@material-ui/core/colors";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";
import NoAccess from "components/NoAccess/NoAccess";
import { useDispatch, useSelector } from "react-redux";
import { LoaderActions } from "components/Spinner/actions";
import { Switch } from "@material-ui/core";
import FormControlLabel from '@mui/material/FormControlLabel';
import { AccordianContainer } from "components/VendorTagsUpdate/AccordionContainer";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ONE_FIFTY_MB_IN_BYTES } from "constants/fileConstants";
import { FORM_DATA_KEY } from "container/JobRequests/JobRequestDetails/jobRequest.constants";
import { FileOperationActions } from "container/common/FileOperations/actions";
import SnackBarNotification from "components/SnackBar/SnackBar";
import { IFiles } from "container/JobRequests/JobRequestDetails.interfaces";
import { checkUserHasOnlyVendorViewRole } from "shared/helpers/checkHasAccess";
import { VendorDocumentAccordion } from "./VendorDocumentAccordian";

const useStyles = makeStyles((theme) => ({
  tableContainer: { marginTop: 10 },
  button: { width: 200, marginLeft: "auto" },
  table: { minWidth: 650 },
  selectWidth: { width: 200 },
  disabledSelect: { background: grey[100], cursor: "not-allowed", opacity: 0.7 },
  tableHeader: { "& th": { borderRight: "1px solid #eeeeee" } },
  tableRow: { "& td": { height: 20, padding: "6px 8px 6px 8px", borderRight: "1px solid #eeeeee" } },
  docName: { width: 200 },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },

  vcbLink: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: "#1a73e8",
    textDecoration: "none",
    "&:hover": {
      color: "#3385FF",
      textDecoration: "underline",
    },
  },
}));

const VendorsDocumentsUpdate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { gstn } = useParams<{ gstn: string }>();
  const [vendorDocsData, setVendorDocsData] = useState<IVendorDocsResp | null>(null);
  const [documents, setDocuments] = useState<IVendorDocument[] | null>(null);
  const [disableMoveFiles, setDisableMoveFiles] = useState(true);
  const [showNonFinancialDocs, setShowNonFinancialDocs] = useState(true);
  const [showFinancialDocs, setShowFinancialDocs] = useState(false);
  const [updatedDocuments, setUpdatedDocuments] = useState<IVendorDocument[] | null>(null);
  const { isLoading } = useSelector((state: any) => state.LoaderReducers);
  const disableDestinationValueSelect = (doc: IVendorDocument, vendorDocsData: IVendorDocsResp) => {
    return !doc.destination || !Object.keys(vendorDocsData?.optionsWithValues)?.includes(doc.destination);
  };
  const [reloadUpdate, setReloadUpdate] = useState(false);

  const access = checkUserHasOnlyVendorViewRole()

  const [hasAccess, setHasAccess] = useState(true);
  const [isViewOpen, setIsViewOpen] = useState(access);

  /** Common Doc Upload State */
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const hiddenSensitiveInputRef = useRef<HTMLInputElement>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isCommonDocUploading, setIsCommonDocUploading] = useState(false);
  const [isSensitiveDocUploading, setIsSensitiveDocUploading] = useState(false);
  const [commonDocType, setCommonDocType] = useState<IOtherDocumentsKeyNamesTypeWithoutGstn>(OTHER_DOCUMENTS_KEY_NAMES_ENUM.others);
  const [sensitiveDocType, setSensitiveDocType] = useState<IOtherSensitiveDocumentsKeyNamesType>(OTHER_SENSITIVE_DOCUMENTS_KEY_NAMES_ENUM.nda);
  const [sensitiveClientName, setSensitiveClientName] = useState(SENSITIVE_DOC_UPLOAD_CLIENT_OPTIONS[0].value);
  const [isFetchingOtherDocData, setIsFetchingOtherDocData] = useState(false);
  const [isFetchingVCBDocData, setIsFetchingVCBDocData] = useState(false);
  const [otherDocumentsData, setOtherDocumentsData] = useState<IOtherDocumentsData>({});
  const [reportDocumentsData, setReportDocumentsData] = useState<IReportDocumentsData>({});
  const [vcbDocumentsData, setVcbDocumentsData] = useState<IVcbDocumentData>([]);
  const hiddenReportInputRef = useRef<HTMLInputElement>(null);
  const [reportDocType, setReportDocType] = useState<IReportDocumentsKeyNamesType>(REPORTS_KEY_NAMES_ENUM.others);
  const [isReportDocUploading, setIsReportDocUploading] = useState(false);
  const [isFetchingReportDocData, setIsFetchingReportDocData] = useState(false);
  
  const tableColumnName = (name: string) => {
    return (
      <Typography variant="button" display="block" gutterBottom>
        {name}
      </Typography>
    );
  };

  const getSourceString = (doc: IVendorDocument) => {
    const sourceString = doc?.source;
    const keyName = sourceString?.includes("-") ? sourceString?.split("-")[0] : sourceString;
    return DOCUMENT_NAMES?.[keyName] || keyName;
  };

  const handleDocumentsView = (toggleId: string) => {
    try {

      (async () => {
        let payload
        if (toggleId === 'showNonFinancialDocs') {
          payload = { showFinancialDocuments: showFinancialDocs, showNonFinancialDocuments: !showNonFinancialDocs }
        } else if (toggleId === 'showFinancialDocs') {
          payload = { showFinancialDocuments: !showFinancialDocs, showNonFinancialDocuments: showNonFinancialDocs }
        }
        
        /** POST CALL */
        const result: AxiosResponse = await axios({
          method: "POST",
          url:  process.env.REACT_APP_API_URL + `/v1/company-infos/${gstn}`,
          data: payload,
          headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
        });
        
        if (result?.status === 201 || result?.data?.status === "SUCCESS") {
          if (toggleId === 'showNonFinancialDocs') {
            setShowNonFinancialDocs(!showNonFinancialDocs);
          } else if (toggleId === 'showFinancialDocs') {
            setShowFinancialDocs(!showFinancialDocs);
          }
        }
      })();
    } catch (err) {
      console.error("handleDocumentsView error: ", err);
    }
  };

  const handleMoveFiles = () => {
    try {
      /** POST CALL */
      (async () => {
        const result: AxiosResponse = await axios({
          method: "POST",
          url: process.env.REACT_APP_API_URL + `/v1/document-manager/${gstn}`,
          data: { documents: updatedDocuments },
          headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
        });
        if (result?.status === 201 || result?.data?.status === "SUCCESS") {
          leadSquaredTrigger(gstn);
        }
      })();
    } catch (err) {
      console.error("Unable to Update Vendor Documents", err);
    }
    setReloadUpdate(true);
    setUpdatedDocuments([]);
    setDisableMoveFiles(true);
  };

  const handleSetDocuments = (
    currentDoc: IVendorDocument,
    keyName: "destination" | "destinationValue",
    optionValue: string
  ) => {
    setDocuments((prev) => {
      return prev
        ? prev?.map((document) => {
            return document.source === currentDoc.source ? { ...currentDoc, [keyName]: optionValue } : document;
          })
        : [];
    });
  };

  const getDestinationOptions = (doc: IVendorDocument, vendorDocsData: IVendorDocsResp) => {
    const SOURCE_FIELD = doc?.source?.includes("-") ? doc?.source.split("-")[0] : doc?.source;
    const options: { label: string; value: string }[] = [];
    Object.keys(DOCUMENT_NAMES)?.forEach((option) => {
      if (option !== SOURCE_FIELD) {
        if (["experience", "technical", "esic", "epf", "reference", "oem", "cin"].includes(option)) {
          if (Object.keys(vendorDocsData?.optionsWithValues).includes(option)) {
            options.push({
              label: DOCUMENT_NAMES?.[option],
              value: option,
            });
          }
        } else {
          options.push({
            label: DOCUMENT_NAMES?.[option],
            value: option,
          });
        }
      }
    });
    return options;
  };

  const getOptionWithValues = (destination: string) => {
    if (!destination) return [];
    if (vendorDocsData && Object.keys(vendorDocsData?.optionsWithValues)?.includes(destination)) {
      return vendorDocsData?.optionsWithValues?.[destination]?.map((val) => ({ value: val, label: val }));
    }
    return [];
  };

  useEffect(() => {
    /** GET CALL - populates common documents data */
    (async () => {
      try {
        setIsFetchingOtherDocData(true);
        setIsFetchingReportDocData(true);
        const otherDocResp: AxiosResponse = await axios({
          method: "GET",
          url: process.env.REACT_APP_API_URL + `/v1/document-manager/other-documents/${gstn}`,
          headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
        });
        if (otherDocResp?.status === 200) {
          setIsFetchingOtherDocData(false)
          setIsFetchingReportDocData(false)
          setReportDocumentsData(otherDocResp?.data?.reports);
          setOtherDocumentsData(otherDocResp?.data);
        }
      } catch (err) {
        setIsFetchingOtherDocData(false)
        setIsFetchingReportDocData(false)
        setReloadUpdate(false);
        console.log("unable to get other-Documents", err);
      }
    })();
  }, [reloadUpdate]);

  useEffect(() => {
    /** GET CALL - populates vcb documents data */
    (async () => {
      try {
        setIsFetchingVCBDocData(true);
        const vcbDocResp: AxiosResponse = await axios({
          method: "GET",
          url: process.env.REACT_APP_API_URL + `/v1/document-manager/vcb-documents/${gstn}`,
          headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
        });
        if (vcbDocResp?.status === 200) {
          setIsFetchingVCBDocData(false)
          setVcbDocumentsData(vcbDocResp?.data);
        }
      } catch (err) {
        setIsFetchingVCBDocData(false)
        setReloadUpdate(false);
        console.log("unable to get vcb-Documents", err);
      }
    })();
  }, [reloadUpdate]);

  useEffect(() => {
    /** GET CALL - populates move-files data */
    try {
      (async () => {
        dispatch(LoaderActions.setIsLoading(true));
        const resp: AxiosResponse = await axios({
          method: "GET",
          url: process.env.REACT_APP_API_URL + `/v1/document-manager/${gstn}`,
          headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
        });
        if (resp?.status === 200) {
          setReloadUpdate(false);
          dispatch(LoaderActions.setIsLoading(false));
          setVendorDocsData(resp?.data);
          setDocuments(
            resp?.data?.documents.map((doc: IVendorDocument) => ({ ...doc, destination: "", destinationValue: "" }))
          );
        }

        const companyInfoResp: AxiosResponse = await axios({
          method: "GET",
          url: process.env.REACT_APP_API_URL + `/v1/company-infos/${gstn}`,
          headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
        });
        if (companyInfoResp?.status === 200) {
          if (companyInfoResp?.data?.showNonFinancialDocuments !== null) {
            setShowNonFinancialDocs(companyInfoResp?.data?.showNonFinancialDocuments);
          }
          if (companyInfoResp?.data?.showNonFinancialDocuments !== null) {
            setShowFinancialDocs(companyInfoResp?.data?.showFinancialDocuments);
          }          
        }
      })();
    } catch (err) {
      dispatch(LoaderActions.setIsLoading(false));
      setReloadUpdate(false);
      console.error("unable to get Documents", err);
    }
  }, [reloadUpdate]);

  useEffect(() => {
    /** Creates Payload & disables MoveFiles Button when required */
    const PAYLOAD =
      documents?.filter((doc) => {
        if (vendorDocsData?.optionsWithValues && doc?.destination) {
          // non-empty destinationField
          if (vendorDocsData?.optionsWithValues?.[doc.destination]) {
            // destinationField in optionsWithValues object
            if (doc?.destinationValue) return doc;
          }
          return doc;
        }
      }) || [];
    if (PAYLOAD?.length >= 1) {
      setDisableMoveFiles(false);
      setUpdatedDocuments(PAYLOAD);
    } else {
      setDisableMoveFiles(true);
      setUpdatedDocuments([]);
    }
  }, [documents]);

  const handleChangeCommonDocType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const VALUE = e?.target?.value as IOtherDocumentsKeyNamesTypeWithoutGstn || OTHER_DOCUMENTS_KEY_NAMES_ENUM.others;
    setCommonDocType(VALUE);
  };

  const handleChangeReportDocType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const VALUE = e?.target?.value as IReportDocumentsKeyNamesType;
    setReportDocType(VALUE);
  };

  const handleChangeSensitiveDocType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const VALUE: any = e?.target?.value;
    setSensitiveDocType(VALUE);
  };

  const handleChangeSensitiveClientName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const VALUE: any = e?.target?.value;
    setSensitiveClientName(VALUE);
  };

  const postCommonDoc = (payload: IOtherDocumentsData) => {
    axios({
      method: "POST",
      data: payload,
      url: process.env.REACT_APP_API_URL + `/v1/document-manager/common-documents/${gstn}`,
      headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
    }).then((res: AxiosResponse) => {
      setReloadUpdate(true);
      setIsCommonDocUploading(false);
    }).catch((err: AxiosError) => {
      setShowNotification(true);
      setIsCommonDocUploading(false);
      setNotificationMessage("Error in updating common docs");
    });
  };

  const postReportDoc = (payload: IReportDocumentsData) => {
    const newPayload: IReportPayload = {
      gstn: gstn,
      reports: payload
    }
    axios({
      method: "POST",
      data: newPayload,
      url: process.env.REACT_APP_API_URL + `/v1/document-manager/report-documents/${gstn}`,
      headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
    }).then((res: AxiosResponse) => {
      setReloadUpdate(true);
      setIsReportDocUploading(false);
    }).catch((err: AxiosError) => {
      setShowNotification(true);
      setIsReportDocUploading(false);
      setNotificationMessage("Error in updating report docs");
    });
  };

  const postSensitiveDoc = (payload: any) => {
    axios({
      method: "POST",
      data: payload,
      url: process.env.REACT_APP_API_URL + `/v1/document-manager/sensitive-documents/${gstn}`,
      headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
    }).then((res: AxiosResponse) => {
      setReloadUpdate(true);
      setIsSensitiveDocUploading(false);
    }).catch((err: AxiosError) => {
      setShowNotification(true);
      setIsSensitiveDocUploading(false);
      setNotificationMessage("Error in updating sensitive docs");
    });
  };

  const handleSensitiveMediaUpload = ({ e, TYPE }: {
    e: React.FormEvent<HTMLInputElement>,
    TYPE: IOtherSensitiveDocumentsKeyNamesType
  }) => {
    e.stopPropagation();
    if (!TYPE) return;
    const files = Object.entries(((e?.target as any)?.files || {}) as FileList);
    let filesWithError: File[] = [];
    let newFilesToBeUploaded: File[] = [];
    if (files?.length) {
      setIsSensitiveDocUploading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const [_, file] = files[i];
        const isFileTypeUndefined = file.type === "undefined" || file.type === "null";
        const isFileSizeExceedingLimit = file.size > ONE_FIFTY_MB_IN_BYTES;
        const isDuplicateFile = otherDocumentsData?.[TYPE]
          ? (otherDocumentsData?.[TYPE] as IFiles[])?.find((existingFile) => existingFile.name === file.name)
          : false;

        if (isDuplicateFile || isFileSizeExceedingLimit || isFileTypeUndefined) {
          filesWithError.push(file);
        } else {
          newFilesToBeUploaded.push(file);
        }
      }

      if (filesWithError?.length) {
        setShowNotification(true);
        setNotificationMessage(`Uploaded file is either undefined, exceeds limit or already exists. ${filesWithError?.map(file => file?.name).join(", ")}`);
        if (!newFilesToBeUploaded?.length) setIsSensitiveDocUploading(false);
      }

      if (newFilesToBeUploaded?.length) {
        newFilesToBeUploaded.forEach((file) => formData.append(FORM_DATA_KEY, file));
        /** Multiple file upload */
        dispatch(FileOperationActions.uploadFileToPermanentFolder(formData)).then(
          (res: IFiles[]) => {
            if (res?.length) {
              const newUploadedFiles = res.map((file) => ({ name: file.name, documentFile: file, clientName: sensitiveClientName })) || [];
              const allFilesToBeUploaded = otherDocumentsData?.[TYPE] && otherDocumentsData?.[TYPE]?.length
                ? otherDocumentsData?.[TYPE]?.concat(newUploadedFiles)
                : newUploadedFiles;
              const payload: IOtherDocumentsData = { [TYPE]: allFilesToBeUploaded };
  
              /** proceed with uploading sensitive common docs */
              if (payload?.[TYPE]?.length) postSensitiveDoc(payload);

            }
          },
          (err: any) => {
            setShowNotification(true);
            setIsSensitiveDocUploading(false);
            setNotificationMessage("Error in file upload");
          }
        );
      } else {
        setIsSensitiveDocUploading(false);
      }

      /** reset input value for same file upload */
      (e.target as any).value = "";
    }
  };

  const handleDeleteSensitivenDoc = ({ file, TYPE } : { file: IFileDoc, TYPE: IOtherSensitiveDocumentsKeyNamesType }) => {
    const filteredFiles = otherDocumentsData?.[TYPE] && otherDocumentsData?.[TYPE]?.length
      ? otherDocumentsData?.[TYPE]?.filter((existingFile) => existingFile?.documentFile?.fileUrl !== file?.documentFile?.fileUrl)
      : [];
    const payload: any = { gstn, [TYPE]: filteredFiles };
    postSensitiveDoc(payload);
  };

  if (!hasAccess) {
    return <NoAccess />;
  }

  return (
    <Box>
      <SnackBarNotification
        severity={"error"}
        message={notificationMessage}
        openNotification={showNotification}
        closeSnackbar={() => setShowNotification(false)}
      />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <VendorDocumentAccordion
          gstn={gstn}
          defaultExpanded={true}
          isViewOpen={true}
          title={"Upload Common Documents"}
          docType={commonDocType}
          handleChangeDocType={handleChangeCommonDocType}
          isDocUploading={isCommonDocUploading}
          DocumentsData={otherDocumentsData}
          setIsDocUploading={setIsCommonDocUploading}
          DOCUMENTS_UPLOAD_OPTIONS={COMMON_DOCUMENTS_UPLOAD_OPTIONS}
          DOCUMENTS_KEY_NAMES_ENUM={OTHER_DOCUMENTS_KEY_NAMES_ENUM}
          hiddenInputRef={hiddenInputRef}
          postDoc={postCommonDoc}
          setShowNotification={setShowNotification}
          setNotificationMessage={setNotificationMessage}
      />
      <Box className="mb-4">
        <Paper>
          <AccordianContainer
            name="VCB Link"
            accordionContent={
              <Box width={"100%"}>
                {/* get VCB Docs */}
                {Object?.keys(vcbDocumentsData)?.length !== 0 && (
                  <Box width={"100%"} overflow={"auto"} maxHeight={"150px"}>
                    {vcbDocumentsData.map(
                      (item) =>
                        item?.vcbLink?.fileUrl && (
                          <Box
                            key={item?.jobId}
                            style={{ marginBottom: "8px" }}
                          >
                            <a
                              href={item?.vcbLink?.fileUrl}
                              target="_blank"
                              style={{ textDecoration: "none" }}
                            >
                              <Typography className={classes.vcbLink}>
                                {`${item?.jobId}`}
                                {item?.jobTitle && (
                                  <span>{` | ${item?.jobTitle}`}</span>
                                )}
                              </Typography>
                            </a>
                          </Box>
                        )
                    )}
                  </Box>
                )}
              </Box>
            }
          >
            <></>
          </AccordianContainer>
        </Paper>
      </Box>
      <VendorDocumentAccordion
          gstn={gstn}
          isViewOpen={isViewOpen}
          defaultExpanded={true}
          title={"Upload Reports"}
          docType={reportDocType}
          handleChangeDocType={handleChangeReportDocType}
          isDocUploading={isReportDocUploading}
          DocumentsData={reportDocumentsData}
          setIsDocUploading={setIsReportDocUploading}
          DOCUMENTS_UPLOAD_OPTIONS={REPORTS_UPLOAD_OPTIONS}
          DOCUMENTS_KEY_NAMES_ENUM={REPORTS_KEY_NAMES_ENUM}
          hiddenInputRef={hiddenReportInputRef}
          postDoc={postReportDoc}
          setShowNotification={setShowNotification}
          setNotificationMessage={setNotificationMessage}
      />
      <Box className="mb-4">
        <Paper elevation={3}>
          <AccordianContainer
            name="Upload Sensitive Documents"
            accordionContent={(
              <Box width={"100%"}>
                {isViewOpen && <Box
                  marginBottom={1}
                  display={"flex"}
                  width={"max-content"}
                  flexDirection={"row"}
                >
                  <Box marginRight={4}>
                    <SelectDropdown
                      label="Client Name"
                      value={sensitiveClientName}
                      style={{ width: "250px" }}
                      handleOnChange={handleChangeSensitiveClientName}
                      options={SENSITIVE_DOC_UPLOAD_CLIENT_OPTIONS as any}
                    />
                  </Box>
                  <Box marginRight={4}>
                    <SelectDropdown
                      label="Document Type"
                      value={sensitiveDocType}
                      style={{ width: "250px" }}
                      handleOnChange={handleChangeSensitiveDocType}
                      options={SENSITIVE_DOC_UPLOAD_TYPE_OPTIONS as any}
                    />
                  </Box>
                  <>
                    <input
                      multiple
                      type="file"
                      ref={hiddenSensitiveInputRef}
                      style={{ display: "none" }}
                      disabled={isSensitiveDocUploading}
                      onInput={(e) => handleSensitiveMediaUpload({ e, TYPE: sensitiveDocType }) }
                    />
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={isSensitiveDocUploading}
                      startIcon={isSensitiveDocUploading 
                        ? <CircularProgress size={20} />
                        : <CloudUploadIcon />
                      }
                      onClick={() => hiddenSensitiveInputRef?.current?.click() }
                    >
                      Upload
                    </Button>
                  </>
                </Box>}
                {/* Uploaded Other Sensitive Docs */}
                {Object?.keys(otherDocumentsData)?.length!==0 && <Box
                  width={"100%"}
                  overflow={"auto"}
                  maxHeight={"150px"}
                >
                  {Object?.keys(otherDocumentsData)?.length && Object?.keys(otherDocumentsData)?.sort((a, b) => a.localeCompare(b))?.map((keyName) => {
                    if (!(Object.values(OTHER_SENSITIVE_DOCUMENTS_KEY_NAMES_ENUM) as string[]).includes(keyName)) {
                      return <></>
                    } else {
                      /** Rendering Sensitive Docs with specific type */
                      const typeOfSensitiveDoc = keyName as IOtherSensitiveDocumentsKeyNamesType;
                      const sensitiveDocLabel = SENSITIVE_DOC_UPLOAD_TYPE_OPTIONS?.find((doc) => 
                        doc?.value === typeOfSensitiveDoc
                      )?.label;
                      const values = otherDocumentsData?.[typeOfSensitiveDoc] || [];

                      if (!values?.length) return <></>;

                      return (
                        <Box 
                          padding={1}
                          marginRight={1}
                          marginBottom={1}
                          key={typeOfSensitiveDoc}
                          borderTop={"1px solid #ccc"}
                        >
                          <Box marginBottom={1}>
                            <Typography>{sensitiveDocLabel}</Typography>
                          </Box>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            flexWrap={"wrap"}
                            alignItems={"center"}
                            justifyContent={"flex-start"}
                            gridGap={2}
                          >
                            {values?.map((file, index) => {
                              return (
                                <Box 
                                  marginRight={1}
                                  marginBottom={1}
                                  key={file?.documentFile?.fileUrl || index}
                                >
                                  <Chip
                                    clickable
                                    icon={(
                                      <Box
                                        px={1}
                                        borderRadius={"50%"}
                                        bgcolor={"#eeeeee"}
                                        height={20}
                                        width={20}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                      >
                                        {index+1}
                                      </Box>
                                    )}
                                    label={(
                                      <>
                                        <Typography
                                          style={{ fontSize: "14px" }}
                                          onClick={() => window.open(file?.documentFile?.fileUrl, "_blank")}
                                        >
                                          {file?.name}
                                        </Typography>
                                      </>
                                    )}
                                    onDelete={() => {
                                      handleDeleteSensitivenDoc({ file, TYPE: typeOfSensitiveDoc as IOtherSensitiveDocumentsKeyNamesType });
                                    }}
                                  />
                                </Box>
                              )
                            })}
                          </Box>
                        </Box>
                      );
                    };
                  })}
                </Box>}
              </Box>
            )} 
          >
            <></>
          </AccordianContainer>
        </Paper>
      </Box>
      <Box display={"flex"}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleMoveFiles}
          disabled={disableMoveFiles || !isViewOpen}
        >
          Move Files
        </Button>
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        <FormControlLabel
          control={
            <Switch
              checked={showFinancialDocs}
              onChange={() => handleDocumentsView("showFinancialDocs")}
              color="primary"
            />
          }
          label="Show Financial documents"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showNonFinancialDocs}
              onChange={() => handleDocumentsView("showNonFinancialDocs")}
              color="primary"
            />
          }
          label="Show Non Financial documents"
        />
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {COLUMNS(tableColumnName, isViewOpen)?.map((column, index) => (
                <TableCell align={"left"} width={column.width}>
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {documents &&
              documents?.map((doc, index) => {
                if (doc?.sourceFile?.file?.fileUrl) {
                  return (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.docName}>
                        <a href={doc?.sourceFile?.file?.fileUrl} target="_blank">
                          <Tooltip
                            disableFocusListener
                            disableTouchListener
                            TransitionComponent={Zoom}
                            title={<Typography>{doc.sourceFile.name}</Typography>}
                          >
                            <Typography noWrap className={classes.docName}>
                              <>{doc.sourceFile.name}</>
                            </Typography>
                          </Tooltip>
                        </a>
                      </TableCell>
                      <TableCell>{getSourceString(doc)}</TableCell>
                      <TableCell>{doc?.sourceValue || ""}</TableCell>
                      {isViewOpen &&<TableCell>
                        {vendorDocsData && documents && (
                          // Options
                          <SelectDropdown
                            className={classes.selectWidth}
                            label="Select"
                            value={doc?.destination}
                            handleOnChange={(e) => handleSetDocuments(doc, "destination", e?.target?.value)}
                            options={getDestinationOptions(doc, vendorDocsData)}
                          />
                        )}
                      </TableCell>}
                      {isViewOpen && <TableCell width={"20%"}>
                        {vendorDocsData && documents && (
                          // OptionsWithValues
                          <SelectDropdown
                            className={`${classes.selectWidth} ${
                              disableDestinationValueSelect(doc, vendorDocsData) && classes.disabledSelect
                            }`}
                            disabled={disableDestinationValueSelect(doc, vendorDocsData)}
                            label={disableDestinationValueSelect(doc, vendorDocsData) ? "" : "Select"}
                            value={doc?.destinationValue}
                            handleOnChange={(e) => {
                              handleSetDocuments(doc, "destinationValue", e?.target?.value);
                            }}
                            options={doc.destination ? getOptionWithValues(doc.destination) : []}
                          />
                        )}
                      </TableCell>}
                    </TableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VendorsDocumentsUpdate;
