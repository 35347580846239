import { useRef, useEffect } from "react";
import _ from "lodash";
import { isMediaAlreadyExistingInEntity } from "components/LinkAvailableGalleryItemsModal/LinkAvailableGalleryItemsModal.helper";
import { getUrlFromMediaObject } from "components/VendorCapabilitiesUpdate/components/GalleryModal/galleryModal.handler";

export function useDeepCompareMemoize(value) {
    const ref = useRef();
  
    if (!_.isEqual(value, ref.current)) {
      ref.current = value;
    }
  
    return ref.current;
}

export const ImageAttactedCount = (currEntityObj, associatedMediaItems) =>{
  const filteredArray = associatedMediaItems.filter((item, index) =>{
    const url = getUrlFromMediaObject(item);
    return isMediaAlreadyExistingInEntity(url, currEntityObj);
  });
  return filteredArray?.length || 0;
}