import React, { useRef, useState } from "react";
import { saveAs } from "file-saver";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Button,
  CardHeader,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Card } from "antd";
import TagsTab from "./TagsTab";
import NoAccess from "../../components/NoAccess/NoAccess";
import Papa from "papaparse";
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { axiosHandler } from "shared/handlers/axios.handler";
import moment from "moment";
import { END_POINTS } from "./constants";
import { CSVUploadButton } from "./CSVUploadButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  input: { display: "none", visibility: "hidden" },
  fileUploadButtonWidth: { width: 200 },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
        
      )}
    </div>
  );
}

const Tags = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [hasAccess, setHasAccess] =React.useState(true);
  const buttonInputRefForTags = useRef(null);
  const buttonInputRefForGroups = useRef(null);
  const [isBulkUpdateTagsInProgress, setIsbulkUpdateTagsInProgress] = useState(false);
  const [isBulkUpdateGroupsInProgress, setIsbulkUpdateGroupsInProgress] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const downloadCSVFile = (csvFileString, fileName) => {
    const blob = new Blob([csvFileString], {type: "text/csv;charset=utf-8"});
    saveAs(blob, `${fileName}.csv`);
  }

  /** @param {Object[]} payload 
   * @param {"BULK_UPDATE_TAGS" | "BULK_UPDATE_GROUPS_OR_FAMALIES"} type
  */
  const postBulkUpdatePayload = (payload, type) => {
    switch (type) {
      case "BULK_UPDATE_TAGS":
        setIsbulkUpdateTagsInProgress(true);
        axiosHandler({
          withAuth: true,
          axiosConfig: { 
            method: "POST", 
            url: process.env.REACT_APP_API_URL + END_POINTS.UPDATE_BULK_PROFILE.TAGS,
            data: payload,
          },
          successCallback: (successResp) => {
            if (successResp?.status === "SUCCESS" || successResp?.data?.status === "SUCCESS") {
              const CSV_FILE_STRING = Papa.unparse(successResp?.data);
              if (CSV_FILE_STRING) {
                const FILE_NAME = `bulk_update_tags_results_${moment().format("DD-MM-YYYY_ddd_h-mm-ss_a")}`;
                downloadCSVFile(CSV_FILE_STRING, FILE_NAME);
              }
              setIsbulkUpdateTagsInProgress(false);
            }
          },
          failureCallback: (err) => {
            console.log("Unable to Upload bulk update tags file", err);
            setIsbulkUpdateTagsInProgress(false);
          }
        })
        break;

      case "BULK_UPDATE_GROUPS_OR_FAMALIES":
        setIsbulkUpdateGroupsInProgress(true);
        axiosHandler({
          withAuth: true,
          axiosConfig: { 
            method: "POST", 
            url: process.env.REACT_APP_API_URL + END_POINTS.UPDATE_BULK_PROFILE.GROUPS,
            data: payload,
          },
          successCallback: (successResp) => {
            if (successResp?.status === "SUCCESS" || successResp?.data?.status === "SUCCESS") {
              const CSV_FILE_STRING = Papa.unparse(successResp?.data);
              if (CSV_FILE_STRING) {
                const FILE_NAME = `bulk_update_groups_results_${moment().format("DD-MM-YYYY_ddd_h-mm-ss_a")}`;
                downloadCSVFile(CSV_FILE_STRING, FILE_NAME);
              }
              setIsbulkUpdateGroupsInProgress(false);
            }
          },
          failureCallback: (err) => {
            console.log("Unable to Upload bulk update tags file", err);
            setIsbulkUpdateGroupsInProgress(false);
          }
        })
        break;
    
      default:
        break;
    }



  }

  /** @param {{
   *  e: React.ChangeEvent<HTMLInputElement>,
   *  callback: (csvPayload: any[]) => void
   * }}
  */
  const handleCSVFileInput = ({e, callback}) => {
    const csvFile = e?.target?.files[0];
    if (csvFile) {
      Papa.parse(csvFile, {
        header: true,
        dynamicTyping: true,
        complete: (results) =>  {
          // async operation
          const PAYLOAD = [];
          const CSV_DATA = results?.data;
          CSV_DATA?.length && CSV_DATA?.forEach(rowDataObj => {
            if (rowDataObj) {
              const rowDataForPayload = {};
              Object.keys(rowDataObj)?.forEach((columnName) => {
                if (!["Result", "result"]?.includes(columnName)) {
                  // row object payload must only contain string values
                  if ([0, 1]?.includes(rowDataObj[columnName])) {
                    // csv cell value can be 0 or 1
                    rowDataForPayload[columnName] = rowDataObj[columnName];
                  } else {
                    rowDataForPayload[columnName] = rowDataObj[columnName] || "";
                  }
                }
              });
              PAYLOAD.push(rowDataForPayload);
            }
          });
          callback(PAYLOAD);
        }
      });
    }
  }

  return (
    hasAccess ?
    <>
      <Card className={classes.root}>
        <Box display={"flex"} alignItems="center" justifyContent={"space-between"}>
          <CardHeader title="Tags" />
          <Box display={"flex"} alignItems="center" justifyContent={"space-between"} gridGap="1rem">
            {/* Bulk Update Groups/Families - Button */}
            <CSVUploadButton
              buttonWidth={250}
              inputRef={buttonInputRefForGroups}
              buttonName="Bulk Update Groups/Families" 
              loadingState={isBulkUpdateGroupsInProgress}
              handleOnButtonClick={() => buttonInputRefForGroups?.current?.click()}
              handleOnInput={(e) => {
                handleCSVFileInput({ e, callback: (csvPayload) => postBulkUpdatePayload(csvPayload, "BULK_UPDATE_GROUPS_OR_FAMALIES")});
              }}
            />
            {/* Bulk Update Tags - Button */}
            <CSVUploadButton
              inputRef={buttonInputRefForTags}
              buttonName="Bulk Update Tags" 
              loadingState={isBulkUpdateTagsInProgress}
              handleOnButtonClick={() => buttonInputRefForTags?.current?.click()}
              handleOnInput={(e) => {
                handleCSVFileInput({ e, callback: (csvPayload) => postBulkUpdatePayload(csvPayload, "BULK_UPDATE_TAGS")});
              }}
            />
          </Box>
        </Box>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Equipments" value={0}/>
            <Tab label="OEM" value={1}/>
            <Tab label="Service" value={2} />
            <Tab label="New Equipment Tags" value={3} />
            <Tab label="New OEM Tags" value={4} />
            <Tab label="New Service Tags" value={5} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <TagsTab setAccessStatus = {(status) => setHasAccess(status)} type={"Equipment"} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TagsTab setAccessStatus = {(status) => setHasAccess(status)} type={"OEM"} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TagsTab setAccessStatus = {(status) => setHasAccess(status)} type={"Service"} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TagsTab setAccessStatus = {(status) => setHasAccess(status)} type={"NewEquipment"} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TagsTab setAccessStatus = {(status) => setHasAccess(status)} type={"NewOEM"} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <TagsTab setAccessStatus = {(status) => setHasAccess(status)} type={"NewService"} />
        </TabPanel>
      </Card>
    </>
:
<NoAccess></NoAccess>
  );
};

export default Tags;
