import moment from "moment";
import Text from "../Template/Text";
import File from "../Template/File";
import { IncomingMessageContainer, MessageDate, MessageWrapper, ParticipantImage, DeleteIcon, Message } from "../Chat.styles";

interface MessageProps {
  message_id: string;
  date: string;
  sharedFiles: any;
  message: string;
  link?: any;
  message_type?: string;
}
interface TextProps {
  message: MessageProps;
  onMouseLeave: (id: number) => void;
  onMouseEnter: (index: number) => void;
  index: number;
  setMessageData: (message: any) => void;
  setIsDeleteIncomingModal: (bool: boolean) => void;
  vendorIntials: string;
  onHoverIncoming: any;
}
const IncomingText = (props: TextProps) => {
  const renderTemplate = (data: any) => {
    switch (data?.message_type) {
      case "file":
        return (
          <File
            file={data?.sharedFiles?.[0]}
            message={data?.message}
            type={"incoming"}
          />
        );
      case "link":
        return (
          <Text
            message={data?.message}
            isLinkAvailable={true}
            message_type={"incoming"}
            linkData={props?.message?.link}
          />
        );
      case "text":
        return (
          <Text
            message={data?.message}
            isLinkAvailable={false}
            message_type={"incoming"}
          />
        );
      default:
        return (
          <Text
            message={data?.message}
            isLinkAvailable={false}
            message_type={"incoming"}
          />
        );
    }
  };
  return (
    <>
      <IncomingMessageContainer key={props?.message.message_id}>
        {props.message.message_type !== 'request' && 
          <MessageDate>
            {moment(props?.message?.date).format("YYYY-MM-DD HH:mm:ss")}
          </MessageDate>
        }
        <MessageWrapper
          onMouseLeave={() => props?.onMouseLeave(0)}
          onMouseEnter={() => props?.onMouseEnter(props?.index + 1)}
        >
          {props.message.message_type !== 'request' && 
          <ParticipantImage bgColor="#FFFFFF" color="#215ec9">
            {props?.vendorIntials}
          </ParticipantImage>
          }
          <Message>{renderTemplate(props?.message)}</Message>
          {!!(props?.index + 1 === props?.onHoverIncoming) && props.message.message_type !== 'request' && (
            <DeleteIcon
              onClick={() => {
                props?.setMessageData(props?.message);
                props?.setIsDeleteIncomingModal(true);
              }}
              src="images/trash.svg"
            ></DeleteIcon>
          )}
        </MessageWrapper>
      </IncomingMessageContainer>
    </>
  );
};

export default IncomingText;
