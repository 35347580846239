export const TITLE = {
  advanceFilters: {
    equipment: "Equipment",
    oem: "OEM",
    service: "Service",
    equipmentGroups: "Equipment Group",
    serviceGroups: "Service Group",
    industriesServed: "Industry Served",
    statesServed: "Location",
    turnOverSlab: "Turn Over Slab"
  },
  initialFilters: {
    industriesServed: "Industries Served",
    turnOverSlab: "TurnOverSlab",
    statesServed: "States Served",
    regionServed: "Region Served",
    service: "Service",
    equipment: "Equipment",
    serviceGroup: "Service Group",
    equipmentGroup: "Equipment Group",
    customerTier  : "Customer Tier",
    equipFamily : "Equipment Family",
  },
  dateTypeFilters: {
    dateType: "Date Type",
  },
} as const;
