import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  peopleInfoContainer: {
    padding: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  subHeader: {
    color: "#66759c",
    fontWeight: 600,
  },
  chipContainer: {
    position: "relative",
    display: "block",
    border: "0.5px solid #c4c4c4",
    borderRadius: "4px",
    height: "100px",
    overflow: "auto",
    width: "70vw",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  chip: {
    float: "left",
    marginLeft: "10px",
    marginTop: "10px",
  },
  phoneInputContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "0.25rem",
  },
  phoneCountryCodeDropdown: {
    minWidth: "120px",
    fontSize: "14px",
  },
  phoneNumberErrorText: {
    color: "red",
  },
}));
