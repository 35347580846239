import { Table, Button, Typography, Empty } from "antd";
import { popOverComponent } from "container/JobRequests/components/PopOverComponent/PopOverComponent";
import UploadIcon from "@mui/icons-material/Upload";
import { formatTime, getDateFromUTC } from "Utils/DisplayTimeFormat";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JobRequestActions } from "../actions";
import { IReportFile, IReportRow } from "../Interfaces/JobRequestInterfaces";
import { IFiles } from "../JobRequestDetails.interfaces";
import { FORM_DATA_KEY } from "../JobRequestDetails/jobRequest.constants";
import { FileOperationActions } from "container/common/FileOperations/actions";
import moment from "moment";
import { REPORTS_SUPPORTED_UPLOAD_FILE_TYPES, REPORTS_TABLE_CONSTANTS } from "../Constants/constants";
import { LoaderActions } from "components/Spinner/actions";
import { Spinner } from "components/Spinner";
import { validateUploadedFiles, handleShowNotification } from "shared/handler/fileUpload/fileUpload.handler";
import { FileTag } from "./FileTag";


const { Column } = Table;
const { Text } = Typography;

export const Reports = () => {
  const dispatch = useDispatch();
  const [selectedReportId, setSelectedReportId] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [existingReportFiles, setExistingReportFiles] = useState<IReportFile[]>([]);
  const uploadFileRef = useRef<HTMLInputElement | null>(null);
  const { isLoading } = useSelector((state: any) => state.LoaderReducers);
  const { allCustomReportsData, } = useSelector((state: any) => state.JobRequestReducers);
  const { description, createdAt, documents, reports, uploadReports, organization } = REPORTS_TABLE_CONSTANTS;
  const { selectedProject } = useSelector((state: any) => state.ClientRequestReducer);

  const handleResetStates = () => {
    setIsUploading(false);
    setSelectedReportId("");
    setExistingReportFiles([]);
  };

  useEffect(() => {
    return (() => {
      dispatch(JobRequestActions.resetCustomReports());
    })
  }, []);
  
  useEffect(() => {
    dispatch(LoaderActions.setIsLoading(true));
    dispatch(JobRequestActions.getAllCustomReports(`?requestType=custom_report&projectId=${selectedProject?.id}`)).then((resp: any) => {
      dispatch(LoaderActions.setIsLoading(false));
    });
  }, [selectedProject]);

  const handleOnClickUploadButton = (row: IReportRow) => {
    setSelectedReportId(row._id);
    setExistingReportFiles(row.reports);
    uploadFileRef?.current?.click();
  };

  const handleUpdateReport = (reportsPayload: { reports: IReportFile[] }) => {
    const reportId = selectedReportId;
    dispatch(JobRequestActions.updateClientRequest({ ...reportsPayload, id: reportId, requestType: 'custom_report' })).then((resp: any) => {
      if (resp?.status === "SUCCESS") {
        handleResetStates();
        handleShowNotification("success", "AUTO_CLOSE", {
          message: "Reports Successfully Uploaded",
          description: "",
        });
        dispatch(JobRequestActions.getAllCustomReports(`?requestType=custom_report&projectId=${selectedProject?.id}`));
      }
    });
  };

  const getPermanentFilesAndUpdateReports = (formData: FormData) => {
    setIsUploading(true);
    dispatch(FileOperationActions.uploadFileToPermanentFolder(formData)).then((res: IFiles[]) => {
      // prepare payload for update-report
      // payload must contain only that data(reports), which needs to be updated
      const newReportFiles = res?.map((fileObj) => ({
        name: fileObj?.name,
        date: moment().toISOString(),
        version: "v1",
        file: { ...fileObj },
      }));

      const reportsPayload = {
        reports: existingReportFiles.concat(newReportFiles),
      };

      handleUpdateReport(reportsPayload); // update report;
    });
  };

  const handleUploadReports = (e: any) => {
    const newFiles: any[] = Object.values(e.target.files);
    const validFiles = validateUploadedFiles({
      newFiles,
      existingFiles: existingReportFiles,
      supportedFileTypes: REPORTS_SUPPORTED_UPLOAD_FILE_TYPES,
      errCallback: () => {
        setIsUploading(false); // when error occurs - stop showing loader
      }
    })

    if (validFiles?.length > 0) {
      const formData = new FormData();
      for (const file of validFiles) {
        formData.append(FORM_DATA_KEY, file);
      }
      getPermanentFilesAndUpdateReports(formData);
    }
  };

  const renderTitle = (title: string) => <Text strong>{title}</Text>;


  if (isLoading) {
    return <Spinner />;
  } else if (!allCustomReportsData && !allCustomReportsData?.length) {
    return <Empty description="No Reports Present" />;
  }

  return (
    <>
      {allCustomReportsData?.length > 0 && (
        <>
          <Table
            sticky
            pagination={false}
            scroll={{ y: 450 }}
            dataSource={allCustomReportsData}
          >
            <Column
              width={"15%"}
              title={renderTitle(createdAt.title)}
              dataIndex={createdAt.keyName}
              key={createdAt.keyName}
              render={(createdAt: string) => <>{createdAt ? formatTime(createdAt, "YYYY-MM-DD HH:mm:ss") : "-"}</>}
            />
            <Column
              width={"13%"}
              title={renderTitle(description.title)}
              dataIndex={description.keyName}
              key={description.keyName}
              render={(text: string) => popOverComponent(text)}
            />
            <Column
              width={"25%"}
              title={renderTitle(documents.title)}
              dataIndex={documents.keyName}
              key={documents.keyName}
              render={(documents: IReportFile[]) => <FileTag docs={documents} type="CLIENT_DOCUMENTS" />}
            />
            <Column
              width={"25%"}
              title={renderTitle(reports.title)}
              dataIndex={reports.keyName}
              key={reports.keyName}
              render={(reports: IReportFile[]) => <FileTag docs={reports} type="ADMIN_REPORTS" />}
            />
            <Column
              width={"10%"}
              key={uploadReports.keyName}
              title={renderTitle(uploadReports.title)}
              render={(row: IReportRow) => (
                <Button
                  disabled={isUploading}
                  loading={row._id === selectedReportId && isUploading}
                  size="large"
                  shape="circle"
                  onClick={() => {
                    handleOnClickUploadButton(row);
                  }}
                  icon={<UploadIcon color="primary" style={{ fontSize: "1.5rem" }} />}
                />
              )}
            />
          </Table>
          <input hidden multiple type="file" ref={uploadFileRef} onInput={(e) => handleUploadReports(e)} />
        </>
      )}
    </>
  );
};
