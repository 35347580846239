import { Typography } from "antd";
import { PopOverContainer } from "./PopOverComponent.styles";

const { Paragraph } = Typography;

export const popOverComponent = (text: string) => {
  if (!text) {
    return <>-</>;
  };

  const renderTooltip = (text: string) => {
    return <PopOverContainer>{text}</PopOverContainer>;
  };

  return <Paragraph ellipsis={{ tooltip: renderTooltip(text) }}>{text}</Paragraph>;
};
